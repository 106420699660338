import React, { useState, useEffect, useRef } from 'react';

//import 'whatwg-fetch';

import packageJson from '../package.json';
global.appVersion = packageJson.version;

//useInterval hook
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// version from `meta.json` - first param
// version in bundle file - second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const CacheBuster = () => {

  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(true);

  const refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...')
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  }

  const testVersion = async () => {
    try {
      const response = await fetch('/meta.json');
      if(response) {
        var meta = await response.json();
      }
    } catch (err) {
      return;
    }

    if(meta) {
      const latestVersion = meta.version;
      const currentVersion = global.appVersion;

      const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
      if (shouldForceRefresh) {
        console.log(`New version - ${latestVersion}. Should force refresh`);
        //this.setState({ loading: false, isLatestVersion: false });
        setLoading(false);
        setIsLatestVersion(false);
      } else {
        //console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
        setLoading(false);
        setIsLatestVersion(true);
      }
    }
  }

  useEffect(()=>{testVersion()}, []);

  useInterval(()=> {
    testVersion()
  }, 600000);

  //useEffect(()=>{testVersion()}, [activepane]);

  //const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
  //return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });

  //if (loading) return null;
  if (!loading && !isLatestVersion) {
    // You can decide how and when you want to force reload
    refreshCacheAndReload();
  }
  return (
    <button className="cb" onClick={testVersion}>v{global.appVersion}</button>
  )

};

export default CacheBuster;