export default `
#####WELCOME TO YOUR HOMEPAGE
Whats New?

- Complete sections in the order that suits you.
- Add photo's and notes to detail any issues identified.
- No internet access onsite? No problem. You only need a connection to download
- Manage multiple sites with a single login. Share access to your reports with coworkers.
- View historic reports.
`;
