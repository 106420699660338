/*
Function for storing tree structures for Firesmart
Bluebird
*/

import React from 'react';
import PouchDB from 'pouchdb';
import PouchDBUpsert from 'pouchdb-upsert';
//import workerPouch from 'worker-pouch';
//const worker = require('worker-pouch');

//import 'whatwg-fetch';

import {
	isArray,
	ensureArray
}
from './FSUtils';
//import { subParser } from 'showdown';


//const db='https://deichen.uk:15984';
/*let subsite = 'alpha';

if(window.location.hostname.includes('alpha')) subsite='alpha';
else if(window.location.hostname.includes('beta')) subsite='beta';

const db=`https://${['db', subsite].join('.')}.firesmart.co.uk`;*/

const dbserver = process.env.REACT_APP_DB_SERVER;
const adminserver = process.env.REACT_APP_ADMIN_SERVER;
const appversion = process.env.REACT_APP_VERSION;
const db = dbserver;

const cuid = require('cuid');

//const domainparser = require('tld-extract');
//const $ = require('jquery');


//!const hash = require('object-hash');

//Deep object copying
//const extend = require('jquery-extend'); //Depth predictable - not required?

//PouchDB
//const PouchDB = require('pouchdb');
//PouchDB.plugin(require('pouchdb-authentication'));
//require('pouchdb-seamless-auth')(PouchDB);
//!PouchDB.plugin(require('pouchdb-quick-search'));
//!PouchDB.plugin(require('pouchdb-upsert'));
//PouchDB.plugin(require('pouchdb-find'));

window.PouchDB = PouchDB;

PouchDB.plugin(PouchDBUpsert);

/*const workerPouch = require('worker-pouch');

PouchDB.adapter('worker', workerPouch);*/

//const wpad = {};

/*
workerPouch.isSupportedBrowser().then(supported => {
  if (supported) {
    console.log("Workers supported");
    wpad.adapter="worker";
  }
}).catch(console.log.bind(console)); // shouldn't throw an error


/*
IOS problem
*/

PouchDB.plugin(require('pouchdb-authentication'));


let userDB_remote = false;
let userDB = false;
let userDB_sync = false;
let userDB_changes = false;
let user_logout=false;
//const siteDB = new PouchDB('site');

//let SiteID = "";
let siteDB_remote = false;
let siteDB = false;
let siteDB_sync = false;
let siteDB_changes = false;


let localDB = new PouchDB('local', {skip_setup: true});

//Search data for top search bar
let searchData = [];

//import {flatten} from './FSUtils';

/*

PouchDB database:
- User - all within _user --no!
-- User info
-- Organisation
-- 
- Site
-- Risks
-- Equipment
-- Areas

*/

// utf8 to latin1
const toHex = str => {
    var s = unescape(encodeURIComponent(str));
    var h = '';
    for (var i = 0; i < s.length; i++) {
        h += s.charCodeAt(i).toString(16);
    }
    return h;
}

/* Get standard name for user database */
const userdbname = email => 'userdb-'+toHex(email);


//!Store useremail to local storage



//const isLoggedIn = () => db.get()

//const saveUseremail = email => {useremail=email; return localDB.put({_id: '_local/useremail', email}, {force: true})};

/**
 * Save/Load local store data
 * @param {string} name 
 * @param {*} value 
 */
const localData = async (name, value, suppresserror) => {

  if(value===undefined) {
    let doc;

    try {
      doc = await localDB.get(`_local/${name}`);
    } catch (err) {
      //console.log("localData error (get): ", name, value, err);
      return;
    }

    if(doc) return doc[name];
  }
  else {
    let out;

    try {
      out = await localDB.upsert(`_local/${name}`, doc=>({[name]: value}));
    } catch(err) {
      //console.log("localData error (upsert): ", name, value, err);

      if(!suppresserror) {
        if(user_logout) user_logout('Logged out by another window');
        else userlogout('localData');
      }

      return;
    }
    return out;
  }
}


let useremail = false;
let userpassword = false;

//const saveUseremail = email => {useremail=email; return localDB.upsert('_local/useremail', doc=>({email}))};
const saveUseremail = email => {useremail=email; return localData('useremail', email)};

//const saveUseremail = email => {useremail=email; return email};
//const loadUseremail = () => localDB.get('_local/useremail').then(doc=>{useremail=doc.email; return doc.email});
const loadUseremail = () => localData('useremail').then(ue=>{useremail=ue; return ue});

const email = () => useremail;


//Logged in username


/**
 * Get/set position
 * @param {*} pos 
 */
const pos = ({siteid=null, sitename=null, view=null, activepane=null, surveyid=null, section=null, id=null, itemid=null} = {}, suppresserror) => {
  //console.log("pos: ", {siteid, sitename, view, activepane, surveyid, section, id, itemid});
  return localData("pos", siteid!==null?{siteid, sitename, view, activepane, surveyid, section, id, itemid}:undefined, suppresserror);
}

const clearPos = () => {
  try {
    return pos({siteid: false}, true);
  } catch (err) {

  }
}


/*
watch one or more ids
*/
const registerChangeWatcher = (func, id, watchers, reserve) => { //, push
  const ids=ensureArray(id);

  ids.forEach(id=>{
    watchers[id]=(watchers[id]?watchers[id].concat(func):[func]);

    //If there's a cached doc for this id, run function immediately
    if(watchers["_cache_"+id]) func(watchers["_cache_"+id]);

    //Can only reserve one function per ID; reserved functions are restored after wiping watchers due to Site change
    if(reserve) {
      watchers["_reserved"] = {...watchers["_reserved"], [id]: [func]};
    }
  })
}

/*
  Runs updates to watchers of a given ID any time a document with the given root ID changes
*/
const registerRootWatcher = (func, id, rootid, watchers) => {
  const wid="_root_"+rootid;

  const f=doc=>
    func(rootid)
    .then(docs=>({_id: id, [id]: docs}))
    .catch(err=>{console.log("Root Watchers function err!",err)});

  watchers[wid]=(watchers[wid]?watchers[wid].concat(f):[f]);

  f()
  .then(rdoc=>{
    watchers["_cache_"+rdoc._id]={[rdoc._id]: rdoc[rdoc._id]};
    (watchers[rdoc._id] || []).forEach(f=>typeof f==="function" && f({[rdoc._id]: rdoc[rdoc._id]}));
  });
}

/*
Run watchers where doc id matches
doc = incoming doc
watchers = object containing list of watcher arrays by document id
cache = if true, keep a copy of the doc and pass it immediately to any new watchers
*/
const updateChangeWatchers = (docin, watchers, cache, reserve) => {

  //console.log("updateChangeWatchers watchers: ", docin, watchers);

  var doc;

  if(docin && typeof docin==='function') {
    doc = docin(watchers);

    if(doc===false) return;
    //console.log("uCW fupdate: ", doc);
  }
  else doc=docin;

  const d={ values: doc.values, uservalues: doc.uservalues, elements: doc.elements, _attachments: doc._attachments, attdata: doc.attdata, reports: doc.reports, user: doc.user, when: doc.when };
  const { _id, ...df } = doc; //Full doc except _id

  //Execute any root watchers and pass cache to update watchers
  if(doc.rootid && watchers["_root_"+doc.rootid]) {

    watchers["_root_"+doc.rootid].forEach(f=>{
      typeof f==="function" && f(df).then(rdoc=>{
        if(rdoc) {
          //watchers["_cache_"+rdoc._id]=rdoc;
          //(watchers[rdoc._id] || []).forEach(f=>typeof f==="function" && f(rdoc));
          watchers["_cache_"+rdoc._id]={[rdoc._id]: rdoc[rdoc._id]};
          (watchers[rdoc._id] || []).forEach(f=>typeof f==="function" && f({[rdoc._id]: rdoc[rdoc._id]}));
        }
      })
    });
  }

  if(cache) watchers["_cache_"+doc._id]=df;

  //Run watcher functions; pass full doc if cache==true
  if(watchers[doc._id]) {
    //watchers[doc._id].forEach(f=>typeof f==="function" && f(cache?df:d));

    for(const f of watchers[doc._id]) {
      if(typeof f==="function") f(cache?df:d);
    }
  }

  if(reserve) {
    watchers["_reserved"] = Object.assign({}, watchers["_reserved"], {["_cache_"+doc._id]: df});
  }
}

const setShow = (id, params, watchers) => {
  //console.log("setShow: ", id, params, watchers);

  if(watchers[id] && isArray(watchers[id])) {
    watchers[id].forEach(f=>{
      if(typeof f==="function") f({rshow: params});
    })
  }
}

//Forgiving sync cancel
//!Doesn't wait for cancellation(??)
const synccancel = async dbsync => {
  if(dbsync) {
    try {
    if(dbsync.cancel && typeof dbsync.cancel==="function") return dbsync.cancel();
    else if(dbsync.sync && dbsync.sync.cancel && typeof dbsync.sync.cancel==="function") return dbsync.sync.cancel();
    } catch (err) {
      console.log("synccancel error: ", err);
    }
  }
}

/**
 * Sync local user database to server
 * @param {*} email 
 * @param {*} password 
 */
const userSync = async (email, password, logout) => {

  //console.log("userSync: ", email, password, logout, runfrom);

  if(logout && !user_logout) user_logout = logout;

  if(!(userDB && userDB.sync)) {
    //console.log("userSync: No user DB: ", userDB, userDB.sync);
    return false;
  }

  if(userDB) await synccancel(userDB);
  //if(userDB_sync) await synccancel(userDB_sync);
  //if(userDB_remote) await synccancel(userDB_remote);

  //if(!userDB_remote) userDB_remote = new PouchDB(db+'/'+userdbname(email), {skip_setup: true, heartbeat: true}, { fetch: (url, opts)=>PouchDB.fetch(url, opts, {credentials : "include"}) });
  if(!userDB_remote) userDB_remote = new PouchDB(db+'/'+(userDB.name), {skip_setup: true, heartbeat: true}, { fetch: (url, opts)=>PouchDB.fetch(url, opts, {credentials : "include"}) });

  //userDB_remote = new PouchDB(db+'/'+userdbname(email),{skip_setup: true});

  //let login;

  if(password) {
    var login = await userDB_remote.logIn(email, password);
  }

  const sinfo = await userDB_remote.getSession();

  //console.log("sinfo: ", sinfo);

  if(((sinfo || {}).userCtx || {}).name===null) {
    console.log("userSync error - cannot log user in: ", email, password, login, sinfo);

    throw Error('Login timed out - Please log in');
    //user_logout('User logged out - please log in', false); //!Force user logout?
  }

  if(!(userDB && userDB.sync)) return false;

  return userDB?.sync(userDB_remote)
  .on('complete', info=>{
    
    //console.log("Initial / catch-up sync complete");

    userDB_sync = userDB.sync(userDB_remote, {
      live: true,
      retry: true
    }).on('paused', info=> {
      //useremail=email;
      userpassword=password;
      return ("User logged in");
    }).on('error', err=> {
      console.log("Sync error: ", err);
      //throw Error(err);
      if(user_logout) user_logout('You have been logged out - please log back in', false); // user_logout('Live user sync error: '+ err.message);
      else userlogout('userSync 1');
    });
  })
  .on('error', err=> {
    console.log("Sync set-up error: ", err);
    //throw Error(err);
    //throw Error(err);
    if(user_logout) user_logout('You have been logged out - please log back in', false); //user_logout('User sync error: '+ err.message);
    else userlogout('userSync 2');
  })
}

const cancelUserSync = async () => {
  //synccancel(userDB);
  synccancel(userDB_sync);
}

/* Log user into CouchDB */
const userlogin = async (email, password, logout) => {

    if(!email) return false;

    //if(userDB_sync) synccancel(userDB_sync);
    //if(userDB_changes) synccancel(userDB_changes);
    if(userDB) await synccancel(userDB);
    if(userDB_remote) {
      await synccancel(userDB_remote);
      userDB_remote = undefined;
    }
    
    if(logout) user_logout=logout;
    
    //userDB_remote = new PouchDB(db+'/'+userdbname(email), {skip_setup: true, auth: {username: email, password: password}});
    //userDB_remote = new PouchDB(db+'/'+userdbname(email), {skip_setup: true});S

    saveUseremail(email);

    //console.log("userDB_remote: ", userDB_remote);

    userDB = new PouchDB(userdbname(email), {skip_setup: true}); //, {adapter: 'worker'} IOS problem!

    const watchers={};

    userDB_changes={
      sync: userDB.changes({
        live: true,
        retry: true, //Was commented out??
        since: 'now',
        include_docs: true
      }).on('change', change=>{
        if(change.doc) {
          updateChangeWatchers(change.doc, watchers);
        }
      }),
      registerChangeWatcher: (func, id) => registerChangeWatcher(func, id, watchers),
      updateChangeWatchers: (doc, cache) => updateChangeWatchers(doc, watchers, cache)
    }

    console.log("Userlogin...");

    //Has database previously sync'd?
    try {

      await userDB.get('organisation');

    } catch (err) {
      console.log("Userlogin err:", err);
      return false;
    }

    console.log("Previously sync'd");
    return true;

    //console.log("User login: ", login);
    //Should check for login.ok (?)

};

const userlogout = async (fr) => {
  console.log("Logging out...", fr);

  //saveUseremail(""); //Retain for now

  //if(!keeplocal) {
    try {
    await localDB.destroy();
    localDB = new PouchDB('local', {skip_setup: true});
    }
    catch (err) {}
  //}

  await clearPos();

  await synccancel(siteDB_sync);
  await synccancel(userDB_sync);
  await synccancel(siteDB_changes);
  await synccancel(userDB_changes);
  if(siteDB) await synccancel(siteDB);
  if(userDB) await synccancel(userDB);
  if(siteDB_remote) await synccancel(siteDB_remote);
  if(userDB_remote) await synccancel(userDB_remote);

  user_logout=false;

  //userDB_remote.logOut();
  //siteDB_remote.logOut();
  
  userDB=false;
  //userDB_remote=false;
  //SiteID="";
  siteDB=false;
  siteDB_remote=false;

  useremail=false;
  userpassword=false;

  //localData("pos", ''); //Need to remove from hard logout

  return "Logged out";
}


//const purgeLogout = () =>

const trac = () => {
  const ad = (email() || '').split('@').pop().toLowerCase();
  return (ad && ['trilogygroup.com','firesmart.co.uk','test.com'].includes(ad));
}

/**
 * Check if user is a super user
 */
const isSuperUser = async () => {
  if(trac()) return true;

  const su = await siteDB.get('superusers');
  return (((su || {}).superusers || []).includes(email()));
}

const setUserPassword = password => {userpassword = password}

const siteDBchangesLive = (watchers) => { //editon
  siteDB_changes = {
    sync: siteDB.changes({
      live: true,
      /*retry: true,*/
      since: 'now',
      include_docs: true
    }).on('change', change=>{
      //console.log("Site Change! ",change);
      if(change.doc) {
        updateChangeWatchers(change.doc, watchers)
      }
    }),
    //editon: ()=>editon.editon,
    registerChangeWatcher: (func, id, reserve)=> registerChangeWatcher(func, id, watchers, reserve),
    registerRootWatcher: (func, id, rootid)=> registerRootWatcher(func, id, rootid, watchers),
    updateChangeWatchers: (doc, cache, reserve) => updateChangeWatchers(doc, watchers, cache, reserve),
    resetWatchers: () => {watchers={...watchers._reserved, _reserved: watchers._reserved}},
    //edit: edit => setEdit(edit, editon, watchers),
    rShow: (id, params) => setShow(id, params, watchers)
  }
}

const siteSync = async (sitename, syncUpdate, syncStart, syncComplete, logout) => {

  //console.log("Site sync... ", sitename);

  if(logout && !user_logout) user_logout = (logoutreason, keeplocal=false) => {clearPos(); logout(logoutreason, keeplocal=false)};

  if(!(siteDB && siteDB.sync)) return false;

  if(siteDB) await synccancel(siteDB);

  //if(!siteDB_remote) siteDB_remote = new PouchDB(db+'/'+sitename, {skip_setup: true, heartbeat: true}, { fetch: (url, opts)=>PouchDB.fetch(url, opts, {credentials : "include"}) });

  if(!siteDB_remote) siteDB_remote = new PouchDB(db+'/'+(siteDB.name), {skip_setup: true, heartbeat: true}, { fetch: (url, opts)=>PouchDB.fetch(url, opts, {credentials : "include"}) });

  syncUpdate('pull','Syncing docs...');

  /*if(userpassword) {

    await siteDB_remote.logIn(useremail, userpassword);
  }*/

  var sinfo = await siteDB_remote.getSession();
  //console.log("Site sinfo: ", sinfo);

  if(((sinfo || {}).userCtx || {}).name===null) {

    //Attempt login is userpassword set

    if(userpassword) {
      await siteDB_remote.logIn(useremail, userpassword);
      sinfo = await siteDB_remote.getSession();
    }
    
    if(((sinfo || {}).userCtx || {}).name===null) {
      if(user_logout) user_logout("Site sync failure - please log in again", true);
      else {
        userlogout('siteSync 1'); //?
        //throw new Error('Cannot sync site - User not logged in');
      }
    }
  }

  var docssyncd=0;
  const ds = async change => {
    if(change?.change?.docs) docssyncd+=change.change.docs.length;
    //console.log("Docs sync'd: ", docssyncd);
    syncUpdate('pull',`Syncing docs... [${docssyncd}]`);
  }

  //if(syncStart && typeof syncStart==='function') syncStart();

  if(!(siteDB && siteDB.sync)) return false;
  
  return siteDB.sync(siteDB_remote, {open_revs: 'all'}) //, {batch_size: 500}
  .on('active', info=>{
    if(syncStart && typeof syncStart==='function') syncStart();
  })
  .on('change', change=>{
    //console.log("change: ", change);
    ds(change);
  })
  .on('complete', info=> {

    if(syncComplete && typeof syncComplete==='function') syncComplete();

    //console.log("Initial / catch up site sync complete"); //, info);
    syncUpdate('pull',`R:${info.pull.docs_read}/W:${info.pull.docs_written}`);
    syncUpdate('push',`R:${info.push.docs_read}/W:${info.push.docs_written}`);

    //SiteID = sitename;

    if(!(siteDB && siteDB.sync)) return false;

    siteDB_sync = siteDB.sync(siteDB_remote, {
      live: true,
      retry: true
    })
    .on('active', info=>{
      if(syncStart && typeof syncStart==='function') syncStart();
    })
    .on('change', change=>{
      //console.log("OpenSiteDB change: ",change);
      syncUpdate(change.direction);
    })
    .on('paused', info=> {
      //console.log("Site DB sync paused", info);
      syncUpdate("pause");
      if(syncComplete && typeof syncComplete==='function') syncComplete();
      
      return ("Site connected");		
    })
    .on('error', err=> {
      console.log("Site Sync error!", err);
      //logout();
      //throw Error(err);

      if(user_logout) user_logout('You have been logged out - please log back in', true); // user_logout('Live site Sync error: '+ err.message);
      else userlogout('sitesync 2');
    });
  })
  .on('error', err=> {
    console.log("Error Syncing Site: ", err);
    //throw Error(err);
    if(user_logout) user_logout('You have been logged out - please log back in', true); // user_logout('General site sync error: '+ err.message);
    else userlogout('sitesync 3');
  })

}

const cancelSiteSync = async () => {
  synccancel(siteDB_sync);
  synccancel(siteDB_remote);
  //synccancel(siteDB);
}

//Connect to CouchDB Site database
const openSiteDB = async (sitename, logout) => {
    //Remote User database
    
    if(logout && !user_logout) user_logout = (logoutreason, keeplocal=false) => {clearPos(); logout(logoutreason, keeplocal=false)};

    //Reset search bar data
    searchData=[];

    //synccancel(siteDB_sync);
    //synccancel(siteDB_changes);
    if(siteDB) synccancel(siteDB);
    if(siteDB_remote) {
      synccancel(siteDB_remote);
      //userDB_remote = undefined;
      siteDB_remote = undefined;
    }

    //siteDB_remote = new PouchDB(db+'/'+sitename, {skip_setup: true, auth: {username: useremail, password: userpassword}});

    /*try {
      //Compact remote DB
      var com = await siteDB_remote.compact();

      console.log("Compact: ", com);
    }
    catch (err) {
      console.log("Compact / purge failed: ", err);
    }*/

    siteDB = new PouchDB(sitename, {auto_compaction: true, revs_limit: 10, skip_setup: true}); //, {adapter: 'worker'} IOS problem!S // 

    let watchers = {};
    //const editon={editon: false};

    siteDBchangesLive(watchers);

    //!Skip if logged in?

 

    /*.on('change', change=>{
      //console.log("SiteDB Sync Change");
    });*/

    //resolve("Site connected");

    //Has database previously sync'd?
    try {
      await siteDB.get('site');
    } catch (err) {
      return false;
    }

    return true;
};

//Add a watcher to SiteDB changes (for use outside of Containers)
//const addSiteChangeWatcher = (func, id, reserve) => siteDB_changes.registerChangeWatcher(func,id, reserve);

//Reset SiteDB watchers
const resetSiteWatchers = () => siteDB_changes.resetWatchers();

//Add current timestamp (user/time) to a doc
//const timeStamp = (doc={}, prefix='') => Object.assign({}, doc,{[prefix+'user']: useremail, [prefix+'when']: Date.now()})
const timeStamp = (doc={}, prefix='') => ({ ...doc, [prefix+'user']: useremail, [prefix+'when']: Date.now() });

//Standard id
const newStandardId = (type, id) => (type?type+"-":"")+(id || cuid());

//Create a new item in the standard format
const newItem = (objin={}) => {
  const id=objin._id || newStandardId(objin.type);
  return Object.assign(
    {},
    timeStamp(timeStamp({_id: id, rootid: id, parentid: "", type:"", elements: [], values: {}}, "created_")), 
    objin
  );
}

//console.log("newItem: ",newItem({_id: "blah", type:"moo"}));

const surveyRootId = (type, surveyid) => (isArray(type)?type[0].type:type)+"-"+surveyid;

const idFromSurveyRoot = (rootid) => rootid.split('-').pop(); //??Changed to pop from [1]

//Create a new Survey (ie Risks, Equipment, Areas)
const addSurvey = async (surveyid, types) => {
  const elements = types.map(type=>newItem({_id: surveyRootId(type,surveyid), type: type[0].type, when: 0}));

  try {
    return siteDB.bulkDocs(
      [].concat(
        newItem({_id: surveyRootId("survey",surveyid), type: "Survey", elements: elements.map(el=>el._id)}),
        elements
      )
    )
  } catch (err) {
    console.log("Add survey error: ", err);
  }

}

/*
  Hierarchical get of entire survey
  Must replace:
  - created_user
  - created_when
  - user
  - when
  - _id
  - elements ids
  - parentid
  - rootid
  - delete _rev
*/

const hget = async ids => {
  if(!ids) return;

  const idlist = [].concat(...ids).filter(id=>id); //Ensure array + collapse array of arrays + filter blank ids

  //console.log("hget: ", ids, idlist);

  if(idlist.length===0) return;

  const getitems = await siteDB.allDocs({ //Get Audit children
    include_docs: true,
    keys: idlist,
    attachments: true
  });

  const items = (getitems.rows || []).map(row=>row.doc && row.doc.type && row.doc).filter(doc=>doc);
  const kids = await hget(items.map(item=>item.elements));

  return items.concat(kids).filter(i=>i);

  //.then(items=>[].concat(items).map(item=>item && item.doc).concat(hget([].concat(items).map(item=>item && item.doc && item.doc.elements))));
}

//Remove any missing children (from "elements" array) that are not listed with an _id
const removeMissingChildren = docs => docs.map(d=>({...d, elements: d.elements.filter(e=>docs.find(ad=>ad._id===e))}));

//Fix parent ids
const fixParentIds = docs => {
  const getparent = {};

  docs.forEach(doc=>{
    if(doc.elements && doc.elements.length) {
      doc.elements.forEach(el=>{
        getparent[el] = doc._id;
      })
    }
  })

  return docs.map(doc=>{
    if(doc._id===doc.rootid) return doc;

    const parentid=getparent[doc._id];
    if(doc.parentid!==parentid) {
      const nd = Object.assign({}, doc, {parentid});
      //console.log("Parent ID fix: ", doc, nd);
      return nd;
    }

    return doc;
  })
}

const doType = type => (typeof type==="object")?type.type:type;

//Always run removeMissingChildren *after* this
const removeDeadTypes = (docs, types) => {

  //Build type/parenttype/roottype list
  const typelist = [].concat(...types.map(tg=>{
    const roottype=tg[0].type;

    return [{type: roottype, parenttype: "", roottype}].concat(...tg.map(ty=>{
      const parenttype=ty.type;

      return [].concat(...ty.childTypes.map(type=>({ type: doType(type), parenttype, roottype })));
    }))
  }));

  //console.log({typelist});

  //Build id: type object
  const typeById={};
  docs.forEach(doc=>{
    typeById[doc._id]=doc.type;
  });

  //Check each doc is of a type and that its parent and root are of a type that exists in the typelist
  return docs.map(doc=>{
    const typecheck = typelist.find(tl=>{
      const typecheck = tl.type===doc.type;
      const parentcheck = (!doc.parentid) || tl.parenttype===typeById[doc.parentid];
      const rootcheck = (doc._id === doc.rootid) || tl.roottype===typeById[doc.rootid];

      return typecheck && parentcheck && rootcheck;
    });
    
    if(typecheck) return doc;
    else {
      console.log("Dropping doc: ", doc);
      return null
    }

  }).filter(doc=>doc);
}

//Update IDs on a list of items
const reid = (items, rootcuid, rootparent) => {
  const idlist = {}; //list of renamed ids
  //const rootcuid=cuid(); //param?

  return items
    .map((item, i)=>{ //Re-id items
      const newid = (item.type==="Assessment" || (rootparent && !i))?rootcuid:newStandardId(item.type, item._id===item.rootid?rootcuid:false);

      idlist[item._id] = newid;

      //Include original ID(s)
      return newItem({
        _id: newid, 
        rootid: item.rootid, 
        parentid: ((rootparent && !i)?rootparent:item.parentid), 
        type: item.type, 
        elements: item.elements, 
        values: item.values, //item.type==="Assessment" && assname?Object.assign({}, item.values, {name: assname}):
        oldids: [].concat(item._id, item.oldids || []),
        when: 0,
        user: '',
        ...(item._attachments && {_attachments: item._attachments})
      })
    })
    .map(item=>{

      //Transpose categories
      /*const categories = item.type==="SurveySection"
        ?((item.values || {}).categories || []).map(c=>idlist[c])
        :[]; */
      const categories = (item.values || {}).categories
        ?((item.values || {}).categories || []).map(c=>idlist[c])
        :[];

      //Transpose Precautions
      const precautionid = item.type==="Precaution" || item.type==="ExtinguisherPrecation"
        ?idlist[(item.values || {}).precautionsid]
        :null;

      //Remove actions from ActionSummary (they will be rebuilt by the app)
      const asitems = item.type==="ActionSummary"
        ?{_escaperoutes: [], _riskactions: [], _precautionmeasures: [] }
        :null

      //Add categories / precautions to items if relevant
      /*const newitem = (categories.length || precautionid || asitems)
        ?{...item, values: Object.assign({}, item.values, (categories.length?{categories}:{}), (precautionid?{precautionid}:{}), (asitems?asitems:{}))}
        :item;
      */

      //if(item.type==="ActionSummary") console.log("AS item: ", Object.assign({}, ...(asitems?asitems:{})) );

      //const newitem = item;

      return ( //Re-id elements, parentids, rootids
      {
        ...item,

        ...((categories.length || precautionid || asitems)
          ?{values: Object.assign({}, item.values, (categories.length?{categories}:{}), (precautionid?{precautionid}:{}), (asitems?asitems:{}))}
          : {}
        ),

        rootid: item.rootid==="site"
          ?"site"
          :rootparent
            ?item.rootid
            :idlist[item.rootid], 
        elements: item.elements.map(el=>idlist[el]), 
        parentid: idlist[item.parentid] || item.parentid
      })}
    )
}

const cloneSurvey = async (newsid, surveyid, types) => {
  const items = await hget(types.map(type=>surveyRootId(type, surveyid)));
  const itemsp = fixParentIds(items); //fix broken parent IDs
  const itemsnd = removeDeadTypes(itemsp, types); //remove any items with a dead type
  const itemsmc = removeMissingChildren(itemsnd);
  const reiditems = reid(itemsmc, newsid);

  return siteDB.bulkDocs(reiditems);
}

//!!  Add licence number: /sites/getLicenceNumber
/**
 * Import survey items supplied as array
 * @param {*} newsid 
 * @param {*} items 
 */
const importSurvey = (newsid, items, rootparent, noreid=false) => {
  //console.log("Import survey: ", newsid, items, rootparent, noreid);

  //no reid - must strip _rev

  return siteDB.bulkDocs(
    noreid
      ?items.map(d=>{
        const {_rev, ...docout} = d;
        return docout;
      })
      :reid(items, newsid, rootparent)
    );
}

const deleteSurvey = async (surveyid, types) => {
  const items = await hget(types.map(type=>surveyRootId(type, surveyid)));

  const delItems = items.map(item=>({_id: item._id, _rev: item._rev, _deleted: true}));

  return siteDB.bulkDocs(delItems);

  //console.log("deleteSurvey Items: ", items, delItems);
}

const downloadSurvey = async (surveyid, types) => {

  console.log("downloadSurvey: ", surveyid, types);

  const items = await hget(types.map(type=>surveyRootId(type, surveyid))); //get items
  const itemsp = fixParentIds(items); //fix broken parent IDs
  const itemsnd = removeDeadTypes(itemsp, types); //remove any items with a dead type
  const itemsmc = removeMissingChildren(itemsnd); //remove missing children from elements lists
  const gsurveyhead = await siteDB.get(surveyid, {attachments: true}); //get base survey data

  const surveyhead = { ...gsurveyhead, exported: Date.now() };

  const name = surveyhead?.values?.name || '';

  const ass = [].concat(surveyhead, itemsmc);

  let link=document.createElement('a');
  link.setAttribute('href', URL.createObjectURL( new Blob([JSON.stringify(ass)], {type: 'text/json'}) ) );
  link.setAttribute('download',`Firesmart_Survey_${name}-${surveyid}.json`);
  link.click();
}

/**
 * Clone a risk
 * @param {*} id 
 * @param {*} types 
 */
const cloneRisk = async (id, newparent, move) => {

  if(move) {
    try {
      const item = await siteDB.get(id);

      if(!item) return false;

      const parentid = item.parentid;

      if(!parentid) return false;

      await dbdeleteChild(siteDB, parentid, id);

      await dbattachChild(siteDB, newparent, id);

    } catch (err) {

      console.log("Failed to move Risk: ", err);
      return false;
    }

    return true;
  }


  const items = await hget([id]); //get items
  //const itemsp = fixParentIds(items); //fix broken parent IDs
  //const itemsnd = removeDeadTypes(items, types); //remove any items with a dead type
  const itemsmc = removeMissingChildren(items); //remove missing children from elements lists

  const itemsNoOldIds = itemsmc.map(el=>({...el, oldids: []})); //Clear oldids array

  const roottype = itemsNoOldIds[0].type;

  const newsid = newStandardId(roottype);

  const np = newparent || itemsNoOldIds[0].parentid;

  //console.log("CloneRisk: ", id, np, move);

  try {
    await importSurvey(newsid, itemsNoOldIds, np); //import new elements

    //await siteDB.upsert(np, doc=>({...timeStamp(doc), elements: [...(doc.elements || []), newsid] })); //Add item to parent
    await dbattachChild(siteDB, np, newsid);

    //Delete original
    ///if(move) await dbdeleteElement(siteDB, id);

  } catch (err) {
    console.log("Failed to clone risk: ", err);
    return false;
  }

  return true;

}

/* Get a list of risks */
/*const getSelectedRisks = rootid =>
  new Promise((resolve, reject) => {
    siteDB.get(rootid)
    .then(rootdoc=>{
      if(!rootdoc || !rootdoc.elements || !rootdoc.elements.length) return resolve([]);

      siteDB.allDocs({
        include_docs: true,
        keys: rootdoc.elements
      }).then(secdocs=>{ //Survey sections
        Promise.all(secdocs.rows.map(secdoc=> //Get risks within each survey section
          siteDB.allDocs({
            include_docs: true,
            keys: secdoc.doc.elements
          })
        ))
        .then(riskdocs=>{
          const risklist=secdocs.rows.map((secdoc,sdi)=>{ //Return object with section and risks where risk has been clicked
            const risks=riskdocs[sdi].rows.filter(risk=>risk.doc.values.risk==="2");
            return risks.length>0?
              {id: secdoc.doc._id, section: secdoc.doc.values.name, risks: risks.map(risk=>({id: risk.id, name: risk.doc.values.name}))}
              :undefined
          }).filter(sec=>sec); //Remove null elements in array

          return resolve(risklist);
        })
        .catch(err=>reject(err));
      }).catch(err=>{
        console.log(err);
        return reject(err);
      });
    })
    .catch(err=>reject(err));
  });
*/

/*
Get hierarchy of key information working down from a node id
Both parameters and arrays
!!Could be more efficient if Elements are grouped and batch-fetched
*/
/*const getHierarchy = (ids,types=null,skiproot=false) => 
  new Promise((resolve, reject) => {
    if(!(Array.isArray(ids) && ids.length) || !siteDB) return resolve([]);
    siteDB.allDocs({
      include_docs: true,
      keys: ids
    })
    .then(docs=>{

      Promise.all(docs.rows.map(doc=>{
        const d = doc && doc.doc && doc.doc.elements?doc.doc.elements:[];
        return getHierarchy(d, types);
      }))
      .then(children=>{

        const c=docs.rows.map((doc,dno)=>(
          doc.doc===null?null: //Account for deleted docs
          {
            id: doc.doc._id, 
            rootid: doc.doc.rootid,
            name: doc.doc.values.name,
            questiontext: doc.doc.values.questiontext,
            icon: doc.doc.values.icon,
            type: doc.doc.type,
            values: doc.doc.values,
            user: doc.doc.user,
            when: doc.doc.when,
            _attachments: doc.doc._attachments,
            children: children[dno]?children[dno].filter(child=>(child && (types===null || types.indexOf(child.type)>-1))):[]
          }
        )).filter(d=>d);

        //console.log("hierarchy ",ids,docs,children,c);

        return resolve(skiproot && Array.isArray(c) && c.length?c[0]:c);
      })
      .catch(err=>{
        console.log("getHierarchy error: ",err,ids,types,skiproot,docs);
        return resolve([]);
      })
    })
    .catch(err=>{
      console.log("getHierarchy Error ",err,ids,types,skiproot);
      return reject(err);
    })
  })
*/

const getHierarchy = async (ids,types=null,skiproot=false) => {

    if(!(isArray(ids) && ids.length) || !siteDB) return [];

    const docs = await siteDB.allDocs({
      include_docs: true,
      keys: ids
    })

    //Get a list of all children of returned docs
    const gc = [].concat(...docs.rows.map(doc=>(doc && doc.doc && doc.doc.elements) || []));

    const children = await getHierarchy(gc, types);

    const c=docs.rows.map((rdoc,dno)=>{
      const doc = rdoc.doc;

      if(doc===null) return null;

      const out = {
        id: doc._id, 
        rootid: doc.rootid,
        parentid: doc.parentid,
        name: doc.values.name,
        questiontext: doc.values.questiontext,
        icon: doc.values.icon,
        type: doc.type,
        values: doc.values,
        user: doc.user,
        when: doc.when,
        _attachments: doc._attachments,
        children: children.filter(child=>child && doc.elements.includes(child.id) && (types===null || types.includes(child.type))).map(c=>({...c, grandparentid: doc.parentid}))
      };

      return out;
    }).filter(d=>d);

    const cout = (skiproot && isArray(c) && c.length?c[0]:c)

    return cout;

}

/*
Get a list of Operations
*/
/*const getCategories = rootid => 
  new Promise((resolve, reject) => {
    getHierarchy(["Categories-"+rootid],['Category','EscapeArea'])
    .then(c=>resolve(Array.isArray(c) && c.length && c[0].children?c[0].children:[]))
  })
  */

//const getCategories = rootid => getHierarchy([rootid],['Category','EscapeArea'],true);

//const getRisks = rootid => getHierarchy(["RiskSurvey-"+rootid],['SurveySection','Risk'], true);

/*const getLocations = rootid => {
  //var start = new Date().getTime();

  const l = getHierarchy([rootid],['Building','Level','Room'], true);

  //var end = new Date().getTime();

  //console.log("getLocations time: ", end-start);

  return l;
}
*/

//Get completed / check for every section (exclude and hidden by Category selection)
const getSections = async (rootid, sectiontypelist) => {

  try {
    const rid = idFromSurveyRoot(rootid);

    if(!(isArray(sectiontypelist) && sectiontypelist.length) || !siteDB) return [];

    const panedocs = await siteDB.allDocs({
      include_docs: true,
      keys: sectiontypelist.map(t=>`${t[0]?.type}-${rid}`)
    })

    if(!panedocs?.rows) return false;

    //Get pane info by id
    const panes = {};
    sectiontypelist.forEach((s,i)=>{
      //console.log("pd: ", panedocs.rows[i]);

      panedocs.rows[i].doc.elements.forEach(el=>{
        panes[el] = s[0].pane;
      })
    })

    const sectiondocs = await siteDB.allDocs({
      include_docs: true,
      keys: (panedocs.rows.map(p=>p?.doc.elements).flat())
    })

    if(!sectiondocs?.rows) return false;

    const sectionsok = sectiondocs.rows.map(d=>{
      const doc = d?.doc;

      if(!doc) return false;

      const sectionok = doc.values?.sectionok || doc.values?.cathide || false;

      if(sectionok) return false;

      return ({
        id: doc._id,
        parentid: doc.parentid,
        pane: panes[doc._id],
        name: doc.values?.name,
        icon: doc.values?.icon,
        sectionok,
        titlepath: [],
        title: 'Incomplete Section',
        questiontext: `Please complete your ${doc.values?.name} section`,
        priority: 1,
        riskident: '',
        section: doc._id,
        measure: {},
        sectioncheck: !sectionok
      })
    }).filter(s=>s);

    return sectionsok;

  } catch(err) {
    console.log("getSections error: ", err);

    return false;
  }

}

/*const categoryshow = (catlist, surveycats) => {
  if (
    surveycats && 
    isArray(surveycats) && 
    surveycats.length &&
    catlist &&
    catlist.children &&
    catlist.children.length
    ) {
      const inplacecats = catlist.children.filter(mc=>mc.values.inplace); //list of check categories
      if(inplacecats.length===0) return true; //If no categories are checked, show everything
      return inplacecats.filter(mc=>surveycats.indexOf(mc.id)>-1).length; //Else only show if *this* category is ticked
    }
  return true;
}*/

//!Optimise
/*const categoryshow = (catlist, surveycats) => {
  if (
    surveycats && 
    isArray(surveycats) && 
    surveycats.length &&
    catlist &&
    catlist.children &&
    catlist.children.length
    ) {
      let inplacecats = catlist.children.filter(mc=>(mc.values || {}).inplace); //list of check categories

      //Check for default category
      if(inplacecats.length===0) inplacecats = catlist.children.filter(mc=>(mc.values || {}).default);

      if(inplacecats.length===0) {
        return true; //If no categories are checked, show everything
      }
      return inplacecats.filter(mc=>surveycats.indexOf(mc.id)>-1).length; //Else only show if *this* category is ticked
    }
  return true;
}*/

const categoryshow = (catlist, surveycats, id) => {

  if (
    surveycats && 
    catlist &&
    isArray(surveycats) && 
    surveycats.length &&
    catlist.children &&
    catlist.children.length
    ) {
      var def;
      var inplace=false;

      //let inplacecats = catlist.children.filter(mc=>mc.values?.inplace); //list of check categories
      const inp = catlist.children.some(mc=>{
        if(mc.values?.inplace) {
          if(surveycats.includes(mc.id)) return true;
          else inplace = true;
        }
        else if(mc.values?.default) def = mc.id;
        
        return false;
      })

      if(inp) return true;
      if(inplace) return false;
      if(def && surveycats.includes(def)) return true;

      return false;

      //Check for default category
      //if(inplacecats.length===0) inplacecats = [catlist.children.find(mc=>mc.values?.default)];

      //if(inplacecats.length===0) out = true; //If no categories are checked and no default, show everything
      //else out = inplacecats.find(mc=>surveycats.indexOf(mc.id)>-1); //Else only show if *this* category is ticked
    }

  return true;
}

//!!! Need to add rows of seating
/*const getLiveRisks = (categories, risks) => 
  risks && risks.children && categories?
    Object.assign({},risks,{
      children: 
        risks.children
          .filter(section=>categoryshow(categories, section.values.categories)) //Remove hidden categories
          .map(section=>section && section.children?Object.assign({},section,{
            children: section.children.filter(risk=>risk && risk.values && risk.values.risk==="2")
          }):section)
          .filter(section=>section && section.children && section.children.length)
    })
  :risks;
*/


/* Get a list of Equipment */
const getPrecautions = rootid => getHierarchy([rootid],['SurveySection','Equipment','Type'],true);
//! Should only show live equipment

/* Get a list of Equipment (Fire Precaution) Measures at any level (except Audits)*/
const getPrecautionMeasures = rootid => getHierarchy([rootid],['SurveySection','ExtinguisherSection','Equipment','Measure','Item' /*,'Audit'*/ ,'Type'],true);

/* Get a list of Fire Extinguisher Categories (EG "A") */
const getExtinguisherCategories = eh => 
  //new Promise((resolve, reject) => {
    {
      if(!isArray(eh) || !eh[0] || !isArray(eh[0].children) || !eh[0].children[0] || !eh[0].children[0].values || !isArray(eh[0].children[0].values.extcategories)) return []; //return resolve([]);

      const ext=eh[0].children[0].values.extcategories.map(e=>({id: e.id, name: e.title, children: []}));

      //return resolve({id: eh[0].id, children: ext})
      return {id: eh[0].id, children: ext};
    }
    //.catch(err=>reject(err));
  //});

/*
const getEscapeRouteRoot = rootid =>
    getHierarchy(["EquipmentSurvey-"+rootid],['EscapeRouteSection'])
    .then(eh=>Array.isArray(eh) && eh[0] && Array.isArray(eh[0].children) && eh[0].children[0] && eh[0].children[0]._id)
*/

/* Get a list of Extinguishers */
const getExtinguisherList = rootid => getHierarchy([rootid],['ExtinguisherSection','ExtinguisherType','Extinguisher']);

/*
const getExtinguishers = ex => {
  //new Promise((resolve, reject) => {
    //getExtinguisherList(rootid)
    //.then(ex=>{

      if(!ex.length || !ex[0].children || !ex[0].children[0] || !ex[0].children[0].values) return []; //return resolve([]);

      const ext=ex[0].children[0];
      
      const {extcategories} = ext.values;
      const types=ext.children;

      return ({id: ex[0].id, name: "", children: !extcategories?[]:extcategories.map(c=>
        ({id:c.id, name: c.title, description: c.description, levelreq: c.levelreq, formulae:c.formulae, children: c.ratings && c.ratings.length
          ?c.ratings.map(ra=>
            ({id:ra.id, name:ra.rating, coverage: ra.coverage, coveragel: ra.coveragel, children: [].concat.apply([],types.map(ex=>
              ex.children.filter(aex=>aex.values && aex.values.ratings && aex.values.ratings.find(r=>r && r.rid===ra.id && ex.values.extcategories.indexOf(r.cid)>-1)).map(aext=>
                ({id:aext.id, name: aext.name+": "+aext.values.ratings.filter(r=>r && ex.values.extcategories.indexOf(r.cid)>-1).map(rt=>"["+rt.ctitle+": "+rt.rating+"]").join(" ")+(aext.values.weight?" "+aext.values.weight+"KG":"")+(aext.values.volume?" "+aext.values.volume+"L":""), values: aext.values, children: []})
            )))})
          ).filter(r=>r.children.length)
          :[].concat.apply([],ext.children.map(ex=>
            ex.values && ex.values.extcategories && ex.values.extcategories.find(cat=>cat===c.id) && ex.children.map(aext=>
              ({id:aext.id, name: aext.name+": ["+c.title+"]"+(aext.values.weight?" "+aext.values.weight+"KG":"")+(aext.values.volume?" "+aext.values.volume+"L":""), values: aext.values, children: []})
            ))).filter(r=>r)
        })
      )})
    //})
    //.catch(err=>reject(err));
  }
*/

//const getEscapeRoutes = rootid => getHierarchy([rootid],['EscapeRouteSection','EnclosedArea','OpenPlanArea','EscapeRoute','EscapePoint'], true);

/* Measures from Risks pane */
const getRiskActions = rootid => getHierarchy([rootid],['SurveySection','Risk','Measure','PrecautionMeasure','Item' /*,'Audit'*/ ], true);

const getLicenceActions = rootid => getHierarchy([rootid],['LicenseSurvey','License'], true);

/* Measures from Areas pane */
//const getAreaActions = rootid => getHierarchy(["AreaSurvey-"+rootid],['Building','OutdoorArea','Basement','Level','Room','Corridor','AreaRisk','AreaEscapeRoute','AreaEquipment','AreaExtinguisher','Audit','Measure'], true);

//const isin = (thing, list) => list.indexOf(thing)>-1;

//Estracts Risks from Area and classifies them by impact area
//Works from getAreaActions to save processing
/*const getAreaRisks = (actions) => 
{

  if(actions && actions.type && actions.type==="AreaSurvey" && actions.children && Array.isArray(actions.children) && actions.children.length) {
    
    const risks=[];
    const precautions=[];
    const places=[];

    const riskadd=(risk, building, level, room)=> {
      if(risk) {
        if(risk.values && risk.values.data && (risk.values.data.risklevel || risk.values.data.escapeareatype)) {
          const s=risk.values.data.riskscope;
          const riskdata={id: risk.id, name: risk.name, type: risk.type, values: risk.values && risk.values.data, riskhere: s>0};

          if(room) riskdata["room"]=room.id;
          riskdata["level"]=level.id;
          if(s>1) riskdata["building"]=building.id;
          if(s>2) riskdata["site"]=true;

          risks.push(riskdata);
        }
      }
      else {
        if(room) places.push({room: room.id, level: level.id, building: building.id});
        else places.push({level: level.id, building: building.id});
      }
    }

    const precautionadd=(precaution, building, level, room)=> {
      if(precaution.values) {
        const precautiondata={id: precaution.id, name: precaution.name, type: precaution.type, values: precaution.values};

        if(room) precautiondata["room"]=room.id;
        if(level) precautiondata["level"]=level.id;
        precautions.push(precautiondata);
      }
    }

    actions.children.forEach(b=>{
      if(b.type==="Building") {
        b.children.forEach(l=>{

          riskadd(null,b,l);

          l.children.forEach(lc=>{
            //Level children could include Rooms/Corridors and Risks (and other junk)

            if(lc.type==="AreaRisk") {
              riskadd(lc, b, l);
            }
            else if(['AreaEscapeRoute','AreaExtinguisher','AreaEquipment'].indexOf(lc.type)>-1) {
              precautionadd(lc, b, l);
            }
            else if(lc.type==="Room" || lc.type==="Corridor") {
              riskadd(null, b, l, lc);

              lc.children.forEach(rmc=>{
                if(rmc && rmc.type==="AreaRisk") {
                  riskadd(rmc, b, l, lc);
                }
                else if(rmc && ['AreaEscapeRoute','AreaExtinguisher','AreaEquipment'].indexOf(rmc.type)>-1) {
                  precautionadd(rmc, b, l, lc);
                }
              })
            }
          })
        })
      }
    });

    //console.log("risks/places", actions, risks, places);

    risks.forEach(risk=>{
      places.forEach(place=>{
        if(risk.site) place.risks=(place.risks || []).concat(risk);
        else if(risk.room && place.room && place.room===risk.room) place.risks=(place.risks || []).concat(risk);
        else if(risk.level && place.level && place.level===risk.level) place.risks=(place.risks || []).concat(risk);
        else if(risk.building && place.building && place.building===risk.building) place.risks=(place.risks || []).concat(risk);
      })
    })

    precautions.forEach(precaution=>{
      places.forEach(place=>{
        if(precaution.level && place.level && !place.room && place.level===precaution.level) place.precautions=(place.precautions || []).concat(precaution);
      })
    })

    //console.log("places: ",places);

    return places;
  }
  else return null;
}
*/

//get parent id of a given item
//const getParentId = id => siteDB.get(id).then(item=>item.parentid);

//!!Do not delete (yet) - not sure if required
/* Return Audit(s) for a given id */
/*const getAudits = (id, parent) =>
  new Promise((resolve, reject) => {

    //console.log("getAudits:: ",id, parent);

    if(!siteDB) return reject(new Error("Site Database disconnected"));

    siteDB.get(id)
    .then(item=>item && item.type==="Type" && item.parentid?siteDB.get(item.parentid):item) //Move one step up hierarchy if item is Type
    .then(item=>{
      if(!siteDB) return reject(new Error("Site Database disconnected"));
      if(!item) return resolve([]);

      //console.log("getAudits::: ",id,item);

      siteDB.allDocs({
        include_docs: true,
        keys: item.elements
      })
      .then(els=>{
        let audits=[];

        if((els && els.rows && els.rows.length)) {
          audits=audits.concat(els.rows.filter(a=>a.doc.type==="Audit").map(a=>({id: a.doc._id, name: a.doc.values.name}))); //.map(a=({a.doc.id, a.doc.values.name})));
        }
        //console.log("getAudits: ", id, parent,item,els);

        //Also get audit(s) at parent level
        if(parent!==false && item.parentid) {
          getAudits(item.parentid, false)
          .then(paudits=>resolve(audits.concat(paudits)))
          .catch(err=>reject(err));
        }
        else return resolve(audits);
      })
      .catch(err=>reject(err));
    })
    .catch(err=>reject(err));
  })
*/

/* Clone an existing audit as a child of a given item */
/*const addAudit = (id, auditid) =>
  new Promise((resolve, reject) => {
    siteDB.get(id) //Get item doc
    .then(item=>{
      if(!item) return reject("Audit failed to retrieve parent document id: ",id);

      siteDB.get(auditid) //Get Audit root doc
      .then(ar=>{
        if(!(ar && ar.type==="Audit")) return reject("Item passed is not Audit");
        siteDB.allDocs({ //Get Audit children
          include_docs: true,
          keys: ar.elements
        })
        .then(ac=>{
          if(!(ac && ac.rows && ac.rows.length)) return reject("Audit check problem ", id, auditid, ac);

          const arn = Object.assign(timeStamp(timeStamp(ar, "created_")),{_id: ar.type+cuid(), parentid: item._id, _rev: undefined, rootid: item.rootid, sourceid: auditid});

          //Create array of new audit children
          const acn = ac.rows.map(a=>
            Object.assign(timeStamp(timeStamp(a.doc, "created_")),{_id: a.doc.type+cuid(), _rev: undefined, parentid: arn._id, rootid: item.rootid})
          );

          //Add children to new audit root doc
          Object.assign(arn, {elements: acn.map(child=>child._id)});

          siteDB.bulkDocs(acn.concat(arn))
          .then(res=>
            res?
              siteDB.upsert(id, doc=> Object.assign(timeStamp(doc), {elements: doc.elements.concat(arn._id)}))
              .then(res=>resolve(res))
              .catch(err=>reject(err))
            :reject("Audit bulkDocs failed")
          )
          .catch(err=>reject(err));
        })
        .catch(err=>reject(err));
      })
      .catch(err=>reject(err));
    })
    .catch(err=>reject(err));
  })
*/

/*
Get attachments of doc from SiteDB 
*/
const getAttachments = async docid => {
  if(siteDB) {
    const doc = await siteDB.get(docid, {attachments:true, binary: true});
    if(doc) return doc._attachments;
  }
}

//Close Action
/*const closeAction = id =>
  new Promise((resolve, reject) => {
    let parentid;

    siteDB.upsert(id, doc=> {
      Object.assign(doc.values, {'action': true, 'actionwhen':timeStamp(), 'closed': true, 'closedwhen': timeStamp()});
      doc=timeStamp(doc);
      parentid=doc.parentid;
      return doc;
    })
    .then(res=>{
      return resolve(parentid);
    })
    .catch(err=>{
      return reject(err);
    });
  })*/


const getAssessmentLoginDate = async id => {
  let ad;

  try {
    //ad = await siteDB.get(`AssessmentData-${id}`);
    ad = await siteDB.get(`SiteInfo-${id}`);
  } catch (err) {
    console.log("getAssessmentLoginDate error: ", err);
   }
  
  return (ad || {}).when;
}

const updateAssessmentLoginDate = async id => {

  try {
    //ad = await siteDB.get(`AssessmentData-${id}`);
    await dbupdateValue(siteDB, `SiteInfo-${id}`, {user: email()})
  } catch (err) {
    console.log("Failed to update login date");
    return false;
  }

  return true;
}


/*const dbupdateValue = async (db=siteDB, id, name, value) => {
  let parentid;

  await db.upsert(id, doc=> {
    if(!doc.values || isArray(doc.values)) {
      //console.log("Adding values...");
      Object.assign(doc,{values: {}});
    }
    if(typeof name==="object") Object.assign(doc.values, name);
    else doc.values[name]=value;
    doc=timeStamp(doc);
    parentid=doc.parentid;

    return doc;
  })

  return parentid;
}*/

/**
 * 
 * @param {*} db Database
 * @param {*} id Doc id
 * @param {*} name Name of property
 * @param {*} value value of property
 * @param {*} quiet if true, don't set user / when
 */
const dbupdateValue = async (db=siteDB, id, name, value, quiet=false) => {
  
  let parentid;
  const nameobject = typeof name==="object";
  const q = nameobject?value===true:quiet;

  //await
  try {
    await db.upsert(id, doc=> {
      parentid=doc.parentid;

      /*return timeStamp({
        ...doc, 
        values: (!doc.values || isArray(doc.values))
          ?{}
          :typeof name==="object"
            ?{ ...doc.values, ...name}
            :{ ...doc.values, [name]:value }
      })*/

      return ({
        ...doc,
        ...(q?{}:{user: useremail}), 
        ...(q?{}:{when: Date.now()}),
        values: nameobject
          ?{ ...(doc.values || {}), ...name}
          :{ ...(doc.values || {}), [name]:value }
      })
    })
  } catch(err) {
    if(appversion==="LOCAL") alert(`DB update error: ${err}`);
    if(user_logout) user_logout('You have been logged out due to an error updating the database - please log back in', false); //user_logout('User sync error: '+ err.message);
    else userlogout('dbupdateValue');
  }


  return parentid;
}

/*const dbaddChild = (db, parentid, type, values={}, id=newStandardId(type), position="end") =>
  new Promise((resolve, reject)=>{
   // console.log("dbAddChild id: ", id);

    db.get(parentid)
    .then(pdoc=>{
      db.put(newItem({_id:id, type: type, parentid: parentid, rootid: pdoc.rootid, values: values}))
      .then(res=>{
        db.upsert(pdoc._id, doc=> {
          doc.elements.splice((position==="end"?doc.elements.length:position),0,id);
          doc=timeStamp(doc);
          return doc;
        }).then(res=>resolve(id))
        .catch(err=>reject(err))
      });
    })
    .catch(err=>{
      console.log("Error adding child: "+err.message)
      return reject(err);
    });
  })*/


const dbaddChild = async (db, parentid, type, values={}, id=newStandardId(type), position="end", rootid) => {
  try { 
    //const pdoc = await db.get(parentid);
    await db.put(newItem({_id:id, type: type, parentid: parentid, rootid: rootid, values: values}))
    await db.upsert(parentid, doc=> {
      doc.elements.splice((position==="end"?doc.elements.length:position),0,id);
      doc=timeStamp(doc);
      return doc;
    })
  } catch(err) {
    console.log("Error adding child: ", err);
    throw Error(err);
  }

  return id;
}

/*
const dbaddItems = async (db, rootid, items=[]) => {
  try { 
    //const pdoc = await db.get(parentid);
    //await db.put(newItem({_id:id, type: type, parentid: parentid, rootid: rootid, values: values}))

    if(!(items.length)) return;

    const co=items.map(c=>newItem({rootid: rootid, ...c}));
    db.bulkDocs(co);

    await db.upsert(parentid, doc=> {
      //doc.elements.splice((position==="end"?doc.elements.length:position),0,id);
      doc.elements=(doc.elements || []).concat(items.map(c=>c._id));
      doc=timeStamp(doc);
      return doc;
    })
  } catch(err) {
    console.log("Error adding child: ", err);
    throw Error(err);
  }

  return id;
}
*/

//Add child with children
//Log Audit should contain necessary information to add a new row
/*const addItemWithChildren = async (db, parentid, rootid, child={}, grandChildren=[]) => {

  const childid = newStandardId(child.type);

  const grandChildrenItems = grandChildren.map(gc=>newItem({...gc, rootid: rootid, parentid: childid}));
  const childItem = newItem({...child, rootid: rootid, _id: childid, elements: grandChildrenItems.map(gc=>gc._id)});

  try {
  await db.bulkDocs(grandChildrenItems.concat(childItem));
  await db.upsert(parentid, doc=> timeStamp({...doc, elements: doc.elements.concat(childid)}));
  } catch(err) {
    console.log("Failed to addItemWithChildren: ", err);
    throw Error(err);
  }
}*/

/**
 * Fast clone to grandchild level. Can only copy within same parent.
 * @param {} db 
 * @param {*} parentid 
 * @param {*} rootid 
 * @param {*} childToCopyId 
 * @param {*} values 
 * @param {*} gcvalues 
 */
const dbcloneChild = async (db, parentid, rootid, childToCopyId, values={}, gcvalues={}) => {
  
  //console.log("Clone child id, values: ", childToCopyId, values);
  
  //Get child
  const child = await db.get(childToCopyId);

  if(!child) return false;

  //Get Grandchildren
  var grandChildren = [];

  //Get great grandchildren
  var greatGrandChildren = [];

  //Get Grandchildren
  if(child.elements) {
    var gc = await db.allDocs({
      include_docs: true,
      keys: child.elements
    });

    if(gc && gc.rows && gc.rows.length) {
      grandChildren = gc.rows.map(g=>g.doc)

      //Great grandchildren?
      const ggcids = [].concat(...grandChildren.map(c=>c.elements || []));

      //console.log("grandChildren, ggc: ", grandChildren, ggcids);

      if(ggcids.length) {
        var gcc = await db.allDocs({
          include_docs: true,
          keys: ggcids
        });

        greatGrandChildren = gcc.rows.map(g=>g.doc);
      }
    }
  }
  
  //console.log("child, grandChildren, greatGrandChildren: ", child, grandChildren, greatGrandChildren);

  const newId = newStandardId(child.type);

  var newGreatGrandchildren = [];

  //Create new grandchildren
  const newGrandChildren = grandChildren.map(gc=>{
      
      const newgcid = newStandardId(gc.type);

      //Great grandchildren
      const ggcs = greatGrandChildren.filter(ggc=>gc.elements.includes(ggc._id)).map(ggc=>(
        timeStamp(
          timeStamp(
            {...ggc, _id: newStandardId(ggc.type), _rev: undefined, parentid: newgcid, elements: []},
            "created_"
          )
        )
      ));

      newGreatGrandchildren = newGreatGrandchildren.concat(ggcs);
      //console.log("ggcs: ", ggcs);

      return timeStamp(
        timeStamp(
          {...gc, _id: newgcid, _rev: undefined, parentid: newId, elements: ggcs.length?ggcs.map(ggc=>ggc._id):[], values: {...(gc.values || {}), gcvalues} },
          "created_"
        )
      )
    }
  );

  //Create new child
  const newChild = 
    timeStamp(
      timeStamp(
        { ...child, _id: newId, _rev: undefined, parentid: parentid, elements: newGrandChildren.map(gc=>gc._id), values: {...(child.values || {}), ...values} },
        "created_"
      )
    );

  //console.log("new values: ", {...(child.values || {}), values});

  //console.log("New child, grandchildren: ", newChild, newGrandChildren);

  /*const bdout =*/ await db.bulkDocs(
    newGreatGrandchildren.concat(newGrandChildren, newChild)
  );

  //console.log("bdout: ", bdout);

  await dbattachChild(db, parentid, newId);

  return newId;
}



  /**
   * Add an existing child to a parent
   * !!Shoulg logout on fault
   * @param {*} db 
   * @param {*} parentid 
   * @param {*} childid 
   */
const dbattachChild = async (db, parentid, childid) => db.upsert(parentid, doc=>timeStamp({...doc, elements: [...(doc.elements || []), childid] } ));

//!Problem in some surveys?
const dbdeleteChild = (db, id, childid) =>
  db.upsert(id, doc=>{
    //console.log("dbdeleteChild: ", id, doc);

    if(doc.elements) doc.elements=doc.elements.filter(d=>d!==childid);
    else console.log("doc missing elements array: ", doc, id, childid);
    doc=timeStamp(doc);
    return doc;
  })
  .then(res=>"Child removed from parent")
  .catch(err=>{
    console.log("Failed to remove child from parent: ",err);
    return err;
  })

//Returns array of id + descendent ids
//!Should use bulk fetch
const dbfamily = (db, id) =>
  new Promise((resolve, reject) => 
    db.get(id)
    .then(doc=>{
      if(doc && doc.elements && doc.elements.length>0) {
        Promise.all(
          doc.elements.map(el=>dbfamily(db,el))
        ).then(out=>{
          const fo=[].concat.apply([], out).concat(doc);
          return resolve(fo);
        }).catch(err=>{
          return reject(err);
        });
      }
      else return resolve([doc]);
    })
    .catch(err=>{
      return reject(err);
    })
  )

//Deletes element, its children and any mention of it as a child in other elements
//!!Check order: should be: any mention in other elements, then item, then children
//Returns parentid
const dbdeleteElement = (db,id) =>
  new Promise((resolve, reject)=>{
    let parentid;

    //console.log("deleteElement: ",id);

    db.get(id)
    .then(doc=>{
      if(doc && doc.parentid) parentid=doc.parentid;
      //console.log("deleteElement: parentid: ",parentid)

      dbdeleteChild(db, parentid, id)
      .then(res=>{
        //console.log("doc: ",doc);
        dbfamily(db,id)
        .then(fam=>{
          //console.log("deleteElement fam: ",fam);
          Promise.all(
            fam.map(el=>
              db.upsert(el._id, doc=>{
                doc._deleted=true;
                return doc;
              })
            )
          ).then(res=>{
            return resolve(parentid);	
          }).catch(err=>{
            console.log("deleteElement error: ",err);
            return reject(err);
          });
        })
        .catch(err=>reject(err));	
      })
      .catch(err=>reject(err));
    })
    .catch(err=>{
      console.log("Failed to remove child from parent: ",err);
      reject(err);
    });
  });

//Change a child's position within the parent's Elements array
//id=elementid (parent found by search)
//direction= -int (up/left) or +int (down/right)
//Return parentid
const dbmoveElement = (db, id, direction) =>
  new Promise((resolve, reject) => {
    db.get(id)
    .then(c=>{
      db.upsert(c.parentid, doc=> {
        if(doc && doc.elements) {
          const ipos=doc.elements.indexOf(id);

          //prevent move if impossible
          const swel=doc.elements[ipos+direction];
          if(!swel) return resolve(false);

          const item=doc.elements[ipos];
          
          doc.elements[ipos]=swel;
          doc.elements[ipos+direction]=item;

          doc=timeStamp(doc);

          return doc;
        }
        else return reject(new Error("moveElement: No children to move"));
      })
      .then(res=>{
        return resolve(c.parentid);
      }).catch(err=>{
        console.log("moveElement failed: ",err);
        return reject(err);
      })
    })
    .catch(err=>{
      console.log("moveElement: Failed to retrieve parentid",err);
      return reject(err);
    })

  })

const updateSiteValue = (id, name, value) => {
  if(siteDB) {
    //console.log("updateSiteValue: ", id, name, value);

    return dbupdateValue(siteDB, id, name, value)
  }
}

const dbactions = (db, docid, types, rootid) => ({
  setChildValue: (childid, name, value) => dbupdateValue(db,childid,name,value),
  renameChild: (childid, value) => dbupdateValue(db,childid, "name", value),
  setValue: (name, value, quiet) => dbupdateValue(db,docid,name,value,quiet),
  addChild: (type,childid,values={},position) => dbaddChild(db, docid,type,Object.assign({name: (types.find((typestype => typestype.type===type) || {}).title || type).replace(/([a-z](?=[A-Z]))/g, '$1 ')}, values),childid,position, rootid),
  cloneChild: (childToCopyId, values) => dbcloneChild(db, docid, rootid, childToCopyId, values),
  attachChild: childid => dbattachChild(db, docid, childid),
  deleteChild: (childid) => dbdeleteElement(db,childid),
  deleteMe: ()=>dbdeleteElement(db,docid),
  moveMe: direction=>dbmoveElement(db, docid,direction),
  moveUp: ()=>dbmoveElement(db,docid,-1),
  moveDown: ()=>dbmoveElement(db,docid,1),
  renameMe: value => dbupdateValue(db,docid,"name", value),
  updateSite: (name, value) => dbupdateValue(db,"site",name,value) //Write to Site values
})

//Functions for adding/manipulating attachments
const dbattachments = (db,docid) => ({
  get: attid=> db && attid?db.getAttachment(docid, attid):getAttachments(docid),
  add: (attid, atttype, attdata, forceUpdate=false)=>
    db.upsert(docid, doc=> {
      if(!doc._attachments) doc._attachments={};

      doc._attachments[(doc._attachments[attid] && !forceUpdate)?cuid()+"_"+attid:attid] = {
        content_type: atttype,
        data: attdata
      };

      return doc;
    }),
  remove: attid=>
    db.upsert(docid, doc=>{
      if(doc._attachments) {
        if(attid) delete doc._attachments[attid];
        else delete doc._attachments;
      }
      return doc;
    }),
  replace: (attid, atttype, attdata, newid=`${cuid()}.jpg`) =>
    db.upsert(docid, doc=> {

      /*console.log("att rep: ", doc._attachments, attid);

      const {[attid]: omitted, ...atts} = doc._attachments || {};

      console.log("att rep atts: ", atts);*/

      return ({
        ...doc,
        _attachments: {
          //...atts,
          [newid]: {
            content_type: atttype,
            data: attdata
          }
        }
      })

      /*doc._attachments={
        [attid]: {
          content_type: atttype,
          data: attdata
        }
      };

      return doc;*/
    })
})


//Breakouts to allow external processes to interact with siteDB
const siteActions = docid => dbactions(siteDB, docid);

const siteAttachments = docid => dbattachments(siteDB,docid);


/**
 * Create full path data for elements
 */
let hierachy = {};
let specialtype = {};
const addToHierachy = (id, elements=[]) => {

  const parenthierachy = hierachy[id] || [id];

  elements.forEach(el=>{
    hierachy[el] = parenthierachy.concat(el);
  })
}
const getPath = (id) => {
  //console.log("Hierarchy, searchData: ", hierachy, searchData);

  //console.log("getPath: ", id, specialtype, hierachy[id], hierachy[id] && hierachy[id].map(hid=>specialtype[hid] || hid) );

  return hierachy[id] && hierachy[id].map(hid=>specialtype[hid] || hid); //.map(hid=>specialtype[hid] || hid);
}

/**
 * Search bar data
 */
//Which types to include in search bar
const searchTypes=['AssDataSection','CategorySection','LocationSection','LicensesSection','SurveySection', 'ExtinguisherCalculator','EscapeRouteSection','EmergencyEscapeSection','Risk','Equipment','Measure','PrecautionMeasure','Type'];
const subtypes=['Risk','Equipment'];
const subsubtypes=['Measure','PrecautionMeasure','Type'];

const specialTypes={
  'SiteInfo': 'assinfo',
  'RiskSurvey': 'risk',
  'EquipmentSurvey': 'equipment',
  'ActionSummary': 'actionsummary'
};

const addSearchData = (item, type) => {

  //console.log("addSearchData: ", item);

  const id=item._id, itemtype=item.type, text=(item.values || {}).name || type.title;

  if(specialTypes[itemtype]) specialtype[id] = specialTypes[itemtype];

  if(!searchTypes.includes(itemtype)) return;

  const newval = {value: id, name: `${subtypes.includes(itemtype)?'>> ':''}${subsubtypes.includes(itemtype)?'>>>> ':''}${text}`};

  const si = searchData.findIndex(v=>v.value===id);
  if(si>-1) searchData[si] = newval;
  else searchData = searchData.concat(newval);
}
const getSearchData = () => searchData;


/* Render item structure */
/*
types = javascript array of objects defining object hierarchy
rootid= Root id in the object hierarchy
db = pouchdb database

*/
const renderitems = async (types, rootid, db, dbsync, props) => {

  //console.log("Render Items: ", types, rootid, db, dbsync, props);

  //Gets childTypes from types for a given parent type
  //If the childType is an object, the parameters are bound onto the returned childType
  const getChildTypes = type => (
    types.find(item => item.type===type).childTypes.map(c=>
      typeof c.type==='undefined'?types.find(t=>t.type===c):Object.assign({},types.find(t=>t.type===c.type),c)
    )
  )

  //Creates a react Element from an item extracted from items
  const getElement = async (id,props={}) => {

    //console.log("getElement: ",id);

    //if(props) console.log("getElement props: ", id, props);

    if(!db) return false;

    try {
      const items = await db.allDocs({
        include_docs: true,
        keys: ensureArray(id)
      })

      return items && items.rows && items.rows.map(docitem=>{
        //console.log("getElement getItem: ", item);

        const item=docitem.doc;

        if(!item || !item.type) {
          console.log("Damaged item: ", id, docitem, item, props);
          return null;
        }

        const type=types.find(type => type.type===item.type);

        if(!type) {
          console.log("Child type not found! Type: ", item.type," id: ", item._id);
          return null;
        }

        addToHierachy(item._id, item.elements);

        addSearchData(item, type);

        let np={
          state: {
            _id: item._id,
            user: item.user,
            when: item.when,
            values: item.values,
            elements: item.elements,
            _attachments: item._attachments,
            attdata: item.attdata,
            //edit: dbsync.editon(),
            reports: item.reports
          },
          id: item._id,
          rootid: item.rootid,
          parentid: item.parentid,
          path: getPath(item._id),
          key: item._id,
          type: item.type,
          title: type.title,
          icon: type.icon,
          created: {
            user: item.created_user,
            when: item.created_when
          },
          actions: dbactions(db, item._id, types, item.rootid),
          childTypes: getChildTypes(item.type),
          attachments: dbattachments(db,item._id),
          registerChangeWatcher: (func, id) => dbsync.registerChangeWatcher(func, id || item._id, type.reserveWatcher),
          registerRootWatcher: dbsync.registerRootWatcher,
          updateChangeWatchers: dbsync.updateChangeWatchers,
          getElements: getElement,
          //setEdit: dbsync.edit,
          rShow: dbsync.rShow,
          ...props
        };

        return React.createElement(
          type.class, 
          np
        );
      }).filter(v=>v)
    } catch(err) {
      console.log("Error getting element: ", id, err);
      return [];
    }
  };

  //return getElement(getItem(rootid));
  
  let items=[];

  try {
    items = await getElement(rootid, props);
  } catch(err) {
    console.log("getElement error: ", err);
  }

  if(items && items.length===0) {
    const s = await addSurvey(idFromSurveyRoot(rootid),[types]);

    console.log("Survey Item Added: ", s, rootid, types);

    items = await getElement(rootid, props);
  }

  return items;
}

//Wrappers for renderitems to remove need to export database globals
/*
Returns a hierachy of React elements
*/
const userItems = (types,rootid,props,parentupdate) => {
  //console.log("userItems: ", userDB, userDB_changes);
  //console.log("userItems: ", types,rootid,userDB,userDB_changes,props,parentupdate);
  return renderitems(types,rootid,userDB,userDB_changes,props,parentupdate);
}

/*

*/
const siteItems = async (types,rootid,props,parentupdate) => {

  //console.log("siteItems props: ", props);

  if(!rootid) return;
  return await renderitems(types,rootid,siteDB,siteDB_changes,props,parentupdate);
}

/**
 * Fetch from / perform function in admin
 * @param {string} model eg 'sites'
 * @param {string} section eg 'create'
 * @param {object} fields
 */
const adminFetch = async (model, section, fields={}, method, dev) => {
  let response, json;

  /*const url = dev
    ?`http://localhost:3010/${model}${section?'/'+section:''}`
    :`https://${['admin',subsite].join('.')}.firesmart.co.uk/${model}${section?'/'+section:''}`;*/
  
  const url = `${adminserver}/${model}${section?'/'+section:''}`;

  try {
    response = await fetch(url,
      {
          mode: "cors", 
          cache: "no-cache", 
          method: method || "post",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(fields)
      }
    )
  }
  catch (err) {
    dev && console.log('adminFetch Error: ', err);
    throw new Error(err);
  }

  try {
    json = await response.json();
    dev && console.log("Json:", json);
  }
  catch (err) {
    console.log("Error: ", err, response);
    throw new Error('Unexpected response from server; ',response);
  }

  return json;
}

export {
  /*watchValue,*/
  /*updateWatch,*/
  /*resetWatchers,*/
  setUserPassword,
  localData,
  //addSiteChangeWatcher,
  resetSiteWatchers,
  timeStamp,
  loadUseremail,
  userlogin,
  userSync,
  cancelUserSync,
  siteSync,
  cancelSiteSync,
  userlogout,
  //trac,
  isSuperUser,
  openSiteDB,
  email,
  pos,
  clearPos,
  surveyRootId,
  idFromSurveyRoot,
  addSurvey,
  cloneSurvey,
  importSurvey,
  deleteSurvey,
  downloadSurvey,
  cloneRisk,
  //getCategories,
  //getSelectedRisks,
  //getRisks,
  //getLocations,
  categoryshow,
  //getLiveRisks,
  //getEscapeRouteRoot,
  getPrecautions,
  getPrecautionMeasures,
  getExtinguisherCategories,
  getExtinguisherList,
  //getExtinguishers,
  //getEscapeRoutes,
  getRiskActions,
  getLicenceActions,
  getSections,
  //getAreaActions,
  //getAreaRisks,
  //getParentId,
  //getAudits,
  //addAudit,
  getAssessmentLoginDate,
  updateAssessmentLoginDate,
  //closeAction,
  //getAttachments,
  updateSiteValue,
  siteActions,
  siteAttachments,
  getPath,
  getSearchData,
  userItems,
  siteItems,
  adminFetch
}