/*
Firesmart React+Bootstrap components
*/

import React, { Component, useState, useEffect, useRef } from 'react';

//import * as serviceWorker from './serviceWorker';

import {
  //selectElementContents,
  deselectAll,
  //resize64,
  resizeBlob,
  rotateBlob,
  fsicons,
  /*fsiconscols,*/
  humanFileSize,
  maxBlobSize,
  getHeight,
  arraysEqual,
  //isArray,
  ensureArray
}
  from './FSUtils';

import {
  getPath,
  getSearchData
}
  from './FSDataModel';

//import loading from './icons/loading.gif';

/* Markdown stuff */
import 'font-awesome/css/font-awesome.css';
//import 'easymde/dist/easymde.min.css';

import { Markdown } from 'react-showdown';

import SelectSearch from 'react-select-search';

//import SimpleMDE from "react-simplemde-editor";

import Editor from './Editor';

//import PinchZoomPan from "react-responsive-pinch-zoom-pan";

//import { Combobox } from 'react-widgets'

const cuid = require('cuid');

const math = require('mathjs');

//const SimpleMDE = require('simplemde');

//const EasyMDE = require('easymde');

const FSMarkdown = ({ markup='', options }) => {

  if(!markup) return null;

  return (
    <Markdown markup={markup} options={{ openLinksInNewWindow: true, noHeaderId: true, ...options }} className="fsmarkdown" />
  )
}

const Logo = () =>
  <div class="header-logo">
    <span class="logo">fire<span>smart</span></span>
  </div>


class MDModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || "",
      show: false,
      updated: false,
      getMarkdown: false,
      defaultValue: this.props.value || ""
    };

    this.id = this.props.id || cuid();
  }

  onChange = value => {
    this.setState({ value });
  }

  show = () => {
    this.setState({ show: true, defaultValue: this.state.value });
    if (this.props.onShow) this.props.onShow();
  }

  /*static getDerivedStateFromProps(newProps, oldState) {
    const out = (typeof newProps.value !== "undefined" && newProps.value !== oldState.value) ? { value: newProps.value, defaultValue: newProps.value } : null;
    return out;
  }*/

  componentDidUpdate(prevProps, prevState) {
    if(!this.state.show && this.props.value !== this.state.value) {
      this.setState({value: this.props.value, defaultValue: this.props.value});
    }
  }

  save = () => {
    this.setState({ show: false });

    const value = this.state.value; //this.getMarkdown();

    //console.log("Save: ", this.state.value);

    //if (this.props.edit) this.props.name ? this.props.onChange(this.props.name, this.state.value) : this.props.onChange(this.state.value);
    if (this.props.edit) this.props.name ? this.props.onChange(this.props.name, value) : this.props.onChange(value);
    if (this.props.onHide) this.props.onHide();
  }

  render() {

    return (
      <ButtonModal hidden={!(this.props.edit || (this.props.value && !this.props.inline))} hideButton={this.props.hideButton} vcenter={this.props.vcenter} className={this.props.className} scroll getShow={this.props.getShow} modalClassName="MDModal" icon={this.props.icon} buttonText={this.props.buttonText} title={this.props.title} onHide={this.save} onShow={this.show} showState={this.props.showState} small={this.props.small}>
        {this.props.edit && this.state.show
          //? <MDE id={"mde" + this.id} value={this.state.value} onChange={this.onChange} options={{ autoDownloadFontAwesome: false }} show={this.state.show} />
          //? <SimpleMDE id={"mde" + this.id} value={this.state.value} onChange={this.onChange} options={{ autoDownloadFontAwesome: false }} />
          ? <Editor value={this.state.defaultValue || ''} onChange={this.onChange} />
          : this.props.value ? <FSMarkdown markup={this.props.value} /> : null
        }
      </ButtonModal>
    )
  }
}

const HelpModal = props => (
  <MDModal className={"HelpModal " + (props.className || "")} small={props.small} hideButton={props.hideButton} icon="fs-help" vcenter buttonText={props.buttonText} title={props.title || 'Help'} getShow={props.getShow} id={props.id} edit={props.edit} name={props.name} onChange={props.onChange} value={props.value} onShow={props.onShow} onHide={props.onHide} showState={props.showState} />
)

//const InlineHelp = props => <div />

/*const ZoomImg = ({ alt, src }) => (
	<div style={{maxWidth: '100%'}}>
		<PinchZoomPan>
			<img alt={alt} src={src} />
		</PinchZoomPan>
	</div>
)*/

//Converter?

const InlineHelp = props => {

  const markup = props.collapsed ? (props.value || "").split("\n")[0] : props.value;

  return (
    props.value || props.edit ?
    <div className="InlineHelp">
      <MDModal inline icon="fs-help" title="Inline Help" buttonText="Inline" id={props.id} edit={props.edit} className={"InlineHelpButton " + (props.className || "")} name={props.name} onChange={props.onChange} value={props.value} />
      <div className="inlinehelp">
        {props.collapsed && props.expand && (!props.edit) 
        ?<button className="expander" onClick={props.expand} >
            <FSMarkdown markup={markup || ''} />
          </button>
        :<FSMarkdown markup={markup || ''} />}
      </div>
    </div>
    : null
  )
}

const Namer = ({ edit, name, value, defaultName, onChange, className }) => (
  edit
    ? <EditableA className={`ztitle form-control ${className || ''}`} name={name} onChange={onChange} editFixedOn={true}>
      {value || defaultName}
    </EditableA>
    : <div className={`ztitle form-control title ${className || ''}`} >{value || defaultName}</div>
)

const ReportIntro = props => (
  props.edit ?
    <div className="InlineHelp ReportIntro alert">
      <MDModal inline icon="fs-clipboard" title={props.title || "Report Introduction"} buttonText={props.buttonText || "Report Intro"} id={props.id} edit={props.edit} className={"InlineHelpButton " + (props.className || "")} name={props.name} onChange={props.onChange} value={props.value} />
      <FSMarkdown markup={props.collapsed ? (props.value || "").split("\n")[0] : props.value} />
    </div>
    : null
)

//BootStrap does this when Modals are shown, but it doesn't seem to do anything??
//const shadebody = shade => document.body.className=shade?"modal-open":"";

const InputGroup = props => <div className={"input-group " + (props.className || "")} data-toggle="buttons">{props.children}</div>

const FButton = props => {

  let press = false;

  if (props.addButton) return (
    <button
      className={`AddButton outlineButton ${props.className || ''}`}
      id={props.id}
      onClick={props.onClick}
      ref={props.ref}
    >
      <span className={props.icon || "fs-plus-circle"} /><div className="adbtitle">{props.children}</div>
    </button>
  )

  return (
    <button
      id={props.id}
      type={props.type || "button"}
      className={`btn FButton ${props.size || ''}${props.large ? ' large' : ''}${props.small ? ' small' : ''}${props.active ? ' active' : ''}${props.vcenter?' vcenter':''} ${props.className || ''}`}
      disabled={props.disabled}
      onClick={e => { if (props.onClick && typeof props.onClick === "function") { e.preventDefault(); e.stopPropagation(); props.onClick(e); } }}
      onMouseDown={e => { if (props.onMouseDown) props.onMouseDown(e); if (props.onLongPress && !e.button) press = window.setTimeout(() => { props.onLongPress(); return false; }, 1000) }}
      onMouseUp={e => { if (props.onMouseUp) props.onMouseUp(e); if (props.onLongPress) { window.clearTimeout(press); press = false; } }}
      style={props.style}
      ref={props.ref}
      title={props.title}
    >
      {!props.hideIcon && <span className="FButtonIcon">
        {props.icon && ensureArray(props.icon).map((ic, i) => <span key={i} className={ic} />)}
        {props.glyph || props.image}
        <span className={(props.drop ? " dropdown-toggle" : "")} />
      </span>}
      {props.children && !props.small ? <span className={props.noshrink ? null : "FButtonText"}> {props.children}</span> : null}
      {props.overlay ? <span className="FButtonOverlay">{props.overlay}</span> : null}
    </button>
  )
}

const ExpandButton = ({ expanded=false, onClick }) => (
  <FButton icon={expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={onClick} />
)

const UnlockButton = ({ show=false, unlocked=false, onClick }) => {
  const [unlocking, setUnlocking] = useState(false);

  const click = () => {
    setUnlocking(true);
    if(onClick) onClick();
  }

  useEffect(()=>{
    setUnlocking(false);
  }, [unlocked, setUnlocking])

  return (
    show
      ?unlocking
        ?<Loading title={false} />
        :<FButton icon={unlocked?"fs-unlock-alt":"fs-lock"} vcenter onClick={click} />
      :null
  )
}

//Icon button with cross/tick overlay 
const OnOffButton = ({ show=true, value=false, icon='fs-circle-o', onClick, children }) => {

  return (
    show
      ?<FButton icon={icon} vcenter onClick={onClick} overlay={<span className={value?'fs-check':'fs-times'} />}>{children}</FButton>
      :null
  )
}

/*
Standard buttons as used on most Container elements
*/
const StButtons = props => (
  typeof props.edit === "undefined" || props.edit ?
    <>
      {props.up !== false && <FButton icon={props.horizontal ? "fs-arrow-1-left" : "fs-arrow-1-up"} disabled={props.pos && props.pos.startpos} onClick={props.actions.moveUp} >Up</FButton>}
      {props.down !== false && <FButton icon={props.horizontal ? "fs-arrow-1-right" : "fs-arrow-1-down"} disabled={props.pos && props.pos.endpos} onClick={props.actions.moveDown} >Down</FButton>}
      {props.delete !== false && <SureButton icon="fs-trash" onClick={props.actions.deleteMe} buttonText="Delete">Delete?</SureButton>}
    </>
    : null
)

const AddChildButtons = props => (
  (props.childTypes || []).map(childType =>
    typeof props.edit === "undefined" || props.edit || childType.userAdd
      ? <FButton addButton={props.addButton} glyph="+" key={childType.type} icon={props.addButton ? undefined : childType.icon} disabled={props.disabled} onClick={e => { props.addChild(childType.type) }}>
        {props.addButton ? 'Add ' : ''}{childType.title}
      </FButton>
      : null
  ).filter(c => c)
)

const FInfo = props => (
  <div className={"FInfo " + (props.className || "") + (props.edit ? " edit" : "")} style={props.style}>
    <span className="FInfoMain">{props.children}</span>
    <span className={`FInfoTitle${props.titleLeft ? ' titleLeft' : ''}${props.titleRight ? ' titleRight' : ''}`}>{props.title}</span>
  </div>
)

const Loading = ({ className, style, title, vcenter }) => (
  <span className={`${className} ${vcenter || ''}`} style={style}>{title === false ? "" : "Loading... "}<span className="icon fs-spinner fs-pulse"></span></span>
)

const FormRow = props => (
  <div className={"form-group row " + (props.className || "")}>
    <label className="col-4 col-form-label">{props.label}</label>
    <div className="col-8">
      {props.children}
    </div>
  </div>
)

//const FormText = props => (<input type={props.type || "text"} name={props.name} autoComplete="off" className="form-control" value={props.value || ""} onChange={e=>props.getset(props.name, e.target.value)} />)

//const FormTextArea = props => (<textarea name={props.name} autoComplete="off" className="form-control" value={props.value || ""} onChange={e=>props.getset(props.name, e.target.value)} />)

class FormTextArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      active: false
    }

    this.updateTimeout = false;

    this.id = this.props.id || cuid();

    this.a = React.createRef();
  }

  blur = e => {
    this.setState({ active: false });
    this.update(e);
  }

  update = e => {
    window.clearTimeout(this.updateTimeout);
    if (this.state.value !== this.props.value) {
      if (this.props.name) this.props.update(this.props.name, this.state.value);
      else this.props.update(this.state.value);
    }
  }
  
  change = e => {
    const v = e.target.value;

    this.setState({ value: v }, () => this.props.live && this.update(e));

    this.expand(this.a.current);

    if (this.props.onChange) this.props.onChange(e);

    if (this.props.autoupdate) {
      if (this.updateTimeout) window.clearTimeout(this.updateTimeout);
      this.updateTimeout = window.setTimeout(() => {
        this.update();
      }, this.props.autoupdatedelay || 1000);
    }
  }
  
  select = e => {
    this.setState({ active: true });
    this.a.current.select();
  }

  selectexpand = e => {
    this.setState({ active: true });
    this.expand(this.a.current);
    //this.select(e);
  }

  /*this.expand = e => {
    const minRows = this.props.minRows || 3;
    this.a.current.rows = minRows;
    this.a.current.rows = Math.ceil((this.a.current.scrollHeight - this.a.current.baseScrollHeight) / 16) + minRows;

    console.log("Expand", this.a);
  }*/

  valueheight = () => {
    const vh = (this.state.value || this.props.value || '').split('\n').length;

    return vh;
  }

  expand = node => {
    if (node) {
      node.style.height = 0;

      const newheight = `${getHeight(node, this.props.rows || Math.max(this.valueheight(), 3)) + 5}px`;

      node.style.height = newheight;
    }
  }

  componentDidMount() {
    this.expand(this.a.current);
    if (this.props.autoFocus) this.select();
    if (this.props.value) this.setState({value: this.props.value});
  }

  //Deprecated!
	/*componentWillReceiveProps(newProps) {
		if(typeof newProps.value!=="undefined" && this.id !== document.activeElement.id && this.state.value!==newProps.value) this.setState({value:newProps.value});
		//if(typeof newProps.value!=="undefined") this.setState({value:newProps.value});
	}*/

  /*static getDerivedStateFromProps(newProps, oldState) {
    const ns = (typeof newProps.value !== "undefined" && !oldState.active && oldState.value !== newProps.value) ? ({ value: newProps.type && newProps.type === "number" && isNaN(newProps.value) ? '' : newProps.value }) : null;

    return ns;
  }*/

  componentDidUpdate(prevProps, prevState) {
    //console.log("FormText update: ", prevProps, this.props, prevState, this.state);
    /*if(typeof this.props.value!=='undefined' && this.props.value!==prevProps.value) { //&& !this.state.active
      this.setState({ value: String(this.props.value)});
    }*/
    if((this.props.value || this.props.value==='' || isNumeric(this.props.value)) && this.props.value!==prevProps.value) { //&& !this.state.active
      this.setState({ value: this.props.value});
    }
  }

  render() {

    return (
      <textarea
        ref={this.a}
        id={this.id}
        name={this.props.name}
        autoComplete={this.props.autoComplete ? "on" : "off"}
        autoFocus={this.props.autoFocus}
        className={"form-control " + (this.props.className || "")}
        value={this.state.value}
        onChange={this.change}
        placeholder={this.props.placeholder}
        onBlur={this.blur}
        onFocus={this.selectexpand}
        rows={this.props.rows || Math.max(this.valueheight() || 3)}
        style={this.props.style}
      />
    )
  }
}

class FormText extends FormTextArea {

  keyCheck = e => { if (e.keyCode === 13) { e.preventDefault(); this.update(e) } }

  select = e => {
    this.setState({ active: true });
    //this.a.select();
  }

  render() {
    return (
      <input
        ref={a => { this.a = a }}
        type={this.props.type || "text"}
        id={this.id}
        name={this.props.name}
        autoComplete={this.props.autoComplete ? "on" : "off"}
        autoFocus={this.props.autoFocus}
        className={(this.props.formcontrol === false ? "" : "form-control ") + (this.props.className || "")}
        value={this.state.value}
        onChange={this.change}
        onKeyDown={this.keyCheck}
        placeholder={this.props.placeholder}
        onBlur={this.blur}
        min={this.props.min}
        max={this.props.max}
        style={this.props.style}
      />
    )
  }
}

//onFocus={this.select}

class FormCheckBox extends Component {
  render() {
    //console.log("FormCheckBox value: ",this.props.value);
    return (
      <button
        id={this.props.id}
        autoComplete="off"
        className={"form-control btn " + (this.props.className || "btn-light") + (this.props.value ? " active" : "")}
        style={this.props.style}
        onClick={e => this.props.name ? this.props.update(this.props.name, !this.props.value) : this.props.update(!this.props.value)}
      >
        <span className={this.props.value ? "fs-square-o" : "fs-check-square-o"} />
      </button>
    )
  }
}

const FormNumInc = props => (
  <input type="number" className="form-control" name={props.name} min={props.min} max={props.max} value={props.value} onChange={e => props.update(props.name, e.target.value)} />
)

//const select = e => e.target.select();

const FormNumber = props => (
  <input type="number" placeholder={props.placeholder} className="form-control" name={props.name} min={props.min} max={props.max} value={props.value || ""} onChange={e => { props.update(props.name, Number(e.target.value)); if (props.onChange) props.onChange(e); }} style={props.style} /> 
) // onFocus={select}

const ZoomImage = props =>
  <ButtonModal modalIcon="fa fa-file-image-o" className={"ZoomImage"} showOnRender={props.showOnRender} scroll padding buttonClass={"image" + (props.big ? " big" : "")} modalClassName="ZoomImageModal" title={props.title} buttonImage={<img style={{height: '8rem', maxHeight: '100%', maxWidth: '100%'}} src={props.src} alt={props.alt} />} topButtons={props.topButtons}>
    <img src={props.src} alt={props.alt} />
    <div>{props.children}</div>
  </ButtonModal>

class FormImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      img: null,
      stubs: {}
    }

    this.id = this.props.id || cuid();
  }

  showNotice = notice => {
    this.setState({ notice: notice }, () => {
      setTimeout(() => {
        this.setState({ notice: false });
      }, 10000);
    })
  }

  addDoc = async e => {
    if (e.target.files && e.target.files[0]) {

      this.setState({ imloading: true });
      const file = e.target.files[0];

      try {
        const ob = Object.keys(this.props.stubs || {});

        const rep = ob && ob.length?ob[0]:0; //Image to replace

        const blob = await resizeBlob(file);
        const maxBlob = await maxBlobSize(blob, 5 * 1024 * 1024);
        await this.props.attachments.replace(rep, blob.type, maxBlob); //file.type
      } catch (err) {
        this.showNotice(err.message);
      }

      this.setState({ imloading: false });
      //if(e && e.target) e.target.value="";
    }
  }

  rotateImage = (angle) => async e => {
    
    if (this.props.stubs) {
      const ob = Object.keys(this.props.stubs);
      if (ob.length) {
        const img = await this.props.attachments.get(ob[0]);
        const rotated = await rotateBlob(img, angle);
        this.props.attachments.replace(ob[0], img.type, rotated);
      }
    }

  }

  /*
    rotateImage = (angle) => async e => {

    console.log("ri stubs: ", this.props.stubs);

    //if (this.props.stubs) {
      //const ob = Object.keys(this.props.stubs);
      //if (ob.length) {
        //const img = await this.props.attachments.get(ob[0]);
        const img = await this.props.attachments.get();
        //const rotated = await rotateBlob(img, angle);
        if(!img.length) return;
        const rotated = await rotateBlob(img[0], angle);
        this.props.attachments.replace(cuid(), img.type, rotated);
      //}
    //}

  }
  */

  deleteDoc = () => {
    return this.props.attachments.remove()
      .then(res => { //Remove image preview
        if (this._mounted !== false) this.setState({ img: null });
      })
  }

  getpi = async attid => {
    const blob = await this.props.attachments.get(attid);

    try {
      let img = null;

      if (blob && blob.type.startsWith("image")) {
        img = URL.createObjectURL(blob);
      }
      
      if (this._mounted !== false) this.setState({ img });
    } catch (err) {
      console.log("Image deleted ", err);
    }
  }

  getPrevImgs = (stubs) => {
    this.setState({stubs});
    //If there are attachments, loop through and load any which are images into state.previmgs
    if (stubs) {
      const ob = Object.keys(stubs);
      if (ob.length) this.getpi(ob[0]);
    } else {
      this.setState({ img: null });
    }
  }

  componentDidMount() {
    this.getPrevImgs(this.props.stubs);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    /*if ((!this.props.stubs && prevProps.stubs) || (this.props.stubs && (!prevProps.stubs || JSON.stringify(prevProps.stubs !== JSON.stringify(this.props.stubs))))) {
      this.getPrevImgs();
    }*/

    if(this.props.stubs && JSON.stringify(this.props.stubs) !== JSON.stringify(this.state.stubs)) {
      this.getPrevImgs(this.props.stubs);
    }

  }

  render() {

    return (
      <div className={"FormImage input-group " + (this.props.className || "")}>
        {this.state.notice ||
          this.state.imloading
          ? <Loading title={false} />
          : <div>
            {this.state.img
              ? <ZoomImage src={this.state.img} alt={this.props.placeholder} title={this.props.title || "Image"}
                topButtons={
                  <>
                    <FButton icon="fa fa-undo" onClick={this.rotateImage(270)}>Rotate</FButton>
                    <FButton icon="fa fa-repeat" onClick={this.rotateImage(90)}>Rotate</FButton>
                    <label htmlFor={`${this.id}-img`} className="upbtn btn FButton"><span className={"FButtonIcon fs-camera"}>+</span><span className="FButtonText">Image</span></label>
                    <SureButton icon="fs-trash" buttonText="Delete" onClick={this.deleteDoc}>Delete?</SureButton>
                  </>
                }
              />
              : this.props.placeholder}
          </div>
        }
        {!this.state.img && !this.state.imloading && <label htmlFor={`${this.id}-img`} className="upbtn btn FButton"><span className={"FButtonIcon fs-camera"}>+</span><span className="FButtonText">Image</span></label>}
        <input className="upload" type="file" accept={this.props.accepttypes || "image/*"} id={`${this.id}-img`} onChange={this.addDoc} />
        <div className="form-control"> </div>
      </div>
    )
  }
}

const FormCombo = ({ name, values, valuesname, value = '', update, placeholder }) => {
  const [v, setv] = useState(false);
  const [opener, setOpener] = useState();

  //const a = React.createRef();
  //const select = e => a.current.select();

  //const setTAHeight = () => {
  /*a.current.style.height='auto';
  a.current.style.overflowY='hidden';
  a.current.style.height=(a.current.scrollHeight) + 'px';
  if(!a.current.value) a.current.style.height='2rem';*/
  //}

	/*const setValue = v => {
		setTAHeight();
		setv(v);
	}*/

	/*const change = v => {
		setv(v);
	}*/

  const doupdate = newval => {
    //console.log("doupdated?", v, value);
    if (newval !== v) {
      //console.log("Do update!")	;
      setv(newval);
      update(name, newval);
    }
  }

  const addvalue = () => {
    //Already in values?
    if ((!v && v !== 0 && v !== '0') || (values || []).find(vv => vv === v)) return;

    update({
      [valuesname]: (values || []).concat(v).sort(),
      [name]: ''
    });

    if (opener) opener();
  }

  const notInList = () => (v !== '' && !(values || []).find(val => val === v));

  const deletevalue = val => update(valuesname, values.filter(v => v !== val));

  const addOpener = dropOpener => setOpener(() => dropOpener);

  useEffect(() => setv(value), [value]);

  return (
    <div className="FormCombo input-group form-control">
      {/*<input ref={a} onFocus={select} type="text" className="form-control" onChange={change} value={(v===false?value:v)} onBlur={doupdate} placeholder={placeholder} />*/}
      {/*<textarea ref={a} onFocus={select} type="text" className="form-control" onChange={change} value={(v===false?value:v)} onBlur={doupdate} placeholder={placeholder} />*/}
      <EditableA onChange={doupdate} editFixedOn className="form-control">
        {v}
      </EditableA>

      {/*v && <FButton icon="fs-times" onClick={()=>update(name, '')}></FButton>*/}
      {valuesname && notInList() && <FButton icon="fs-plus-circle" onClick={addvalue}>Add To List</FButton>}
      <div className="input-group-append">
        {values && values.length > 0 &&
          <BSDrop right wide setOpener={addOpener} hideIcon>
            {values.map(v =>
              <div className="input-group dropdown-item" key={v}>
                <button type="button" className="form-control" onMouseDown={e => { e.preventDefault(); setv(v); update(name, v) }}>{v}</button>
                {valuesname && <div className="input-group-append">
                  <button className="addon-button" onClick={e => e.preventDefault()} onMouseDown={() => deletevalue(v)}><span className="fs-trash" /></button>
                </div>}
              </div>
            )}
          </BSDrop>
        }
      </div>
    </div>
  )
}

/* Bootstrap Modal */
const Modal = props => (
  <div className={"Modal " + (props.className || "") + (props.oneline ? ' oneline' : '')}>
    <div className={"modal fade" + (props.scroll ? " modal-scroll" : "") + (props.show ? " show modalshow" : " modalhide")} id={props.id} tabIndex="-1" role="dialog" aria-hidden={props.show ? "false" : "true"} onClick={props.closeOnBackground?props.hideModal:()=>{}} > {/* onClick={props.hideModal} */}
      <div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
        <div className="modal-content card card-edit">
          {props.oneline ? '' : <div className="card-header input-group">
            <div className="input-group" data-toggle="buttons">
              {props.icon &&
                <div className="input-group-prepend btn-group-toggle" data-toggle="buttons">
                  <span className={"icon " + props.icon}> </span>
                </div>
              }
              {props.onTitleChange ?
                <EditableA className="form-control title" onChange={props.onTitleChange} editFixedOn={true}>
                  {props.title}
                </EditableA>
                :
                <span className="form-control title input-group-text">{props.title}</span>
              }
              <div className="input-group-append btn-group-toggle" data-toggle="buttons">
                {props.topButtons && props.topButtons}
                {props.headerButtons && props.headerButtons}
                {props.hideCloseIcon ? '' : <FButton icon="fs-times" onClick={props.hideModal}>Close</FButton>}
              </div>
            </div>
          </div>}
          <div className={"card-block" + (props.padding ? " padding" : "")}>
            {props.children}
          </div>
          {props.oneline ? '' : <div className="card-footer">
            <div className="input-group">
              <span className="form-control title"> </span>
              <div className="input-group-append">
                <div className="btn-group-toggle" data-toggle="buttons">
                  {props.footerButtons && props.footerButtons}
                  {props.buttonCancel && <FButton icon="fs-times" onClick={props.hideModal}>Cancel</FButton>}
                  {props.buttonOK && <FButton icon="fs-check" onClick={props.buttonOK} disabled={props.buttonOKDisabled}>OK</FButton>}
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
    {props.show && <div className="modal-backdrop fade show" />}
  </div>
)

/**
 *  Bootstrap Modal activated by a button 
 */
class ButtonModal extends Component {
  constructor(props) {
    super(props);

    this.state = { show: this.props.show || this.props.showOnRender || false };
  }

  setShow = show => {
    const s = typeof show !== "undefined" ? show : !this.state.show;
    this.setState({ show: s }, e => {
      if (s && typeof this.props.onShow === 'function') this.props.onShow();
      else if (!s && typeof this.props.onHide === 'function') this.props.onHide();
      if (typeof this.props.showState === 'function') this.props.showState(s);
    });
  }

  ok = () => {
    if (typeof this.props.buttonOK === 'function') this.props.buttonOK();
    this.setShow(false);
  }

  cancel = () => {
    if (typeof this.props.buttonCancel === 'function') this.props.buttonCancel();
    this.setShow(false);
  }

  componentDidMount = () => {
    if (this.props.getShow) {
      this.props.getShow(this.setShow);
    }
  }

  render = () => {

    const buttonCancel = this.props.buttonCancel ? this.cancel : null;

    return (
      <div className={`${this.props.standardButton ? '' : 'btn-group'} ${this.props.className || ''}`} hidden={this.props.hidden}>
        {/*<button type="button" className={"btn FButton "+(this.props.buttonClass || "")} onClick={this.setShow}><span className={this.props.buttonImage?"":(this.props.buttonIcon || this.props.icon || "fs-pencil")} />{this.props.buttonText || this.props.buttonImage}</button>*/}

        {
          this.props.greybutton
            ? <GreyBox subtitle={this.props.overlay} onClick={e => { e.stopPropagation(); this.setShow() }}>{this.props.buttonText}</GreyBox>
            : this.props.linktext
              ? <button title={this.props.buttontitle} className="form-control abutton" onClick={e => { e.stopPropagation(); this.setShow() }}>{this.props.linktext}</button>
              : this.props.hideButton
                ? null
                : this.props.standardButton
                  ? <button title={this.props.buttontitle} type="button" className={`btn ${this.props.buttonClass}`} onClick={e => { e.stopPropagation(); this.setShow() }} ><span className={this.props.buttonIcon || this.props.icon} /> {this.props.buttonText}</button>
                  : <FButton title={this.props.buttontitle} className={this.props.buttonClass} vcenter={this.props.vcenter} addButton={this.props.addButton} large={this.props.large} small={this.props.small} onClick={e => { e.stopPropagation(); this.setShow() }} hideIcon={this.props.hideIcon} icon={this.props.buttonIcon === false || this.props.addButton ? false : (this.props.buttonIcon || this.props.icon || (!this.props.buttonImage && "fs-pencil"))} glyph={this.props.glyph || this.props.buttonImage} overlay={this.props.overlay}>{this.props.buttonText}</FButton>
        }
        <Modal
          id={this.props.id}
          hideCloseIcon={this.props.hideCloseIcon}
          className={this.props.modalClassName}
          topButtons={this.props.topButtons}
          footerButtons={this.props.footerButtons}
          scroll={this.props.scroll}
          padding={this.props.padding}
          icon={this.props.modalIcon || this.props.icon}
          title={this.props.title || "Modal"}
          show={this.state.show}
          hideModal={e => this.setShow(false)}
          buttonOKDisabled={this.props.buttonOKDisabled}
          buttonOK={this.ok}
          buttonCancel={buttonCancel}
          onTitleChange={this.props.onTitleChange}
          oneline={this.props.oneline}
        >
          {this.props.children}
        </Modal>
      </div>
    )
  }

}

/* Bootstrap 4 dropdown - could have more options */
class BSDrop extends Component {
  constructor(props) {
    super(props);
		/*this.dropClick = this.dropClick.bind(this);
		this.dropBlur = this.dropBlur.bind(this);*/
    this.state = {
      show: this.props.show || false
    }

    this.id = props.id || cuid();
  }

  clear = e => {
    this.props.onClear();
  }

  //For submenus? Eg SureButton
  hold = e => {
    this.setState({ hold: true });
  }

  unhold = e => {
    this.setState({ hold: false });
    this.dropBlur();
  }

  dropClick = () => {
    const show = !this.state.show;
    this.setState({ show });

    if (typeof this.props.showState === 'function') this.props.showState(show);
  }

  dropBlur = () => {
    this.setState({ show: false });
    if (typeof this.props.showState === 'function') this.props.showState(false);
  }

  show = () => {
    this.setState({ show: true });
    document.getElementById(`${this.id}_tog`).focus();
    if (typeof this.props.showState === 'function') this.props.showState(true);
  }

  componentDidMount = () => {

    //Allow parent to show dropdown
    if (this.props.setOpener) this.props.setOpener(this.show);
    if (typeof this.props.showState === 'function') this.props.showState(this.state.show);
  }

	/*componentDidUpdate(prevProps, prevState) {
		if(this.props.show!==prevProps.show) {
			this.setState({show:this.props.show});
		}
	}*/

  render() {
    return (
      <div id={this.id} className={`BSDrop drop${this.props.up ? 'up' : 'down'} btn-group ${this.state.show ? "show" : ""} ${this.props.className || ""}`} onBlur={this.dropBlur}>
        <FButton id={`${this.id}_tog`} title={this.props.buttontitle} autoFocus={this.props.show} small={this.props.small} icon={this.props.icon} hideIcon={this.props.hideIcon} glyph={this.props.title || this.props.glyph} overlay={this.props.overlay} drop={this.props.point !== false && this.props.buttonText} className={((this.props.point !== false && !this.props.buttonText) ? " dropdown-toggle" : "") + (this.props.split ? " dropdown-toggle-split" : "")} onClick={this.dropClick} onLongPress={this.props.onLongPress} data-toggle="dropdown" style={this.props.buttonStyle}>
          {this.props.buttonText}
        </FButton>
        {this.props.onClear && <button type="button" className="btn FButton cancel" onClick={e => { e.preventDefault(); this.clear(e); }}>
          <span className="fs-times" />
        </button>}
        <div className={`dropdown-menu${this.state.show?" show":""}${this.props.right?" dropdown-menu-right":""}${this.props.wide?" wide":""}`} onMouseDown={this.props.noHide === false ? null : this.dropBlur}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

/*const BSDropItem = props => (
	<a className="dropdown-item" href="#" onClick={e=>e.preventDefault()} onMouseDown={e=>{e.preventDefault(); props.onClick(e)}}><span className={props.icon} /> {props.text || props.children}</a>
)*/

const BSDropItem = props => (
  <div className="BSDropItem dropdown-item">
    <button className={`dropdown-button${props.active?' active':''}`} style={props.style} glyph={props.glyph} overlay={props.overlay} onClick={e => e.preventDefault()} onMouseDown={e => { e.preventDefault(); props.onClick(e) }}><span className={props.icon} style={props.iconStyle} /> {props.text || props.children}</button>
    {/*props.addonClick && <button className="addon-button" onClick={e=>e.preventDefault()} onMouseDown={e=>{e.preventDefault(); props.addonClick(e)}}><span className={props.addonIcon || "fs-pencil"} /></button>*/}
    {props.addonButton}
  </div>
)

const BSDropHeading = ({ children }) => (
  <h6 className="dropdown-header">{children}</h6>
)

const SureButton = props => (
  <BSDrop buttontitle={props.title} className={"SureButton " + (props.size || "") + " " + (props.className || "")} glyph={props.glyph} overlay={props.overlay} icon={props.icon || "fs-trash"} buttonText={props.buttonText} right={props.right !== false} point={false}>
    <BSDropItem onClick={props.onClick}>
      {props.children || "Sure?"}
    </BSDropItem>
  </BSDrop>
)

/*const Icondrop = props => (
	<BSDrop icon={props.icon || "fs-folder-o"} columns="10">
		{fsiconscols().map(il=>
			<div key={il[0]} className="dropdown-row">
				{il.map(i=>
					<BSDropItem onClick={e=>typeof props.name!=="undefined"?props.onClick(props.name,i):props.onClick(i)} key={i} icon={(i && i.startsWith('fa-'))?`fa ${i}`:i} />
				)}
			</div>
		)}
	</BSDrop>
)*/

const Icondrop = ({ title, name, icon, onShow, onHide, onClick, edit }) => (
  <ButtonModal scroll modalClassName="MDModal" title={`${title ? `${title}: ` : ''} Icon Select`} buttonText="Icon" icon={icon} onShow={onShow} onHide={onHide}>
    <div>
      {fsicons().map((i, c) => {
        const iname = (i && i.startsWith('fa-')) ? `fa ${i}` : i;

        return (
          <button type="button" className="IcondropButton" onClick={e => typeof name !== "undefined" ? onClick(name, iname) : onClick(iname)} key={i + c}><div className={iname} /><div className="iconname">{iname.split(':')[0]}</div></button>
        )

      }
      )}
    </div>
  </ButtonModal>
)

/* a/href based, Bootstrap styles button  */
const IconButton = props => (
  <a className={"btn " + (props.buttontype || "btn-secondary") + " " + (props.className || "")} href={props.href || "#"} onClick={e => { e.preventDefault(); props.onClick(e) }}>
    <span className={props.icon}></span>{props.glyph} {props.title}
  </a>
)

/* Checkbox button, bootstrap styled */
const CheckButton = props => (
  <label className={"btn " + (props.buttontype || "btn-secondary") + " " + (props.checked ? " active" : "") + " " + (props.className || "")}>
    <input type="checkbox" checked={props.checked || false} autoComplete="off" onChange={e => { props.name ? props.onChange(props.name, !props.checked) : props.onChange(e) }} /><span className={props.icon} /> {props.title} {props.children} <span className={props.iconafter} />
  </label>
)

/*Content-editable span*/
class EditableA extends Component {
  constructor(props) {
    super(props);
		/*this.editOn = this.editOn.bind(this);
		this.editOff = this.editOff.bind(this);
		this.keyPress = this.keyPress.bind(this);
		this.onClick = this.onClick.bind(this);*/

    this.press = false;
  }

  editOn = e => {
    this.a.contentEditable = true;
    //selectElementContents(this.a);
  }

  editOff = e => {
    if (typeof this.props.onChange === 'function' && e.target.isContentEditable) {
      if (this.props.name) this.props.onChange(this.props.name, e.target.innerText || e.target.textContent);
      else this.props.onChange(e.target.innerText || e.target.textContent);
    }
    if (!this.props.editFixedOn) {
      this.a.contentEditable = false;
      deselectAll();
    }
    if (typeof this.props.onBlur === "function") {
      this.props.onBlur(e);
    }
  }

  keyPress = e => {
    if (e.key === 'Enter' && !this.props.multiline) {
      e.preventDefault();
      this.editOff(e);
    }
  }

  paste = e => {
    e.preventDefault();

    //! Experimental - allow paste but remove HTML
    const text = e.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  }

  onClick = e => {
    e.stopPropagation();

    if (e.target.isContentEditable) return;

    if (!this.props.editFixedOn && this.props.singleClickEdit) {
      this.editOn(e);
    }
    else if (typeof this.props.onClick === "function") {
      e.preventDefault();
      this.props.onClick(e);
    }
  }

  componentDidMount() {
    if (this.props.focusOnRender) this.a.focus();
    if (this.props.editOnRender) this.editOn();
  }

  render() {
    return (
      <div id={this.props.id}
        ref={a => { this.a = a }}
        hidden={this.props.show === false}
        contentEditable={this.props.editFixedOn || this.props.editOn}
        href={this.props.href}
        onKeyPress={this.keyPress}
        onPaste={this.paste}
        onMouseDown={e => { this.press = !e.button && window.setTimeout(() => { if (!this.props.editFixedOn) this.editOn(e); return false; }, 1000) }}
        onMouseUp={e => { window.clearTimeout(this.press); }}
        onBlur={this.editOff}
        onClick={this.onClick}
        onFocus={e => { if (this.props.editFixedOn) this.editOn(e) }}
        className={"EditableA " + (this.props.className || "")}
        style={{ ...this.props.style, minHeight: "2.2rem" }}
        suppressContentEditableWarning>
        {this.props.children}
      </div>
    )
  }
}

//onDoubleClick={e=>{if(!this.props.editFixedOn) this.editOn(e)}}

/*
Produces a series of dropdowns to select from a tree in the form {id title [children]}
*/
class ProgressiveDrop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.values.name || this.props.rootname,
      id: this.props.values.id,
      children: this.props.values.children,
      level: 0,
      breadcrumbs: []
    }



    //this.update = e => this.props.update(this.props.name, e.target.value);

    //console.log("ProgressiveDrop props: ",this.props);
  }

  /* passes incoming "value" or "values" to setValue */
	/*componentWillReceiveProps(newProps) {
		//console.log("Progressive Drop newProps: ",newProps);

		if(newProps.value || newProps.values) this.setValue(newProps.value,true,undefined,newProps.values);
	}*/

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value || this.props.values !== prevProps.values) {
      this.setPDValue(this.props.value, true, undefined, this.props.values);
    }
  }


  componentDidMount() {
    this.setPDValue(this.props.value, true, undefined, this.props.values);
  }

  /* Sets value by navigating data tree "values" to find "value" */
  setPDValue = (id, any, click, values) => {

    /* subfunction to traverse tree */
    const ft = (id, tree = {}, breadcrumbs = [], parentid = false) => {
      const bc = parentid ? breadcrumbs.concat({ id: tree.id, title: tree.questiontext || tree.name, parentid: parentid }) : [];

      return tree.id === id
        ? { children: (this.props.any !== false && any && tree.children && tree.children.length > 0) ? tree.id : tree.children, breadcrumbs: bc, data: tree }
        : tree.children
          ? tree.children.map(child => ft(id, child, bc, tree.id)).find(out => typeof out === "object")
          : false
    }

    const v = ft(id, values || this.props.values);
    //console.log("ProgressiveDrop: ", this.props.id, values, v, id);

    if (typeof v === "object") {
      this.setState(v);
      if (click && (v.children.length === 0 || (any && this.props.any !== false))) {

        let up = {};

        up[this.props.name] = id;

        //if summaryfield pass summary of selection to return value
        if (this.props.summaryfield) up[this.props.summaryfield] =
          v.breadcrumbs && v.breadcrumbs.length ? v.breadcrumbs.map(b => b.title).join('> ') : "";



        if (this.props.datafield && v.data && v.data.values) up[this.props.datafield] = v.data.values;

        this.props.update(up);
      }
    }
    //else if(!v && id) this.props.update({[this.props.name]: undefined, [this.props.summaryfield]: undefined, [this.props.datafield]: undefined}); //this.setState({breadcrumbs: []}); //!Value is missing from values - do something
  }

  render() {

    return (
      <div className={"input-group form-control ProgressiveDrop " + (this.props.className || "")} id={this.props.id}>
        {this.state.title ? <span className="input-group-addon input-group-text">{this.state.title}</span> : null}

        {this.state.breadcrumbs.length > 0 ?
          <ol className="breadcrumb input-group-addon">
            {this.state.breadcrumbs.map(b =>
              <li className="breadcrumb-item" key={b.id}>
                {this.props.edit ? <a href={b.title} onClick={e => { e.preventDefault(); this.setPDValue(b.parentid) }}>{b.title}</a> : b.title}
              </li>
            )}
            {!Array.isArray(this.state.children) && this.props.any !== false ?
              <li className="breadcrumb-item">
                {this.props.edit ? <a href="ANY" onClick={e => { e.preventDefault(); this.setPDValue(this.state.children) }}>ANY</a> : "ANY"}
              </li>
              : null}
          </ol>
          : null}

        {this.props.edit && Array.isArray(this.state.children) && this.state.children.length > 0 ?
          <BSDrop title="Select Type" show className="input-group-addon">
            {this.state.breadcrumbs.length > 0 && this.props.any !== false ?
              <BSDropItem onClick={e => { this.setPDValue(this.state.breadcrumbs[this.state.breadcrumbs.length - 1].id, true, true) }}>
                ANY
								</BSDropItem>
              : null}
            {this.state.children.map(child =>
              <BSDropItem onClick={e => { this.setPDValue(child.id, false, true) }} key={child.id}>
                {child.questiontext || child.name}
              </BSDropItem>
            )}
          </BSDrop>
          : null}

        {/*<div className="form-control" >&nbsp;</div>*/}
      </div>
    )
  }
}

const runFormula = (formula, fields = {}) => {
  if (!(formula)) return;

  let ev, ee;
  try {
    ev = math.parse(formula);
    ee = ev.eval(fields);
  }
  catch (err) {
    ee = "???";
  }
  return ee;
}

class FormulaField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formulae: this.props.value || [],
      calc: {},
      fields: {}
    }
  }
	/*componentWillReceiveProps(newProps) {
		if(typeof newProps.value!=="undefined") this.setState({formulae:newProps.value}); //! Update calcs
	}*/

  static getDerivedStateFromProps(nextProps, prevState) {
    return (typeof nextProps.value !== "undefined" && !arraysEqual(nextProps.value, prevState.formulae)) ? { formulae: nextProps.value } : null;
  }

  changeFormula = (formulaid, e) => {
    let f = this.state.formulae.slice();

    let fi = f.findIndex(ff => ff.id === formulaid);

    const value = e.target.value.trim();

    if (fi > -1) {
      f[fi].value = value;
    }
    else {
      f.push({ id: formulaid, title: this.props.standards.find(s => s.id === formulaid).title, value: value });
    }

    this.setState({ formulae: f }, this.updateCalc(formulaid, value));
  }

  updateCalcs = () => {
    this.state.formulae.forEach(f => this.updateCalc(f.id, f.value));
  }

  updateCalc = (formulaid, formula) => {
    let ev, ee;
    const f = (typeof formula !== "undefined" ? formula : this.getFormula(formulaid));
    try {
      ev = math.parse(f);
      ee = ev.eval(this.state.fields);
    }
    catch (err) {
      ee = "???";
    }

    //console.log("ev type",typeof ev);

    //let c=Object.assign({}, this.state.calc, {[formulaid]: typeof ee==="number"?ee:"???"});

    //console.log("Calc: (f,c) ",f, c);

    this.setState({ ["calc_" + formulaid]: typeof ee === "number" ? ee : "???" });
  }

  getFormula = (formulaid) => {
    const formula = this.state.formulae.find(f => f.id === formulaid);
    if (formula) return formula.value;
    return "";
  }

  changeField = (n, v) => {
    //console.log("Field check: ",n,v,this.props.fields.find(f=>f.code===n));
    this.setState({ fields: Object.assign({}, this.state.fields, { [n]: this.props.fields.find(f => f.code === n && f.array) ? (v.replace(/, /g, ',').replace(/ /g, ',').split(",") || []).filter(v => v) : v }) }, this.updateCalcs);
  }

  keyPress = (e) => {
    //if(e.keyCode===13) this.save();
    //if(e.key.match(/[0-9]|\/|-|\*|x|X|\+|\.| |\(|\)/)===null) e.preventDefault();
  }
  paste = (e) => {
    //e.preventDefault();
  }
  save = () => {
    this.props.update(this.props.name, this.state.formulae);
  }
  render() {

    const standards = this.props.standards || [];
    //const calc=this.state.calc;

    //console.log("state: ", this.state);

    return (
      <>
        <div className={"input-group " + (this.props.className || "")}>
          <div className="input-group-prepend">
            {this.props.title && <span className="input-group-text">{this.props.title}</span>}
          </div>
          {this.props.fields.map(f =>
            <React.Fragment key={f.code}>
              <div className="input-group-prepend input-group-append">
                <span className="input-group-text">{f.title}: {f.code}</span>
              </div>
              {f.array
                ? <FormText name={f.code} value={this.state.fields[f.code]} update={this.changeField} placeholder="Test Value" />
                : <FormNumber name={f.code} value={this.state.fields[f.code]} update={this.changeField} placeholder="Test Value" />
              }
            </React.Fragment>
          )}
        </div>
        {standards.map(standard =>
          <div className={"input-group " + (this.props.className || "")} key={standard.id}>
            <div className="input-group-prepend">
              <span className="input-group-text">{standard.title}</span>
            </div>
            <input type="text" onKeyPress={this.keyPress} onPaste={this.paste} className="form-control" placeholder={this.props.placeholder} value={this.getFormula(standard.id)} onChange={e => this.changeFormula(standard.id, e)} onBlur={this.save} autoComplete="false" autoCorrect="false" spellCheck="false" />
            <div className="input-group-append">
              <span className="input-group-text">#<span className="fs-fire-extinguisher" /></span>
              <span className="input-group-text">{this.state["calc_" + standard.id]}</span>
            </div>
          </div>
        )}
      </>
    )
  }
}

const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

const toFixedIfNecessary = ( value, dp=2 ) => +parseFloat(value).toFixed( dp );

/*
CalcField takes numbers or simple calculations

on change, executes props.set to set object containing:
value: output number
calc: calculation entered

Allows entry of area in sqm or simple calcs
eg 3x4
3x4 + 10x6

Spaces interpreted as "+"

*/
class CalcField extends Component {
  constructor(props) {
    super(props);

		/*this.changeCalc = this.changeCalc.bind(this);
		this.keyPress = this.keyPress.bind(this);
		this.paste = this.paste.bind(this);
		this.save = this.save.bind(this);*/

    this.state = {
      value: (this.props.value && isNumeric((this.props.value || {}).value))
        ? this.props.value
        : (this.props.value || this.props.value === 0 || this.props.value === "0") ? { value: this.props.value, calc: "" } : { value: "", calc: "" },
      active: false
    }
  }

  select = e => {
    this.setState({ active: true });
    //e.target.select();
  }

	/*componentWillReceiveProps(newProps) {
		if(typeof newProps.value!=="undefined") this.setState({value:newProps.value || {value:"", calc:""}});
	}*/

  static getDerivedStateFromProps(nextProps, prevState) {
    return (
      !prevState.active &&
      typeof nextProps.value !== "undefined" &&
      nextProps.value.value === (prevState.value || {}).value &&
      nextProps.value.calc === (prevState.value || {}).calc
    ) ? { value: nextProps.value } : null;
  }

  changeCalc = (e) => {
    //this.setState({areacalc: math.eval(e.target.value)});
    //e.target.value=e.target.value.replace(/x/g,'*');

    if (!e.target.value && e.target.value!==0) {
      this.setState({ value: { value: '', calc: "" } });
      //this.setState({ value: false });
      return;
    }

    let ev;
    try {
      ev = math.evaluate(e.target.value.trim().replace(/x|X|×/g, '*').replace(/ /g, '+'));
    }
    catch (err) {
      ev = "";
    }

    //this.props.update({value: ev, calc: e.target.value});
    this.setState({ value: { value: ev, calc: e.target.value } }, () => {
      if (this.props.onChange) this.props.onChange(e);
    })

    //areacalc has value & area does not == error
  }
  keyPress = (e) => {
    if (e.key === 'Enter') {
      this.save();
    }
    else if (e.key.match(/[0-9]|\/|-|\*|x|X|\+|\.| |\(|\)/) === null) e.preventDefault();
  }
  paste = (e) => {
    e.preventDefault();
  }
  save = () => {
    this.setState({ active: false });
    this.props.update(this.props.name, this.state.value);

    //console.log("Calc save: ", this.props.name, this.state.value);
  }
  render() {
    let areadetail;

    if (typeof this.state.value === "undefined" || (this.state.value || {}).calc === "") areadetail = "";
    else if ((this.state.value || {}).value === "") areadetail = "???";
    else if (((this.state.value || {}).value || '').toString() === ((this.state.value || {}).calc || '').toString()) areadetail = "";
    else if ((this.state.value || {}).value) areadetail = "= " + toFixedIfNecessary(this.state.value.value, 2);
    else areadetail = '';

    return (
      <div className={"CalcField input-group " + (this.props.className || "")}>
        <div className="input-group-prepend">
          {this.props.title && <span className="input-group-text">{this.props.title}</span>}
          {/*<span className="input-group-text fs-calculator" />*/}
        </div>
        <input type="text" className="form-control" size="11" onKeyPress={this.keyPress} onPaste={this.paste} placeholder={this.props.placeholder} value={this.state?.value?.calc ? this.state.value.calc : ""} onChange={this.changeCalc} onBlur={this.save} autoComplete="false" autoCorrect="false" spellCheck="false" /> {/* onFocus={this.select} */}
        {(areadetail || this.props.unit) &&
          <div className="input-group-append">
            <span className="input-group-text">{areadetail} {this.props.unit}</span>
          </div>
        }
      </div>
    )
  }
}

/*
class CalcFieldGroup extends Component {

	updateField(i) {

		this.props.update()
	}

	render() {

		return (
			<div className="CalcField form-group">
				<label>{this.props.title}:</label>

				{this.props.value.map((cf,i)=>
					<CalcField unit={this.props.unit} placeholder={this.props.placeholder} update={v=>this.updateField(i)} value={cf} />
				)}
			</div>
		)
	}
}
*/

/*
Image upload and display Component
Resizes each uploaded images to  have x/y dimensions within a given parameter
and returns a JPEG in base64

!!EXIF rotation..?
*/
/*class ImgUpload extends Component {
	constructor(props) {
		super(props);

		this.addImg = this.addImg.bind(this);

		this.state= {
			imloading: false
		}
	}

	//Upload and resize image
	addImg(e) {
		if(e.target.files && e.target.files[0]) {
			this.setState({imloading: true})
			const reader = new FileReader();

			reader.onload = imgin => {
				//Img rotation??

				resize64(imgin.target.result, this.props.maxres || 2000)
				.then(imgout => {
					//console.log("imgout: ",imgout.substring(0,100));
					this.props.addImg(imgout);
					this.setState({imloading: false});
				});
			}

			reader.readAsDataURL(e.target.files[0]);
			e.target.value="";
		}
	}

	render() {
		return (
			<div className="imgupload">
				<input type="file" accept="image/*" id={this.props.id} onChange={this.addImg} />
				<label htmlFor={this.props.id} className="btn btn-secondary fs-camera"> Add Image…</label>
				{this.state.imloading?
					<img className="loading" src={loading} alt="Loading" />
				:""}
				<div className="imgpreview">
				{this.props.imgs.map(img =>
					<div key={img.id}>
						<img src={img.src} alt="Uploaded" />
						<button type="button" className="close" onClick={()=>this.props.deleteImg(img.id)} aria-label="Delete">
							<span className="fs-trash" />
						</button>
					</div>
				)}
				</div>
			</div>
		)
	}
}*/

//const base64img = (content_type, data) => `data:${content_type};base64,${data}`;


//Representation of a an attached file
const AttFile = props => (
  <div className="AttFile">
    {/*<button type="button" className="floatbutton" onClick={props.download} aria-label="Save">
			<span className="fs-floppy-o" />
		</button>*/}
    {props.topButtons !== false && props.download && <FButton className="floatbutton" icon={"fs-floppy-o"} onClick={props.download} >Download</FButton>}
    {props.topButtons !== false && props.delete && <SureButton icon="fs-trash" className="floatbutton right" onClick={props.delete} buttonText="Delete">Delete?</SureButton>}
    {/*<a href="#">*/}
    <button onClick={props.onClick || props.download}>
      <div className="previewImg">{props.img ? <img src={props.img} alt={"User Attached"} /> : <span className={"icon fs-file-text-o"} />}</div>
      {/*<div className="filename">{props.name}</div>*/}
      {props.fileType !== false && <div className="fileinfo">{(props.type || "").split("/").pop()}</div>}
      {props.fileSize !== false && <div className="fileinfo">{humanFileSize(props.size)}</div>}
      {props.subtitle && <div className="fileinfo">{props.subtitle}</div>}
    </button>

    {/*</a>*/}
  </div>
)

//Provides a control for uploading files
//Must be supplied with "attachments" library as a prop

class AttachDocModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imloading: false,
      previmgs: {},
      /*show: false,*/
      /*note: false,*/
      notice: false
    }

		/*this.addDoc = this.addDoc.bind(this);
		this.deleteDoc = this.deleteDoc.bind(this);
		this.downloadDoc = this.downloadDoc.bind(this);*/
    //this.showNote = this.showNote.bind(this);
    //this.updateNote = this.updateNote.bind(this);

		/*this.show = show => {
			this.setState({show:show});
			if(!show) this.saveNote();
		}*/

    this.id = this.props.id || cuid();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  //!Should add attdata
  addDoc = async e => {
    if (e.target.files && e.target.files.length) {
      if (this._mounted !== false) this.setState({ imloading: true });
      
      //const file = e.target.files[0];

      var erm = [];

      for( const file of e.target.files ) {

        try {
          const blob = await resizeBlob(file);
          const rsblob = await maxBlobSize(blob, 5 * 1024 * 1024);

          //Use filename as ID unless attid specified
          const isattid = (typeof this.props.attid !== 'undefined' && this.props.attid !== false && this.props.attid !== null);
          const pattid = isattid ? this.props.attid : file.name;

          const attid = `${this.props.attprefix ? `${this.props.attprefix}__` : ''}${pattid}`;

          await this.props.attachments.add(attid, file.type, rsblob, isattid);

          if (this._mounted !== false) this.setState({ imloading: false });
          this.getPrevImgs(attid);

        } catch (err) {
          erm.concat(err.message)
        }

      }

      if(erm.length) this.showNotice(erm.join('; '));

      //e.target.value = "";
    }
  }

  showNotice = notice => {
    this.setState({ notice: notice }, () => {
      setTimeout(() => {
        if (this._mounted !== false) this.setState({ notice: false });
      }, 10000);
    })
  }

  //Delete an attachment
  deleteDoc = attid => {
    return this.props.attachments.remove(attid)
      .then(res => { //Remove image preview
        if (attid in this.state.previmgs) {
          const p = Object.assign({}, this.state.previmgs);
          delete p[attid];
          if (this._mounted !== false) this.setState({ previmgs: p });
          //if(typeof this.props.onPreview==='function') this.props.onPreview(false);
        }
      })
  }

  downloadDoc = attid => {
    //console.log("Get attachment: ",attid);

    //Grab ObjectURL direct from previmgs if it's already buffered
    if (attid in this.state.previmgs) {
      let link = document.createElement('a');
      link.setAttribute('href', this.state.previmgs[attid]);
      link.setAttribute('download', attid);
      link.click();
    }
    else this.props.attachments.get(attid)
      .then(blob => {
        const obj = URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.setAttribute('href', obj);
        link.setAttribute('download', attid);
        link.click();
      });
  }

  componentDidUpdate(prevProps, prevState) {

    //this.getPrevImgs();

    if ((!this.props.stubs && prevProps.stubs) || (this.props.stubs && (!prevProps.stubs || JSON.stringify(prevProps.stubs) !== JSON.stringify(this.props.stubs)))) {
      this.getPrevImgs();
    }
  }

  componentDidMount() {
    this.getPrevImgs();
  }

	/*componentDidUpdate() {
		if(this.state.note===true) document.getElementById(this.id+'_notes').focus();
	}*/

  getPrevImgs = attid => {
    const s = this.props.stubs;

    //If there are attachments, loop through and load any which are images into state.previmgs

    const getpi = (attid) => {
      this.props.attachments.get(attid)
        .then(blob => {
          if (blob.type.startsWith("image")) {
            const obj = URL.createObjectURL(blob);
            if (this._mounted !== false) this.setState({ previmgs: {...this.state.previmgs, [attid]: obj }});
            //if(typeof this.props.onPreview==='function') this.props.onPreview(obj);
          }
        })
        .catch(err => {
          console.log("Image deleted", attid, err);
        })
    }

    if (attid) {
      getpi(attid);
    }
    else if (typeof s === "object") {
      Object.keys(s).forEach(k => {
        if (s[k].content_type.startsWith("image") && (!(k in this.state.previmgs) || this.props.attid)) getpi(k);
      })
    }
    else {
      this.setState({ previmgs: {} });
    }
  }

  /**
   * Rotate image
   * @param {*} id Image ID
   * @param {*} angle 90,270
   */
  rotateImage = (id, angle) => async e => {
    //if (this._mounted !== false) this.setState({ imloading: true });
    const img = await this.props.attachments.get(id); //Get the image data
    const rotated = await rotateBlob(img, angle); //Rotate the image data
    const newid = `${cuid()}.jpg`; //Create a new ID for the image

    //await this.props.attachments.add(id, img.type, rotated, true);
    await this.props.attachments.replace(id, img.type, rotated, newid); //Replace the old image with the rotated image
    //if (this._mounted !== false) this.setState({ imloading: false });
    //this.getPrevImgs(newid); //Update the image in the interface
    this.setState({showOnRender: newid});
  }

  render() {
    //console.log("Previmgs: ",this.state.previmgs);

    //! Add notes as JSON docs text/json?? with creator and date/time

    const s = this.props.stubs;
    const hasAttachments = (typeof s === "object" && Object.keys(s).length > 0); //!Must account for prefixes
    const attcount = (this.props.note ? 1 : 0) + (hasAttachments ? Object.keys(s).length : 0); //!Must account for prefixes

    return (
      <ButtonModal className={"AttachModal btn-group " + (this.props.className || "")} buttonIcon={hasAttachments || this.props.note ? "fs-docs" : "fs-paperclip"} scroll padding icon="fs-paperclip" buttonText={this.props.buttonText || "Attach"} title={this.props.title || "Attachments"}
        overlay={attcount}
        topButtons={
          <>
            {this.props.typedoc !== false && <label htmlFor={`${this.id}-doc`} className="upbtn btn FButton"><span className={"FButtonIcon fs-file-text-o"}>+</span><span className="FButtonText">Doc</span></label>}
            {this.props.typeimg !== false && <label htmlFor={`${this.id}-img`} className="upbtn btn FButton"><span className={"FButtonIcon fs-camera"}>+</span><span className="FButtonText">Image</span></label>}
          </>
        }>

        {this.state.notice &&
          <div className="notice">{this.state.notice}</div>
        }

        {this.props.typenote !== false &&
          <div className="form-group AttachNote">
            <label htmlFor={this.id + '_notes'}>Note</label>
            <button type="button" className="close" onClick={e => this.props.actions.setValue('note', '')} aria-label="Delete">
              <span className="fs-trash" />
            </button>

            <FormTextArea id={this.id + '_notes'} name="note" className="notes" value={this.props.note} update={this.props.actions.setValue} />
          </div>
        }

        <div className="form-group">
          <div className="imgupload">
            <div className="uploadButtons">
              <input type="file" accept={this.props.accepttypes || "*"} id={`${this.id}-doc`} onChange={this.addDoc} />
              {/*<label htmlFor={`${this.id}-doc`} className="btn btn-secondary">{this.state.imloading?<Loading title={false} />:<span className={this.props.icon || "fs-file-text-o"} />} {this.props.title || "Add Document…"}</label>*/}

              <input type="file" accept={this.props.accepttypes || "image/*"} id={`${this.id}-img`} onChange={this.addDoc} multiple />
              {/*<label htmlFor={`${this.id}-img`} className="btn btn-secondary">{this.state.imloading?<Loading title={false} />:<span className={"fs-camera"} />} {this.props.title || "Add Image…"}</label>*/}
            </div>

            <div className="docpreview">
              {hasAttachments ? Object.keys(s).map(k =>
                (s[k].content_type || "").startsWith("image")
                  ? <ZoomImage key={k} src={this.state.previmgs[k]} big alt={"Attached"} title={"Attached Image"} buttonText={k} showOnRender={k===this.state.showOnRender}
                    topButtons={
                      <>
                        <FButton icon="fa fa-undo" onClick={this.rotateImage(k, 270)}>Rotate</FButton>
                        <FButton icon="fa fa-repeat" onClick={this.rotateImage(k, 90)}>Rotate</FButton>
                        <FButton icon={"fs-floppy-o"} onClick={e => this.downloadDoc(k)} >Download</FButton>
                        <SureButton icon="fs-trash" onClick={e => this.deleteDoc(k)} buttonText="Delete">Delete?</SureButton>
                      </>
                    }
                  >
                    {/*<div className="filename">{k}</div>*/}
                    {/*<div className="fileinfo">{s[k].content_type}</div>*/}
                    <div className="fileinfo">{humanFileSize(s[k].length)}</div>
                  </ZoomImage>
                  : <AttFile name={k} type={s[k].content_type} key={k} size={s[k].length} img={this.state.previmgs[k]} delete={e => this.deleteDoc(k)} download={e => this.downloadDoc(k)} />
              ) : ""}
            </div>
          </div>
        </div>
      </ButtonModal>
    )
  }
}

class AttachmentView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imloading: false,
      previmgs: {},
      notice: false
    }

    //this.addDoc = this.addDoc.bind(this);
    //this.deleteDoc = this.deleteDoc.bind(this);
    //this.downloadDoc = this.downloadDoc.bind(this);

    this.id = this.props.id || cuid();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  //Delete an attachment
  deleteDoc = (attid) => {
    return this.props.attachments.remove(attid)
      .then(res => { //Remove image preview
        if (attid in this.state.previmgs) {
          const p = Object.assign({}, this.state.previmgs);
          delete p[attid];
          if (this._mounted !== false) this.setState({ previmgs: p });
          //if(typeof this.props.onPreview==='function') this.props.onPreview(false);
        }
      })
  }

  downloadDoc = (attid) => {
    this.props.attachments.get(attid)
      .then(blob => {
        const obj = URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.setAttribute('href', obj);
        link.setAttribute('download', attid);
        link.click();
      });
  }

  openDoc = (attid) => {
    var win = window.open();

    if (!win) {
      console.log("Popup Blocked!");
      return this.downloadDoc(attid);
    }

    this.props.attachments.get(attid)
      .then(blob => {
        const obj = URL.createObjectURL(blob);

        win.document.write(`<html><head><title>${attid}</title><style type="text/css">body {margin: 0; padding: 0;}</style></head><body>`);
        win.document.write(`<iframe src="${obj}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`);
        win.document.write(`</body></html>`);
      })
  }

  getPrevImgs = (attid) => {
    const s = this.props.stubs;

    //console.log("getPrevImgs: ",s);

    //If there are attachments, loop through and load any which are images into state.previmgs

    const getpi = (attid) => {
      this.props.attachments.get(attid)
        .then(blob => {
          if (blob.type.startsWith("image")) {
            const obj = URL.createObjectURL(blob);
            if (this._mounted !== false) this.setState({ previmgs: Object.assign({}, this.state.previmgs, { [attid]: obj }) });
            //if(typeof this.props.onPreview==='function') this.props.onPreview(obj);
          }
        })
        .catch(err => {
          console.log("Image deleted", this.props.id, attid, err);
        })
    }

    if (attid) {
      getpi(attid);
    }
    else if (typeof s === "object") {
      Object.keys(s).forEach(k => {
        if (s[k].content_type.startsWith("image") && (!(k in this.state.previmgs) || this.props.attid)) getpi(k);
      })
    }
  }

  componentDidUpdate() {
    this.getPrevImgs();
  }

  componentDidMount() {
    this.getPrevImgs();
  }

  render() {
    //console.log("Previmgs: ",this.state.previmgs);

    //! Add notes as JSON docs text/json?? with creator and date/time

    const s = this.props.stubs;
    const hasAttachments = (typeof s === "object" && Object.keys(s).length > 0);
    //const attcount=(this.props.note?1:0)+(hasAttachments?Object.keys(s).length:0);

    //console.log("Stubs: ", s);

    //console.log("Attachmentview docid: ", this.props.docid);

    return (
      <div className="docpreview">
        {hasAttachments ? Object.keys(s).map(k =>
          (s[k].content_type || "").startsWith("image")
            ? <ZoomImage key={k} src={this.state.previmgs[k]} big alt={"Attached"} title={"Attached Image"} buttonText={k}
              topButtons={
                <>
                  <FButton icon={"fs-floppy-o"} onClick={e => this.downloadDoc(k)} >Download</FButton>
                  <SureButton icon="fs-trash" onClick={e => this.deleteDoc(k)} buttonText="Delete">Delete?</SureButton>
                </>
              }
            >
              {/*<div className="filename">{k}</div>*/}
              {/*<div className="fileinfo">{(s[k].content_type || "")}</div>*/}
              <div className="fileinfo">{humanFileSize(s[k].length)}</div>
            </ZoomImage>
            : this.props.greybutton
              ? <GreyBox subtitle={this.props.subtitle} onClick={e => this.openDoc(k)} key={k}>View PDF</GreyBox>
              : <AttFile subtitle={this.props.subtitle} topButtons={this.props.topButtons} fileType={this.props.fileType} fileSize={this.props.fileSize} name={k} type={s[k].content_type} key={k} size={s[k].length} onClick={e => this.openDoc(k)} img={this.state.previmgs[k]} delete={e => this.deleteDoc(k)} download={e => this.downloadDoc(k)} />
        ) : ""}
      </div>
    )
  }
}

const readFileAsync = file =>
  new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = e => {
      resolve(JSON.parse(e.target.result));
    };

    reader.onerror = reject;

    reader.readAsText(file);
  })

class AssessmentImport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imloading: false,
      file: null,
      notice: false,
      show: false,
      noreid: false
    }

    this.id = cuid();
  }

  addDoc = async e => {
    if (e.target.files && e.target.files[0]) {
      //if(this._mounted!==false) this.setState({imloading: true});

      const file = e.target.files[0];
      try {
        var json = await readFileAsync(file);

        console.log("Import file: ", json);

        if (json && json.length && json[0].type === "Assessment") {

        }

        const name = json && json.length && json[0].values && json[0].values.name;


        if (this._mounted !== false) this.setState({ notice: name ? `${name}: ${json ? `${json.length} rows` : ''}` : "Incorrect format", file: name ? json : null });

        //e.target.value="";

        this.setShow(true);
      }
      catch (err) {
        console.log("Assessment Import Error: ", err);
        return err;
      }
    }
  }

  importSurvey = async () => {
    if (this.state.file) {
      await this.props.importSurvey(this.state.file, this.state.noreid);
      this.setShow(false);
    }
  }

  setShow = show => {
    const s = typeof show !== "undefined" ? show : !this.state.show;
    this.setState({ show: s }, e => {
      if (s && typeof this.props.onShow === 'function') this.props.onShow();
      else if (!s && typeof this.props.onHide === 'function') this.props.onHide();
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {

    return (
      <div className={"btn-group " + (this.props.className || "")}>
        {/*<button type="button" className={"btn FButton "+(this.props.buttonClass || "")} onClick={this.setShow}><span className={this.props.buttonImage?"":(this.props.buttonIcon || this.props.icon || "fs-pencil")} />{this.props.buttonText || this.props.buttonImage}</button>*/}

        <label htmlFor={`${this.id}-doc`} className="upbtn btn FButton">
          <span className="FButtonIcon fa fa-upload"></span>
          <span className="FButtonText">Upload</span>
        </label>

        <Modal id={this.props.id} className="AttachModal btn-group" topButtons={this.props.topButtons} scroll={this.props.scroll} icon="fs-clipboard" title="Import Assessment" show={this.state.show} hideModal={e => this.setShow(false)} buttonCancel={e => this.setShow(false)}
          footerButtons={this.state.file && <FButton icon="fa fa-upload" onClick={this.importSurvey} >Import</FButton>}
        >

          <div>{this.state.notice}</div>

          <div style={{marginTop: '1.5em'}}>
            <label htmlFor="noreid">
              <input type="checkbox" id="noreid" value={this.state.noreid} onClick={()=>this.setState({noreid: !this.state.noreid})}></input> No re-id (for debugging only!)
            </label>
          </div>

        </Modal>

        <div className="imgupload">
          <div className="uploadButtons">
            <input type="file" accept={".json"} id={`${this.id}-doc`} onChange={this.addDoc} />
          </div>
        </div>
      </div>
    )
  }

}

/*
class MDE extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyChange: false
    }

    this.id = this.props.id || cuid() + "_mde";

    //this._bind('createEditor','eventWrapper','removeEvents','addEvents','addExtraKeys');
  }

  active = () => console.log("active");

  componentDidUpdate(prevProps, prevState) {
    if (this.simplemde && !this.state.keyChange && (this.props.value !== this.simplemde.value())) {
      this.simplemde.value(this.props.value);
      if (prevState.keyChange) this.setState({ keyChange: false });
      return;
    }

    if (this.state && this.props.show && !this.simplemde) {
      this.createEditor();
      this.addEvents();
      this.addExtraKeys();

      //this.simplemde.codemirror.refresh();
    }
  }

  componentWillUnmount() {
    if (this.simplemde) this.removeEvents();
  }

  createEditor = () => {
    const initialOptions = {
      element: document.getElementById(this.id),
      initialValue: this.props.value
    };

    const allOptions = Object.assign({}, initialOptions, this.props.options || {});
    this.simplemde = new EasyMDE(allOptions);
  }

  eventWrapper = () => {
    this.setState({ keyChange: true });
    if (typeof this.props.onChange === "function") this.props.onChange(this.simplemde.value());
  }

  removeEvents = () => {
    this.editorEl.removeEventListener('keyup', this.eventWrapper);
    this.editorToolbarEl && this.editorToolbarEl.removeEventListener('click', this.eventWrapper);
  }

  addEvents = () => {
    const wrapperId = `${this.id}-wrapper`;
    const wrapperEl = document.getElementById(wrapperId);

    this.editorEl = wrapperEl.getElementsByClassName('CodeMirror')[0];
    this.editorToolbarEl = wrapperEl.getElementsByClassName('editor-toolbar')[0];

    this.editorEl.addEventListener('keyup', this.eventWrapper);
    this.editorToolbarEl && this.editorToolbarEl.addEventListener('click', this.eventWrapper);
  }

  addExtraKeys = () => {
    // https://codemirror.net/doc/manual.html#option_extraKeys
    if (this.props.extraKeys) {
      this.simplemde.codemirror.setOption(
        'extraKeys',
        this.props.extraKeys
      );
    }
  }

  render() {
    const textarea = React.createElement('textarea', { id: this.id, onFocus: this.active });
    return React.createElement('div', { id: `${this.id}-wrapper`, className: this.props.className }, textarea);
  }
}
*/


/** 
 * Firesmart's Tree components all extend Container
 */ 
class Container extends Component {
  constructor(props) {
    super(props);

    if (!this.props.id) console.warn("Container components require props.id");
    this.state = { 
      children: [], 
      ...this.props.state
    };

    this.local = {}; //localdata

    this.watchChildren=false; //If set to true, automatically passes child docs when they change
    this._childdata={};
  }

  //Registers a change watcher for a given value id
  //Changes to that id will update this.state[id]
  addWatcher = id => {
    //console.log("Add watcher: ", this.props.id, id)	;
    this.props.registerChangeWatcher(this.runStateUpdate, id);
  }

  //Set a local variable - like setState but doesn't update state
  setLocal = v => {
    if(typeof v==='object') this.local = {...this.local, ...v};
    else if(typeof v ==='function') this.local = {...this.local, ...v(this.local)};
  }

	/**
	 * Update state if mounted
	 */
  updateState = (n, v) => { if (this._mounted !== false) this.setState(typeof n === 'object' ? n : { [n]: v }) };

	/**
	 * Executed whenever state is updated by the database
	 */
  /*stateUpdate = (newstate, oldstate) => {

  }*/

	/**
	 * Experimental! Sets local value before running database setValue
	 * !!Convert to setState function
	 */
  setValue = (name, value, quiet) => {
    //console.log("setValue shortcut!");
    //if (this._mounted !== false) this.setState({ values: Object.assign({}, this.state.values, typeof name === "object" ? name : { [name]: value }) });
    if (this._mounted !== false) this.setState(oldstate=>(
      { values: {...oldstate.values, ...(typeof name === "object" ? name : { [name]: value }) }}
    ));
    return this.props.actions.setValue(name, value, quiet);
  }

	/**
	 * Load generated children from database
	 */
  getChildren = async (newelements = [], oldelements = (this?.state?.elements?.slice()) || [], props={}) => {

    //console.log("getChildren: ",this.props.id,newelements);

    //const ch=this.state.children?Object.assign({}, this.state.children):[]; //Copy of children / blank array - overcautious?
    //const oldelements=(this.state.elements && this.state.elements.slice()) || [];

    //Return if arrays are identical and children consistent length
    if (arraysEqual(oldelements, newelements) && this.state?.children?.length === newelements.length) return;

    //No children? Easy.
    if (newelements.length === 0) {
      //console.log("No children");
      this.setState({ children: [], childinfo: [] });
      return;
    }

    const newchildren = new Array(newelements.length);
    const missingChildren = [];

    //Add any existing children
    newelements.forEach((el, i) => {
      const existingindex = oldelements.indexOf(el);
      if (existingindex > -1) newchildren[i] = this.state.children[existingindex];
      else missingChildren.push(el);
    })

    //console.log("getChildren: ",newchildren);

    //All children accounted for?
    if (missingChildren.length === 0) {
      //console.log("All children account for");
      this.setState({ children: newchildren });
      return;
    }

    //More children to get
    //mc && - account for deleted children
    //.filter(c=>c) - remove blank elements from array (caused by deleted children)
    try {
      var morechildren = await this.props.getElements(missingChildren, {...props, ...(this.watchChildren?{childWatcher: this.childWatcher}:{}), ...(this.props.childWatcher?{childWatcher: this.props.childWatcher}:{}) });
    } catch (err) {
      console.log("Error getting children ", err, missingChildren);

      return;
    }

    const children = newelements.map((c, i) => newchildren[i] || morechildren.find(mc => mc?.props?.id === newelements[i])).filter(c => c);

    const childinfo = children.map(c=>({id: c.key, type: c?.props?.type, name: c?.props?.state?.values?.name, values: c?.props?.state?.values}));

    if (this._mounted !== false) this.setState({ children, childinfo });
  }

	/**
	 * Add a new child and pass a prop to 
	*/
  addChildAndFlag = async (type, childid, values = {}, position, props = {}) => {

    const id = await this.props.actions.addChild(type, childid, values, position)

    if (this._mounted !== false) {
      this.setState({ newchild: id, newchildprops: { [id]: props } });
    }
  }

	/**
	 * Proxy for setState in that takes n,v or {[n]: v} format paramaters
	 */
  setVal = (n, v) => this.runStateUpdate(typeof n === 'object' ? n : { [n]: v });

  runStateUpdate = async (newstate) => {

    if (this._mounted === false) return; //Ugly

    if (newstate.edit && newstate.edit !== this.state.edit) {
      this.setState({ edit: newstate.edit });
      return;
    }

    const when = newstate.when; // || this.state.when || this.props.state.when;

    //if(when || this.state.when===0) this.updateEditDate(when?new Date(when):new Date(), new Date(this.state.editdate || 0), "runStateUpdate");
    if(when || this.state.when===0) this.updateEditDate(when?new Date(when):undefined, new Date(this.state.editdate || 0), "runStateUpdate");

    let oldstate;
    if (this.stateUpdate) oldstate = { ...this.state }; //!Speed???

    if (newstate) {
      if (newstate.elements) {
        this.getChildren(newstate.elements, [], this.props.functions && { functions: this.props.functions }); //functions??
      }

      if (this._mounted !== false) {
        //console.log("newstate: ", newstate)	;
        this.setState(newstate);
      }
    }

    if (this.stateUpdate) {
      this.stateUpdate(newstate, oldstate);
    }
  }

  //Passed to children by idfunc to watch their updates
  /*childWatcher = id => doc => {
    if(this._mounted!==false) {
      this.setState(oldstate=>({
        _childdata: { ...(oldstate?._childdata || {}), [id]: doc }
      }))
    }
  }*/
  childWatcher = id => doc => {
    //if(this._mounted!==false) {
      /*this.setState(oldstate=>({
        _childdata: { ...(oldstate?._childdata || {}), [id]: doc }
      }))*/

      //console.log("childWatcher..!", id, doc);

      this._childdata={ ...(this._childdata || {}), [id]: doc }
      if(this.childDidUpdate) this.childDidUpdate(id, doc);
    //}
  }
  /*childDidUpdate = (id, doc) => {

  }
  */

  componentDidMount() {
    //console.log("Component Mounted ",this.props.id, this.state);
    if (this.state.elements) this.getChildren(this.state.elements, [], this.props.functions && { functions: this.props.functions });

    this.props.registerChangeWatcher(this.runStateUpdate); //Register item to watch itself

    if(this.props.childWatcher) {

      this.props.registerChangeWatcher(this.props.childWatcher(this.props.id)); //Register parent to watch this child
      this.props.childWatcher(this.props.id)({...this.state, type: this.props.type, parentid: this.props.parentid, title: this.props.title, rootid: this.props.rootid}); //Initial population
    }

    if (this.watchlist) this.addWatcher(this.watchlist);

    if(this.props.state.when) {
      this.updateEditDate(new Date( this.props.state.when ), undefined, "componentDidMount");
    }

    //if(this.props.type==='AssessmentData') console.log("AssessmentData props: ", this.props, this.props.state.when);
  }

  componentWillUnmount() {
    this._mounted = false; //This is bad
  }

  /**
   * Update Edit Date from children (update if > local/current edit date)
   * @param {string} newdate 
   */
  updateEditDate = (newdate, olddate, callfrom) => {
    //console.log("updateEditDate: ", this.props.id, newdate, olddate, callfrom);

    if(!this.state.editdate || (olddate && newdate>olddate) || newdate>(this.state.editdate || new Date(0))) {

      //console.log("Update edit date: ", this.props.id, newdate, olddate);

      if(this._mounted!==false) this.setState(st=>(!st.editdate || newdate>st.editdate)?{editdate: newdate}:{});
      if(this.props.updateEditDate) this.props.updateEditDate(newdate, undefined, this.props.id);
    }
  }

  //!!"children" could (possibly) be made more efficient by maintaining list of parameters passed to children and only rerendering if there's a change

	/**
	*	props: parameters to add to all children: {param1: val, param2, val2 ...}
	*	propsId: props only to be passed if id matches
	*	propsIdFunc: calls each listed function with id of child
	*	types: includes only these types: type / [type1, type2, ...]
	*	untypes: excludes these types: type / [type1, type2, ...]
	*	orderByType: order by order of childTypes
	*	sendTypeToEnd: move this/these types to the end
	*	placeholder: JSX to return if no children
	*   excludeByValues: object containing key/value pairs to exclude
	*	selectIds: ony show these ids
	*/
  children = ({ props = false, types = false, untypes = false, orderByType = false, sendTypeToEnd = false, propsId = false, propsNotId = false, propsIdFunc = false, placeholder = false, excludeByValues = false, selectIds = false } = {}) => {

    //let c = Array.isArray(this.state.children)?this.state.children:[].concat(this.state.children);

    let c = ensureArray(this.state.children);

		/*if(selectIds) {
			const sid = [].concat(selectIds);
			c = c.filter(ch=>sid.includes(ch.props.id));
		}*/

    if (c.length === 0 && placeholder) return placeholder;

    if (excludeByValues) {
      const keys = Object.keys(excludeByValues);

      c = c.filter(ch => ch && !keys.find(k => (ch.props.values || {})[k] === excludeByValues[k]));
    }

		/*if(types || untypes) {
			const t=[].concat(types);
			const ut=[].concat(untypes);
			c=c.filter(ch=>(types && t.includes(ch.props.type)) || (untypes && !ut.includes(ch.props.type)));
		}*/

    if (types) {
      const t = ensureArray(types);
      c = c.filter(ch => (types && t.includes(ch.props.type)));
    }

    /*if (orderByType) {
      const out = [];
      ensureArray(this.props.childTypes).forEach(childType => c.forEach(ch => { if (c.props.type === childType.type) out.push(ch); }));
      c = out;
    }*/

    if (sendTypeToEnd) {
      const toEnd = ensureArray(sendTypeToEnd);

      const main = [];
      const end = [];

      c.forEach(ch => {
        if (ch && toEnd.includes(ch.props.type)) end.push(ch);
        else main.push(ch);
      })

      c = [].concat(main, end);
    }

    //if (props || propsId || propsNotId || propsIdFunc || selectIds || untypes) {
      //Always run for updateEditDate

      var pNIkey; //propsNotId
      if (propsNotId) for (pNIkey in propsNotId) break; //propsNotId

      const sid = selectIds ? ensureArray(selectIds) : false; //selectIds

      const ut = untypes ? ensureArray(untypes) : false;

      c = c.map(child => {

        const pif = propsIdFunc
          ? Object.keys(propsIdFunc).reduce((acc, key) => { acc[key] = propsIdFunc[key](child.props.id); return acc; }, {})
          : {};

        const pNI = (pNIkey && child.props.id !== pNIkey) ? propsNotId[pNIkey] : {}; //propsNotId

        const showChild = sid && !sid.includes(child.props.id) ? { hidden: true } : {}; //selectIds

        const unt = ut && ut.includes(child.props.type) ? { hidden: true } : {}; //untypes - exclude types

        return React.cloneElement(
          child,
          {
            updateEditDate: this.updateEditDate,
            ...(props || {}),
            ...(propsId && propsId[child.props.id]) ? propsId[child.props.id] : {},
            ...pNI,
            ...pif,
            ...showChild,
            ...unt
          }
        );
      })
    //}

    // If this child has just been added to this parent by this user, pass newchild prop
    if (this.state.newchild) {
      c = c.map(ch => ch.props.id === this.state.newchild ? React.cloneElement(ch, Object.assign({ newchild: true }, this.state.newchildprops[ch.props.id])) : ch);
    }

    return c;
  }

}

const GreyBox = ({ children, subtitle, onClick, right, large, xlarge, medium, notButton, popout, highlight, popoutTitle }) => (
  notButton
    ? <div className={`GreyBox${right ? ' right' : ''}${large ? ' large' : ''}${xlarge ? ' xlarge' : ''}${medium ? ' medium' : ''}${popout ? ' popout' : ''}${highlight ? ' highlight' : ''} notButton`} tabIndex={notButton ? -1 : undefined}>
      {children && <div className="GreyBox-main">{children}</div>}
      {subtitle && <div className="GreyBox-subtitle">{subtitle}</div>}
      {popout}
    </div>
    : <button className={`GreyBox${right ? ' right' : ''}${large ? ' large' : ''}${xlarge ? ' xlarge' : ''}${medium ? ' medium' : ''}${highlight ? ' highlight' : ''}`} tabIndex={notButton ? -1 : undefined} onClick={onClick}>
      {children && <div className="GreyBox-main">{children}</div>}
      {subtitle && <div className="GreyBox-subtitle">{subtitle}</div>}
    </button>
)

const FSearch = ({ onChange=(v)=>{console.log("FSearch value: ", v, getPath(v))} }) => {
  const [sdata, setsdata] = useState([]);
  const [show, setShow] = useState(false);
  const inp = useRef(null);
  //const [show, setShow] = useState(false);

  const showModal = sh => {
    const newshow = (sh===true || sh===false?sh:!show);
    if(newshow) {
      setsdata(getSearchData());
    }
    setShow(newshow);
  }

  //Option selected; close modal and execute onChange code
  const opSelect = v => {
    showModal(false);
    onChange(v);
  }

  useEffect(()=>{if(show) inp.current.focus();}, [show]);

  return (
    <div className="btn-group">
      <FButton icon="fa fa-search" onClick={showModal} />
      <Modal 
        oneline
        show={show} 
        hideModal={e=>showModal(false)}
        closeOnBackground
        >
        <SelectSearch options={sdata} onChange={opSelect} defaultValue="" name="search" placeholder="Search..." search 
          renderValue={valueProps=><input {...valueProps} ref={inp} tabIndex={0} placeholder="Search" className="select-search__input" style={{}} />} 
        />
      </Modal>
    </div>
  )
}

const Toast = ({ title, children, show }) => {

  if(!show) return null;

  return (
    <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <span>{title}</span>
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="toast-body">
        {children}
      </div>
    </div>
  )
}

const CopyTo = ({ move, onClick, elements }) => {

  const [working, setWorking] = useState(false);

  if(!(elements?.length)) return null;

  //Remove current section from list if 'move'
  const els = move
    ?elements.filter(el=>!el.parent)
    :elements

  const click = id => async e => {
    setWorking(true);
    await onClick(id);
    setWorking(false);
  }

  return (
    working
    ?<Loading title={false} />
    :<BSDrop icon={`fa fa-${move?'share-square-o':'clone'}`} buttonText={move?'Move To':'Copy To'} right>
      {els.map(el => 
        <BSDropItem onClick={click(el.id)} key={el.id}>
          {el.parent && '* '}{el.name}
        </BSDropItem>
      )}
    </BSDrop>
  )
}


export {
  FSMarkdown,
  Logo,
  arraysEqual,
  MDModal,
  HelpModal,
  InlineHelp,
  Namer,
  ReportIntro,
  InputGroup,
  StButtons,
  AddChildButtons,
  Loading,
  FButton,
  ExpandButton,
  UnlockButton,
  OnOffButton,
  FInfo,
  FormRow,
  FormText,
  FormTextArea,
  FormCheckBox,
  FormNumInc,
  FormNumber,
  FormImage,
  FormCombo,
  Modal,
  ButtonModal,
  Icondrop,
  IconButton,
  CheckButton,
  BSDrop,
  BSDropItem,
  BSDropHeading,
  SureButton,
  EditableA,
  ProgressiveDrop,
  CalcField,
  runFormula,
  FormulaField,
  /*ImgUpload,*/
  AttFile,
  AttachDocModal,
  AttachmentView,
  AssessmentImport,
  //MDE,
  Container,
  GreyBox,
  FSearch,
  Toast,
  CopyTo
  //ServiceWorkerWrapper
};