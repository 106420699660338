const help = `
Support Desk Contact: [info@firesmart.co.uk](mailto:info@firesmart.co.uk)

Fire Triangle

For a fire to start, three things are needed:

- a source of ignition;
- fuel; and
- oxygen.

If any one of these is missing, a fire cannot start. Taking measures to avoid the three coming together will therefore reduce the chances of a fire occurring.

Sources of Ignition

These sources could include:

- smokers' material, e.g. cigarettes, matches and lighters;
- naked flames, e.g. candles or gas or liquid-fuelled open-flame equipment;
- electrical, gas or oil-fired heaters (fixed or portable);
- hot processes, e.g. welding by contractors or shrink wrapping;
- cooking equipment;
- faulty or misused electrical equipment;
- lighting equipment, e.g. halogen lamps or display lighting too close to stored products;
- hot surfaces and obstruction of equipment ventilation, e.g. office equipment; and
- arson.

Sources of fuel

Anything that burns is fuel for a fire.

- flammable-liquid-based products, such as paints, varnishes, thinners and
adhesives;
- flammable liquids and solvents, such as white spirit, methylated spirit, cooking oils and disposable cigarette lighters;
- flammable chemicals, such as certain cleaning products, photocopier chemicals and dry cleaning that uses hydrocarbon solvents;
- packaging materials, stationery, advertising material and decorations;
	- plastics and rubber, such as video tapes, polyurethane foam-filled furniture and polystyrene-based display materials;
- textiles and soft furnishings, such as hanging curtains and clothing displays;
- waste products, particularly finely divided items such as shredded paper and
wood shavings, off cuts, and dust; and
- flammable gases such as liquefied petroleum gas (LPG).


Identify sources of oxygen

- some chemicals (oxidising materials), which can provide a fire with additional oxygen and so help it burn. These chemicals should be identified on their container (and Control of Substances Hazardous to Health data sheet, see Figure 4) by the manufacturer or supplier who can advise as to their safe use and storage;
- oxygen supplies from cylinder storage and piped systems, e.g. oxygen used in welding processes; and
- pyrotechnics (fireworks), which contain oxidising materials and need to be treated with great care.

IDENTIFYING PEOPLE AT RISK

- employees who work alone and/or in isolated areas, e.g. cleaners, security staff;
- people who are unfamiliar with the premises, e.g. seasonal workers, contractors, visitors and customers;
- people with disabilities14* or those who may have some other reason for not
being able to leave the premises quickly, e.g. elderly customers or parents
with children;
- other persons in the immediate vicinity of the premises; and
- people with language difficulties

Remove or reduce sources of ignition

- Wherever possible replace a potential ignition source by a safer alternative.
- Replace naked flame and radiant heaters with fixed convector heaters or a central heating system. Restrict the movement of and guard portable heating appliances.
- Seperate ignition hazards and combustibles e.g. ensure sufficient clear space between lights and combustibles.
- Operate a safe smoking policy in designated smoking areas and prohibit smoking elsewhere.
- Ensure electrical and mechanical and gas equipment is installed, used, maintained and protected in accordance with the manufacturer-s instructions.
- Check all areas where hot work (e.g. welding) has been carried out to ensure that no ignition has taken place or any smouldering materials remain that may cause of fire.
- Ensure that no-one carrying out work on gas fittings which involves exposing pipes that contain or have contained flammable gas uses any source of ignition such as blow-lamps or hot-air guns.
- Take precautions to avoid arson.

Remove or reduce sources of fuel

- Reduce stocks of flammable materials, liquids and gases on display in public areas to a minimum. Keep remaining stock in dedicated storerooms or storage areas where the public are not allowed to go, and keep the minimum required forthe operation of the business. 
- Ensure flammable materials, liquids and gases, are kept to a minimum, and are stored properly with adequate separation distances between them.
- Keep areas containing flammable gasses ventilated
- Do not keep flammable solids, liquids and gases together.
- Remove, or treat large areas of highly combustible wall and ceiling linings, e.g. polystyrene or carpet tiles, to reduce the rate of flame spread across the surface.
- Develop a formal system for the control of combustible waste by ensuring that waste materials and rubbish are not allowed to build up and are carefully stored until properly disposed of, particularly at the end of the day.
- Take action to avoid storage areas being vulnerable to arson or vandalism.
- Check all areas where hot work (e.g. welding) has been carried out to ensure that no ignition has taken place and no smouldering or hot materials remain that may cause a fire later.

Fire-detection and warning systems

-  a simple shout of -fire- or a simple manually operated device, such as a gong or air horn that can be heard by everybody when operated from any single point within the building.
- an electrical system incorporating sounders and manually operated call points (break-glass boxes).
-  an automatic fire detection system.
- You may need to consider special arrangements for times when people are working alone, are disabled, or when your normal occupancy patterns are different.

Escape routes

When determining whether your premises have adequate escape routes, you need to consider a number of factors, including:

- the type and number of people using the premises;
- escape time;
- the age and construction of the premises;
- the number and complexity of escape routes and exits;
- whether lifts can or need to be used;
- the use of phased or delayed alarm evacuation; and
- assisted means of escape/personal evacuation plans (PEEPS).
 

Emergency evacuation of persons with mobility impairment

The means of escape you provide must be suitable for the evacuation of everyove likely to be in your premises. This may require additional planning and allocation of staff roles - with appropriate training. Provsions for the emergency evacuation of disabled persons may include:

- stairways;
- evacuation lifts;
- firefighting lifts;
- horizontal evacuation;
- refuges; and
- ramps.

Notices 

Notices must be used, where necessary, to provide the following:

- instructions on how to use any fire safety equipment;
- the actions to be taken in the event of fire; and 
- help for the fire and rescue service (e.g. location of sprinkler valves or electrical cut-off switches).


Daily checks

- Remove bolts, padlocks and security devices from fire exits.
- Ensure that doors on escape routes swing freely and close fully and check escape routes to ensure they are clear from obstructions and combustible materials.
- Check the fire alarm panel to ensure the system is active and fully operational. 
- Where practicable,visually check that emergency lighting units are in good repair and working.
- Check that all safety signs and notices are legible. 
 

Weekly tests and checks

- Test fire-detection and warning systems and manually-operated warning devices weekly, following the manufacturer-s or installer-s instructions.
- Check the batteries of safety torches and that fire extinguishers and hose reels are correctly located and in apparent working order. 
- Fire pumps and standby diesel engines should be tested for 30 minutes each week 


Monthly tests and checks

- Test all emergency lighting systems and safety torches to make sure they have enough charge and illumination according to the manufacturer-s or supplier-s instructions.
- Check that fire doors are in good working order and closing correctly and that the frames and seals are intact.



Six-monthly tests and checks

A competent person should test and maintain the fire-detection and warning system. 


Annual tests and checks

- The emergency lighting and all firefighting equipment, fire alarms and other installed systems should be tested and maintained by a competent person.
- All structural fire protection and elements of fire compartmentation should be inspected and any remedial action carried out.


Emergency plans

You need to have an emergency plan for dealing with any fire situation. The purpose of an emergency plan is to ensure that the people in your premises know what to do if there is a fire and that the premises can be safely evacuated.

- If you or your organisation employ five or more people, or your premises are licensed or an alterations notice requiring it is in force, then details of your emergency plan must be recorded. Even if it is not required, it is good practice to keep a record.
- Your emergency plan should be based on the outcome of your fire risk assessment and be available for your employees, their representatives (where appointed) and the enforcing authority.
- In small offices and shops the emergency plan may be no more than a fire action notice.
- In multi-occupied, larger and more complex offices and shops, the emergency plan will need to be more detailed and compiled only after consultation with other occupiers and other responsible people, e.g. owners, who have control over the building.


Fire safety training

You must provide adequate fire safety training for your staff. The type of training should be based on the particular features of your premises and should:

- take account of the findings of the fire risk assessment;
- explain your emergency procedures;
- take account of the work activity and explain the duties and responsibilities of staff;
- take place during normal working hours and be repeated periodically where appropriate;
- be easily understandable by your staff and other people who may be present; and
- be tested by fire drills.

In larger premises, such as a supermarket with a high staff turnover and many shift patterns, the organisation of fire safety training will need to be planned. 

- what to do on discovering a fire;
- how to raise the alarm and what happens then;
- what to do upon hearing the fire alarm;
- the procedures for alerting members of the public and visitors including, where appropriate, directing them to exits;
- the arrangements for calling the fire and rescue service;
- the evacuation procedures for everyone in your office or shop to reach an assembly point at a place of total safety;
- the location and, when appropriate, the use of firefighting equipment;
- the location of escape routes, especially those not in regular use;
- how to open all emergency exit doors;
- the importance of keeping fire doors closed to prevent the spread of fire, heat and smoke;
- where appropriate, how to stop machines and processes and isolate power supplies in the event of a fire;
- the reason for not using lifts (except those specifically installed or nominated,
following a suitable fire risk assessment, for the evacuation of people with a disability);
- the safe use of and risks from storing or working with highly flammable and explosive substances; 
- the importance of general fire safety, which includes good housekeeping.


Managing fire safety

- who will hold the responsibility for fire safety at board level;
- who will be the responsible person for each of their premises (this will be the person who has overall control usually the manager);
- the arrangement whereby those responsible for fire safety will, where necessary, nominate in writing specific people to carry out particular tasks if there is a fire; and
- arrangements to monitor and check that individual persons responsible for fire safety are meeting the requirements of the fire safety law
- ensure good fire safety management by helping you establish your fire prevention measures, fire precautions and fire safety procedures (systems equipment and plans); and 
- assist you to carry out your fire safety risk assessment and identify any issues that need attention.


Dangerous substances: storage, display and use

- substitute highly flammable substances and materials with less flammable ones; 
- reduce the quantity of dangerous substances to the smallest reasonable amount necessary for running the business or organisation;
- correctly store dangerous substances, e.g.in a fire-resisting enclosure. All flammable liquids and gases should ideally be locked away, especially when the premises are unoccupied, to reduce the chance of them being used in an arson attack; and
- ensure that you and your employees are aware of the fire risk the dangerous substances present and the precautions necessary to avoid danger.


Flammable liquids

Highly flammable liquids present a particularly high fire risk. For example, a leak from a container of flammable solvents, such as methylated spirit, will produce large quantities of heavier-than-air flammable vapours. These can travel large distances, increasing the likelihood
of their reaching a source of ignition well away from the original leak.
The risk is reduced by ensuring the storage and use of highly flammable liquids is carefully managed, that materials contaminated with solvent are properly disposed of and when not in use, they are safely stored.
There should be no potential ignition sources in areas where flammable liquids are used or stored and flammable concentrations of vapours may be present. Any electrical equipment used in these areas, including fire alarm and emergency lighting systems, 
needs to be suitable for use in flammable atmospheres.



Aerosols

- All staff involved in the movement, storage and display of aerosol cans should be adequately instructed, trained and supervised. 
- Damaged and leaking aerosol cans should be removed immediately to a safe, secure, well ventilated place prior to disposal. Powered vehicles should not be used to move damaged stock, unless specially adapted for use in flammable atmospheres.
- Arrangements should be made for disposal at a licenced waste management facility.


Heating

- All heaters should be kept well clear of combustible materials and where they do not cause an obstruction. 
- Heaters which burn a fuel should be sited away from draughts. 
- Portable fuel burning heaters (including bottled gas (LPG)) should only be used in exceptional circumstances and if shown to be acceptable in your risk assessment.
- All gas heating appliances should be used only in accordance with manufacturer-s instructions and should be serviced annually by a competent person.
- In general, staff should be discouraged from bringing in their own portable heaters and other electrical equipment (e.g. kettles) into the premises.


Cooking processes

The following should be considered to reduce the risk from cooking processes:
- regular cleaning to prevent build-up of crumbs and other combustible material;
- fire resisting containers for waste products;
- a fire suppression system capable of controlling an outbreak of fire;
- monitored heat/oil levels, even after the cooking process is complete and installation of temperature control/cut-off/shut off devices as appropriate;
- duct, joints and supports able to withstand high cooking temperatures;
- seperation from wall and ceiling panels (with combustible insulation) e.g. 2.5m for walls, 4m for ceilings;
- insulation of ducts to prevent heating/ignition of nearby combustible wall and ceiling materials;
- a regular programme for inspection and cleaning;
- a programme of electrical and mechanical maintenance; 
- annual service of all gas heating appliances by a competent person


Smoking

Carelessly discarded cigarettes and other smoking materials are a major cause of fire. A cigarette can smoulder for several hours, especially when surrounded by combustible material. Many fires are started several hours after the smoking materials have been emptied into waste bags and left for future disposal.

- Consider prohibiting smoking in your premises other than in the designated smoking areas.
- Display suitable signs throughout the premises informing people of the smoking policy and the locations where smoking is permitted. 
- In those areas where smoking is permitted, provide non-combustible deep and substantial ashtrays to help prevent unsuitable containers being used.
- Empty all ashtrays daily into a metal waste bin and take it outside. 
- It is dangerous to empty ashtrays into plastic waste sacks which are then left inside for disposal later.



Hazards in corridors and stairways used as escape routes

Items that are a source of fuel, pose an ignition risk, or are combustible and likely to increase the fire loading or spread of fire, should not be located on any corridor or, stairway or circulation space that will be used as an escape route. 

- portable heaters, e.g. bottled gas (LPG) or electric radiant heaters and electric convectors or boilers;
- gas cylinders for supplying heaters;
- cooking appliances; and - unenclosed gas pipes, meters, and other fittings.
where more than one escape route is available and depending on the findings of your risk assessment, items such as those below may be acceptable if the minimum exit  widths are maintained and the item presents a relatively low fire risk:
- non-combustible lockers;
- vending machines;
- small items of electrical equipment (e.g. photocopiers); and
- small coat racks and/or small quantities of upholstered furniture which meets BS 7176 or the Furniture and Furnishings (Fire) (Safety) Regulations 1988.


Restricting the spread of fire and smoke. 


To reduce the risk to people if there is a fire, you need to consider how to control or restrict the spread of fire and smoke. The majority of people who die in fires are overcome by the smoke and gases.
It is important ensure that, in the event of fire, the rate of fire growth is restricted in its early  stages. It should also be noted that most measures which restrict the rate of fire growth in its early stages will also serve to restrict the
fire spread in its later stages.


Fire-resisting structures

Many buildings are divided into different areas by fire doors and fire-resisting walls and floors. These are partly designed to keep a fire within one area, giving people more time to escape.
You will need to identify which doors, walls and floors in your building are fire-resisting. 
There may be information available from when the building was built, if alterations have been made, or from a previously held fire certificate.
- High-risk areas should be seperated from the rest of the premises by 30-minute fireresisting construction.
Normally if there are fire doors in a wall, then the wall itself will also need to be fire-resisting.
If a wall or floor is required to be fire-resisting then you should not make any holes in it, e.g. for extra doors or pipe ducts, without consulting a competent person.


Smoke control 

In larger buildings and some units in shopping complexes, there may be some form of automatic smoke ventilation provided for the safety of the occupants and to assist firefighting (e.g. Smoke and Heat Exhaust Ventilation Systems (SHEVS)). 
These systems are designed to restrict the spread of fire and smoke usually by venting the heat and smoke through the roof or via other routes to the outside. Low level inlet air is essential for the operation of SHEVS and all openings for this purpose
should not be obstructed.


Arson

Measures to reduce arson may include the
following:

- ensure the outside of the premises is well lit and, if practical, secure the perimeter of the premises;
- thoroughly secure all entry points to the premises, including windows and the roof, but make sure that this does not compromise people-s ability to use the escape routes;
- make sure you regularly remove all combustible rubbish;
- do not place rubbish skips adjacent to the building and secure waste bins in a compound separated from the building;
- do not park vehicles or store goods or materials in the open next to windows or doors opening into buildings;
- encourage staff to report people acting suspiciously;
- remove automatic entry rights from staff who have been dismissed;
- ensure that your security alarm/firedetection system is monitored and acted on;
- secure flammable liquids so that intruders cannot use them;
- secure all storage areas and unused areas of the building that do not form part of an escape route against unauthorised access, ensure access to keys to those areas is restricted;
- fit secure metal letterboxes on the inside of letter flaps to contain any burning materials that may be pushed through.


Help for people with special needs:

Of all the people who may be especially at risk you will need to pay particular attention to people who may have special needs, including those with a disability. The Disability Rights Commission estimates that 11 million people in this country have some form of disability,
which may mean that they find it more difficult to leave a building if there is a fire. Under the Disability Discrimination Act,13 if disabled people could realistically expect to use your premises, then you must anticipate any reasonable adjustments that would make it easier for that
right to be exercised. The Disability Discrimination Act13 includes the concept of -reasonable adjustments- and this can be carried over into fire safety law. It can mean different things in different circumstances. For a small business, it may be considered reasonable to provide contrasting colours on a
handrail to help those with vision impairment to follow an escape route more easily. However, it might be unreasonable to expect that same business to install an expensive voice-alarm system. Appropriate -reasonable adjustments- for a large business or organisation may be much more significant.
If disabled people are going to be in your premises then you must also provide a safe means for them to leave if there is a fire. You and your staff should be aware that disabled people may not react, or can react differently, to a fire warning or small fire. You should give similar consideration to others with special
needs such as parents with young children or the elderly.


Manual call points

Manual call points, often known as -breakglass- call points, enable a person who discovers a fire to immediately raise the alarm and warn other people in the premises of the danger.  
Manual call points should normally be positioned so that, taking into account all fixtures and fittings, machinery and stock are in place, no one should have to travel more than 45m to the nearest alarm point. 
This distance may need to be less if your premises cater for people of limited mobility.


Portable firefighting equipment

Fires are classed according to what is burning.
Fire extinguishers provided should be appropriate to the classes of fire found in your premises.

Class of fire Description
Class A Fires involving solid materials such as wood, paper or textiles.
Class B Fires involving flammable liquids such as petrol, diesel or oils.
Class C Fires involving gases.
Class D Fires involving metals.
Class F Fires involving cooking oils such as in deep-fat fryers.

- If there is a possibility of a fire in your premises involving gases then you should seek advice from a competent person.
- It is not safe to fight fires involving aerosols with fire extinguishers.


Number and type of extinguishers 

Water extinguishers (red)
This type of extinguisher can only be used on Class A fires. They allow the user to direct water onto a fire from a considerable distance. A 9-litre water extinguisher can be quite heavy and some water extinguishers with additives can achieve the same rating, although they are
smaller and therefore considerably lighter. This type of extinguisher is not suitable for use on live electrical equipment.

Water extinguishers with additives (red)
This type of extinguisher is suitable for Class A fires. They can also be suitable for use on Class B fires and where appropriate, this will be indicated on the extinguisher. They are generally more efficient than conventional water extinguishers.

Foam extinguishers (cream)
This type of extinguisher can be used on Class A or B fires and is particularly suited to extinguishing liquid fires such as petrol and diesel. They should not be used on freeflowing liquid fires unless the operator has been specially trained, as these have the
potential to rapidly spread the fire to adjacent material. This type of extinguisher is not suitable for deep-fat fryers or chip pans.

Powder extinguishers (blue)
This type of extinguisher can be used on most classes of fire and achieve a good -knock down- of the fire. They can be used on fires involving electrical equipment but will almost certainly render that equipment useless. Because they do not cool the fire appreciably it
can re-ignite. Powder extinguishers can create a loss of visibility and may affect people who have breathing problems, and are not generally suitable for enclosed spaces. 

Carbon dioxide extinguishers (black)
This type of extinguisher is particularly suitable for fires involving electrical equipment as they will extinguish a fire without causing any further damage (accept in the case of some electronic equipment e.g. computers). As with all fires involving electrical equipment, the
power should be disconnected if possible.

Class -F- extinguishers
This type of extinguisher is particularly suitable for commercial catering establishments with deep-fat fryers. 


Selection, installation and maintenance of portable fire extinguishers:

All portable fire extinguishers will require periodic inspection, maintenance and testing. Depending on local conditions such as the likelihood of vandalism or the environment where extinguishers are located, carry out brief checks to ensure that they remain serviceable.
In normal conditions a monthly check should be enough. Maintenance by a competent person should be carried out annually. New fire extinguishers should comply with BS EN 3-7.79 Guidance on the selection and installation of fire extinguishers can be found in BS 5306-8,18 for maintenance, BS 5306-319
and for colour coding in BS 7863.20

Fire blankets:

Fire blankets should be located in the vicinity of the fire hazard they are to be used on, but in a position that can be safely accessed in the event of a fire. They are classified as either light duty or heavy duty. Light-duty fire blankets are suitable for dealing with small
fires in containers of cooking oils or fats and fires involving clothing. Heavy-duty fire blankets are for industrial use where there is a need for the blankets to resist penetration by molten materials.

Hose reels
Permanent hose reels installed in accordance with the relevant British Standard (BS EN 671-3: 200021) provide an effective firefighting facility. They may offer an alternative, or be in addition to, portable firefighting equipment. A concern is that untrained people will stay and fight a fire
when escape is the safest option. Where hose reels are installed, and your fire risk assessment expects relevant staff to use them in the initial stages of a fire, they should receive appropriate training.
It is not safe to fight fires involving aerosols with hose reels.

Maintenance of hose reels includes visual checks for leaks and obvious damage and should be carried out regularly. More formal maintenance checks should be carried out at least annually by a competent person.

Sprinkler systems:

Sprinkler systems can be very effective in controlling fires. They can be designed to protect life and/or property and may be regarded as a cost-effective solution for reducing the risks created by fire. Where installed, a sprinkler system is usually part of a package of fire precautions in a building and may form an
integral part of the fire strategy for the building. Sprinkler protection could give additional benefits, such as a reduction in the amount of portable firefighting equipment necessary, and the relaxation of restrictions in the design of buildings.


Access for fire engines and firefighters.

Buildings that have been constructed to modern building regulations or in accordance with certain local Acts will have been provided with facilities that allow fire engines to approach and park within a reasonable distance so that firefighters can use their equipment without too much difficulty.
These facilities may consist of access roads to the building, hard standing areas for fire engines and access into the building for firefighters. It is essential that where such facilities are 60 provided they are properly maintained and available for use at all relevant times.


Suitability of escape routes

You should ensure that your escape routes are:

- suitable;
- easily, safely and immediately usable at all relevant times;
- adequate for the number of people likely to use them;
- free from any obstructions, slip or trip hazards;
- available for access by the emergency services.


Travel distance

Having established the number and location of people and the exit capacity required to evacuate them safely, you now need to confirm that the number and location of existing exits is adequate. This normally determined by the distance people have to travel to reach them.
Below gives guidance on travel distances. It should be understood, however, that these distances are flexible and may be increased or decreased depending upon the level of risk after you have put in place the appropriate fire-prevention measures

Escape routes Suggested range of travel distance
Where more than one escape route is provided 
 25m in higher fire-risk area1
 45m in normal fire-risk area
 60m in lower fire-risk area2
Where only a single escape route is provided  
 12m in higher fire-risk area1
 18m in normal fire-risk area
 25m in lower fire-risk area

In new buildings which have been designed and constructed in accordance with modern  building standards the travel distances will already have been calculated. Once you have completed your fire risk assessment you need to confirm that those distances are still relevant.
When assessing travel distances you need to consider the distance to be travelled by people when escaping, allowing for walking around furniture or display material etc. The distance should be measured from all parts of the premises (e.g. from the most remote part of an office or shop on any floor) to the nearest
place of reasonable safety which is:
- a protected stairway enclosure (a storey exit);
- a separate fire compartment from which there is a final exit to a place of total safety;
- the nearest available final exit.


Measuring travel distance

The route taken through a room or space will be determinted by the layout of the contents e.g. work stations, aisle layout. It is good practice to ensure routes to the exits are
kept as direct and short as possible. In a small room there may be only one exit but in a larger room or area there may be many exits. In some cases where the contents are moved around or the space is liable to frequent change, e.g. in a storage area or where racking
is moveable you should ensure that the exits, or the routes to them, do not become blocked or the length of the route is not significantly extended.

Inner rooms
Where the only way out of a room is through another room, an unnoticed fire in the outer room could trap people in the inner room. This means of exit should be avoided where possible. If, however, this cannot be achieved then adequate warning of a fire should be provided by any one of the
following means:
- a vision panel between the two rooms providing adequate vision to give an indication of the conditions in the outer room and the means of escape;
- a large enough gap between the dividing wall and the ceiling, e.g. 500mm, so that smoke will be seen;
- an automatic smoke detector in the outer room that will sound a warning in the inner room 
In addition, the following points should also be considered:
- Restrict the number of people using an inner room to 60.
- Access rooms should be under the control of the same person as the inner room.
- The travel distance from any point in the inner room to the exit from the access room should be restricted to escape in one direction only unless there are alternative exits from the access room.
- No one should have to pass through more than one access room while making their escape.
- The outer room should not be an area of high fire risk.


Alternative exits

Where alternative exits from a space or room are necessary, they should wherever possible be located at least 45- apart unless the routes to them are seperated by fire-resisting construction. If in doubt consult a competent person. 


Measuring travel distances for initial dead-end travel.

Where the initial direction of travel in an open area or within an inner room is in one direction only the travel distance (A-B) should be limited to that for a -single escape route- 
Any alternative exits should be positioned to ensure a fire will not compromise both exits. 

Escape routes with dead-end conditions

If your premises has escape routes from which escape can be made in one direction only (a dead end), then an undetected fire in that area could affect people trying to escape. To overcome this problem, limit the travel distance and use one of the following solutions:

- Fit an automatic fire detection and warning system in those areas where a fire could present a risk to the escape route.
- Protect the escape route with fire-resisting construction to allow people to escape safely past a room in which there is a fire. 
- Provide an alternative exit.
Alternative approaches may be acceptable, although expert advice may be necessary.


Basement, escape and protection

In all buildings with basements (other than very small basements), stairways serving upper floors should preferably not extend to the basement and in any case should not do so where they are the only stairway serving the upper floors. Any stairway that does extend from the basement to the upper floors should
be seperated at basement level by a fire resisting lobby or corridor between the basement and the stairway. All basements used by more than 60 people or where there are no exits directly to a place of total safety, should have at least two protected escape stairways.
`;

export default help;