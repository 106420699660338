//import { read } from 'fs';

/*
Firesmart JavaScript utils
*/

require('blueimp-canvas-to-blob');

//const piexif = require('piexifjs');

//const 'EXIF' from 'exif';

const cloneObject = obj => JSON.parse(JSON.stringify(obj));

//Select all text in a contenteditable control
const selectElementContents = el => {
  var range = document.createRange();
  range.selectNodeContents(el);
  var sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}

//Remove all text selections
const deselectAll = () => {
  if (document.selection) {
    document.selection.empty();
  }
  else if (window.getSelection) {
    window.getSelection().removeAllRanges();
  }
}

/*
Resizes img using canvas step-down to <= maxdim for both width and height
*/
const resize64 = (src, maxdim = 2048, type = 'image/jpeg', quality = 0.9) => {
  return new Promise((resolve, reject) => {
    let tmp = new Image();

    tmp.src = src;
    tmp.onload = () => {
      let w = tmp.naturalWidth;
      let h = tmp.naturalHeight;
      let canvas;

      //var exif = piexif.load(e.target.result);

			/*EXIF.getData(tmp, () => {
				const exd=EXIF.getAllTags(this);
				alert(exd);
			});*/

      do {
        canvas = document.createElement('canvas');
        if (w > maxdim || h > maxdim) {
          w /= 2;
          h /= 2;
        }
        canvas.width = w;
        canvas.height = h;
        canvas.getContext('2d').drawImage(tmp, 0, 0, w, h);
      }
      while (w > maxdim || h > maxdim);

      resolve(canvas.toDataURL(type, quality));
    }
  });
}

//Returns given file size in human-readable format
const humanFileSize = size => {
  const i = size ? Math.floor(Math.log(size) / Math.log(1024)) : 0;
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

/*const jpegOrientation = blob =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = e => {

			const view = new DataView(e.target.result);
			if (view.getUint16(0, false) !== 0xFFD8) return resolve(-2);
			const length = view.byteLength;
			let offset = 2;
			while (offset < length) {
			  var marker = view.getUint16(offset, false);
			  offset += 2;
			  if (marker === 0xFFE1) {
			  	offset+=2;
			    if (view.getUint32(offset, false) !== 0x45786966) return resolve(-1);
			    const little = view.getUint16(offset += 6, false) === 0x4949;
			    offset += view.getUint32(offset + 4, little);
			    const tags = view.getUint16(offset, little);
			    offset += 2;
			    for (var i = 0; i < tags; i++)
			      if (view.getUint16(offset + (i * 12), little) === 0x0112)
			        return resolve(view.getUint16(offset + (i * 12) + 8, little));
			  }
			  else if ((marker & 0xFF00) !== 0xFF00) break;
			  else offset += view.getUint16(offset, false);
			}
			return resolve(-1);
		};
		reader.readAsArrayBuffer(blob);
	})*/

const rotateBlob = (blob, degrees, type = 'image/jpeg', quality = 0.5) => {
  return new Promise((resolve, reject) => {

    if (!blob.type.startsWith("image")) return resolve(blob);

    let tmp = new Image();

    tmp.onload = e => {

      let w = tmp.naturalWidth;
      let h = tmp.naturalHeight;

      let canvas;

      canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d'); // .drawImage(tmp,0,0,w,h);

      if (degrees === 90 || degrees === 270) {
        canvas.width = h;
        canvas.height = w;
      } else {
        canvas.width = w;
        canvas.height = h;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (degrees === 90 || degrees === 270) {
        ctx.translate(h / 2, w / 2);
      } else {
        ctx.translate(w / 2, h / 2);
      }
      ctx.rotate(degrees * Math.PI / 180);
      ctx.drawImage(tmp, -w / 2, -h / 2);

      canvas.toBlob(blob => resolve(blob), type, quality);
    }

    const reader = new FileReader();
    reader.onloadend = e => {
      tmp.src = URL.createObjectURL(blob);
    }
    reader.readAsDataURL(blob);

  });
}

const resizeBlob = (blob, maxdim = 1024, type = 'image/jpeg', quality = 0.5) => {
  return new Promise((resolve, reject) => {

    //console.log("resize blob");

    if (!blob.type.startsWith("image")) return resolve(blob);

    let tmp = new Image();

    //let orientation = 0;

    //let ori=0;

    tmp.onload = e => {

      let w = tmp.naturalWidth;
      let h = tmp.naturalHeight;

      let canvas;

      //console.log("Orientation: ", orientation);

      do {
        canvas = document.createElement('canvas');
        if (w > maxdim || h > maxdim) {
          w /= 2;
          h /= 2;
        }
        canvas.width = w;
        canvas.height = h;
        canvas.getContext('2d').drawImage(tmp, 0, 0, w, h);
      }
      while (w > maxdim || h > maxdim);

			/*if(orientation) {
				var ctx = canvas.getContext("2d");
				var x = 0;
				var y = 0;
				ctx.save();
				if (orientation === 2) {
						x = -canvas.width;
						ctx.scale(-1, 1);
				} else if (orientation === 3) {
						x = -canvas.width;
						y = -canvas.height;
						ctx.scale(-1, -1);
				} else if (orientation === 4) {
						y = -canvas.height;
						ctx.scale(1, -1);
				} else if (orientation === 5) {
						canvas.width = h;
						canvas.height = w;
						ctx.translate(canvas.width, canvas.height / canvas.width);
						ctx.rotate(Math.PI / 2);
						y = -canvas.width;
						ctx.scale(1, -1);
				} else if (orientation === 6) {
						canvas.width = h;
						canvas.height = w;
						ctx.translate(canvas.width, canvas.height / canvas.width);
						ctx.rotate(Math.PI / 2);
				} else if (orientation === 7) {
						canvas.width = h;
						canvas.height = w;
						ctx.translate(canvas.width, canvas.height / canvas.width);
						ctx.rotate(Math.PI / 2);
						x = -canvas.height;
						ctx.scale(-1, 1);
				} else if (orientation === 8) {
						canvas.width = h;
						canvas.height = w;
						ctx.translate(canvas.width, canvas.height / canvas.width);
						ctx.rotate(Math.PI / 2);
						x = -canvas.height;
						y = -canvas.width;
						ctx.scale(-1, -1);
				}
				ctx.drawImage(tmp, x, y,w,h);
				ctx.restore();
			}*/

      canvas.toBlob(blob => resolve(blob), type, quality);
    }

    const reader = new FileReader();
    reader.onloadend = e => {

      /*if (blob.type.startsWith("image/jpeg") || blob.type.startsWith("image/jpg")) {
        const exif = piexif.load(e.target.result);
        orientation = exif["0th"][piexif.ImageIFD.Orientation];
      }*/

      tmp.src = URL.createObjectURL(blob);
    }
    reader.readAsDataURL(blob);

  });
}

//Throws descriptive error if blob size> size
const maxBlobSize = (blob, size) =>
  new Promise((resolve, reject) => {
    if (blob.size > size) return reject(new Error("File is too large (" + humanFileSize(blob.size) + ")! The maximium file size allowed is " + humanFileSize(size)));
    else return resolve(blob);
  })

//List .fs- stylesheet options
let ic = [];
const fsicons = () => {
  if (ic.length === 0) {
    var sSheetList = document.styleSheets;
    var ic2 = [];

    for (var sSheet = 0; sSheet < sSheetList.length; sSheet++) {
      var ruleList = document.styleSheets[sSheet].cssRules;
      for (var rule = 0; rule < ruleList.length; rule++) {
        if (
          ruleList[rule].selectorText &&
          (
            (ruleList[rule].selectorText.match(/^\.fs-/)) ||
            (ruleList[rule].selectorText.match(/^\.fa-/) && rule > 34) ||
            (ruleList[rule].selectorText.match(/^\.ion-/))
          )
        ) ic2.push(ruleList[rule].selectorText.replace(/::before$/g, "").replace(/\./g, ""));
      }
    }
    ic = ic2;
  }

  return ic;
}

//Collate icons into array of arrays of five icons
let icc = [];
const fsiconscols = () => {
  const cols = 10, icons = fsicons();
  if (icc.length === 0) {
    for (let cn = 0; cn < icons.length; cn += cols) {
      icc.push(icons.slice(cn, cn + cols));
    }
  }
  return icc;
}

//Flattens a multi-dimensional array
const flatten = arr =>
  arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), []);

const getOrdinal = n => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const ukdate = ms => { const d = ms ? new Date(ms) : new Date(); return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear() };

const omit = (keys, obj) =>
  Object.keys(obj).reduce((acc, key) => {
    if (keys.indexOf(key) === -1 && obj[key] !== undefined) {
      acc[key] = obj[key]
      return acc
    }
    return acc
  }, {})

const getComputedRowHeight = (node, rows) => {
  if (!rows) {
    return 0
  }

  const { lineHeight, paddingBottom, paddingTop } = window.getComputedStyle(node)

  return parseInt(lineHeight, 10) *
    parseInt(rows, 10) +
    parseInt(paddingBottom, 10) +
    parseInt(paddingTop, 10)
}

const getHeight = (node, rows) => {

  return Math.max(
    getComputedRowHeight(node, rows),
    node.scrollHeight,
    32
  )
}

const arraysEqual = (a, b) => a.length === b.length && !a.some((el, i) => el !== b[i]);

//const isArray = obj => !!obj && obj.constructor === Array;
/**
 * Efficiently check if the passes variable is an array
 * @param {*} obj 
 * @returns Boolean
 */
const isArray = obj => obj?.constructor === Array;

/**
 * Ensures that the passed variable is an array. If not, it is either added to an array 
 * @param {*} obj 
 * @returns 
 */
const ensureArray = obj => isArray(obj)
  ? obj
  : typeof obj === 'undefined'
    ? []
    : [obj];

/**
 * Searches in the url parameter (location.search) and returns the parameter or false
 * if the parameter couldn't be found
 * @param {String} paramName the name of the url parameter to search for
 */
/*const getUrlParam = paramName => {
	//splites '?' off
	const searchSplitted = window.location.search.split("?");
	//if no '?' was found, return false
	if (searchSplitted.length === 1) return false;
	const search = searchSplitted[1];
	// split for '&' and query them
	let pair = search.split("&").map(param => param.split("=")).find(param => {
		const name = param[0];
		if (paramName === name) {
			if (param.length < 2) {
				return true;
			}
			return true
		}
		return false;
	});
	if (pair !== false && pair.length > 1) {
		return decodeURIComponent(pair[1]);
	}
	return false;

}*/

function convertRemToPixels(rem) {    
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function isItIE() {
  var user_agent = navigator.userAgent;
  var is_it_ie = user_agent.indexOf("MSIE ") > -1 || user_agent.indexOf("Trident/") > -1;
  return is_it_ie; 
}

export {
  cloneObject,
  selectElementContents,
  deselectAll,
  resize64,
  humanFileSize,
  rotateBlob,
  resizeBlob,
  maxBlobSize,
  //jpegOrientation,
  fsicons,
  fsiconscols,
  flatten,
  getOrdinal,
  ukdate,
  omit,
  getComputedRowHeight,
  getHeight,
  arraysEqual,
  isArray,
  ensureArray,
  convertRemToPixels,
  isItIE
  //getUrlParam
};
