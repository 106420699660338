/**
 * Firesmart main JSX
*/

import React, { Component, Fragment, useState, useEffect, useRef } from 'react';
//import { BrowserRouter as Router, Route, Link } from "react-router-dom";
//import sum from 'hash-sum';

import loadinggif from './loading.gif';

//Polyfills
import 'url-search-params-polyfill';
//import 'whatwg-fetch';

// require('default-passive-events');

// import CreatableSelect from 'react-select';

import './css/fsfont.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ionicons/dist/css/ionicons.min.css';

//import { Markdown } from 'react-showdown';

//import { Detector } from "react-detect-offline";


import {
  arraysEqual,
  isArray,
  ensureArray,
  convertRemToPixels,
  isItIE
}
  from './FSUtils';

import {
  // watchValue,
  // updateWatch,
  // resetWatchers,
  // addSiteChangeWatcher,
  setUserPassword,
  localData,
  resetSiteWatchers,
  timeStamp,
  loadUseremail,
  userlogin,
  userSync,
  cancelUserSync,
  siteSync,
  cancelSiteSync,
  userlogout,
  //trac,
  isSuperUser,
  openSiteDB,
  email,
  pos,
  clearPos,
  surveyRootId,
  idFromSurveyRoot,
  addSurvey,
  cloneSurvey,
  importSurvey,
  deleteSurvey,
  downloadSurvey,
  cloneRisk,
  /* getSelectedRisks, */
  //getCategories,
  // getEscapeRouteRoot,
  // getRisks,
  /// getLocations,
  categoryshow,
  // getLiveRisks,
  getPrecautions,
  getPrecautionMeasures,
  getExtinguisherCategories,
  getExtinguisherList,
  // getExtinguishers,
  // getEscapeRoutes,
  getRiskActions,
  getLicenceActions,
  getSections,
  // getAreaActions,
  // getAreaRisks,
  // getParentId,
  //getAudits,
  //addAudit,
  // closeAction,
  // getAttachments,
  getAssessmentLoginDate,
  updateAssessmentLoginDate,
  updateSiteValue,
  siteActions,
  siteAttachments,
  userItems,
  siteItems,
  adminFetch,
  getPath
} from './FSDataModel';

import {
  FSMarkdown,
  // MDModal,
  // Logo,
  HelpModal,
  InlineHelp,
  Namer,
  ReportIntro,
  InputGroup,
  StButtons,
  AddChildButtons,
  Loading,
  FButton,
  ExpandButton,
  FInfo,
  // FormRow,
  FormText,
  FormTextArea,
  /* FormNumInc, */
  FormNumber,
  FormImage,
  FormCombo,
  /* FormCheckBox, */
  Icondrop,
  IconButton,
  // CheckButton,
  BSDrop,
  BSDropItem,
  BSDropHeading,
  SureButton,
  Modal,
  ButtonModal,
  EditableA,
  ProgressiveDrop,
  /* runFormula, */
  FormulaField,
  CalcField,
  /* ImgUpload, */
  /* AttFile, */
  AttachDocModal,
  AttachmentView,
  AssessmentImport,
  // MDE,
  Container,
  GreyBox,
  FSearch,
  UnlockButton,
  OnOffButton,
  //Toast,
  //ServiceWorkerWrapper
  //CheckButton,
  CopyTo,
  //FormCheckBox
} from './FSReactLib';

import CacheBuster from './cachebuster';

import './css/firesmart.scss';
//import './css/editor.css';

import gh from './help.js';

import homeintro from './homeintro';

import isOnline from 'is-online';

const cuid = require('cuid');


//const dbserver = process.env.REACT_APP_DB_SERVER;
const adminserver = process.env.REACT_APP_ADMIN_SERVER;


var globalhelp = gh;


fetch(`/files/globalhelp.md`)
.catch(err=>{console.log("gh fetch error: ", err)})
.then(h=>h?.ok && h.text())
.then(t=>{
  if(t) globalhelp=t;
})



//Login button colour
const lbcol = process.env.REACT_APP_LOGINBUTTONCOL;

var fslogo;

/*
try {
  // eslint-disable-next-line
  fslogo = require('./altlogo.jpg').default;
} catch (e) {
  fslogo = require('./fs.jpg').default;
}
*/
if(process.env.REACT_APP_ALTLOGO) {
  fslogo = require(process.env.REACT_APP_ALTLOGO);
} else {
  fslogo = require('./fs.jpg');
}


//const gh = require('./help.md');


const MainContext = React.createContext();

//let subsite = 'alpha';

let actualname = '';

const rv = (success = false, message = '', code = 0, data = {}) => ({ success, message, code, ...data });

/*if(window.location.hostname.includes('alpha')) subsite='alpha';
else if(window.location.hostname.includes('beta')) subsite='beta';*/


/*
if (process.env.NODE_ENV !== 'production') {
  const {whyDidYouUpdate} = require('why-did-you-update');
  whyDidYouUpdate(React);
}
*/

const validateEmail = (email) => {
  const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}

const EditUsers = ({ members = [], share, unshare }) => {
  const [newuser, setNewuser] = useState(''); //Username (email)
  //const [isSuper, setSuper] = useState(false); //Super User?
  const isSuper = false;
  //const [newuser, setNewuser] = useState('');
  const [message, setMessage] = useState(''); //(Error) message
  const [messageLevel, setMessageLevel] = useState(''); //Error severity

  const [loading, setLoading] = useState(false); //True = waiting

  const validEmail = validateEmail(newuser);

  //Share to the user with the entered email address
  const doShare = async () => {
    setLoading(true);
    setMessage('');
    setMessageLevel('alert-primary');

    if (members.includes(newuser)) return;

    const sh = await share(newuser, isSuper);

    if (!sh.success) {
      setMessage(sh.message);
      setMessageLevel('alert-warning');
    }

    setLoading(false);
  }

  const doUnshare = async email => {
    setLoading(true);
    setMessage('');
    setMessageLevel('alert-primary');

    const sh = await unshare(email);

    if (!sh.success) {
      setMessage(sh.message);
      setMessageLevel('alert-warning');
    }

    setLoading(false);
  }

  const exists = newuser && members.includes(newuser);

  return (
    <div className="EditUsers">
      <div>
        <div className="form-group">
          <label>Exiting Users</label>

          {members.map(u =>
            <div key={u} className="input-group">
              <span className="form-control input-group-text">{u}</span>
              <div className="input-group-append">
                {u !== email() && <SureButton icon="fs-user" overlay="X" onClick={e => doUnshare(u)} buttonText="Deny">Remove {u}'s Access?</SureButton>}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="form-group">
        <label>Add User</label>
        <div className="input-group AddUser">

          {message && <div className={`alert ${messageLevel || 'alert-primary'}`}>{message}</div>}

          <FormText type="email" className="form-control" name="email" value={newuser} update={(n, v) => setNewuser(v)} placeholder="Email Address" live />

          <div className="input-group-append">
            {/*<FButton icon={isSuper?"fs-user-shield":"fs-user"} overlay={<span className={isSuper?'fs-check':'fs-times'} />} onClick={()=>setSuper(!isSuper)}>Super?</FButton>*/}
            {loading
              ? <Loading title={false} />
              : <FButton icon={isSuper ? "fs-user-shield" : "fs-user"} overlay="+" onClick={doShare} disabled={!newuser || !validEmail || exists}>Add {isSuper ? 'Super' : 'User'}</FButton>
            }
          </div>
        </div>
        <small className="form-text text-muted">{newuser && !validEmail && 'Invalid Email Address'} <small className="form-text text-muted">{exists && 'User exists'}</small>&nbsp;</small>
      </div>

    </div>
  )
}

const OrderField = ({ children, reverse, onClick } = {}) => <button onClick={onClick} className="abutton">{children}</button> //<span className={reverse?'fs-arrow-1-up':'fs-arrow-1-down'} />

// Fields that are set in SiteData when site is created
const siteDataFields = [
  { name: 'email', title: 'Email', readonly: false },
  { name: 'contact', title: 'Contact', readonly: false },
  { name: 'company', title: 'Company', readonly: false },
  { name: 'address', title: 'Address', readonly: false },
];

const ShowContainer = ({ show, refGrandparent, headerId, noScroll, className, style, children }) => {
  const el = useRef(null);

  useEffect(() => {
    //This element
    if (show && !noScroll) {
      const elem = el.current;

      //Header + dimensions
      const headerEl = 
        headerId
        ?document.getElementById(headerId).querySelector('.card-header')
        :refGrandparent 
          ?elem.parentElement.parentElement.querySelector('.card-header') 
          :elem.parentElement.querySelector('.card-header, .EP-heading, .ER-heading');

      if (!headerEl) return;
      const headerRec = headerEl.getBoundingClientRect();

      //Element dimensions
      const elemRec = elem.getBoundingClientRect();

      //Scroll to below header
      const ydiff = elemRec.top - headerRec.bottom;

      //console.log("HeaderEl: ", headerEl, elemRec.top, headerRec.bottom, ydiff);

      //console.log("sf: ", {show, refGrandparent, headerId, noScroll, headerEl, et: elemRec.top, hb: headerRec.bottom, ydiff});

      if ((headerId && ydiff<0) || (!headerId && ydiff)) {
        //console.log("Scroll fix: ", ydiff);
        window.scrollBy(0, ydiff - (headerId?convertRemToPixels(4.5):0));
      }
      else {
        const footerpos = document.getElementById('footerbar').getBoundingClientRect().top;

        if (elemRec.top > (footerpos - 100)) {
          //console.log("Baseline jog: ", elemRec.top, footerpos);
          window.scrollBy(0, 100);
        }
      }
    }
  }, [show, headerId, noScroll, refGrandparent])

  return (
    <div hidden={!show} ref={el} className={className} style={style}>
      {children}
    </div>
  )
}

/* New Folder strcuture */
class BaseFolder extends Container {

  clearmessage = () => this.setState({ message: false });

  setSettingsValue = (n, v) => {

    localData(n, v);

    if (n === 'actualname') actualname = v; //Global value

    this.setValue(n, v);
  }

  /*addSite = async () => {
    let response;

    try {
      response = await adminFetch('sites', 'create', {
        email: email(),
        isSuper: true,
        name: this.state.newSiteName,
        address: this.state.newSiteAddress,
        members: this.state.newMembers || []
      } ) // false, true  = ad
    } catch (err) {
      this.setState({message: 'Error creating site: ', err});
      return;
    }

    this.setState({message: response.error || response.message, newSiteName: '', newSiteAddress: '', newMembers: []});
  }*/

  /*addSiteFields = id => (fields, searchterms) => {

    if(this._mounted!==false) this.setState({
      siteFields: [...new Set([].concat(this.state.siteFields || [], fields))],
      searchterms: {...this.state.searchterms, [id]: searchterms}
    })
  }*/

  /**
   * Function to pass to setState that mutates existing state
   */
  siteUpdater = (id, data = {}, name, loadSite, refresh) => prevState => {

    const newState = {};

    if (data && data.fieldnames) newState.siteFields = [...new Set([].concat(prevState.siteFields || [], data.fieldnames))];

    newState.siteData = (prevState.siteData || [])
      .filter(sd => sd.siteid !== id)
      .concat(data.data
        ? data.data.map(d => (
          {
            siteid: id,
            sitename: data.name,
            aid: d.id,
            aname: d.name,
            apdf: d.assessment,
            adate: (d.assessment || {}).when,
            fields: d.fields.reduce((acc, f) => { if (f.name) acc[f.name] = f.value; return acc }, {}),
            members: (data.members || {}).names || [],
            search: `${data.name}|${d.name}|${d.fields.map(f => f.value).join('|')}`.toUpperCase(),
            priorities: d.priorities,
            loadSite,
            refresh
          }
        ))
        : {
          siteid: id,
          sitename: name || data.name || 'Site',
          members: (data.members || {}).names || [],
          search: (data.name || '').toUpperCase(),
          loadSite,
          refresh
        }
      )

    return newState;
  }

  setOrderBy = (orderBy, userField = false) => this.setState({ orderBy, reverseOrder: this.state.orderBy !== orderBy ? false : !this.state.reverseOrder, userField });

  isReversed = field => this.state.orderBy === field && this.state.reverseOrder;

  /**
   * Sort a field / column into a reasonable order based upon orderBy and reverseOrder state variables
   */
  sortFields = (a, b) => {

    //Is this a user defined field?
    const fa = this.state.userField ? (a.fields || {}) : a;
    const fb = this.state.userField ? (b.fields || {}) : b;

    //Reverse order? + fetch specific field
    const v1 = this.state.reverseOrder ? fb[this.state.orderBy] : fa[this.state.orderBy];
    const v2 = this.state.reverseOrder ? fa[this.state.orderBy] : fb[this.state.orderBy];

    if (typeof v1 === 'object') {
      if (typeof v2 !== 'object') return -1;

      let out = false;

      Object.keys(v1).find(k => {
        if (v1[k] === v2[k]) return false;
        else {
          out = v1[k] < v2[k] ? 1 : -1;
          return true;
        }
      })

      if (out !== false) return out;
      else return 0;
    }

    if (v1 === v2 || (v1 === '' && typeof v2 === 'undefined') || (v2 === '' && typeof v1 === 'undefined')) return a.sitename < b.sitename ? -1 : 1;

    if (typeof v1 === 'undefined' || v1 === '') return this.state.reverseOrder ? -1 : 1;
    if (typeof v2 === 'undefined' || v2 === '') return this.state.reverseOrder ? 1 : -1;

    return v1 < v2 ? -1 : 1;
  }

  loadSite = async (id, name) => {
    this.setState({ loadingSite: id, viewgrid: false });
    await this.props.functions.loadSite(id, name);
    if (this._mounted !== false) this.setState({ loadingSite: false });
  }

  addSiteData = id => (data, name, loadSite, refresh) => {
    if (this._mounted !== false) this.setState(this.siteUpdater(id, data, name, loadSite, refresh));
  }

  _dateExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])$');

  formatField = (val = '') => {
    //Looks like date?
    if (val && (val.startsWith('20') || val.startsWith('19')) && this._dateExp.test(val)) return ukdate(val); //Number.isInteger((val || '').charAt(0)) && 

    return val;
  }

  /**
   * Add a new user to a site
   */
  share = async (site, user, isSuper = false) => {
    /*
      post.sharebyemail; //User doing adding
      const sharetoemail = post.sharetoemail; //Array of users to add
      const siteid = post.site;
    */

    try {
      var res = await adminFetch('sites', 'share', {
        sharebyemail: email(),
        sharetoemail: user,
        isSuper,
        site
      }) //,true = admin !!!
    } catch (err) {
      console.log("Share error: ", err);
      return rv(false, `Error sharing site: `, err);
    }

    if (res && res.success) {

      console.log("Share res: ", res);

      //Reload this Site
      const s = this.state.siteData.find(s => s.siteid === site);

      if (s) {
        await s.refresh();
      }

    }

    return rv(res.success, res.message);
  }

  unshare = async (site, user) => {
    try {
      var res = await adminFetch('sites', 'unshare', {
        sharebyemail: email(),
        sharetoemail: user,
        site: site
      }) //,true = admin
    } catch (err) {

      return rv(false, `Error unsharing site: `, err);
    }

    if (res && res.success) {

      //Reload this Site
      const s = this.state.siteData.find(s => s.siteid === site);

      if (s) {
        await s.refresh();
      }

    }

    return rv(res.success, res.message);
  }

  componentDidMount() {
    super.componentDidMount();

    this.setSettingsValue("actualname", this.state.values.actualname);
  }

  render = () => {

    //console.log("orderBy: ", this.state.orderBy, this.state.reverseOrder, this.state.userField);

    /*let hideChildren={};
    if(this.state.siteSearch) {
      hideChildren = 
        this.state.elements
          .filter(cid=>!((this.state.searchterms[cid] || []).find(st=>(st || '').includes(this.state.siteSearch))))
          .reduce((ac,cv)=>({...ac, [cv]:{hidden: true}}), {});
    }*/

    const search = (this.state.siteSearch || '').toUpperCase();

    let siteData = search
      ? this.state.siteData.filter(sd => sd.search.includes(search))
      : this.state.siteData;

    let children = this.children({
      //props: {
      /*viewgrid: this.state.viewgrid || false, */
      /*siteFields: this.state.siteFields*/
      //},
      props: {
        loadSite: this.loadSite,
        ...(this.state.loadingSite ? { disabled: true } : {})
      },
      /*propsId: hideChildren,*/
      ...(search ? { selectIds: siteData.map(sd => sd.siteid) } : {}), //may need to convert to hiding child nodes
      /*...(siteData?{propsId: siteData.map(sd=>sd.siteid).reduce((ac,cv)=>({...ac, [cv]:{hidden: true}}), {})}:{}),*/
      propsIdFunc: { addSiteData: this.addSiteData /*, addSiteFields: this.addSiteFields*/ },
      /* propsNotId: (this.state.loadingSite?{[this.state.loadingSite]:{disabled: true}}:false), */ //Actually, disabled *all* site if one is loading
      placeholder: <p className="placeholder">Please Add a Site</p>
    });

    if (this.state.orderBy) {
      //Clone to avoid mutating state
      siteData = siteData.slice(0);
      siteData.sort(this.sortFields);
    }

    //if(siteData) children=children.filter(ch=>siteData.map(sd=>sd.siteid).includes(ch.props.id));

    //console.log("Basefolder children: ", children);

    //if(this.state.siteSearch) children = children.filter(c=>((((c.props || {}).state || {}).values || {}).name || '').replace(/ /g,'').toLowerCase().includes(this.state.siteSearch.toLowerCase()));

    //if(this.state.siteSearch) children = children.filter(c=>(this.state.searchterms[(c.props || {}).id] || []).find(st=>(st || '').includes(this.state.siteSearch)));

    //console.log("searchterms: ", this.state.searchterms);

    const edit = (this.state.edit || this.props.edit);

    return (

      <div className="BaseFolder">
        {/*<div className="areanew">
          <FButton glyph="+" icon="fs-map-marker" onClick={() => {window.open(`http://${window.location.hostname}/admin/sites/create?email=${email()}`)}}>
            Site
          </FButton>
        </div>*/}
        {/*<div className="Folders">
            {
              this.children({placeholder: <p className="placeholder">Please Add a Site</p>})
            }
          </div>*/}

        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />

        <div className="card card-edit">
          <div className="input-group card-header">
            <div className="input-group-prepend">
              {/*<span className="ztitle title input-group-text">Sites</span>*/}
              <span className="icon fa fa-search" />
            </div>

            <div className="searchbox form-control"><FormText id="siteSearch" name="siteSearch" value={this.state.siteSearch} update={this.updateState} placeholder="Search Sites" live /></div>

            <div className="input-group-append">
              <FButton icon={this.state.viewgrid ? "fa fa-th" : "fa fa-th-list"} onClick={() => this.setState({ viewgrid: !this.state.viewgrid })}>
                {this.state.viewgrid ? 'Grid View' : 'List View'}
              </FButton>

              {/*<FButton glyph="+" icon="fs-map-marker" onClick={() => {window.open(`http://${window.location.hostname}/admin/sites/create?email=${email()}`)}}>OLD ADD Site</FButton>*/}

              {/*trac() && <ButtonModal icon="fs-map-marker" glyph="+" buttonText={"Site"} title="Add Site" buttonOK={this.addSite} buttonCancel={true} onShow={this.clearmessage} >
                  {this.state.message && <div className="message alert">{this.state.message}</div>}

                    <FormRow label="Site Name">
                      <FormText name="newSiteName" value={this.state.newSiteName} update={this.updateState} placeholder="Site Name?" />
                    </FormRow>

                    <FormRow label="Site Address">
                      <FormTextArea name="newSiteAddress" value={this.state.newSiteAddress} update={this.updateState} placeholder="Site Address?" />
                    </FormRow

                </ButtonModal>*/}

              <ButtonModal modalClassName="" icon={"fs-cog"} title="Settings" buttonText="Settings">
                <div className="form-group">
                  <label>Name</label>
                  {/*<FormText name="email" type="email" value={this.state.email} update={this.changeField} autoComplete />*/}
                  <FormText name="actualname" value={this.state.values.actualname} update={this.setSettingsValue} />
                </div>
              </ButtonModal>

            </div>
          </div>
          <div className="card-block">

            <div className="sitegridbox">
              {this.state.message && <div className="message">{this.state.message}</div>}

              <div hidden={!this.state.viewgrid} className={'sitegrid'} style={{ gridTemplateColumns: `1fr 2em repeat(${3 + (this.state.siteFields || []).length}, 1fr)` }}>
                {
                  [
                    <div key={`${this.props.id}_site`} className="gridheader"><OrderField onClick={() => this.setOrderBy('sitename')} reverse={this.isReversed('sitename')}>Site</OrderField></div>,
                    <div key={`${this.props.id}_users`} className="gridheader" style={{ textAlign: 'center' }}> </div>,
                    <div key={`${this.props.id}_report`} className="gridheader"><OrderField onClick={() => this.setOrderBy('aname')} reverse={this.isReversed('aname')}>PDF Report</OrderField></div>,
                    <div key={`${this.props.id}_reportdate`} className="gridheader"><OrderField onClick={() => this.setOrderBy('adate')} reverse={this.isReversed('adate')}>Report Generated Date</OrderField></div>,
                    <div key={`${this.props.id}_priorities`} className="gridheader"><OrderField onClick={() => this.setOrderBy('priorities')} reverse={this.isReversed('priorities')}>Action Required</OrderField></div>
                  ]
                    .concat((this.state.siteFields || []).map(fn => <div key={`_fn_${fn}`} className="gridheader"><OrderField onClick={() => this.setOrderBy(fn, true)} reverse={this.isReversed(fn)}>{fn}</OrderField></div>))
                }
                {(siteData || []).map(s => {
                  return [
                    <div key={`${s.aid}_sitename`}><button className="abutton" onClick={s.loadSite}>{s.sitename}</button></div>,
                    <div key={`${s.aid}_users`}>
                      <ButtonModal small modalClassName="" icon={"fs-user"} title="Users" buttonText="" overlay={(s.members || []).length}>
                        <EditUsers id={`${s.aid}_users_edit`} members={s.members} share={(email, isSuper) => this.share(s.siteid, email, isSuper)} unshare={email => this.unshare(s.siteid, email)} />
                      </ButtonModal>
                    </div>,
                    <div key={`${s.aid}_report`}>
                      {s.apdf ? <a href={`${adminserver}/reports/${s.siteid}/${s.aid}/${(s.apdf || {}).id}`} target="_blank" rel="noopener noreferrer"><span className="fs-clipboard" /> {s.aname}</a> : s.aname}
                    </div>,
                    <div key={`${s.aid}_reportdate`}>
                      {s.apdf && s.apdf.when ? <span>{ukdate(s.apdf.when)}</span> : ''} {/*  <span style={{whiteSpace: "nowrap"}}>[{daysAgo(s.apdf.when)}]</span> */}
                    </div>,
                    <div key={`${s.aid}_priorities`}>
                      P1 - {(s.priorities || {}).p1}<br />P2 - {(s.priorities || {}).p2}<br />P3 - {(s.priorities || {}).p3}
                    </div>
                  ]
                    .concat((this.state.siteFields || []).map(fn => <div key={`${s.aid}_${fn}`}>{this.formatField((s.fields || {})[fn])}</div>))
                })
                }
              </div>
              <div hidden={this.state.viewgrid}>
                {children}
              </div>

            </div>

          </div>
        </div>

      </div>
    )
  }
}

/*!

If sites are to load individually, they should pass field data to BaseFolder, which should compile the fields and feed it back to 
all children (sites) so that each site can display the appropriate rows in the appropriate places

*/

//const toDays = (d=0) => Math.floor(d / 24 / 60 / 60 / 1000);

/*const daysAgo = d => {
  const rd = new Date(d);
  
  const now = new Date();
  now.setHours(23,59,59,999);

  const days = toDays( now.valueOf() - rd.valueOf() );

  if(!days) return "Today";
  if(days===1) return "Yesterday";
  
  return `${days} days ago`;
}*/

class Site extends Container {
  /*constructor(props) {
    super(props);

    //this._bind('loadSite');

    this.state['loading']=false;
  }*/

  loadSite = async () => {
    this.setState({ loading: true });

    //await this.props.functions.loadSite(this.props.id, this.state.values.name);
    await this.props.loadSite(this.props.id, this.state.values.name);

    if (this._mounted !== false) this.setState({ loading: false });
  }

  getSiteData = async () => {

    try {
      var res = await adminFetch('sites', 'sitedata', {
        email: email(),
        site: this.props.id
      })
    } catch (err) {
      console.log("Site err: ", err);
    }

    //console.log("Site data: ", res);

    if (res && res.success === true) {
      this.props.addSiteData(res, ((this.state || {}).values || {}).name, this.loadSite, this.getSiteData);
    }

  }

  /*addMember = async email => {
    console.log("Add Member to ", this.props.id);

  }

  deleteMember = email => {
    console.log("Delete Member from ", this.props.id);
  }*/

  componentDidMount() {
    super.componentDidMount();

    this.getSiteData();
  }

  render() {
    if (this.props.hidden) return null;

    return (
      <button className="Site" onClick={this.loadSite} id={this.props.id} disabled={this.props.disabled}>
        <div>
          {this.state.loading ?
            <Loading title={false} />
            :
            <span className={`icon ${this.props.icon}`} />
          }
        </div>
        <div className="ztitle title">
          <span>{(this.state.values && this.state.values.name) || "Site"}</span>
        </div>
      </button>
    )
  }
}

/*

        const ass = sdd.assessment;

        return [
          !ano && <div key={`${sdd.id}_site`} style={{gridRow: `span ${data.length}`}}>
            <button onClick={this.loadSite}><span className={this.props.icon} /> { (this.state.values && this.state.values.name) || "Site" }</button>
          </div>,
          !ano && <div key={`${sdd.id}_users`} style={{gridRow: `span ${data.length}`}}>
            <ButtonModal small modalClassName="" icon={"fs-user"} title="Users" buttonText="" overlay={(this.state.members || []).length}>
              <EditUsers members={this.state.members} />
            </ButtonModal>
          </div>,
          <div key={`${sdd.id}_report`}>
            {ass ? <a href={`https://${['admin',subsite].join('.')}.firesmart.co.uk/reports/${this.props.id}/${sdd.id}/${(sdd.assessment || {}).id}`} target="_blank"><span className="fs-clipboard" /> {sdd.name}</a> : sdd.name}
          </div>,
          <div key={`${sdd.id}_reportdate`}>
            { ass && ass.when ? <span>{ukdate(ass.when)} <span style={{whiteSpace: "nowrap"}}>[{daysAgo(ass.when)}]</span></span> : '' }
          </div>
        ]
        .concat( (this.props.siteFields || []).map(fn=><div key={`_fn_${fn}`}>{((sdd.fields || []).find(f=>f.name===fn) || {}).value}</div>) )
*/

class SiteData extends Container {

  //editClick = e => this.props.setEdit(!(this.state.edit || this.props.edit));

  componentDidMount() {
    super.componentDidMount();

    //Initial update just for watchers
    this.props.updateChangeWatchers({ _id: "site", values: this.props.state.values }, false, true);
  }

  addChildClick = (type) => {
    if (type === "Assessment") {
      this.props.functions.addSurvey()
        .then(id => this.props.actions.addChild(type, id))
    }
    else this.props.actions.addChild(type);
  }

  cloneSurvey = (surveyid, name) => {
    this.props.functions.cloneSurvey(surveyid)
      .then(id => id && this.props.actions.addChild("Assessment", id, { name: name }));
    //this.props.actions.addChild("Assessment",id))
  }

  //Noreid = don't assign new ids to imported items (dangerous)
  importSurvey = async (items, noreid = false) => {

    //console.log("SiteDtaa importSurvey: ", items, noreid);

    if (!items.length) return;

    var newsid;

    if (noreid) {
      newsid = items[0]._id;

    } else {
      newsid = cuid();
    }

    await this.props.functions.importSurvey(newsid, items, undefined, noreid);

    this.props.actions.attachChild(newsid);
  }

  render() {

    //console.log("Site Data: ", this.props, this.state.values, this.props.id);

    const edit = (this.state.edit || this.props.edit || this.props.admin);

    return (
      <div className="SiteData" id={this.props.id}>
        {/*<div className="surveyedit btn-group-toggle" data-toggle="buttons">
          <CheckButton title={edit?"Editing":"Edit"} iconafter={edit?"fs-exclamation-triangle":null} checked={edit} onChange={this.editClick} />
        </div>*/}

        {/*<InlineHelp id={this.props.id+"_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />*/}

        <div className="card card-edit">
          <div className="input-group card-header">
            {/*<span className={"input-group-prepend icon "+(this.props.icon || "")}> </span>*/}
            <span className="ztitle form-control title SiteData-title">
              {this.state.values.name || this.props.title}
            </span>

            <div className="input-group-append">
              {edit && <AddChildButtons childTypes={this.props.childTypes} addChild={this.addChildClick} />}

              {edit && <AssessmentImport importSurvey={this.importSurvey} />}
            </div>
          </div>
          <div className="card-block">
            {/*this.orderChildren()*/}
            {this.children({
              props: {
                cloneSurvey: this.cloneSurvey,
                downloadSurvey: this.props.functions.downloadSurvey,
                deleteSurvey: this.props.functions.deleteSurvey,
                siteid: this.props.siteid,
                edit: this.props.admin,
                siteView: this.props.siteView
              },
              sendTypeToEnd: "Assessment"
            })}
          </div>
        </div>
      </div>
    )
  }
}

//Is this date within the last five minutes?
const newish = when => ((new Date()) - new Date(when)) < (5 * 60 * 1000);

class Assessment extends Container {

  assessmentActions = {
    moveUp: this.props.actions.moveUp,
    moveDown: this.props.actions.moveDown,
    deleteMe: () =>
      this.props.actions.deleteMe()
        .then(() => this.props.functions.deleteSurvey(this.props.id))
  }

  updateAssessmentDate = () => {
    //Get last login date
    getAssessmentLoginDate(this.props.id)
      .then(lastlogin => this._mounted !== false && this.setState({ lastlogin }));
  }

  componentDidMount() {
    super.componentDidMount();

    this.updateAssessmentDate();

    this.props.registerChangeWatcher(this.updateAssessmentDate, `AssessmentData-${this.props.id}`);
  }

  /*componentDidUpdate(prevProps, prevState) {
    //const r=this.state.rshow;

    console.log("Assessment update..!");
  }*/

  stateUpdate = (newState, prevState) => {

    //On new report, switch to site pane and open PDF
    if (newState.reports) {

      if ((newState.reports || []).length > (prevState.reports || []).length) {

        const newreport = ((newState.reports || []).slice(-1)[0] || {}).id;

        if (newreport) {
          this.props.siteView()
            .then(() => {
              this.openReport(newreport);
            })
        }
      }
    }
  }

  openReport = report => {
    window.open(`${adminserver}/reports/${this.props.siteid}/${this.props.id}/${report}`, '_blank');
  }

  deleteReport = async report => {

    this.setState({ message: '', messageLevel: false });

    try {
      var res = await adminFetch('sites', 'report', {
        site: this.props.siteid,
        survey: this.props.id,
        report: report
      },
        'delete') //,true = admin
    } catch (err) {

      console.log(err);

      this.setState({ message: err.message, messageLevel: 'alert-warning' });
    }

    console.log(res);

    this.setState({ message: res.message, messageLevel: res.success ? 'alert-success' : 'alert-warning' });
  }

  render() {

    const reports = this.state.reports || [];

    const report = reports.length > 0 && reports[reports.length - 1];

    const edit = (this.state.edit || this.props.edit);

    return (
      <div className="Assessment" id={this.props.id}>
        <div className="input-group loadSurvey" onClick={() => !edit && this.props.functions.loadSurvey(this.props.id)}>
          {/*<div className="input-group-prepend">
            <span className="icon fs-content-7" />
          </div>*/}

          {edit ?
            <EditableA onChange={this.props.actions.renameMe} editFixedOn className="form-control">
              {(this.state.values && this.state.values.name) || "Assessment"}
            </EditableA>
            :
            <span className="form-control input-group-text">{(this.state.values && this.state.values.name) || "Assessment"}</span>
          }

          <div className="input-group-append">

            {edit && <FButton icon="fa fa-save" onClick={() => this.props.downloadSurvey(this.props.id)}>Export</FButton>}
            {edit && <StButtons actions={this.assessmentActions} />}

            <div className="FInfo Assessment-start" style={this.state.lastlogin ? {} : { display: "flex", alignItems: "center" }}>
              <span className="FInfoMain text">
                {this.state.lastlogin ? 'Update / Review' : 'Start Assessment'} »
              </span>
              {this.state.lastlogin ? <span className="FInfoTitle">
                Last Login: {ukdate(this.state.lastlogin)}
              </span> : ''}
            </div>

          </div>
        </div>
        <div className="Assessment-body">

          {report &&
            <GreyBox onClick={() => this.openReport(report.id)} subtitle={ukdate(report.when)} highlight={newish(report.when)}>Report PDF</GreyBox>
          }

          {reports.length > 0 &&
            <ButtonModal greybutton className="" large modalClassName="ReportArchiveModal" scroll icon={"fa fa-archive"} title="Reports" buttonText="Report Archive" overlay={reports.length}>
              {this.state.message && <div className={`alert ${this.state.messageLevel || ''}`}>{this.state.message}</div>}
              {reports.map(report =>
                <div key={report.id} className="card card-edit archivebox">
                  <div className="card-header input-group">
                    <span className="form-control"></span>
                    {/*<div className="input-group-append">
                      <SureButton icon="fs-trash" buttonText="Delete" onClick={() => this.deleteReport(report.id)}>Delete?</SureButton>
              </div>*/}
                  </div>
                  <div className="card-block">
                    <GreyBox xlarge onClick={() => this.openReport(report.id)} subtitle={ukdate(report.when)}>{report.id}</GreyBox>
                  </div>
                </div>
              )}
            </ButtonModal>
          }

          {(this.state.lastlogin && !process.env.REACT_APP_HIDE_LOGS) ? <GreyBox right onClick={() => this.props.functions.loadSurvey(this.props.id, "log")}>{process.env.REACT_APP_BUTTON_PRINT_LOG_BOOK || 'Fire Log Book'}</GreyBox> : ''}

          {edit &&
            <div className="input-group cloneAssessment">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  Clone / Repair Survey
                </span>
              </div>
              <FormText name="clonename" value={this.state.clonename} update={this.updateState} placeholder="Name?" />
              <div className="input-group-append">
                <FButton icon="fa fa-copy" onClick={() => this.props.cloneSurvey(this.props.id, this.state.clonename || `${this.state.values.name} Copy`)}>Copy</FButton>
              </div>
            </div>
          }

        </div>
      </div>
    )
  }
}

const scrollTo = id => {
  if (!id) return false;
  const el = document.getElementById(id);
  if (el) {
    el.scrollIntoView(true);
    return true;
  }
}

/* Survey v2 */
class Survey extends Container {
  constructor(props) {
    super(props);

    this.state["expanded"] = this.props.expandid || false;
    this.state["exitem"] = false;
    this.state["introcollapsed"] = false;
  }

  //editClick = e => this.props.setEdit(!(this.state.edit || this.props.edit));

  expand = (id, force = false, exitem, itemid) => e => {
    const expanded = (this.state.expanded === id && force !== true) ? false : id;

    if (this.props.onExpand) this.props.onExpand(expanded, itemid);

    //this.setState({expanded, introcollapsed: true, exitem}, ()=>scrollTo(id));
    this.setState({ expanded, introcollapsed: true, exitem });
  }

  expandIntro = () => this.setState({ introcollapsed: false });

  openByPrefix = (id, exitem) => {
    const el = this.state.elements.find(eid => eid.startsWith(id));
    if (el) this.expand(el, true, exitem)();
  }

  componentDidMount() {
    super.componentDidMount();
    this.addWatcher('_categories');

    scrollTo(this.props.itemid);
  }

  componentDidUpdate(prevProps, prevState) {
    //!update on expanded change
    if (prevProps.expandid !== this.props.expandid || prevProps.itemid !== this.props.itemid) {
      this.expand(this.props.expandid, true, this.props.itemid)();
    }

    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.setState({ admin: false });
    }
  }

  render() {
    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    return (
      <div id={this.props.id} className="Survey">
        {/*<div className="surveyedit btn-group-toggle" data-toggle="buttons">
          <CheckButton title={edit?"Editing":"Edit"} iconafter={edit?"fs-exclamation-triangle":null} checked={edit} onChange={this.editClick} />
      </div>*/}

        {this.props.admin &&
          <div className="rightButton">
            <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />
          </div>
        }

        {edit &&
          <div className="areanew">
            {this.props.childTypes.map(childType =>
              <IconButton glyph="+" key={childType.type} onClick={e => this.props.actions.addChild(childType.type)} icon={childType.icon} title={childType.title} />
            )}
          </div>
        }

        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

        {!this.state._categories
          ? <div><Loading /></div>
          : <div className="SurveySections" id={this.props.id}>
            {this.children({
              props: { expand: this.expand, itemid: this.props.itemid, admin: this.props.admin, siblings: this.state.childinfo },
              propsId: this.state.expanded ? { [this.state.expanded]: { expanded: true, exitem: this.state.exitem } } : false,
              orderChildrenByType: true
            })}
          </div>
        }
      </div>
    )
  }
}

class RiskSurvey extends Survey {
  /*constructor(props) {
    super(props);
  }*/

  componentDidMount() {
    super.componentDidMount();

    const rWA = doc => getRiskActions(this.props.rootid);

    this.props.registerRootWatcher(rWA, "_riskactions", this.props.rootid);
  }
}

class EquipmentSurvey extends Survey {

  componentDidMount() {
    super.componentDidMount();

    this.props.registerRootWatcher(doc => getPrecautions(this.props.rootid), "_precautions", this.props.rootid);
    this.props.registerRootWatcher(doc => getPrecautionMeasures(this.props.rootid), "_precautionmeasures", this.props.rootid);
    this.props.registerRootWatcher(doc => getExtinguisherList(this.props.rootid).then(exts => getExtinguisherCategories(exts)), "_extinguishercategories", this.props.rootid);

  }

  /*stateUpdate = newState => {

    if(newState.rshow) {
      const rshow=newState.rshow;

      this.props.setView(rshow.activepane, rshow.id, rshow.itemid)
      .then(()=>{
        this.openByPrefix(rshow.id, rshow.itemid);
        this.setState({rshow: null})
      })
    }
  }*/

  render() {
    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    //console.log("EquipmentSurvey props: ", this.props);

    return (
      <div id={this.props.id} className="Survey">
        {/*<div className="surveyedit btn-group-toggle" data-toggle="buttons">
          <CheckButton title={edit?"Editing":"Edit"} iconafter={edit?"fs-exclamation-triangle":null} checked={edit} onChange={this.editClick} />
        </div>*/}

        {this.props.admin &&
          <div className="rightButton">
            <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />
          </div>
        }

        {edit &&
          <div className="areanew">
            {this.props.childTypes.map(childType =>
              <IconButton glyph="+" key={childType.type} onClick={e => this.props.actions.addChild(childType.type)} icon={childType.icon} title={childType.title} />
            )}
          </div>
        }

        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

        {!this.state._categories
          ? <div><Loading /></div>
          : <div className="SurveySections" id={this.props.id}>
            {this.children({
              props: { expand: this.expand, itemid: this.props.itemid, admin: this.props.admin, siblings: this.state.childinfo, isSuper: this.props.isSuper },
              propsId: this.state.expanded ? { [this.state.expanded]: { expanded: true, exitem: this.state.exitem } } : false,
              orderChildrenByType: true
            })}
          </div>
        }
      </div>
    )
  }
}

const CategorySelector = ({ id, categories = [], categoriesstate = [], setValue }) => {

  const cats = [{ id: -1, name: "Any" }].concat((categories && categories.children) || []);

  const categoryClick = catid => e => {
    let categories;

    if (catid === -1) categories = undefined;
    else if (categoriesstate && categoriesstate.indexOf(catid) > -1) {
      categories = categoriesstate.filter(c => c !== catid);
    }
    else categories = [catid].concat((categoriesstate) || []);

    setValue({ categories });
  }

  return (
    <ButtonModal className="SurveySectionModal" modalClassName="AuditModal" icon="fs-industry" overlay={categoriesstate && categoriesstate.length ? categoriesstate.length : "*"} buttonText={"Categories"} title="Categories">
      {cats.map(op => {
        const ch = (categoriesstate && categoriesstate.indexOf(op.id) > -1) || (op.id === -1 && (!categoriesstate || !categoriesstate.length));

        return (
          <div className="input-group btn-group-toggle" data-toggle="buttons" key={id + "_" + op.id}>
            <Radioaddon id={id + "_" + op.id + "_ch"} checked={ch} icon={ch ? "fs-check-square-o" : "fs-square-o"} onClick={categoryClick(op.id)}> {op.name}</Radioaddon>
          </div>
        )
      })}
    </ButtonModal>
  )
}

/*
const EditDate = ({ editdate, updateEditDate, expanded }) => {
  //editdate?<span className="input-group-text editdate">Last Edit<br />{new Date(editdate).toString()}</span>:null

  return (
    editdate
      ? <span className="input-group-text editdate">Checked<br />{editdate.getTime() === 0 ? '-' : ukdate(editdate)}</span>
      : <span className="input-group-text editdatecheck">Check</span>
  )
}
*/


const EditDate = ({ editdate, actions, expanded }) => {
  //editdate?<span className="input-group-text editdate">Last Edit<br />{new Date(editdate).toString()}</span>:null

  const isDate = editdate && editdate.getTime() !== 0;

  const ed = isDate ? ukdate(editdate) : '-'; //Date formatted for display

  const todaysDate = isDate && new Date(editdate).toDateString() === new Date().toDateString();

  //console.log("Dates: ", isDate, new Date(editdate), new Date(), new Date(editdate).toDateString(), new Date().toDateString());

  //console.log("dates: ", todaysDate, isDate, editdate, new Date(), ukdate(editdate), ukdate());

  return (
    editdate
      ? (expanded && !todaysDate)
        ?<button onClick={()=>actions.setValue({})} className="input-group-text editdateupdate">Update Checked Date?<br />{ed}</button>
        :<span className="input-group-text editdate">Checked<br />{ed}</span>
      : <span className="input-group-text editdatecheck">Check</span>
  )
}


//Updates _sitesections data for use in Action Summary
/*const updateSiteSections = (updateChangeWatchers, fields) => {
  updateChangeWatchers(w => {
    const ss = w?._cache__sitesections?._sitesections || []; //Existing sitesections data

    const nd = ss.find(s => s && s.id === fields.id);
    if (nd && !fields.delete && nd.ok === fields.ok) return false;

    let _ss = ss.filter(s => s && s.id !== fields.id); //Filter new data

    const _sitesections = fields.delete ? _ss : _ss.concat(fields); //Only add if no "delete" field

    return ({
      _id: "_sitesections",
      _sitesections
    })
  }, true)
}
*/

//Updates values.categoryhide if/when categories change
const categoryHider = ({ _categories }, itemcategories, itemcathide, setValue, setState, id) => {
  if (!_categories) return;
  if (setState) setState({ _categories }); //courtesy function

  const cathide = !categoryshow(_categories, itemcategories, id);

  if (cathide !== itemcathide && !(!cathide && !itemcathide)) { //if item category hidden value has changed, update
    setValue({ cathide }, true); //Set cathide quietly
  }

  return cathide;
  //console.log("CategoryHider: ", id, _categories, itemcategories, itemcathide);
}

class RiskSurveySection extends Container {
  constructor(props) {
    super(props);

    //this.state["expanded"]=this.props.expandid || false;
    this.state["expanded"] = this.props.itemid || false;
    this.state["checkedchildren"] = {};
    //this.state["allchildrenchecked"]=false;

    this.headerRef = React.createRef();
  }

  //Expand this section
  expand = (id, force = false, exitem, headerRef) => e => {

    const expanded = (this.state.expanded === id && force !== true) ? false : id;

    //Expand this section
    this.props.expand(this.props.id, true, false, expanded ? id : null)();

    if (this.props.onExpand) this.props.onExpand(expanded);

    this.setState({ expanded, introcollapsed: true, exitem }); //, ()=>{ 

    //}); //, ()=>scrollTo(id)
  }

  //Returns bottom position of the section header
  getSectionPos = () => this.headerRef.current ? this.headerRef.current.getBoundingClientRect().bottom : false;

  showToggle = () => this.setState({ show: !this.state.show });

  /*categoryClick = catid => {
    let categories;

    if(catid===-1) categories = undefined;
    else if(this.state.values && this.state.values.categories && this.state.values.categories.indexOf(catid)>-1) {
      categories = this.state.values.categories.filter(c=>c!==catid);
    }
    else categories = [catid].concat((this.state.values && this.state.values.categories) || []);

    this.setValue({categories});
  }*/

  //Update values.cathide when global _categories ior local values.categories change
  catUpdate = (_categories = { _categories: this.state._categories }) => {
    categoryHider(_categories, this.state.values.categories, this.state.values.cathide, this.setValue, this.updateState, this.props.id);
  }

  componentDidMount() {
    super.componentDidMount();
    //this.addWatcher("_categories");

    //this.props.registerChangeWatcher(this.updateCategories, "_categories");
    this.props.registerChangeWatcher(this.catUpdate, "_categories");


    //updateSiteSections(this.props.updateChangeWatchers, {id: this.props.id, parentid: this.props.parentid, title: this.state.values.name, ok: false});
  }

  //!! This should be stored in a .values variable - only update when all children have reported
  /**
   * Check if all children are checked (Yes / No)
   * @param {*} childid 
   */
  childcheck = childid => value => {

    //console.log("RSS childcheck: ", this.props.id, childid, value);

    if (this._mounted !== false) this.setState(st => {

      const checkedchildren = { ...st.checkedchildren, [childid]: Number(value) > 0 };
      const allchildrenchecked = !(st.elements.find(el => !checkedchildren[el]));

      return ({ checkedchildren, allchildrenchecked })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    //!update on expanded change
    if (this.props.exitem && prevProps.exitem !== this.props.exitem) {
      this.expand(this.props.exitem, true)();
    }

    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.setState({ admin: false });
    }

    //Local category selecion change
    //console.log("cDU: ", this.state.values.categories && (!prevState.values.categories || !arraysEqual(this.state.values.categories, prevState.values.categories)));
    if (this.state.values.categories && (!prevState.values.categories || !arraysEqual(this.state.values.categories, prevState.values.categories))) this.catUpdate();

    //set values.allchildrenchecked if all child data gathered
    if (this.state.checkedchildren
      && Object.keys(this.state.checkedchildren).length === this.state?.elements?.length
      && this.state.values?.sectionok !== this.state.allchildrenchecked) this.setValue({ sectionok: this.state.allchildrenchecked }, true);
  }

  render() {

    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    //if(!categoryshow(this.state._categories, this.state.values.categories, this.props.id) && !edit) return null;
    if (this.state.values.cathide && !(this.props.admin || edit)) return null;

    const siblings = this.props?.siblings?.map(el => ({ ...el, ...(el.id === this.props.id ? { parent: true } : {}) }));

    return (
      <div className="SurveySection card card-edit" id={this.props.id} style={{ zIndex: this.state.popup ? 20 : '' }}>

        <div className={`input-group card-header ${edit ? 'edit' : 'live'}`} data-toggle="buttons" ref={this.headerRef}>
          <div className="input-group-prepend" onClick={() => !edit && this.props.expand(this.props.id)}>
            {edit
              ? <Icondrop icon={this.state.values.icon || this.props.icon} name="icon" onClick={this.props.actions.setValue} />
              : <div className={"icon " + (this.state.values.icon || this.props.icon)} />
            }
          </div>
          {edit
            ? <EditableA className="ztitle form-control input-group-text" name="name" onChange={this.props.actions.setValue} editFixedOn={true}>
              {this.state.values.name || "Section"}
            </EditableA>
            : <div className={"ztitle form-control title input-group-text "} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Section"}</div>
          }

          <div className="input-group-append">
            {edit && <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />}

            {/*edit && <ButtonModal className="SurveySectionModal" modalClassName="AuditModal" icon="fs-industry" overlay={this.state.values.categories && this.state.values.categories.length?this.state.values.categories.length:"*"} buttonText={"Categories"} title="Categories">
                {categories.map(op => {
                  const ch=(this.state.values.categories && this.state.values.categories.indexOf(op.id)>-1) || (op.id===-1 && (!this.state.values.categories || !this.state.values.categories.length));

                  return (
                    <div className="input-group btn-group-toggle" data-toggle="buttons" key={this.props.id+"_"+op.id}>
                      <Radioaddon id={this.props.id+"_"+op.id+"_ch"} checked={ch} icon={ch?"fs-check-square-o":"fs-square-o"} onClick={e=>this.categoryClick(op.id)}> {op.name}</Radioaddon>
                    </div>
                  )
                })}
              </ButtonModal>*/}

            {/*edit && <CategorySelector id={this.props.id} categories={categories} categoriesstate={this.state.values.categories} categoryClick={this.categoryClick} />*/}
            {edit && <CategorySelector id={this.props.id} categories={this.state._categories} categoriesstate={this.state.values.categories} setValue={this.setValue} />}


            {edit && <StButtons actions={this.props.actions} />}
            {<EditDate editdate={this.state.values.sectionok && this.state.editdate} actions={this.props.actions} expanded={this.props.expanded} />}
            {(this.props.expanded || edit) && <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} showState={s => this.setState({ popup: s })} />}
            {/*this.props.admin && <FButton icon={this.state.admin?"fs-unlock-alt":"fs-lock"} vcenter onClick={()=>this.setState({admin: !this.state.admin})} />*/}
            <UnlockButton show={this.props.admin} unlocked={this.state.admin} onClick={() => this.setState({ admin: !this.state.admin })} />
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
        {/*}:
        <div className="input-group card-header live">
          <div className="input-group-prepend" onClick={this.props.expand(this.props.id)}>
            <div className={"icon "+(this.state.values.icon || this.props.icon)} />
          </div>
          <div className={"ztitle form-control title "} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Section"}</div>
          <div className="input-group-append">
            <HelpModal id={this.props.id+"_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} />
            <FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
              */}
        {/*<div className={"card-block childblock"} hidden={!(this.props.expanded || edit)}>*/}
        <ShowContainer className={"card-block childblock"} show={(this.props.expanded || edit)}>
          <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />
          <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />
          {/*this.state.children?this.props.childTypes.map(childType => this.state.children.filter(c=>c.props.type===childType.type)):null*/}
          {this.children({
            props: { setView: this.props.setView, expand: this.expand, edit: this.state.admin, siblings, parenttype: this.props.type },
            propsId: this.state.expanded ? { [this.state.expanded]: { expanded: true, exitem: this.state.exitem } } : false,
            propsIdFunc: { check: this.childcheck }
          })}
        </ShowContainer>
        {/*</div>*/}
      </div>
    )
  }
}

/**
 * Calculates number of extinguishers required per level
 */
//const ExtinguisherCalculator = props => (

//)

/**
 * Survey section specifically for Extinguishers
*/
class ExtinguisherSection extends Container {
  constructor(props) {
    super(props);

    //this._bind('addStandard','updateStandard','moveStandard','deleteStandard','updateCategory','addCategory','deleteCategory','moveCategory','updateFormulae','addRating','updateRating','moveRating');

    //this.state["show"]=true; //(this.props.values.defaultShow!==false);

    //Forumla fields
    this.ff = [
      { code: "a", title: "Area" },
      { code: "f", title: "Vol Fuel" },
      { code: "e", title: "Live Ext Covs", array: true },
      { code: "v", title: "Ext Vol Rating" },
      { code: "p", title: "Ptype Cov" }
    ];

    //!if(this.state.values.name!=="Fire Extinguishers") this.state.actions.setValue({name: "Fire Extinguishers"});
  }

  stateUpdate = newstate => {
    getExtinguisherList(this.props.rootid)
      .then(exts => this.props.updateChangeWatchers({ _id: "_extinguishercategories", _extinguishercategories: getExtinguisherCategories(exts) }, true));
  }

  /*!!childrenHaveUpdated() {
    getExtinguisherList(idFromSurveyRoot(this.props.rootid))
      .then(exts=>{if(this._mounted!==false) {updateWatch('extinguisherlist', exts); updateWatch('extinguishers', getExtinguishers(exts))}});

    getPrecautionMeasures(idFromSurveyRoot(this.props.rootid))
    .then(precautions=>{if(this._mounted!==false) updateWatch('precautionmeasures', precautions)});

    //getExtinguisherCategories(idFromSurveyRoot(this.props.rootid))
    //	.then(exts=>{if(this._mounted!==false) updateWatch('extinguishercategories', exts)});
  }*/

  /*showToggle() {
    this.setState({show:!this.state.show});
  }*/

  getStandards = () => {
    return (isArray(this.state.values.standards)) ? this.state.values.standards.slice() : [];
  }

  addStandard = () => {
    let standards = this.getStandards();
    standards.push({ id: cuid(), title: "New Standard", description: "" });
    this.props.actions.setValue("standards", standards);
    //.then(this.updateExtList);
  }

  updateStandard = (standardid, field, value) => {
    let standards = this.state.values.standards.slice();
    standards.find(s => s.id === standardid)[field] = value;
    this.props.actions.setValue("standards", standards);
    //.then(this.updateExtList);
  }

  moveStandard = (standardid, direction) => {
    let standards = this.getStandards();

    const ipos = standards.findIndex(s => s.id === standardid);

    //prevent move if impossible
    const swel = standards[ipos + direction];
    if (typeof swel === "undefined") return;

    const item = standards[ipos];

    standards[ipos] = swel;
    standards[ipos + direction] = item;

    this.props.actions.setValue("standards", standards);
    //.then(this.updateExtList);
  }

  deleteStandard = (standardid) => {
    let standards = this.getStandards();
    const fc = standards.findIndex(s => s.id === standardid);
    if (fc > -1) {
      standards.splice(fc, 1);
      this.props.actions.setValue("standards", standards);
      //.then(this.updateExtList);
    }
  }

  getCategories = () => {
    return (isArray(this.state.values.extcategories)) ? this.state.values.extcategories.slice() : [];
  }

  updateCategory = (catid, field, value) => {
    let cats = this.state.values.extcategories.slice();
    cats.find(c => c.id === catid)[field] = value;
    this.props.actions.setValue("extcategories", cats);
  }

  addCategory = () => {
    let cats = this.getCategories();
    cats.push({ id: cuid(), title: "New Category", description: "", ratings: [] });
    this.props.actions.setValue("extcategories", cats);
    //.then(this.updateExtList);
  }

  deleteCategory = (catid) => {
    let cats = this.getCategories();
    const fc = cats.findIndex(c => c.id === catid);
    if (fc > -1) {
      cats.splice(fc, 1);
      this.props.actions.setValue("extcategories", cats);
      //.then(this.updateExtList);
    }
  }

  moveCategory = (catid, direction) => {
    let cats = this.getCategories();

    const ipos = cats.findIndex(c => c.id === catid);

    //prevent move if impossible
    const swel = cats[ipos + direction];
    if (typeof swel === "undefined") return;

    const item = cats[ipos];

    cats[ipos] = swel;
    cats[ipos + direction] = item;

    this.props.actions.setValue("extcategories", cats);
    //.then(this.updateExtList);
  }

  updateFormulae = (extid, v) => {
    this.props.actions.setValue("extcategories", this.getCategories().map(c => c.id === extid ? Object.assign({}, c, { formulae: v }) : c));
  }

  getRatings = (catid) => {
    if (this.state.values.extcategories.find(c => c.id === catid) && this.state.values.extcategories.find(c => c.id === catid).ratings) return this.state.values.extcategories.find(c => c.id === catid).ratings.slice();
    return [];
  }

  addRating = (catid) => {
    let r = this.getRatings(catid);
    r.push({ id: cuid(), rating: "Rating", coverage: "0" });
    this.updateCategory(catid, "ratings", r);
  }

  updateRating = (catid, rid, field, value) => {
    let r = this.getRatings(catid);
    const fr = r.findIndex(r => r.id === rid);
    if (fr > -1) {
      r[fr][field] = value;
      this.updateCategory(catid, "ratings", r);
    }
  }

  deleteRating = (catid, rid) => {
    let r = this.getRatings(catid);
    const fr = r.findIndex(r => r.id === rid);
    if (fr > -1) {
      r.splice(fr, 1);
      this.updateCategory(catid, "ratings", r);
    }
  }

  moveRating = (catid, rid, direction) => {
    let r = this.getRatings(catid);
    const ipos = r.findIndex(r => r.id === rid);

    //prevent move if impossible
    const swel = r[ipos + direction];
    if (typeof swel === "undefined") return;

    const item = r[ipos];
    r[ipos] = swel;
    r[ipos + direction] = item;

    this.updateCategory(catid, "ratings", r);
  }

  render() {
    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    const standards = this.state.values.standards || [];

    const ext = this.state.values.extcategories || [];

    return (
      <div className="SurveySection card card-edit" id={this.props.id} style={{ zIndex: this.state.popup ? 20 : '' }}>

        <div className={"input-group card-header " + (edit ? "edit" : "live")} data-toggle="buttons">
          <div className="input-group-prepend" onClick={this.props.expand(this.props.id)}>
            <div className={"icon " + (this.props.icon)} />
          </div>
          <span className="ztitle form-control title input-group-text" onClick={this.props.expand(this.props.id)}>Fire Extinguishers</span>

          <div className="input-group-append">
            {edit && <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />}

            {edit &&
              <ButtonModal scroll modalClassName="AuditModal" icon="fs-clipboard" title="Extinguisher Settings">
                <div className="ExtinguisherStandards card card-edit">
                  <div className="input-group card-header edit" data-toggle="buttons">
                    <div className={"form-control title "}>Standards</div>
                    <div className="input-group-append">
                      <FButton icon={"fs-plus-square-o"} onClick={this.addStandard}>Add Standard</FButton>
                    </div>
                  </div>

                  <div className="card-block">
                    {standards.map(standard =>
                      <div className="input-group extcategory" data-toggle="buttons" key={standard.id}>
                        <div className="input-group-prepend">
                          <span className="input-group-text">Title:</span>
                        </div>
                        <EditableA className="form-control" onChange={v => this.updateStandard(standard.id, "title", v)} editFixedOn={true}>
                          {standard.title || "Category"}
                        </EditableA>
                        <div className="input-group-prepend input-group-append">
                          <span className="input-group-text">Description:</span>
                        </div>
                        <EditableA className="form-control" onChange={v => this.updateStandard(standard.id, "description", v)} editFixedOn={true}>
                          {standard.description || "Description"}
                        </EditableA>
                        <div className="input-group-append">
                          <FButton key="up" icon={"fs-arrow-1-up"} onClick={e => this.moveStandard(standard.id, -1)} />
                          <FButton key="down" icon={"fs-arrow-1-down"} onClick={e => this.moveStandard(standard.id, 1)} />
                          <FButton key="delete" icon="fs-trash" onClick={e => this.deleteStandard(standard.id)} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="ExtinguisherCategories card card-edit">
                  <div className="input-group card-header edit" data-toggle="buttons">
                    <div className={"form-control title "}>Categories</div>
                    <div className="input-group-append">
                      <FButton icon={"fs-plus-square-o"} onClick={this.addCategory}>Add Category</FButton>
                    </div>
                  </div>

                  <div className="card-block">
                    {ext.map(cat =>
                      <div key={cat.id} className="ExtinguisherCategory">
                        <div className="input-group extcategory" data-toggle="buttons">
                          <div className="input-group-prepend">
                            <span className="input-group-text">Category</span>
                            <span className="input-group-text">Title:</span>
                          </div>
                          <EditableA className="form-control" onChange={v => this.updateCategory(cat.id, "title", v)} editFixedOn={true}>
                            {cat.title || "Category"}
                          </EditableA>
                          <div className="input-group-prepend input-group-append">
                            <span className="input-group-text">Description:</span>
                          </div>
                          <EditableA className="form-control" onChange={v => this.updateCategory(cat.id, "description", v)} editFixedOn={true}>
                            {cat.description || "Description"}
                          </EditableA>
                          <div className="input-group-append btn-group-toggle">
                            <Radioaddon checked={cat.levelreq} icon={cat.levelreq ? "fs-check-square-o" : "fs-square-o"} onClick={e => this.updateCategory(cat.id, "levelreq", !cat.levelreq)}>Level req</Radioaddon>
                            <FButton icon={"fs-plus-square-o"} onClick={e => this.addRating(cat.id)}>Add Rating</FButton>
                            <FButton key="up" icon={"fs-arrow-1-up"} onClick={e => this.moveCategory(cat.id, -1)} />
                            <FButton key="down" icon={"fs-arrow-1-down"} onClick={e => this.moveCategory(cat.id, 1)} />
                            <FButton key="delete" icon="fs-trash" onClick={e => this.deleteCategory(cat.id)} />
                          </div>
                        </div>
                        <FormulaField title="Formulae" name="formulae" className="extcategory" standards={standards} value={cat.formulae} update={(n, v) => this.updateFormulae(cat.id, v)} fields={this.ff} />
                        {cat.ratings.map(r =>
                          <div className="input-group extrating btn-group-toggle" data-toggle="buttons" key={r.id}>
                            <div className="input-group-prepend">
                              <span className="input-group-text">Rating</span>
                            </div>
                            <EditableA className="form-control" onChange={v => this.updateRating(cat.id, r.id, "rating", v)} editFixedOn={true}>
                              {r.rating || "Title"}
                            </EditableA>
                            <div className="input-group-prepend input-group-append">
                              <span className="input-group-text">Coverage:</span>
                              {/*<EditableA className="form-control" onChange={v=>this.updateRating(cat.id,r.id,"coverage",v)} editFixedOn={true}>
                              {r.coverage || "Coverage"}
                            </EditableA>*/}
                              <span className="input-group-text">Area:</span>
                            </div>
                            <FormNumber name="coverage" value={r.coverage} placeholder="Coverage (m²)" min="0" max="9999" update={(n, v) => this.updateRating(cat.id, r.id, "coverage", v)} />
                            <div className="input-group-prepend input-group-append">
                              <span className="input-group-text">m²</span>
                              <span className="input-group-text">Vol Fuel:</span>
                            </div>
                            <FormNumber name="coveragel" value={r.coveragel} placeholder="Coverage (L fuel)" min="0" max="9999" update={(n, v) => this.updateRating(cat.id, r.id, "coveragel", v)} />
                            <div className="input-group-append btn-group-toggle">
                              <span className="input-group-text">L</span>

                              {cat.ratings.find(rc => rc.proto && rc.id !== r.id) ? null :
                                <Radioaddon checked={r.proto} icon={r.proto ? "fs-check-square-o" : "fs-square-o"} onClick={e => this.updateRating(cat.id, r.id, "proto", !r.proto)}>Prototype</Radioaddon>
                              }

                              <FButton key="up" icon={"fs-arrow-1-up"} onClick={e => this.moveRating(cat.id, r.id, -1)} />
                              <FButton key="down" icon={"fs-arrow-1-down"} onClick={e => this.moveRating(cat.id, r.id, 1)} />
                              <FButton key="delete" icon="fs-trash" onClick={e => this.deleteRating(cat.id, r.id)} />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </ButtonModal>
            }

            {edit && <StButtons actions={this.props.actions} />}
            <EditDate editdate={this.state.editdate} actions={this.props.actions} expanded={this.props.expanded} />
            {(this.props.expanded || edit) && <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} showState={s => this.setState({ popup: s })} />}
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>


        {/*<div className={"card-block"+(this.props.expanded || edit?"":" hide")}>*/}
        <ShowContainer className={"card-block"} show={(this.props.expanded || edit)}>
          {/*this.state.children && this.props.childTypes.map(childType =>
            this.state.children.filter(c=>c.props.type===childType.type).map(child=>React.cloneElement(child, {extcategories: ext}))
          )*/}
          {/*this.AddParametersToChildren({extcategories: ext},this.orderChildrenByType())*/}
          <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />
          <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />
          {this.children({ props: { extcategories: ext }, orderChildrenByType: true })}
          {/*this.state.children*/}
        </ShowContainer>
        {/*</div>*/}
      </div>
    )
  }
}

class EmergencyEscapeSection extends Container {

  updateSectionOK = sectionok => {
    if (!!this.state?.values?.sectionok !== sectionok) {
      this.setValue({ sectionok }, true);
    }
  }

  componentDidMount() {
    super.componentDidMount();
    siteItems(actionplantypes, surveyRootId(actionplantypes, idFromSurveyRoot(this.props.rootid)), { edit: this.state.admin || false, allowAttachments: this.state.values?.allowAttachments, updateEditDate: this.updateEditDate, updateSectionOK: this.updateSectionOK, sectionicon: this.state.values.icon || this.props.icon })
      .then(items => {
        if (this._mounted !== false) this.setState({ items: items });
      })
  }

  updateChildren = (newvals = {}) => {
    this.setState({ items: [React.cloneElement(this.state.items[0], { edit: this.state.admin, allowAttachments: this.state.values?.allowAttachments, updateEditDate: this.updateEditDate, updateSectionOK: this.updateSectionOK, sectionicon: this.state.values.icon || this.props.icon, ...newvals }, null)] });
  }

  adminChange = () => {
    const admin = !(this.state.admin);
    //this.setState({ admin, items: [React.cloneElement(this.state.items[0], {edit: admin}, null)] });
    this.setState({ admin })
    //this.updateChildren({ admin });
  }

  allowAttachmentsChange = () => {
    const allowAttachments = !this?.state?.values?.allowAttachments;

    this.setValue({ allowAttachments });
    //this.updateChildren({ allowAttachments });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.adminChange();
    }

    if (
      prevState.admin !== this.state.admin || 
      prevState.values?.allowAttachments !== this.state.values?.allowAttachments ||
      this.state?.values?.icon !== prevState?.values?.icon
    ) this.updateChildren();
  }

  render() {

    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    return (
      <div className="EmergencyEscapeSection SurveySection card card-edit" id={this.props.id} style={{ zIndex: this.state.popup ? 20 : '' }}>
        <div className="input-group card-header live">
          <div className="input-group-prepend">
            {edit
              ? <Icondrop icon={this.state.values.icon || this.props.icon} name="icon" onClick={this.setValue} />
              : <div className={"icon " + (this.state.values.icon || this.props.icon)} />
            }
          </div>
          {edit ?
            <EditableA className="ztitle form-control input-group-text" name="name" onChange={this.setValue} editFixedOn={true}>
              {this.state.values.name || "Assessment Data"}
            </EditableA>
            :
            <div className={"ztitle form-control title input-group-text"} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Assessment Data"}</div>
          }
          <div className="input-group-append">
            {/* <HelpModal id={this.props.id+"_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} /> */}
            {edit && <OnOffButton icon="fs-paperclip" value={this?.state?.values?.allowAttachments !== false} onClick={this.allowAttachmentsChange} >Attachments</OnOffButton>}
            {edit && <StButtons actions={this.props.actions} />}
            <EditDate editdate={this.state?.values?.sectionok && this.state.editdate} actions={this.props.actions} expanded={this.props.expanded} />
            {(this.props.expanded || edit) && <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} onShow={() => this.setState({ popup: true })} onHide={() => this.setState({ popup: false })} />}
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={this.adminChange} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
        {/*<div hidden={!(this.props.expanded || edit)} className="card-block">*/}
        <ShowContainer className={"card-block"} show={(this.props.expanded || edit)}>
          {this.state.items}
        </ShowContainer>
        {/*</div>*/}
      </div>
    )
  }
}

class ExtinguisherCalculatorSection extends Container {

  showToggle = () => this.setState({ show: !this.state.show });

  //updateEditDate = (newdate) => {if(this._mounted!==false) this.setState({editdate: newdate})};

  updateSectionOK = sectionok => {
    if (!!this.state?.values?.sectionok !== (sectionok || this.state.values.extexplanation)) {
      this.setValue({ sectionok: sectionok || !!this.state.values.extexplanation }, true);
    }
  }

  componentDidMount = async () => {
    super.componentDidMount();

    const items = await siteItems(
      eclocationtypes, 
      surveyRootId(eclocationtypes, idFromSurveyRoot(this.props.rootid)), 
      { 
        section: this.props.id, 
        exitem: this.props.itemid, 
        edit: this.state.admin, 
        isSuper: this.props.isSuper,
        extexplanation: !!this.state.values?.extexplanation,
        updateEditDate: this.updateEditDate, 
        updateSectionOK: this.updateSectionOK 
      }
    )
    //.then(items=>{
    if (this._mounted !== false) this.setState({ items: items });
    //})
  }

  componentDidUpdate(prevProps, prevState) {
    //!update on expanded change

    if (this._mounted === false) return;

    if ((prevProps.itemid !== this.props.itemid || prevState.admin !== this.state.admin || this.state.values?.extexplanation !== prevState.values?.extexplanation ) && prevState.items) {
      this.setState({ items: [React.cloneElement(prevState.items[0], { section: this.props.id, exitem: this.props.itemid, edit: this.state.admin, extexplanation: !!this.state.values?.extexplanation }, null)] });
    }

    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.setState({ admin: false });
    }

    if(this.state.values.extexplanation !== prevState.values.extexplanation) this.updateSectionOK();

  }

  deleteMe = async () => {

    //Before deleteing, remove Extinguisher data from child - LocationSurvey will otherwise retain this data
    if(this.state.items && this.state.items.length) {
      await this.props.actions.setValue({maintenancecontact: '', maintenancedue: '', extexplanation: ''});
      await this.props.actions.setChildValue(this.state.items[0]?.props?.id, {extcount: 0, extdata: [], extexplanation: false, extinguishers: [], extshortfall: 0, exttotal: 0});
      await this.props.updateChangeWatchers({ _id: "_locations", _locations: [] }, true);
    }

    return await this.props.actions.deleteMe();
  }

  render() {
    //console.log("locations: ",this.state._locations);

    //console.log("Location Hierarchy: ", locationHierachy);

    //console.log("ecs: ", this.state, this.props);

    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    return (
      <div className="ExtinguisherCalculatorSection SurveySection card card-edit" id={this.props.id} style={{ zIndex: this.state.popup ? 20 : '' }}>
        <div className="input-group card-header live">
          <div className="input-group-prepend" onClick={this.props.expand(this.props.id)}>
            <div className={"icon " + (this.state.values.icon || this.props.icon)} />
          </div>
          {edit ?
            <EditableA className="ztitle form-control input-group-text" name="name" onChange={this.props.actions.setValue} editFixedOn={true}>
              {this.state.values.name || "Extinguisher Calculator"}
            </EditableA>
            :
            <div className={"ztitle form-control title input-group-text"} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Extinguisher Calculator"}</div>
          }
          <div className="input-group-append">
            {edit && <StButtons actions={({...this.props.actions, deleteMe: this.deleteMe})} />}
            <EditDate editdate={this.state?.values?.sectionok && this.state.editdate} actions={this.props.actions} expanded={this.props.expanded} />
            {(this.props.expanded || edit) && <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} onShow={() => this.setState({ popup: true })} onHide={() => this.setState({ popup: false })} />}
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
        {/*<div hidden={!(this.props.expanded || edit)} className="card-block">*/}
        <ShowContainer className={"card-block"} show={(this.props.expanded || edit)}>
          <InlineHelp id={this.props.id + "_ec_intro"} edit={edit} name="ec_intro" onChange={this.props.actions.setValue} value={this.state.values.ec_intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
          <ReportIntro id={this.props.id + "_ec_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

          <div className="input-group">
            <div className="input-group-prepend input-group-text">Extinguisher Maintenance Due:</div>
            <FormText type="date" name="maintenancedue" value={this.state.values.maintenancedue} update={this.setValue} style={{ maxWidth: '12em' }} />
          </div>

          <div className="input-group">
            <div className="input-group-prepend input-group-text">Extinguisher Maintenance Contact:</div>
            <FormText name="maintenancecontact" value={this.state.values.maintenancecontact} update={this.setValue} />
          </div>

          <div className="input-group" hidden={!this.props.isSuper}>
            <div className="input-group-prepend">
              <div className="input-group-text">Explanation for Limited A-rated Extinguishers:</div>
            </div>
            <FormTextArea name="extexplanation" value={this.state.values.extexplanation===true?'':this.state.values.extexplanation} update={this.setValue} />
          </div>

          <div style={{ marginBottom: '1em' }} />

          {this.state.items}
        </ShowContainer>
        {/*</div>*/}
      </div>
    )
  }
}

//Reason for extinguisher shortfall
const ShortfallReason = [
  "",
  "Floor area not entered",
  "Insufficient coverage for floor area",
  "Less than the minimum of 2 extinguishers for a floor with an area of 100m² or more"
];

//Default list of extinguisher A-ratings
//const extinguisherList = [13, 21, 27];


/*
** Dynamic Extinguisher customiser with common examples
*/
const extClasses = [
  { class: 'A', description: 'Wood/Paper/Textiles' },
  { class: 'B', description: 'Flammable Liquids' },
  { class: 'C', description: 'Flammable Gases', boolean: true },
  { class: 'D', description: 'Metal', boolean: true },
  { class: 'E', description: 'Electrical Contact', title: 'Electrical', boolean: true },
  { class: 'F', description: 'Cooking Oils/Fats' }
];

//!Remove spaces in type??
const extTech = [
  { type: 'Water', ratings: ['A'] },
  { type: 'Foam', ratings: ['A', 'B', 'F'] },
  { type: 'CO₂', ratings: ['B', 'E'], presets: { B: [{ title: '2KG / 34B', value: 34, weight: 2 }, { title: '5KG / 70B', value: 70, weight: 5 }] } },
  { type: 'Powder', ratings: ['A', 'B', 'C', 'D', 'E'] },
  { type: 'Wet Chemical', ratings: ['A', 'F'] },
  { type: 'Water Mist', ratings: ['A', 'B', 'C', 'E', 'F'] },
  { type: 'Fire Blanket', ratings: ['A', 'F'], unrated: true }
];

const extcats = [
  { id: "extfields", title: "Extinguisher Fields" }
]

class ECLocationSurvey extends Survey {
  constructor(props) {
    super(props);

    //! Should be a list of examples?
    this.state["ext"] = { type: 'Water', ratings: [{ class: 'A', rating: 13 }] };

    this.state["show"] = false; //Show modal?
    this.state["leveladdext"] = null; //Function passed from Level to add ext

    //this.watchlist=["_locations"];

    //x!Not ideal - always runs twice due to setValue
  }

  stateUpdate = (newstate, oldstate) => {
    if ((newstate.elements || []).length !== (oldstate.elements || []).length) this.ecupdate();
  }

  ecupdate = () => {

    let buildingsMissing = false;
    let buildings = [];

    (this.state.elements || []).every(el => {
      const b = (this.state.buildings || {})[el];
      if (!b) {
        buildingsMissing = true;
        return false;
      }
      else buildings.push({ ...b, id: el, section: this.props.section, extexplanation: this.state?.values?.extexplanation });

      return true;
    });

    //Wait for all Building data before running updates
    if (buildingsMissing) {
      return;
    }

    //Collate
    let extcount = 0;
    let exttotal = 0;
    let extshortfall = 0;

    buildings.forEach(b => {
      (b.levels || []).forEach(l => {
        extcount += (l.exts || 0);
        exttotal += (l.exttotal || 0);

        if (extshortfall !== true) {
          extshortfall = (l.shortfall === true) ? true : extshortfall + (l.shortfall || 0);
        }
      })
    })

    //Check exiting values
    if (
      extcount !== this.state.values.extcount ||
      exttotal !== this.state.values.exttotal ||
      extshortfall !== this.state.values.extshortfall ||
      JSON.stringify(buildings) !== JSON.stringify(this.state.values.extdata)
    ) {
      //console.log("ECLocationSurvey ec update: ", extcount, this.state.values.extcount, extshortfall, this.state.values.extcount, JSON.stringify(buildings), JSON.stringify(this.state.values.extdata) );
      this.setValue({ extdata: buildings, extcount, exttotal, extshortfall });
    }

    //this.props.updateSectionOK(!!extcount);
    this.props.updateSectionOK(!!exttotal || exttotal !== this.state.values.exttotal); //?

    this.props.updateChangeWatchers({ _id: "_locations", _locations: buildings }, true);
  }

  updateExtData = bid => bvals => {

    this.setState(prevState => {
      if (bvals === 'delete') {
        const { [bid]: _, ...buildings } = (prevState.buildings || {});
        return { buildings };
      }
      else return { buildings: { ...(prevState.buildings || {}), [bid]: bvals } }
    },
      this.ecupdate
    )
  }

  expandIntro = () => this.setState({ introcollapsed: false });

  showAddCustom = (show, leveladdext = null) => {
    const s = typeof show !== "undefined" ? show : !this.state.show;
    this.setState({ show: s, leveladdext });
  }

  setExt = ({ exttype, extclass, extrating }) => {
    let ext = this.state.ext;

    if (exttype && exttype !== this.state.ext.type) {
      const et = extTech.find(eT => eT.type === exttype);

      if (et && et.unrated) ext = { type: exttype, ratings: et.ratings.map(r => ({ class: r, rating: 0 })) }; //Set 0 ratings for 'unrated' extinguisher type
      else ext = { type: exttype, ratings: [] };
    }

    else if (extclass && (extrating || extrating === false)) {
      const ratings = (this.state.ext.ratings || []);
      const er = ratings.findIndex(r => r && r.class === extclass);
      const nr = { class: extclass, rating: extrating };
      if (er === -1) ratings.push(nr);
      else ratings[er] = nr;

      ext = { type: this.state.ext.type, ratings };
    }

    //console.log({exttype, extclass, extrating, ext});

    this.setState({ ext });
  }

  addExt = () => {
    if (this.state.ext && this.state.ext.type && this.state.ext.ratings && this.state.ext.ratings.length) {
      const id = `${this.state.ext.type}-${(this.state.ext.ratings || []).map(r => `${r.class}${r.rating}`).join("_")}`;

      //Check for existing extinguisher
      const extinguishers = (this.state.values.extinguishers || []).find(ex => ex.id === id)
        ? this.state.values.extinguishers
        : [].concat(this.state.values.extinguishers || [], { id, ...this.state.ext });

      //console.log("Adding extinguisher: ", this.state.ext, this.state.values.extinguishers, extinguishers);

      //Add ordering and deduping!
      this.props.actions.setValue({ extinguishers });
      if (this.state.leveladdext) this.state.leveladdext(id, 1);
      this.setState({ ext: { type: 'Water', ratings: [] } });
      this.showAddCustom(false);
    }
  }

  deleteExt = id => {
    const extinguishers = this.state.values.extinguishers.filter(ext => ext.id !== id);

    //console.log("Delete Ext: ", id, this.state.values.extinguishers, extinguishers);

    this.setValue({ extinguishers })
      .then(() => this.ecupdate());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.erexitem !== nextProps.exitem) {
      //console.log("ECLocationSurvey: componentDidUpdate: ", this.state, this.props.exitem) ;
      return ({ erexitem: nextProps.exitem, expanded: nextProps.exitem });
    }

    return null;
  }

  /*componentDidMount() {
    super.componentDidMount();
    
    //this.props.registerRootWatcher(doc=>getLocations(this.props.rootid), "_locations", this.props.rootid);
    //this.props.registerChangeWatcher(this.locationsUpdate, "_locations");
  }*/

  componentDidMount() {
    super.componentDidMount();

    if ((this.state.elements || []).length !== ((this.state.values || {}).extdata || []).length) this.ecupdate();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!(this.state.elements || []).length && ((prevState.elements || []).length || this.state.values.extcount || this.state.values.extshortfall)) {
      this.setValue({ extdata: [], extcount: 0, extshortfall: 0 });
    }

    if(this.state.values.extexplanation !== this.props.extexplanation) {
      this.setValue({ extexplanation: this.props.extexplanation });
    }
    //extdata: buildings, extcount, extshortfall

  }

  addBuilding = () => {
    this.addChildAndFlag('Building');
  }

  render() {
    //const edit=this.props.values.editmode;
    //const edit=(this.state.edit);

    //console.log("ECLS isSuper: ", this.props.isSuper);

    //console.log("ECLS: ", this.state, this.props);
    /*!!
      values to delete on removal of section: extcount, extdata, extexplanation, extinguishers, extshortfall, exttotal
    */

    const edit = (this.state.edit || this.props.edit);

    const children = this.children({
      props: {
        expand: this.expand,
        showAddCustom: this.showAddCustom,
        customratings: this.state.values.customratings || [], //depr
        extinguishers: this.state.values.extinguishers,
        unregisterFloor: this.unregisterFloor,
        ecsubfields: this.state.values.ecsubfields
      },
      propsIdFunc: { updateExtData: this.updateExtData },
      propsId: this.state.expanded ? { [this.state.expanded]: { expanded: true } } : false,
      orderChildrenByType: true,
      placeholder: <p className="placeholder">Please add {this.props.childTypes && this.props.childTypes[0] && this.props.childTypes[0].title}</p>
    });

    //console.log("ECLS state: ",this.state);

    const selectedExtTech = (extTech.find(eT => eT.type === this.state.ext.type) || {});

    const selectedExtClasses = extClasses.filter(r =>
      (selectedExtTech.ratings || []).includes(r.class)
      ||
      (this.state.ext.ratings || []).find(er => er.class === r.class)
    );

    const hasBuildings = !!children.length;

    return (
      <div className="ECLocationSurvey" id={'ec_' + this.props.id} style={{ zIndex: this.state.popup ? 20 : '' }} >

        {/*<FButton onClick={this.setShow} icon={"fs-pencil"}>Add Custom</FButton>*/}

        {edit && <div style={{ float: "right" }}><SubFieldEditor name="ecsubfields" value={this.state.values.ecsubfields} rootfields={extcats} setValue={this.setValue} onShow={() => this.setState({ popup: true })} onHide={() => this.setState({ popup: false })} /></div>}

        <Modal
          icon="fs-fire-extinguisher"
          title="Extinguisher Types on Site"
          show={this.state.show}
          hideModal={e => this.showAddCustom(false)}
          footerButtons={<FButton icon="fs-fire-extinguisher" glyph="+" onClick={this.addExt}>Add</FButton>}
          scroll
        >

          {(this.state.values.extinguishers || []).length > 0 &&
            <div>
              <h4>The types of extinguishers identified on site</h4>

              {(this.state.values.extinguishers || []).map(ext =>
                <div className="input-group" key={ext.id}>
                  <span className="input-group-text form-control">{ext.type}</span>
                  {(ext.ratings || []).map(rt =>
                    <span key={`${rt.rating}_${rt.class}`} className="input-group-text">{rt.rating !== true && rt.rating}{rt.class}</span>
                  )}
                  <div className="input-group-append">
                    <SureButton icon="fs-trash" onClick={e => this.deleteExt(ext.id)} buttonText="Delete">Delete?</SureButton>
                  </div>
                </div>
              )}
            </div>
          }

          <h4>Add new type of extinguisher</h4>
          <p>The type and rating of each extinguisher is usually found on the body of the extinguisher</p>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Technology: </span>
            </div>
            <BSDrop title={this.state.ext.type} right>
              {extTech.map(t =>
                <BSDropItem onClick={e => this.setExt({ exttype: t.type })} key={t.type}>
                  {t.type}
                </BSDropItem>
              )}
            </BSDrop>
          </div>

          <div>
            {
              selectedExtClasses.map(r => {

                const rating = (this.state.ext.ratings.find(er => er.class === r.class) || {}).rating;

                return (
                  <div className="input-group" key={(this.state.ext.type || "") + r.class}>
                    <div className="input-group-prepend">
                      <span className="input-group-text">{r.title || r.class} Rating:</span>
                    </div>
                    {r.boolean
                      ? <Radioaddon checked={rating} icon={rating ? "fs-check-square-o" : "fs-square-o"} onClick={e => this.setExt({ extclass: r.class, extrating: !rating })}>Rated</Radioaddon>
                      :
                      selectedExtTech.unrated
                        ? <span className="input-group-text fs-check" />
                        : selectedExtTech.presets && selectedExtTech.presets[r.class] && !rating
                          ? <BSDrop title="Select" right>
                            {selectedExtTech.presets[r.class].map(t =>
                              <BSDropItem onClick={() => this.setExt({ extclass: r.class, extrating: t.value })} key={t.title || t.value}>
                                {t.title || t.value}
                              </BSDropItem>
                            )}
                          </BSDrop>
                          : <FormText
                            type="number"
                            value={rating}
                            name="customrating"
                            update={(n, v) => this.setExt({ extclass: r.class, extrating: v })}
                            formcontrol={false}
                            min="0" max="999"
                          />
                    }
                    <div className="input-group-append">
                      <span className="input-group-text">{selectedExtTech.presets && selectedExtTech.presets[r.class] && !rating ? '' : r.title || r.class}</span>
                    </div>
                  </div>
                )
              }
              )}
          </div>

        </Modal>

        <Namer className="sectionTitle" edit={edit} name="title" value={this.state.values.title} defaultName="Building Summary" onChange={this.setValue} />

        <div className="SurveySections" id={this.props.id}>
          {children}

          <div className="areanew">
            {/*<AddChildButtons childTypes={this.props.childTypes} addButton addChild={this.addChildAndFlag} />*/}
            <FButton addButton onClick={this.addBuilding}>{`Add${hasBuildings ? ' Another' : ' A'} Building`}</FButton>
          </div>

          <div className="ecLocationBuilding summary Risk risk-highlight">
            <div className="card card-edit">
              <div className="card-header edit">
                <div className="input-group" data-toggle="buttons" onClick={this.expand("summary")}>
                  <span className="ztitle form-control input-group-text toplevel">Summary</span>
                  <div className="input-group-append">
                    {this.state.expanded !== "summary" &&
                      <>
                        {!!this.state.values.exttotal && <FInfo title="Total Exts" className="fullsize">
                          {this.state.values.exttotal}
                        </FInfo>}
                        <FInfo title="A-Rated" className="fullsize">
                          {this.state.values.extcount}
                        </FInfo>
                        {(this.state.values.extshortfall ?
                          <FInfo title="Shortfall" className="fullsize">
                            <span className="fs-exclamation-triangle">
                              {this.state.values.extshortfall === true ? "?" : this.state.values.extshortfall}
                            </span>
                          </FInfo>
                          :
                          <FInfo title="Covered" className="fullsize">
                            <span className="fs-check" />
                          </FInfo>
                        )}
                      </>
                    }
                    {/*<FButton icon={this.state.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.expand("summary")} />*/}
                    <ExpandButton expanded={this.state.expanded === "summary" || edit} onClick={this.expand("summary")} />
                  </div>
                </div>
              </div>
              {/*<div hidden={!(this.state.expanded==="summary" || edit)} className="card-block">*/}
              <ShowContainer className="card-block" show={this.state.expanded === "summary" || edit}>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Total extinguishers on site: </span>
                  </div>
                  <span className="form-control">{this.state.values.extcount}</span>
                </div>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Shortfall of extinguishers on site (13A equivalent): </span>
                  </div>
                  <span className="form-control">{this.state.values.extshortfall === true ? <span className="fs-exclamation-triangle"> One or more floor areas not entered</span> : (this.state.values.extshortfall || null)}</span>
                </div>

                <div className="shortfall">
                  <div className="title">Explanation of shortfall</div>
                  <div className="shortfall-detail">
                    {this.state.values.extexplanation && <ul><li>Explanation for Limited provision of A-rated extinguishers provided</li></ul>}
                    <ul className="shortfall-detail-buildings">
                      {ensureArray(this.state.values?.extdata).map(b =>
                        <li key={b.id}>{b.name}
                          <ul>
                            {ensureArray(b.levels).map(l =>
                              <li key={l.id}>{l.name}
                                <ul>
                                  <li>A-Rated Extinguishers: {l.exts} {l.shortfall === 0 ? <span className="fs-check" /> : null}</li>
                                  {l.shortfall !== 0 && <li>Shortfall: {l.shortfall === true ? '?' : l.shortfall} <span className="fs-exclamation-triangle" /></li>}
                                  {(l.shortfall && l.shortfallreason) ? <li>{ShortfallReason[l.shortfallreason]}</li> : ""}
                                  <li>Total Extinguishers: {l.exttotal}</li>
                                </ul>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </ShowContainer>
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class ECLocationBuilding extends Container {
  componentDidMount() {
    super.componentDidMount();
    if (this.props.newchild) {
      this.props.expand(this.props.id)();
    }

    //this.props.updateExtData({name: (this.state.values || {}).name, levels: this.state.elements});
    this.ecupdate();
  }

  stateUpdate = (newstate, oldstate) => {
    if (((newstate.values || {}).name !== (oldstate.values || {}).name) || !arraysEqual(oldstate.elements, newstate.elements)) {
      this.ecupdate();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //const r=this.state.rshow;

    if (this.state.rshow) {
      if (!this.props.expanded) this.props.expand(this.props.id)();
      this.setState({ rshow: null });
    }
  }

  ecupdate = (newel) => {

    let levelsMissing = false;
    let levels = [];

    (newel || this.state.elements || []).every(el => {
      const b = (this.state.levels || {})[el];
      if (!b) {
        levelsMissing = true;
        return false;
      }
      else levels.push({ ...b, id: el });

      return true;
    });

    if (levelsMissing || !levels.length) return;

    //this.props.updateExtData({name: (this.state.values || {}).name || 'Building', levels: (this.state.elements || []).map(el=>(this.state.levels || {})[el] || null) });

    this.props.updateExtData({ name: (this.state.values || {}).name || 'Building', levels });
  }

  deleteBuilding = () => {
    this.props.updateExtData('delete');
    this.props.actions.deleteMe();
  }

  updateExtData = lid => lvals => {

    this.setState(prevState => {
      if (lvals === 'delete') {
        const { [lid]: _, ...levels } = prevState.levels;
        return { levels };
      }
      else return { levels: { ...prevState.levels, [lid]: lvals } };
    },
      () => { if (lvals !== 'delete') this.ecupdate() }
    )
  }

  /*stateUpdate = (newstate, oldstate) => {
    if ((newstate.elements || []).length !== (oldstate.elements || []).length) this.ecupdate(newstate.elements);
  }*/

  render() {
    const edit = (this.state.edit || this.props.edit);

    const children = this.children({
      props: {
        showAddCustom: this.props.showAddCustom,
        customratings: this.props.customratings,
        extinguishers: this.props.extinguishers,
        unregisterFloor: this.props.unregisterFloor,
        ecsubfields: this.props.ecsubfields
      },
      propsIdFunc: { updateExtData: this.updateExtData },
      placeholder: <div className="placeholder">Please add {this.props.childTypes && this.props.childTypes[0] && this.props.childTypes[0].title}</div>
    });

    return (
      <div className="ecLocationBuilding Risk">
        <div className="card card-edit">
          <div className="card-header edit">
            <div className="input-group" data-toggle="buttons">
              {this.props.expanded ?
                <EditableA className="ztitle input-group-text form-control toplevel" name="name" onChange={this.setValue} onClick={this.props.expand(this.props.id)} editOnRender={this.props.newchild}>
                  {this.state.values.name || "Building"}
                </EditableA>
                :
                <span className="ztitle input-group-text form-control toplevel" onClick={this.props.expand(this.props.id)}>
                  {this.state.values.name || "Building"}
                </span>
              }
              <div className="input-group-append">
                {(this.props.expanded || edit) && <AddChildButtons childTypes={this.props.childTypes} addChild={this.addChildAndFlag} />}
                {(this.props.expanded || edit) && <SureButton icon="fs-trash" onClick={this.deleteBuilding} buttonText="Delete">Delete?</SureButton>}

                {!this.props.expanded &&
                  <FInfo title={"Floor" + (children && children.length && children.length === 1 ? "" : "s")} className="fullsize">
                    {children && children.length}
                  </FInfo>
                }

                {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
                <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
              </div>
            </div>
          </div>
          {/*<div hidden={!(this.props.expanded || edit)} className="card-block">*/}
          <ShowContainer className="card-block" show={(this.props.expanded || edit)} headerId={this.props.parentid}>
            {children}
          </ShowContainer>
          {/*</div>*/}
        </div>
      </div>
    )
  }
}

/*const extinguisherList = [
  {id: "13A", title: "13A", coverage: 200},
  {id: "21A", title: "21A", coverage: 300},
  {id: "27A", title: "27A", coverage: 400},
  {id: "55A", title: "55A", coverage: 800}
]*/

/*const ShortfallIcon = ({shortfall}) => (
  shortfall===0 || shortfall>0 || shortfall===true
    ?<span className={shortfall?"fs-exclamation-triangle":"fs-check"} />
    :null
)*/

class ECLocationLevel extends Container {

  getCalc = (vals, extinguishers) => {

    const area = (vals.area ? vals.area.value : false);
    let exts = 0;
    let exttotal = 0;
    let cov = 0;
    let shortfall = 0;
    let shortfallreason = false;

    (this.props.extinguishers || []).forEach(ext => {

      const arating = (ext.ratings.find(r => r.class === 'A') || {}).rating;

      const count = Number((vals.extcounts || {})[ext.id] || 0);
      exttotal += count;

      if (arating) {
        //const count=Number((vals.extcounts || {})[ext.id] || 0);

        exts += count;
        cov += count * (arating * (200 / 13));
      }
    });

    if (!area) {
      shortfall = true;
      shortfallreason = 1;
    }
    else {

      if (area < 100) {
        if (exts > 0) shortfall = 0;
        else {
          shortfall = 1;
          shortfallreason = 2;
        }
      }
      else if (area <= cov) {
        if (exts > 1) shortfall = 0;
        else {
          shortfall = 1;
          shortfallreason = 3;
        }
      }
      else {
        //shortfall=Math.max(Math.ceil((area-cov)/200),(exts<2?2:0));
        shortfall = Math.max(Math.ceil((area - cov) / 200), (exts < 1 ? 2 : 0));
        shortfallreason = 2;
      }
    }
    //console.log("exts: ", exts);

    return ({ shortfall, extinguisherpriority: shortfall ? 2 : 0, shortfallreason, exts, exttotal, cov: (Math.floor(cov)) });
  }

  updateVal = async (n, v) => {

    const vals = { ...this.getCalc({ ...this.state.values, [n]: v }), [n]: v };

    /*await*/ this.setValue(vals);

    //this.updateMaster(vals);

    //await this.setValue(n,v)
    //this.updateCalc({ ...this.state.values, [n]:v });
  }

  updateExts = async (id, v) => {

    const extcounts = { ...this.state.values.extcounts, [id]: Number(v) };

    //this.setState({values: { ...this.state.values, extcounts }}); //Unconventional direct setting

    const vals = { ...this.getCalc({ ...this.state.values, extcounts }), extcounts };

    /*await*/ this.setValue(vals);

  }

  deleteLevel = () => {
    this.props.updateExtData('delete');
    this.props.actions.deleteMe();
  }

  updateMaster = (vals = {}) => {

    const v = { ...(this.state || {}).values || {}, ...vals };

    //const hasSubActions = Object.values((v.ecsubfields || {}).extfields || {}).find(v=>v)?true:false;


    let hasSubActions = false;
    let ecsubfields = {};

    //this.state.values.subfields

    //console.log("ecsf: ", Object.values((v.ecsubfields || {}).extfields || {}) );

    Object.entries((v.ecsubfields || {}).extfields || {}).forEach(([skey, svalue]) => {
      if (svalue) {
        hasSubActions = true;
        ecsubfields[skey] = (((this.props.ecsubfields || {}).extfields || {}).find(sf => sf.id === skey) || {}).action;
      }
    });

    const out = {
      id: this.props.id,
      name: v.name,
      area: v.area,
      extcounts: Object.assign({}, ...(this.props.extinguishers || []).map(ext => (v.extcounts || {})[ext.id] ? { [ext.id]: (v.extcounts || {})[ext.id] } : {})),
      exts: v.exts || 0,
      exttotal: v.exttotal || 0,
      shortfall: v.shortfall || 0,
      shortfallreason: v.shortfallreason || false,
      extinguisherpriority: v.extinguisherpriority,
      ecsubfields,
      hasSubActions,
      measure: {
        _attachments: this.state._attachments,
        values: {
          note: (this.state.values || {}).note
        }
      },
    };

    this.props.updateExtData(out);
  }

  componentDidUpdate(prevProps) {
    if (this.props && this.props.extinguishers && !(arraysEqual(prevProps.extinguishers || [], this.props.extinguishers || []))) {
      if (this._mounted !== false) this.setValue(this.getCalc(this.state.values));
    }
  }

  stateUpdate = (newstate, oldstate) => {
    this.updateMaster(newstate);
  }

  componentDidMount() { //Not required??
    super.componentDidMount();

    //?? this.updateVal('area', this.state.values.area);
    this.updateMaster();
  }

  setSubFieldValue = (id, bool) => v => {

    /* new subfield list for this id */
    //const newf = { ...(((this.state.values || {}).subfields || {})[id] || {}), [n]:v };
    const newf = v;
    const fieldok = !((this.props.subfields || {})[id] || []).find(sf => newf[sf.id]);

    //console.log("setSubFieldValue: ", id, n, v, fieldok);

    const val = {
      ...(bool ? { [id]: fieldok } : {}),
      ecsubfields: {
        ...((this.state.values || {}).subfields || {}),
        [id]: newf
      }
    };

    this.setValue(val)
  }

  render() {

    return (
      <div id={this.props.id} className={`ECLocationLevel`}>
        <div className="ECLL-heading input-group">
          <FInfo title="Level Ref" titleLeft className="form-control">
            <EditableA className="ztitle form-control" name="name" onChange={this.setValue} editFixedOn={true}>
              {this.state.values.name || "Building"}
            </EditableA>
          </FInfo>

          <FInfo title="Floor Area" titleRight className="form-control">
            <CalcField className="form-control" unit="m²" placeholder="Area?" name="area" update={this.updateVal} value={this.state.values.area} />
          </FInfo>

          <div className="input-group-append">
            <AttachDocModal id={this.props.id + '_attachDoc'} typedoc={false} title={`Attachments for: ${this.state.values.name}`} attprefix="ec" note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />            <SureButton icon="fs-trash" onClick={this.deleteLevel} buttonText="Delete">Delete Level?</SureButton>
          </div>
        </div>
        <div style={{ textAlign: 'right', fontSize: '0.8em' }}>*To convert sqft to m², type your sqft area into the box then divide by 10.764. e.g 1000/10.764</div>
        <div className="ECLL-body">
          {
            (this.props.extinguishers || []).map(ext => {

              let weight = '';

              const title = `${ext.type} ${(ext.ratings || []).map(r => {

                if (!weight) {
                  const p = extTech.find(et => et.type === ext.type)?.presets || {};
                  if (p) weight = ((p[r.class] || []).find(pr => pr.value === r.rating) || {}).weight;
                }

                return `${(r.rating !== true && r.rating !== 0) ? r.rating : ""}${r.class}`
              }).join(" ") + (weight ? ` ${weight}KG` : '')}`;

              return (
                <GreyBox subtitle={title} medium notButton key={ext.id}>
                  <FormText type="number" name={ext.id} value={this.state.values.extcounts ? this.state.values.extcounts[ext.id] : 0} update={this.updateExts} min="0" max="999" />
                </GreyBox>
              )
            })
          }

          <GreyBox subtitle={'Add Type'} medium onClick={e => this.props.showAddCustom(true, this.updateExts)}>
            <span className="fs-fire-extinguisher" style={{ fontSize: '2rem' }} />+
          </GreyBox>

          {/*<ButtonModal greybutton modalClassName="" scroll icon="fs-exclamation-triangle" title="Add Action" 
            buttonText={<CircleCheck noCheck icon={actions?'fs-exclamation-triangle':'fs-question'} classNameActive={actions?'alertText':''} title="Actions" checked={true} />}
          > 
        </ButtonModal>*/}

          {extcats.map((rc, i) => {

            const fields = (this.props.ecsubfields || {})[rc.id] || [];
            const values = ((this.state.values || {}).ecsubfields || {})[rc.id] || {};

            const actions = fields.find(f => values[f.id]);

            return (
              <GreyBox subtitle={" "} medium notButton key={rc.id}
                popout={
                  <PopoutModal
                    title={`${this.state.values.name || 'Extinguisher'} | ${rc.title}`}
                    fields={fields}
                    values={values}
                    setValue={this.setSubFieldValue(rc.id, rc.type === 'bool')}
                    help={(this.props.ecsubfields || {})[`${rc.id}_help`]}
                    actions={actions}
                  />
                }
              />
            )
          })}

        </div>
      </div>
    )

    /*return (
        <tr>
          <td>
            <EditableA className="ztitle form-control" name="name" onChange={this.props.actions.setValue} editOnRender={this.props.newchild}>
              {this.state.values.name || "Level"}
            </EditableA>
          </td>
          <td>
            <CalcField unit="m²" placeholder="Area?" name="area" update={this.updateVal} value={this.state.values.area} />
          </td>
          <td className="extlist">
            {
              (this.props.extinguishers || []).map(ext=>{
                const title=`${ext.type} ${(ext.ratings || []).map(r=>`${r.rating!==true?r.rating:""}${r.class}`).join(" ")}`;

                return (
                  <FInfo title={title} key={ext.id} edit>
                    <FormText type="number" name={ext.id} value={this.state.values.extcounts?this.state.values.extcounts[ext.id]:0} update={this.updateExts} min="0" max="999" />
                  </FInfo>
                )
              })
            }

            <FButton icon="fs-fire-extinguisher" onClick={e=>this.props.showAddCustom(true, this.updateExts)}>Add Type</FButton>
          </td>
          <td className="controls">
            <SureButton icon="fs-trash" onClick={this.props.actions.deleteMe} buttonText="Delete">Delete?</SureButton>
          </td>
        </tr>
    )*/
  }
}


const precautiontypes = [
  { id: 0, text: 'Equipment', display: ' ', icon: 'fs-tools' },
  { id: 1, text: 'Process', display: '!', icon: 'fs-user' }
]

class PrecautionSurveySection extends Container {

  constructor(props) {
    super(props);

    this.state["expanded"] = this.props.itemid || false;
    this.state["exitem"] = false;
  }

  expand = (id, force = false, exitem) => e => {

    const expanded = (this.state.expanded === id && force !== true) ? false : id;

    this.props.expand(this.props.id, true, false, id)();

    if (this.props.onExpand) this.props.onExpand(expanded);
    this.setState({ expanded, introcollapsed: true, exitem }); //, ()=>scrollTo(id)
  }

  stateUpdate = newstate => {
    //getPrecautions(this.props.rootid)
    //.then(precautions=>this.props.updateChangeWatchers({_id: "_precautions", _precautions: precautions},true));

    getExtinguisherList(this.props.rootid)
      .then(exts => this.props.updateChangeWatchers({ _id: "_extinguishercategories", _extinguishercategories: getExtinguisherCategories(exts) }, true));
  }

  //Update values.cathide when global _categories ior local values.categories change
  catUpdate = (_categories = { _categories: this.state._categories }) => {
    categoryHider(_categories, this.state.values.categories, this.state.values.cathide, this.setValue, this.updateState, this.props.id);
  }

  componentDidMount() {
    super.componentDidMount();
    //this.addWatcher("_categories");
    this.props.registerChangeWatcher(this.catUpdate, "_categories");
  }

  /**
   * Check if all children are checked (Yes / No)
   * @param {*} childid 
   */
  childcheck = childid => value => {

    if (this._mounted !== false) this.setState(st => {

      const checkedchildren = { ...st.checkedchildren, [childid]: (value === false || value === true || value === 1 || value === 2 || value === '1' || value === '2') }; //?
      const allchildrenchecked = !(this.state?.children.filter(el => ['Measure', 'Equipment'].includes(el?.props?.type))?.find(el => !checkedchildren[el])); //!Account for non-Measure children
      const anychildrenselected = allchildrenchecked || !!st.elements.find(el => checkedchildren[el]);

      return ({ checkedchildren, allchildrenchecked, anychildrenselected })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    //!update on expanded change
    if (this.props.exitem && prevProps.exitem !== this.props.exitem) {
      this.expand(this.props.exitem, true)();
    }

    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.setState({ admin: false });
    }

    if (this.state.values.categories && (!prevState.values.categories || !arraysEqual(this.state.values.categories, prevState.values.categories))) this.catUpdate();

    //set values.allchildrenchecked if all child data gathered
    if (this.state.checkedchildren
      && Object.keys(this.state.checkedchildren).length === this.state?.children.filter(el => ['Measure', 'Equipment'].includes(el?.props?.type))?.length
      && this.state.values?.sectionok !== this.state.anychildrenselected) this.setValue({ sectionok: this.state.anychildrenselected }, true);
  }

  render() {
    //const edit=this.props.values.editmode;

    //console.log("Precaution Survey Section props: ", this.props);

    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    //const categories = [{id: -1, name: "Any"}].concat((this.state._categories && this.state._categories.children) || []);

    //if(!categoryshow(this.state._categories, this.state.values.categories, this.props.id) && !(edit || this.props.admin)) return null;
    if (this.state.values.cathide && !(this.props.admin || edit)) return null;

    //! Add "Highlight" toggle switch

    //const siblings = this.props?.siblings?.filter(el=>this.props.type===el.type);

    const siblings = this.props?.siblings?.filter(el => this.props.type === el.type)?.map(el => ({ ...el, ...(el.id === this.props.id ? { parent: true } : {}) }));

    //console.log("siblings: ", this.props.siblings, siblings);

    //console.log("PSS: ", this.props.id, this.state);

    return (
      <div className="SurveySection PrecautionSurveySection card card-edit" id={this.props.id} style={{ zIndex: this.state.popup ? 20 : '' }}>

        <div className={`input-group card-header ${edit ? 'edit' : 'live'}`} data-toggle="buttons">
          <div className="input-group-prepend" onClick={() => !edit && this.props.expand(this.props.id)}>
            {edit
              ? <Icondrop icon={this.state.values.icon || this.props.icon} name="icon" onClick={this.props.actions.setValue} />
              : <div className={"icon " + (this.state.values.icon || this.props.icon)} />
            }
          </div>
          {edit
            ? <EditableA className="ztitle form-control input-group-text" onChange={this.props.actions.renameMe} editFixedOn={true}>
              {this.state.values.name || "Section"}
            </EditableA>
            : <div className={"ztitle form-control title input-group-text"} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Section"}</div>
          }

          <div className="input-group-append">
            {edit && <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />}

            {edit && <BSDrop icon={precautiontypes[this.state.values.precautiontype || 0].icon} right>
              {precautiontypes.map(r =>
                <BSDropItem onClick={e => this.props.actions.setValue("precautiontype", r.id)} key={r.id}>
                  <span className={r.icon} />{r.text}
                </BSDropItem>
              )}
            </BSDrop>}

            {/*edit && <CategorySelector id={this.props.id} categories={this.state._categories} categoriesstate={this.state.values.categories} />*/}
            {edit && <CategorySelector id={this.props.id} categories={this.state._categories} categoriesstate={this.state.values.categories} setValue={this.setValue} />}

            {edit && <StButtons actions={this.props.actions} />}
            <EditDate editdate={this.state.values.sectionok ? this.state.editdate : null} actions={this.props.actions} expanded={this.props.expanded} />
            {(this.props.expanded || edit) && <HelpModal id={this.props.id + "_help"} edit={edit} onChange={v => this.props.actions.setValue('help', v)} value={this.state.values.help} showState={s => this.setState({ popup: s })} />}
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>

        {/*}:
        <div className="input-group card-header live">
          <div className="input-group-prepend" onClick={this.props.expand(this.props.id)}>
            <div className={"icon "+(this.state.values.icon || this.props.icon)} />
          </div>
          <div className={"ztitle form-control title"} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Section"}</div>
          <div className="input-group-append">
            <HelpModal id={this.props.id+"_help"} edit={edit} onChange={v=>this.props.actions.setValue('help',v)} value={this.state.values.help}onShow={()=>this.setState({popup: true})} onHide={()=>this.setState({popup: false})} />
            <FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
        */}

        {/*<div className={"card-block"+(this.props.expanded || edit?"":" hide")}>*/}
        <ShowContainer show={edit || this.props.expanded} className="card-block">
          {/*this.state.children && this.props.childTypes.map(childType => this.state.children.filter(c=>c.props.type===childType.type))*/}

          <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />
          <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

          {this.children({
            props: { expand: this.expand, edit: this.state.admin, childUpdate: this.childUpdate, siblings },
            propsId: this.state.expanded ? { [this.state.expanded]: { expanded: true, exitem: this.state.exitem } } : false,
            orderChildrenByType: true,
            propsIdFunc: { check: this.childcheck }
          })}

        </ShowContainer>
        {/*</div>*/}
      </div>
    )
  }
}

const ERField = props => (
  <div className="input-group">
    <div className="input-group-prepend">
      <span className="input-group-text">{props.title}</span>
    </div>
    {props.children}
    {props.append && <div className="input-group-append">
      {props.append}
    </div>}
  </div>
)

const helpcats = [
  { id: "length", title: "Length" }
];

const routecats = [
  { id: "lengthok", title: "Length", descr: "The length of the route is below the maximum", problem: "The route is too long", priority: 2, type: 'bool' },
  { id: "maxpeople", title: "Max People", descr: "Maximum number of people who may need to use this route", problem: "This route is not large enough for the number of people who may use it", priority: 2, type: 'int', alertLevel: 60 },
  { id: "signage", title: "Signage", descr: "The route displays the appropriate signage", problem: "The route is insufficiently well signposted", priority: 2, type: 'bool' },
  { id: "lighting", title: "Lighting", descr: "The route is sufficiently well lit", problem: "The route is inadequately lit", priority: 2, type: 'bool' },
  { id: "clear", title: "Clear Route", descr: "Route is clear of obstacles", problem: "There are obstacles blocking the route", priority: 1, type: 'bool' },
  { id: "nohighrisks", title: "High Risk Free", descr: "There are no high risks on the path of the route", problem: "There are high risks on this route", priority: 2, type: 'bool' },
  { id: "equipment", title: "Equipment", descr: "Appropriate fire safety equipment is available on the route", problem: "Fire safety equipment is missing from the route", priority: 2, type: 'bool' },
  { id: "risklevel", title: "Risk Level", descr: "", problem: "", priority: 0, type: 'risklevel' }
];

const RouteHelp = ({ showRcHelp, edit, setValue, values, name }) => {

  const setVal = (n, v) => setValue(name, { ...values, [n]: v });

  return (
    helpcats.concat(routecats).map(rc =>
      <HelpModal id={`${rc.id}_help`} title={`${rc.title} Help`} hideButton buttonText={`${rc.title} Help`} key={`${rc.id}_help`} edit={edit} getShow={showRcHelp(rc.id)} name={rc.id} onChange={setVal} value={values[rc.id]} />
    )
  )
}

/**
 * rootFields = [{id, title}]
 * @param {*} param0 
 */
const SubFieldEditor = ({ name, value = {}, rootfields = [], setValue, onShow, onHide }) => {
  //const [show, setShow] = useState(false); //Just for the MDE

  const newField = () => ({ id: cuid(), risk: '', action: '' });

  const addField = id => () => {
    setValue(name, { ...value, [id]: (value[id] || []).concat(newField()) });
  }

  const updateField = (id, fid) => (n, v) => {
    setValue(name, { ...value, [id]: (value[id] || []).map(f => f.id === fid ? { ...f, [n]: v } : f) });
  }

  const deleteField = (id, fid) => () => {
    setValue(name, { ...value, [id]: (value[id] || []).filter(f => f.id !== fid) });
  }

  /* Single-value field at root level */
  const updateMain = (id, v) => {
    setValue(name, { ...value, [id]: v });
  }

  return (
    <ButtonModal scroll modalClassName="MDModal" title="Subfield Editor" buttonText="Subfields" onShow={onShow} onHide={onHide}>
      {rootfields.map(rf =>
        <div key={rf.id} className="card card-edit SFE-card">
          <div className="card-header input-group">
            <span className="form-control input-group-text ztitle">{rf.title}</span>
            <div className="input-group-append">
              <FButton icon="fa fa-indent" onClick={addField(rf.id)} glyph='+' >Add Field</FButton>
            </div>
          </div>
          <div className="card-block">
            {/*<MDE value={value[`${rf.id}_help`]} onChange={updateMain(`${rf.id}_help`)} options={{autoDownloadFontAwesome: false}} show={show} />*/}
            <InlineHelp edit={true} name={`${rf.id}_help`} onChange={updateMain} value={value[`${rf.id}_help`]} />

            {((value || {})[rf.id] || []).map(sf => {

              return (
                <div key={sf.id} className="input-group SubFieldEditor-field">
                  <FInfo title="Risk Text" className="form-control">
                    <FormText name="risk" value={sf.risk} update={updateField(rf.id, sf.id)} placeholder="Risk Text" />
                  </FInfo>
                  <FInfo title="Action Text" className="form-control">
                    <FormText name="action" value={sf.action} update={updateField(rf.id, sf.id)} placeholder="Action Text" />
                  </FInfo>
                  <div className="input-group-append">
                    <SureButton icon="fs-trash" onClick={deleteField(rf.id, sf.id)} buttonText="Delete">Delete?</SureButton>
                  </div>
                </div>
              )

            })}
          </div>
        </div>
      )}
    </ButtonModal>
  )
}

const SubField = ({ field = {}, action, setValue }) => {


  return (
    <div className={`Measure input-group`}>
      <div className="input-group-prepend btn-group-toggle">
        <CircleCheck icon="fs-exclamation-triangle" title="Action" classNameActive="alertText" active={action} checked={action} name={field.id} onChange={() => setValue(field.id, !action)} />
      </div>
      <span className="form-control input-group-text">{field[action ? 'action' : 'risk']}</span>
      <div className="input-group-append btn-group-toggle">

      </div>
    </div>

  )
}

/*
Risk Identified

Action Text
*/

class EscapeRouteSection extends Survey {
  constructor(props) {
    super(props);

    this.defaultNewVals = { escapepoints: 4 }

    this.state.newvals = { ...this.defaultNewVals };
  }

  updateCategories = ({ _categories }) => {
    //Get a list of operation (category) areas
    const areas = _categories && _categories.children && _categories.children.map(cat => {
      const a = cat.children && cat.children.find(r => r.values && !r.values.escapeareatype);

      return {
        name: cat.values.name,
        default: cat.values.default,
        inplace: cat.values.inplace,
        esc1: (a && a.values && a.values.esc1 && a.values.esc1.M) || 0,
        esc1h: (a && a.values && a.values.esc1 && a.values.esc1.H) || 0,
        esc1m: (a && a.values && a.values.esc1 && a.values.esc1.M) || 0,
        esc1l: (a && a.values && a.values.esc1 && a.values.esc1.L) || 0,
        escm: (a && a.values && a.values.escm && a.values.escm.M) || 0,
        escmh: (a && a.values && a.values.escm && a.values.escm.H) || 0,
        escmm: (a && a.values && a.values.escm && a.values.escm.M) || 0,
        escml: (a && a.values && a.values.escm && a.values.escm.L) || 0
      }
    }
    );

    let selectedareas, esc1, esc1h, esc1m, esc1l, escm, escmh, escmm, escml;

    //Find the most relevant areas (selected, default or all)
    if (areas && areas.length) {
      selectedareas = areas.filter(a => a.inplace);
      if (selectedareas.length === 0) selectedareas = areas.filter(a => a.default);
      if (selectedareas.length === 0) selectedareas = areas;
    }

    //Get the worst-case options for single and multiple escape routes
    if (selectedareas && selectedareas.length) {
      esc1 = Math.min(...selectedareas.map(a => a.esc1));
      esc1h = Math.min(...selectedareas.map(a => a.esc1h));
      esc1m = Math.min(...selectedareas.map(a => a.esc1m));
      esc1l = Math.min(...selectedareas.map(a => a.esc1l));
      escm = Math.min(...selectedareas.map(a => a.escm));
      escmh = Math.min(...selectedareas.map(a => a.escmh));
      escmm = Math.min(...selectedareas.map(a => a.escmm));
      escml = Math.min(...selectedareas.map(a => a.escml));
    }

    if (this._mounted !== false) this.setState({ selectedareas, esc1, esc1h, esc1m, esc1l, escm, escmh, escmm, escml });
  }

  updateNewVals = (n, v) => {
    this.setState(st => ({ newvals: { ...st.newvals, [n]: v } }));
  }

  newValsOK = () => {
    if (!this.state.newvals) return false;
    if (
      this.state.newvals.locationname &&
      (
        (this.state.newvals.areatype === "OpenPlanArea" && this.state.newvals.escapepoints) ||
        (this.state.newvals.areatype !== "OpenPlanArea" && this.state.newvals.exitroutes)
      ) &&
      this.state.newvals.areatype
    ) return true;
  }

  createLocation = () => {
    //this.addChildAndFlag = (type,childid,values={},position, props={})

    if (this.state.newvals) {
      this.addChildAndFlag(
        this.state.newvals.areatype,
        undefined,
        { name: this.state.newvals.locationname },
        undefined,
        { routes: Number(this.state.newvals.exitroutes), points: Number(this.state.newvals.escapepoints), maxpeople: this.state.newvals.maxpeople }
      );

      this.setState({ newvals: { ...this.defaultNewVals } });

      this.showAddEscapeRoute(false);
    }
  }

  /**
   * Passed to routeHelp to get a function to show help
   */
  showRcHelp = id => f => {

    //this.setState({[`${id}_show`]: f});

    this.setState((prevState) => ({
      showrchelp: { ...prevState.showrchelp, [id]: f }
    }))
  }

  updateSectionOK = (globalsummary) => {
    const sectionok = (globalsummary || this.state?.values.globalsummary)?.routes?.length > 0;

    if (this.state.values.sectionok !== sectionok) this.setValue({ sectionok }, true);
  }

  componentDidMount() {
    super.componentDidMount();
    this.props.registerChangeWatcher(this.updateCategories, "_categories");
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.erexitem !== nextProps.exitem) {
      //console.log("componentDidUpdate: ", this.state, this.props.exitem) ;
      return ({ erexitem: nextProps.exitem, expanded: nextProps.exitem });
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.setState({ admin: false });
    }

  }

  /*componentDidUpdate() {
    if(this.state.erexitem!==this.props.exitem) {
      //console.log("componentDidUpdate: ", this.state, this.props.exitem) ;
      this.setState({erexitem: this.props.exitem, expanded: this.props.exitem});
    }
  }*/

  rupdate = (del = false) => {
    let locationsMissing = false;
    let locations = [];

    let allRoutesOK = true;
    let routecount = 0;

    (this.state.elements || []).every(el => {
      const b = (this.state.locations || {})[el];
      if (!b) {
        locationsMissing = true;
        return false;
      }
      else {
        locations.push({ ...b, id: el });
        if (b.routesOK === false) allRoutesOK = false;
        //routecount+=(Object.keys(b.routes || {}).length || 0);
        routecount += (b.routes || []).length;
      }

      return true;
    });

    //console.log("Locations: ", locations);

    const globalsummary = { allRoutesOK, routecount };

    //console.log("globalsummary: ", globalsummary);

    globalsummary.routes = locations.map(l => ({
      id: l.id,
      name: l.name,
      routesOK: l.routesOK,
      routecount: (l.routes || []).length,
      routes: (l.routes || []).map(r => ({ id: r.id, name: r.name, lengthok: r.lengthok, routeOK: r.routeOK, routesummary: r.routesummary }))
    }))

    this.setState({ globalsummary });

    //Wait for all Building data before running updates
    if ((locationsMissing || !locations.length) && !del) {
      return;
    }

    this.updateSectionOK(globalsummary);

    if (JSON.stringify(globalsummary) !== JSON.stringify(this.state.values.globalsummary)) {
      this.setValue({ globalsummary }, true);
    }

    //console.log("Global summary: ", globalsummary);

    ////! 
    this.props.updateChangeWatchers({ _id: "_escaperoutes", _escaperoutes: { locations, section: this.props.id, rootid: this.props.rootid, globalsummary } }, true);

    //console.log("_escaperoutes: ", locationsummary);
  }

  //shouldComponentUpdate(nextProps, nextState)

  updateExtData = bid => bvals => {

    this.setState(prevState => {
      if (bvals === 'delete') {
        if (!prevState.locations) return { locations: [] }
        const { [bid]: _, ...locations } = (prevState.locations || {});
        return { locations };
      }
      else return { locations: { ...prevState.locations, [bid]: bvals } }
    },
      () => this.rupdate(bvals === 'delete')
    )
  }

  stateUpdate = (newstate, oldstate) => {
    if ((newstate.elements || []).length !== (oldstate.elements || []).length) this.rupdate();
  }

  showAddEscapeRoute = show => {
    this.setState({
      AddEscapeRouteVisible: (show === true || show === false) ? show : !this.state.AddEscapeRouteVisible,
      newvals: { ...this.defaultNewVals }
    });
  }

  render() {

    //console.log("Subfields: ", this.state.values.subfields);

    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    //Pass expanded field & route summary data to appropriate child
    const childdata = Object.assign({},
      (this.state.values.locationsummary || []).reduce((result, item) => { result[item.id] = { routesummary: item.routesummary }; return result }, {}) || {},
      this.state.expanded ? { [this.state.expanded]: { expanded: true } } : {}
    );

    //console.log("ERS routes: ", this.state.globalsummary);

    const children = this.children({
      props: {
        expand: this.expand,
        //updateRoutes: this.updateRoutes, 
        escm: this.state.escm,
        escmh: this.state.escmh,
        escmm: this.state.escmm,
        escml: this.state.escml,
        esc1: this.state.esc1,
        esc1h: this.state.esc1h,
        esc1m: this.state.esc1m,
        esc1l: this.state.esc1l,
        showrchelp: this.state.showrchelp,
        subfields: this.state.values.subfields || {},
        yesToAll: this.state.values.yesToAll !== false,
        hideRiskLevel: this.state.values.hideRiskLevel,
        edit: (this.props.admin && this.state.admin)
      },
      propsId: childdata,
      propsIdFunc: { updateExtData: this.updateExtData }
    });

    /*const routesOK = this.state.values.globalsummary && this.state.values.routecount
      ?routecats.findIndex(r=>isArray(this.state.values.globalsummary[r.id]))===-1
      :null;
      */

    const routesOK = this.state?.values?.globalsummary?.allRoutesOK;
    const routecount = (this.state?.elements.length && this.state?.values?.globalsummary?.routecount) || '';

    return (
      <div className="SurveySection card card-edit" id={this.props.id} style={{ zIndex: this.state.popup ? 20 : '' }}>
        <div className="input-group card-header edit" data-toggle="buttons">
          <div className="input-group-prepend">
            {edit
              ? <Icondrop icon={this.state.values.icon || this.props.icon} name="icon" onClick={this.props.actions.setValue} />
              : <div className={"icon " + (this.state.values.icon || this.props.icon)} onClick={this.props.expand(this.props.id)} />
            }
          </div>

          {/*<div className={"ztitle form-control title"} onClick={this.props.expand(this.props.id)}>Escape Routes</div>*/}

          {edit ?
            <EditableA className="ztitle form-control input-group-text" name="name" onChange={this.props.actions.setValue} editFixedOn={true}>
              {this.state.values.name || "Escape Routes"}
            </EditableA>
            :
            <div className={"ztitle form-control title input-group-text"} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Escape Routes"}</div>
          }

          <div className="input-group-append">
            {edit && <SubFieldEditor name="subfields" value={this.state.values.subfields} rootfields={routecats} setValue={this.setValue} onShow={() => this.setState({ popup: true })} onHide={() => this.setState({ popup: false })} />}

            {edit && <Radioaddon id={this.props.id + "_yesToAll"} overlay={this.state.values.yesToAll !== false ? '' : 'X'} checked={this.state.values.yesToAll !== false} icon={"fa fa-check-square"} onClick={e => this.props.actions.setValue({ yesToAll: this.state.values.yesToAll !== true })}>Yes to all?</Radioaddon>}
            {edit && <Radioaddon id={this.props.id + "_riskLevel"} overlay={<span className={this.state.values.hideRiskLevel ? "fs-check" : "fs-times"} />} checked={this.state.values.hideRiskLevel} icon={"fs-exclamation-triangle"} onClick={e => this.setValue({ hideRiskLevel: this.state.values.hideRiskLevel !== true })}>Hide Risk Level?</Radioaddon>}

            {edit && <StButtons actions={this.props.actions} />}
            <EditDate editdate={this.state.values.sectionok && this.state.editdate} actions={this.props.actions} expanded={this.props.expanded} />
            {(this.props.expanded || edit) && <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} onShow={() => this.setState({ popup: true })} onHide={() => this.setState({ popup: false })} />}
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
        {/*<div className={"card-block"+(this.props.expanded || edit?"":" hide")}>*/}
        <ShowContainer className="card-block" show={(this.props.expanded || edit)} refGrandparent>

          <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />
          <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

          <FButton addButton className="stickyButton" onClick={this.showAddEscapeRoute}>
            Add Escape Route
          </FButton>

          {/*<ButtonModal title="Add Escape Route" buttonText="Add Escape Route" addButton buttonOK={this.createLocation} buttonOKDisabled={!this.newValsOK()}>*/}

          <Modal
            icon="fs-extinguisher"
            title="Add Escape Route"
            show={this.state.AddEscapeRouteVisible}
            buttonOK={this.createLocation}
            buttonOKDisabled={!this.newValsOK()}
            hideModal={this.showAddEscapeRoute}
          >

            <div className="addWizard">
              <ERField title="Location Ref/Name:">
                <FormText name="locationname" value={(this.state.newvals || {}).locationname || ""} update={this.updateNewVals} />
              </ERField>

              <ERField title="Area Type:"
                append={<HelpModal id={this.props.id + "_areahelp"} className="AddEscapeRoute-help" edit={edit} name="areahelp" onChange={this.props.actions.setValue} value={this.state.values.areahelp} small />}
              >
                {this.props.childTypes.map(childType =>
                  <Radioaddon key={childType.type} checked={(this.state.newvals || {}).areatype === childType.type} icon={childType.icon} onClick={e => { this.updateNewVals('areatype', childType.type) }}>
                    {childType.title}
                  </Radioaddon>
                )}
                <span className="form-control"> </span>
              </ERField>

              {(this.state.newvals || {}).areatype && ((this.state.newvals || {}).areatype === "OpenPlanArea" ?
                <ERField title="Number of Escape Points: ">
                  <FormNumber name="escapepoints" value={(this.state.newvals || {}).escapepoints} update={this.updateNewVals} min="0" max="20" />
                </ERField>
                :
                <ERField title="Number of exit routes: ">
                  <FormNumber name="exitroutes" value={(this.state.newvals || {}).exitroutes} update={this.updateNewVals} min="0" max="50" />
                </ERField>
              )}


              <ERField title="Maximum number of people:">
                <FormNumber name="maxpeople" value={(this.state.newvals || {}).maxpeople} update={this.updateNewVals} min="0" max="9999" />
              </ERField>
            </div>
          </Modal>

          <Namer className="sectionTitle" edit={edit} name="title" value={this.state.values.title} defaultName="Escape Routes" onChange={this.setValue} />

          <div className="RH">
            <RouteHelp showRcHelp={this.showRcHelp} name='rchelp' edit={edit} setValue={this.props.actions.setValue} values={this.state.values.rchelp || {}} />
          </div>

          {children}

          {routecount && <div className="summary Risk risk-highlight">
            <div className="card card-edit">
              <div className="card-header edit liverisk">
                <div className="input-group" data-toggle="buttons" onClick={this.expand("summary")}>
                  <span className="ztitle form-control input-group-text toplevel">Summary</span>
                  <div className="input-group-append">
                    {/*this.state.expanded!=="summary" && this.state.values.globalsummary &&
                    routecats.map(r=>
                      <FInfo title={r.title} className="fullsize" key={r.id}>
                        <span className={tickcross(this.state.values.globalsummary[r.id]===true)} />
                        {Array.isArray(this.state.values.globalsummary[r.id]) && this.state.values.globalsummary[r.id].length}
                      </FInfo>
                    )*/}

                    {this.state.expanded !== "summary" &&
                      <FInfo title={`Route${(routecount !== 1 ? "s" : " ")}`} className="fullsize">
                        {routecount}
                      </FInfo>
                    }

                    {this.state.expanded !== "summary" &&
                      routesOK !== null && <span className={`icon ${tickcross(routesOK)}`} />
                    }

                    {/*<FButton icon={this.state.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.expand("summary")} />*/}
                    <ExpandButton expanded={this.state.expanded === "summary"} onClick={this.expand("summary")} />
                  </div>
                </div>
              </div>
              {/*<div hidden={!(this.state.expanded === "summary" || edit)} className="card-block">*/}
              <ShowContainer className="card-block" show={this.state.expanded === "summary" || edit} headerId={this.props.id}>

                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Escape Routes / Escape Points: </span>
                  </div>
                  <span className="form-control">{routecount}</span>
                </div>
                <div className="shortfall">
                  <div className="title">Summary:</div>
                  <div className="shortfall-detail">
                    <ul className="shortfall-detail-buildings">
                      {((this.state.globalsummary || {}).routes || []).map(gs =>
                        <li key={gs.id}>
                          <span>{gs.name} <span className={gs.routesOK ? 'fs-check' : 'fs-exclamation-triangle'} /></span>
                          <ul>
                            {gs.routes.map(r =>
                              <li key={r.id}>
                                <span>{r.name} <span className={r.routeOK ? 'fs-check' : 'fs-exclamation-triangle'} /></span>
                                <ul>
                                  {routecats.map(rc => {

                                    if (rc.type === 'risklevel') return null;

                                    let ok;

                                    if (rc.type === 'int') ok = (typeof (r.routesummary || {})[rc.id] !== 'object');
                                    else if (rc.id === 'lengthok') ok = r.lengthok;
                                    else ok = (r.routesummary || {})[rc.id] === true;

                                    return ok ? null : (
                                      <li key={rc.id}>{rc.title} <span className={ok ? 'fs-check' : 'fs-exclamation-triangle'} /></li>
                                    )
                                  })}
                                </ul>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}

                      {/*this.state.values.globalsummary && routecats.map(r =>
                        this.state.values.globalsummary[r.id]===true
                        ?null
                        :<li key={r.id}>
                          {`${r.title}: ${r.problem}`}
                          <ul>
                            {this.state.values.globalsummary[r.id].map((v,i)=>
                              <li key={i}>{`${v.building}, ${v.location}`}</li>
                            )}
                          </ul>
                        </li>
                        )*/}
                    </ul>
                  </div>
                </div>
              </ShowContainer>
              {/*</div>*/}
            </div>
          </div>}
        </ShowContainer>
      </div>
    )
  }
}

//Object.entries(this.state.values.globalsummary).map(([key, value])

const tickcross = val => val ? "fs-check YesNoYes" : "fs-exclamation-triangle YesNoNo";

class EnclosedArea extends Container {

  /*stateUpdate = newstate => {
    //this.props.updateRoutes();
  }*/

  componentDidMount() {
    super.componentDidMount();

    if (this.props.newchild) {
      this.props.expand(this.props.id)();

      if (this.props.routes && !this.state.elements.length) {
        //console.log("adding children...", this.props.childTypes);
        Promise.all([...Array(this.props.routes)].map((l, i) => this.props.actions.addChild(this.props.childTypes[0].type, undefined, { name: `${this.props.childTypes[0].title} ${i + 1}`, maxpeople: this.props.maxpeople })));
        //! Add addChildren
      }
    }
  }

  rupdate = (del = false) => {
    let routesMissing = false;
    let routes = [];

    let routesOK = true;

    //!More efficient to compare length of this.state.elements and this.state.routes

    (this.state.elements || []).every(el => {
      const b = (this.state.routes || {})[el];
      if (!b) {
        routesMissing = true;
        return false;
      }
      else {
        routes.push(b);
      }

      return true;
    });

    if (routesMissing && !del) return;

    //if(Object.values(routes).findIndex(r=>r.routeOK===false)>-1) routesOK=false;
    if (routes.find(r => r.routeOK === false)) routesOK = false;


    //console.log("routesOK: ", ((this.state || {}).values || {}).name, routesOK, Object.values(routes) );

    //this.props.updateExtData({name: (this.state.values || {}).name || 'Building', levels: (this.state.elements || []).map(el=>(this.state.levels || {})[el] || null) });

    this.props.updateExtData({ name: (this.state.values || {}).name || 'Escape Location', type: this.props.type, routesOK, routes });

    this.setState({ routesOK });
  }

  updateExtData = lid => lvals => {

    this.setState(prevState => {
      if (lvals === 'delete') {

        if (!prevState.routes) return { routes: [] };
        const { [lid]: _, ...routes } = (prevState.routes || {});

        return { routes };
      }
      else return { routes: { ...prevState.routes, [lid]: lvals } }
    },
      () => { if (lvals !== 'delete') this.rupdate() }
    )
  }

  deleteArea = () => {
    this.props.updateExtData('delete');
    this.props.actions.deleteMe();
  }

  stateUpdate = (newstate, oldstate) => {
    if ((newstate.elements || []).length !== (oldstate.elements || []).length) this.rupdate();
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    const children = this.children({
      props: {
        //updateRoutes: this.props.updateRoutes, 
        esc1: (this.state.elements.length > 1 ? null : this.props.esc1),
        esc1h: (this.state.elements.length > 1 ? null : this.props.esc1h),
        esc1m: (this.state.elements.length > 1 ? null : this.props.esc1m),
        esc1l: (this.state.elements.length > 1 ? null : this.props.esc1l),
        escm: (this.state.elements.length > 1 ? this.props.escm : null),
        escmh: (this.state.elements.length > 1 ? this.props.escmh : null),
        escmm: (this.state.elements.length > 1 ? this.props.escmm : null),
        escml: (this.state.elements.length > 1 ? this.props.escml : null),
        showrchelp: this.props.showrchelp,
        yesToAll: this.props.yesToAll,
        hideRiskLevel: this.props.hideRiskLevel,
        subfields: this.props.subfields
      },
      propsIdFunc: { updateExtData: this.updateExtData }
    });

    /*const routesOK = (this.props.routesummary && children.length)
    ?routecats.map(r=>this.props.routesummary[r.id]).findIndex(r=>r===false)===-1
    :null;*/

    const routesOK = this.state.routesOK;

    return (
      <div className="EnclosedArea Risk">
        <div className="card card-edit">
          <div className="card-header edit liverisk">
            <div className="input-group" data-toggle="buttons">
              {this.props.expanded ?
                <EditableA className="ztitle form-control input-group-text toplevel" name="name" onChange={this.props.actions.setValue} onClick={this.props.expand(this.props.id)}>
                  {this.state.values.name || "Enclosed Area"}
                </EditableA>
                :
                <span className="ztitle form-control input-group-text toplevel" onClick={this.props.expand(this.props.id)}>
                  {this.state.values.name || "Enclosed Area"}
                </span>
              }
              <div className="input-group-append">

                {!this.props.expanded &&
                  <FInfo title={`Route${(children && children.length && children.length === 1 ? " " : "s")}`} className="fullsize">
                    {children && children.length}
                  </FInfo>
                }

                {!this.props.expanded &&
                  <span className={`icon ${tickcross(routesOK)}`} />
                }

                {(this.props.expanded || edit) && <BSDrop icon='fs-bars' right point={false}>
                  {/*extTech.map(t => 
                    <BSDropItem onClick={e=>this.setExt({exttype: t.type})} key={t.type}>
                      {t.type}
                    </BSDropItem>
                  )*/}
                  {/*<div className="BSDropItem dropdown-item">
                    <AddChildButtons childTypes={this.props.childTypes} addChild={this.addChildAndFlag} />
                  </div>
                  <div className="BSDropItem dropdown-item">
                    <SureButton icon="fs-trash" onClick={this.deleteArea} buttonText="Delete">Delete Area?</SureButton>
                </div>*/}
                  {(this.props.childTypes || []).map(childType =>
                    <BSDropItem icon="fs-logout" onClick={() => this.addChildAndFlag(childType.type)} key={childType.type}>
                      + Add {childType.title}
                    </BSDropItem>
                  )}
                  <BSDropItem icon="fs-trash" onClick={this.deleteArea}>
                    Delete Area
                  </BSDropItem>

                </BSDrop>}

                {/*(this.props.expanded || edit) && <AddChildButtons childTypes={this.props.childTypes} addChild={this.addChildAndFlag} />*/}
                {/*(this.props.expanded || edit) && <AttachDocModal id={this.props.id+'_attachDoc'} title={`${this.state.values.name} Attachments`} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />*/}
                {/*(this.props.expanded || edit) && <SureButton icon="fs-trash" onClick={this.deleteArea} buttonText="Delete">Delete Area?</SureButton>*/}


                {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
                <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
              </div>
            </div>
          </div>
          {/*<div hidden={!(this.props.expanded || edit)} className="card-block">*/}
          <ShowContainer className="card-block" show={(this.props.expanded || edit)} headerId={this.props.parentid}>
            {children}
          </ShowContainer>
          {/*</div>*/}
        </div>
      </div>
    )
  }
}

const FormYesNo = ({ name, value, update, invert } = {}) => (
  <span className="FormYesNo btn-group">
    {value === true || value === false
      ? <button type="button" className="btn FButton fullsize" onClick={e => update(name, null)}><span className={value ? "fs-check YesNoYes" : "fs-exclamation-triangle YesNoNo"} /></button>
      : <Fragment><button type="button" className="btn FButton" onClick={e => update(name, invert ? false : true)}><span>Yes</span></button><button type="button" className="btn FButton" onClick={e => update(name, invert ? true : false)}><span>No</span></button></Fragment>
    }
  </span>
)

const MButton = ({ name, value, values = [''], update } = {}) => {

  const nvi = values.findIndex(v => v.id === value) + 1;
  const nextval = values[nvi > (values.length - 1) ? 0 : nvi || 0].id;


  return (
    <button type="button" className="btn FButton fullsize" onClick={e => update(name, nextval)}>{(values.find(v => v.id === value) || {}).title}</button>
  )
}

const routeRisk = [
  { id: 'l', title: 'Low' },
  { id: 'm', title: 'Medium' },
  { id: 'h', title: 'High' },
];

//!Multi-option toggle button

class EscapeRoute extends Container {

  updateLength = (n, v, rl) => {

    //! Needs to update on route risk level update

    const risklevel = rl || this.state.values.risklevel || 'm';

    const lengthok = (v || {}).value ? (this.props[`escm${risklevel}`] || this.props[`esc1${risklevel}`] || 0) >= ((v || {}).value || 99999) : null;

    const val = { [n]: v, lengthok };

    this.setValue(val);

    this.updateMaster(val);
  }

  updateMaster = (vals = {}) => {

    const v = { ...(this.state || {}).values || {}, ...vals };
    const routesummary = {};

    let routeOK = true;

    routecats.forEach((rc, i) => {
      if (i) {
        if (v[rc.id] === true) routesummary[rc.id] = true;
        else if ((v[rc.id] === null || typeof v[rc.id] === 'undefined') && rc.type === 'bool') {
          routesummary[rc.id] = null;
          routeOK = false;
        }
        else {
          let actions = false;

          Object.entries((v.subfields || {})[rc.id] || {}).forEach(([key, value]) => {
            if (value) {
              routesummary[rc.id] = { ...(routesummary[rc.id] || {}), [key]: (((this.props.subfields || {})[rc.id] || []).find(sf => sf.id === key) || {}).action };
              actions = true;
              routeOK = false;
            }
          })

          if (!actions && rc.type === 'bool') {
            routesummary[rc.id] = false;
            routeOK = false;
          }

        }
      }
    });

    if (!this.state.values.lengthok) routeOK = false;

    //console.log("ER: ", (this.state.values || {}).name, v, routeOK)

    //console.log("routesummary: ", routesummary);

    this.props.updateExtData({
      id: this.props.id,
      name: this.state.values.name || 'Escape Route',
      length: ((this.state.values || {}).length || {}).value,
      lengthok: this.state.values.lengthok,
      routeOK,
      routesummary,
      measure: {
        _attachments: this.state._attachments
      }
    });
  }

  stateUpdate = newstate => {
    //this.props.updateRoutes();

    this.updateMaster(newstate.values);
  }

  rchelp = id => () => {
    if (this.props.showrchelp && this.props.showrchelp[id]) this.props.showrchelp[id](true);
  }

  setSubFieldValue = (id, bool) => v => {

    /* new subfield list for this id */
    //const newf = { ...(((this.state.values || {}).subfields || {})[id] || {}), [n]:v };
    const newf = v;
    const fieldok = !((this.props.subfields || {})[id] || []).find(sf => newf[sf.id]);

    //console.log("setSubFieldValue: ", id, n, v, fieldok);

    const val = {
      ...(bool && !fieldok ? { [id]: false } : {}),
      subfields: {
        ...((this.state.values || {}).subfields || {}),
        [id]: newf
      }
    };

    this.setValue(val);
    this.updateMaster(val);
  }

  /* Sets Yes/No main value and resets subfields if True */
  setMainValue = (n, v) => {
    const val = {
      ...(v
        ? { subfields: { ...((this.state.values || {}).subfields || {}), [n]: {} } }
        : {}
      ),
      [n]: v
    };

    this.setValue(val);
    this.updateMaster(val);
  }

  /* Shortcut to set all to yes */
  setAllYes = () => {

    const val =
      Object.assign({},
        ...routecats.map((rc) => (rc.type === 'bool' ? { [rc.id]: true } : {})),
        {
          subfields: Object.assign({},
            ...routecats.map((rc, i) => ({ [rc.id]: {} }))
          )
        }
      )

    this.setValue(val);
    this.updateMaster(val);

  }

  componentDidMount() {
    super.componentDidMount();

    this.updateMaster();
  }

  deleteRoute = () => {
    this.props.updateExtData('delete');
    this.props.actions.deleteMe();
  }

  riskLevelUpdate = (n, v) => {
    this.setValue({ [n]: v });
    this.updateLength('length', this.state.values.length, v);
  }

  render() {

    //console.log("ER state: ", this.state);

    const routecatslocal = this.props.hideRiskLevel ? routecats.filter(rc => rc.id !== 'risklevel') : routecats;

    return (
      <div className="EscapeRoute" id={this.props.id}>
        <div className="ER-heading input-group">
          <div className="input-group-prepend">
          </div>

          <FInfo title="Reference" className="form-control" titleLeft>
            <EditableA className="ztitle form-control" name="name" onChange={this.props.actions.setValue} editFixedOn editOnRender={this.props.newchild}>
              {this.state.values.name || "Escape Route"}
            </EditableA>
          </FInfo>

          <FInfo title="Route Length (m)" titleRight>
            <CalcField className="form-control" unit={<span>m <span className={`${this.state.values.lengthok ? "fs-check YesNoYes" : "fs-exclamation-triangle YesNoNo"}`} /> <FButton icon="fs-help" small onClick={this.rchelp('length')}></FButton></span>} placeholder="Length?" name="length" update={this.updateLength} value={this.state.values.length || ""} />
          </FInfo>

          <div className="input-group-append">
            <AttachDocModal id={`${this.props.id}_attachDoc`} title={`Attachments for: ${this.state.values.name}`} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />
            <SureButton icon="fs-trash" onClick={this.deleteRoute} buttonText="Delete">Delete Route?</SureButton>
          </div>
        </div>

        {this.props.yesToAll && <div style={{ textAlign: 'right' }}><button className="abutton" onClick={this.setAllYes}>Yes to all</button></div>}

        <div className="ER-body">
          {routecatslocal.map((rc, i) => {

            return i
              ? <GreyBox subtitle={rc.title} medium notButton key={rc.id}
                popout={
                  <PopoutModal
                    title={`${this.state.values.name || 'Escape Route'} | ${rc.title}`}
                    fields={(this.props.subfields || {})[rc.id] || []}
                    values={(((this.state.values || {}).subfields || {})[rc.id] || {})}
                    setValue={this.setSubFieldValue(rc.id, rc.type === 'bool')}
                    help={(this.props.subfields || {})[`${rc.id}_help`]}
                    actions={Object.values((this.state.values.subfields || {})[rc.id] || {}).find(v => v)}
                    linkTitle={rc.type === 'risklevel' ? 'Help' : 'Actions'}
                  />
                }
              >
                {rc.type === 'bool' && <FormYesNo name={rc.id} value={this.state.values[rc.id]} update={this.setMainValue} invert={rc.invert} />}
                {rc.type === 'risklevel' && <MButton name={rc.id} value={this.state.values[rc.id] || 'm'} values={routeRisk} update={this.riskLevelUpdate} />}
                {rc.type === 'int' && <FormText type="number" min="0" max="9999" name={rc.id} className={rc.alertLevel > 0 && (this.state.values[rc.id] >= rc.alertLevel) ? 'alertLevel' : ''} value={Number(this.state.values[rc.id])} update={this.setValue} />}

              </GreyBox>
              : null
          })}
          {/*<GreyBox subtitle={"Route Risk Level"} medium notButton>
          
            </GreyBox>*/}
        </div>
      </div>
    )
  }
}

const PopoutModal = ({ title, linkTitle = "Actions", fields, values, help, setValue, actions = false }) => {

  const [newValues, setNewValues] = useState({});

  useEffect(() => {
    setNewValues(values || {});
  }, [values])

  const updateValues = () => setValue(newValues);

  const setSubValue = (n, v) => setNewValues({ ...newValues, [n]: v });

  return (
    <ButtonModal scroll hideIcon
      modalClassName="MDModal" title={title} onHide={updateValues}
      buttonText={<CircleCheck icon={actions ? 'fs-exclamation-triangle' : 'fs-question'} classNameActive={actions ? 'alertText' : ''} title={linkTitle} checked={true} />}
    >
      <FSMarkdown markup={help} />
      {fields.map(sf =>
        <SubField key={sf.id} field={sf} setValue={setSubValue} action={newValues[sf.id]} />
      )}
    </ButtonModal>
  )
}

class OpenPlanArea extends Container {

  /*stateUpdate = newstate => {
    //this.props.updateRoutes();
  }*/

  componentDidMount() {
    super.componentDidMount();

    //If this component has just been added as a child
    if (this.props.newchild) {
      this.props.expand(this.props.id)();

      if (this.props.points && !this.state.elements.length) {
        //console.log("adding children...", this.props.childTypes);
        //! Modify addChild to add multiple children?
        Promise.all([...Array(this.props.points)].map((l, i) => this.props.actions.addChild(this.props.childTypes[0].type, undefined, { name: `${this.props.childTypes[0].title} ${String.fromCharCode(65 + i)}`, maxpeople: this.props.maxpeople })));
      }
    }
  }

  rupdate = (del = false) => {
    let routesMissing = false;
    let routes = [];

    let routesOK = true;

    //!More efficient to compare length of this.state.elements and this.state.routes

    (this.state.elements || []).every(el => {
      const b = (this.state.routes || {})[el];
      if (!b) {
        routesMissing = true;
        return false;
      }
      else {
        routes.push(b);
      }

      return true;
    });


    if (routesMissing && !del) return;

    if (routes.find(r => r.routeOK === false)) routesOK = false;

    this.props.updateExtData({ name: (this.state.values || {}).name || 'Open Area Location', type: this.props.type, routesOK, routes });

    this.setState({ routesOK });
  }

  updateExtData = lid => lvals => {

    this.setState(prevState => {
      if (lvals === 'delete') {
        const { [lid]: _, ...routes } = prevState.routes;
        return { routes };
      }
      else return { routes: { ...prevState.routes, [lid]: lvals } }
    },
      () => { if (lvals !== 'delete') this.rupdate() }
    )
  }

  deleteArea = () => {
    this.props.updateExtData('delete');
    this.props.actions.deleteMe();
  }

  stateUpdate = (newstate, oldstate) => {
    if ((newstate.elements || []).length !== (oldstate.elements || []).length) this.rupdate();
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    const children = this.children({
      props: {
        //updateRoutes: this.props.updateRoutes, 
        escm: this.props.escm,
        escmh: this.props.escmh,
        escmm: this.props.escmm,
        escml: this.props.escml,
        showrchelp: this.props.showrchelp,
        yesToAll: this.props.yesToAll,
        hideRiskLevel: this.props.hideRiskLevel,
        subfields: this.props.subfields
      },
      propsIdFunc: { updateExtData: this.updateExtData }
    });

    /*const routesOK = (this.props.routesummary && children.length)
    ?routecats.map(r=>this.props.routesummary[r.id]).findIndex(r=>r===false)===-1
    :null;*/

    const routesOK = this.state.routesOK;

    return (
      <div className="OpenPlanArea Risk">

        <div className="card card-edit">
          <div className="card-header edit liverisk">
            <div className="input-group" data-toggle="buttons">
              {this.props.expanded ?
                <EditableA className="ztitle form-control input-group-text toplevel" name="name" onChange={this.props.actions.setValue} onClick={this.props.expand(this.props.id)}>
                  {this.state.values.name || "Enclosed Area"}
                </EditableA>
                :
                <span className="ztitle form-control input-group-text toplevel" onClick={this.props.expand(this.props.id)}>
                  {this.state.values.name || "Enclosed Area"}
                </span>
              }
              <div className="input-group-append">
                {!this.props.expanded &&
                  <FInfo title={`Route${(children && children.length && children.length === 1 ? " " : "s")}`} className="fullsize">
                    {children && children.length}
                  </FInfo>
                }

                {!this.props.expanded &&
                  <span className={`icon ${tickcross(routesOK)}`} />
                }

                {(this.props.expanded || edit) && <BSDrop icon='fs-bars' right point={false}>
                  {(this.props.childTypes || []).map(childType =>
                    <BSDropItem icon="fs-logout" onClick={() => this.addChildAndFlag(childType.type)} key={childType.type}>
                      + Add {childType.title}
                    </BSDropItem>
                  )}
                  <BSDropItem icon="fs-trash" onClick={this.deleteArea}>
                    Delete Area
                  </BSDropItem>
                </BSDrop>}

                {/*(this.props.expanded || edit) && <AddChildButtons childTypes={this.props.childTypes} addChild={this.addChildAndFlag} />*/}
                {/*(this.props.expanded || edit) && <AttachDocModal id={this.props.id+'_attachDoc'} title={`${this.state.values.name} Attachments`} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />*/}
                {/*(this.props.expanded || edit) && <SureButton icon="fs-trash" onClick={this.deleteArea} buttonText="Delete">Delete Area?</SureButton>*/}

                {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
                <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
              </div>
            </div>
          </div>
          {/*<div hidden={!(this.props.expanded || edit)} className="card-block">*/}
          <ShowContainer className="card-block" show={(this.props.expanded || edit)} headerId={this.props.parentid}>
            {children}
          </ShowContainer>
          {/*</div>*/}
        </div>
      </div>
    )
  }
}

class EscapePoint extends Container {

  updateLength = (n, v, rl) => {

    const risklevel = rl || this.state.values.risklevel || 'm';


    const lengthok = (this.state.values[n === "length1" ? "length2" : "length1"] || {}).value ? (this.props[`escm${risklevel}`] || 0) >= ((v || {}).value || 9999) && ((this.props[`escm${risklevel}`] || 0) >= ((this.state.values[n === "length1" ? "length2" : "length1"] || {}).value || 9999)) : null;
    const val = { [n]: v, lengthok };

    this.setValue(val);

    this.updateMaster(val);
  }

  updateMaster = (vals = {}) => {

    const v = { ...(this.state || {}).values || {}, ...vals };
    const routesummary = {};

    let routeOK = true;

    routecats.forEach((rc, i) => {
      if (i) {
        if (v[rc.id] === true) routesummary[rc.id] = true;
        else if ((v[rc.id] === null || typeof v[rc.id] === 'undefined') && rc.type === 'bool') {
          routesummary[rc.id] = null;
          routeOK = false;
        }
        else {
          let actions = false;

          Object.entries((v.subfields || {})[rc.id] || {}).forEach(([key, value]) => {
            if (value) {
              routesummary[rc.id] = { ...(routesummary[rc.id] || {}), [key]: (((this.props.subfields || {})[rc.id] || []).find(sf => sf.id === key) || {}).action };
              actions = true;
              routeOK = false;
            }
          })

          if (!actions && rc.type === 'bool') {
            routesummary[rc.id] = false;
            routeOK = false;
          }

        }
      }
    });

    if (!this.state.values.lengthok) routeOK = false;

    this.props.updateExtData({
      id: this.props.id,
      name: this.state.values.name || 'Escape Route',
      length1: ((this.state.values || {}).length1 || {}).value,
      length2: ((this.state.values || {}).length2 || {}).value,
      lengthok: this.state.values.lengthok,
      routeOK,
      routesummary,
      measure: {
        _attachments: this.state._attachments
      }
    });
  }

  stateUpdate = newstate => {
    //this.props.updateRoutes();

    this.updateMaster(newstate.values);
  }

  rchelp = id => () => {
    if (this.props.showrchelp && this.props.showrchelp[id]) this.props.showrchelp[id](true);
  }

  setSubFieldValue = (id, bool) => v => {

    /* new subfield list for this id */
    //const newf = { ...(((this.state.values || {}).subfields || {})[id] || {}), [n]:v };
    const newf = v;
    const fieldok = !((this.props.subfields || {})[id] || []).find(sf => newf[sf.id]);

    //console.log("setSubFieldValue: ", id, n, v, fieldok);

    const val = {
      ...(bool && !fieldok ? { [id]: false } : {}),
      subfields: {
        ...((this.state.values || {}).subfields || {}),
        [id]: newf
      }
    };

    this.setValue(val);
    this.updateMaster(val);
  }

  /* Sets Yes/No main value and resets subfields if True */
  setMainValue = (n, v) => {
    const val = {
      ...(v
        ? { subfields: { ...((this.state.values || {}).subfields || {}), [n]: {} } }
        : {}
      ),
      [n]: v
    };

    this.setValue(val);
    this.updateMaster(val);
  }

  /* Shortcut to set all to yes */
  setAllYes = () => {

    const val =
      Object.assign({},
        ...routecats.map((rc) => (rc.type === 'bool' ? { [rc.id]: true } : {})),
        {
          subfields: Object.assign({},
            ...routecats.map((rc, i) => ({ [rc.id]: {} }))
          )
        }
      )

    this.setValue(val);
    this.updateMaster(val);

  }

  componentDidMount() {
    super.componentDidMount();

    this.updateMaster();
  }

  deleteRoute = () => {
    this.props.updateExtData('delete');
    this.props.actions.deleteMe();
  }

  riskLevelUpdate = (n, v) => {
    this.setValue({ [n]: v });
    this.updateLength('length1', this.state.values.length1, v);
  }

  render() {

    const routecatslocal = this.props.hideRiskLevel ? routecats.filter(rc => rc.id !== 'risklevel') : routecats;

    return (
      <div className="EscapePoint" id={this.props.id}>
        <div className="EP-heading input-group">
          <FInfo title="Reference" className="form-control" titleLeft>
            <EditableA className="ztitle form-control" name="name" onChange={this.props.actions.setValue} editFixedOn editOnRender={this.props.newchild}>
              {this.state.values.name || "Escape Point Ref"}
            </EditableA>
          </FInfo>

          <FInfo title="Length 1 (m)">
            <CalcField className="form-control" unit={<span>m <FButton icon="fs-help" small onClick={this.rchelp('length')} /></span>} placeholder="Length?" name="length1" update={this.updateLength} value={this.state.values.length1 || ""} />
          </FInfo>

          <FInfo title="Length 2 (m)">
            <CalcField className="form-control" unit={<span>m <span className={`${this.state.values.lengthok ? "fs-check YesNoYes" : "fs-exclamation-triangle YesNoNo"}`} /> <FButton icon="fs-help" small onClick={this.rchelp('length')} /></span>} placeholder="Length?" name="length2" update={this.updateLength} value={this.state.values.length2 || ""} />
          </FInfo>

          <div className="input-group-append btn-group-toggle">
            <AttachDocModal id={`${this.props.id}_attachDoc`} title={`Attachments for: ${this.state.values.name}`} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />
            <SureButton icon="fs-trash" onClick={this.deleteRoute} buttonText="Delete">Delete Point?</SureButton>
          </div>
        </div>

        {this.props.yesToAll && <div style={{ textAlign: 'right' }}><button className="abutton" onClick={this.setAllYes}>Yes to all</button></div>}

        <div className="EP-body">
          {routecatslocal.map((rc, i) =>
            i
              ? <GreyBox subtitle={rc.title} medium notButton key={rc.id}
                popout={
                  <PopoutModal
                    title={`${this.state.values.name || 'Escape Route'} | ${rc.title}`}
                    fields={(this.props.subfields || {})[rc.id] || []}
                    values={(((this.state.values || {}).subfields || {})[rc.id] || {})}
                    setValue={this.setSubFieldValue(rc.id, rc.type === 'bool')}
                    help={(this.props.subfields || {})[`${rc.id}_help`]}
                    actions={Object.values((this.state.values.subfields || {})[rc.id] || {}).find(v => v)}
                    linkTitle={rc.type === 'risklevel' ? 'Help' : 'Actions'}
                  />
                }>

                {rc.type === 'bool' && <FormYesNo name={rc.id} value={this.state.values[rc.id]} update={this.setMainValue} invert={rc.invert} />}
                {rc.type === 'risklevel' && <MButton name={rc.id} value={this.state.values[rc.id] || 'm'} values={routeRisk} update={this.riskLevelUpdate} />}
                {rc.type === 'int' && <FormText type="number" min="0" max="9999" className={rc.alertLevel > 0 && (this.state.values[rc.id] >= rc.alertLevel) ? 'alertLevel' : ''} name={rc.id} value={Number(this.state.values[rc.id])} update={this.setValue} />}

              </GreyBox>
              : null
          )}
        </div>

      </div>

    )
  }
}

/* 
* A styled Radio button
*/
const Radioaddon = props => (
  <FButton id={props.id} icon={props.icon} className={"radio-addon " + (props.checked ? "active " : "") + (props.className || "")} onClick={props.onClick} onMouseDown={props.onMouseDown} overlay={props.overlay}>
    {props.children}
  </FButton>
)

/**
 * Circular clickable button with an icon that appears when checked==true
 * @param {*} props 
 */
const CircleCheck = props => (
  <div className={"CircleCheck" + (props.inline ? " inline" : "") + (props.disabled ? " disabled" : "")}>
    <label>
      {!props.noCheck && <input type="checkbox" disabled={props.disabled} checked={props.checked || false} onChange={e => props.onChange && props.onChange(props.name)} />}
      <span className={`cc-icon ${(props.classNameActive || "")} ${props.noCheck ? 'noCheck' : ''}`}><span className={props.icon} /></span>
    </label>
    <span className={`cc-title ${props.titleClassName}`}>{props.title}</span>
  </div>
)

/*const CircleIcon = ({ inline, disabled, classNameActive, icon, title }) => (
  <div className={"CircleIcon"+(inline?" inline":"")+(disabled?" disabled":"")}>
    <div>
      <span className={"cc-icon "+(classNameActive || "")}><span className={icon} /></span>
    </div>
    <span className="cc-title">{title}</span>
  </div>
)*/

/**
 * Popup for editing Text for different states of a Risk
 */
class RiskTextModal extends Component {
  constructor(props) {
    super(props);

    this.id = this.props.id || cuid();
  }

  render() {

    if (!this.props.edit) return null;

    return (
      <ButtonModal scroll modalClassName="MDModal" title="Risk Text" buttonText="Edit">
        <h5>App Text</h5>
        <div className="form-group">
          <label htmlFor={this.id + '_name'}>Title (App)</label>
          <FormText id={this.id + '_name'} name="name" className="notes" value={this.props.name} update={this.props.onChange} />
        </div>
        <h5>Report Text</h5>
        <div className="form-group">
          <label htmlFor={this.id + '_questiontext'}>Title (Report)</label>
          <FormText id={this.id + '_questiontext'} name="questiontext" className="notes" value={this.props.questiontext} update={this.props.onChange} />
        </div>
        <div className="form-group">
          <label htmlFor={this.id + '_yestext'}>'Yes' Text</label>
          <FormText id={this.id + '_yestext'} name="yestext" className="notes" value={this.props.yestext} update={this.props.onChange} />
        </div>
        <div className="form-group">
          <label htmlFor={this.id + '_notext'}>'No' Text</label>
          <FormText id={this.id + '_notext'} name="notext" className="notes" value={this.props.notext} update={this.props.onChange} />
        </div>
      </ButtonModal>
    )
  }
}

//Rescrolls position on previous item concertina close
/*const scrollfix = (expanded, prevexpanded, topPos, headerRef) => {
  //Fix concertina scroll up

  //if(headerRef?.current?.id==='Risk-ckgj5zq4z002wk2zu0k176spb') console.log("sf: ", expanded, prevexpanded, topPos, headerRef);

  if (expanded && expanded !== prevexpanded && (topPos || topPos === 0)) {

    try {

      //Get position of parent of parent
      const headerEl = headerRef.current.parentElement.parentElement.querySelector('.card-header');
      var eltop = headerRef.current.getBoundingClientRect().top;

      //Check not scrolled above level of header
      if (headerEl) {
        const headerBottom = headerEl.getBoundingClientRect().bottom;

        if (eltop < headerBottom) window.scrollBy(0, eltop - headerBottom);
      }

      const footerpos = document.getElementById('footerbar').getBoundingClientRect().top;

      if (eltop > (footerpos - 100)) window.scrollBy(0, 100);

    } catch (err) {
      console.log("scrollfix error: ", err);
    }

  }
}
*/

const OpenCaret = ({ open }) => <span style={{ marginLeft: '0.75em' }} className={`fa fa-caret-${open ? 'up' : 'down'}`} />

const risklevels = [
  { id: 0, text: 'Normal', display: ' ' },
  { id: 1, text: 'High', display: '!' }
]

/* How large an area is endangered by a Risk (Hazard) */
const riskscope = [
  { id: 0, text: 'Room', display: 'R' },
  { id: 1, text: 'Level', display: 'L' },
  { id: 2, text: 'Building', display: 'B' },
  { id: 3, text: 'Site', display: 'S' }
]

/**
 * A Risk (now call "Hazard") is anything that can be identified as posing a danger. Measures should be used to manage the risk.
 */
class Risk extends Container {
  constructor(props) {
    super(props);

    this.state["expanded"] = this.props.expandid || false;
    this.state["exitem"] = false;

    this.state["attachmodalshow"] = false;

    this.radio = [
      { id: '2', icon: "fs-check", title: "Yes" },
      { id: '1', icon: "fs-times", title: "No" }
    ];

    //this.headerRef = React.createRef();
  }

  //Runs when _categories updates
  updateCategories = (newstate = {}) => {
    //console.log("updateCategories: ", this.props.id);

    const cathide = (!categoryshow(newstate._categories, this.state.values.categories, this.props.id)); //True is risk should be hidden due to category selection

    //if(this.props.id==='Risk-ckk59e6yf01hh3160etwdtd4a') console.log("check uC", cathide);
    this.props.check(cathide ? 3 : this.state.values.risk); //Update checked check for RiskSurveySection

    //if(this.props.id==='Risk-ckk59e6yf01hh3160etwdtd4a') console.log("Risk : ", cathide, newstate._categories);

    this.runStateUpdate({ ...newstate, cathide });
  }

  //Update values.cathide when global _categories ior local values.categories change
  catUpdate = (_categories = { _categories: this.state._categories }) => {
    const cathide = categoryHider(_categories, this.state.values.categories, this.state.values.cathide, this.setValue, this.updateState, this.props.id);

    this.props.check(cathide ? 3 : this.state.values.risk); //Update checked check for RiskSurveySection
  }

  componentDidMount() {
    super.componentDidMount();
    //this.addWatcher("_categories");
    //this.props.registerChangeWatcher(this.updateCategories, "_categories");
    this.props.registerChangeWatcher(this.catUpdate, "_categories");

    //if(this.props.id==='Risk-ckk59e6yf01hh3160etwdtd4a') console.log("check cDM");
    //this.props.check(this.state.cathide?3:this.state.values.risk);
  }

  riskClick = r => {
    const risk = this.state.values.risk === r ? '0' : r;

    //this.props.actions.setValue({'risk': newr});
    this.setValue({ risk });

    if (Number(risk) === 2) {
      this.expand(true);
    }

    //id, force=false, exitem, itemid
  }

  expand = force => {
    //this.topPos = this.props.expanded?false:this.headerRef.current.offsetTop;
    //this.topPos = this.props.expanded?this.headerRef.current.getBoundingClientRect().top:false;

    this.props.expand(this.props.id, force === true)();
  }

  componentDidUpdate(prevProps, prevState) {
    //If risk level has changed, update check from parent
    if (prevState.values.risk !== this.state.values.risk) {

      //??const cathide = this.state.cathide || (!categoryshow(this.state._categories, this.state.values.categories, this.props.id));


      //if(this.props.id==='Risk-ckk59e6yf01hh3160etwdtd4a') console.log('Risk cDU: ', prevProps, prevState, this.state, cathide);

      //if(this.props.id==='Risk-ckk59e6yf01hh3160etwdtd4a') console.log("check cDU", cathide);

      //this.props.check(cathide?3:this.state.values.risk);
      this.props.check(this.state.values.cathide ? 3 : this.state.values.risk);
    }

    //Fix concertina scroll up
    //scrollfix(Number(this.state.values.risk)===2 && this.props.expanded, Number(prevState.values.risk)===2 && prevProps.expanded, this.headerRef?.current?.getBoundingClientRect().top, this.headerRef);

    if (this.state.values.categories && (!prevState.values.categories || !arraysEqual(this.state.values.categories, prevState.values.categories))) this.catUpdate();
  }

  /*stateUpdate = (newstate, oldstate) => {
    //If risk level has changed, update check from parent
    console.log("Risk stateUpdate", newstate, oldstate);
    if(oldstate.risk!==newstate.risk) {
      this.props.check(this.state.cathide?3:newstate.risk);
    }
  }*/

  render() {
    const edit = (this.state.edit || this.props.edit);

    //const categories = [{id: -1, name: "Any"}].concat((this.state._categories && this.state._categories.children) || []);

    //if(!categoryshow(this.state._categories, this.state.values.categories) && !edit) return null;
    //if(this.state.cathide && !edit) return null; //Hide due to category selection
    if (this.state.values.cathide && !(this.props.admin || edit)) return null;

    //! Add ordering of children function

    //Which text to display/edit in Risk title (dependant upon Risk selected)
    //const nameedit=['name','notext','yestext'][this.props.values.risk || 0];

    const rlbuttontext =
      this.state.values.risklevel > 0 ? risklevels[this.state.values.risklevel || 0].display + riskscope[this.state.values.riskscope !== undefined ? this.state.values.riskscope : 1].display
        : null;

    const cl = "ztitle form-control input-group-text" + (this.state.values.risk === '1' ? " crossed" : (this.state.values.risk === '2' ? " bold" : ""));

    const risk = Number(this.state.values.risk);

    const open = (risk === 2 && this.props.expanded) || edit;

    //const children=this.children({props: {risk: this.state.values.risk}, orderChildrenByType: true});

    //const items=children.find(c=>c.props.type==="Item")?true:false;

    return (
      <div className="Risk" id={this.props.id} ref={this.headerRef}>
        <div className="card">
          <div className={"card-header " + (risk === 2 ? "liverisk" : "")}>

            <div className={`input-group ${open ? 'open' : ''}`} data-toggle="buttons">

              {edit ?
                <EditableA onChange={this.props.actions.renameMe} editFixedOn className={cl}>
                  {this.state.values.name || "Risk"}
                </EditableA>
                :
                <span className={cl} onClick={this.expand}>{this.state.values.name || "Risk"} {risk === 2 && <OpenCaret open={open} />}</span>
              }

              <div className="input-group-append">

                {open && <HelpModal id={this.props.id + "_help"} edit={edit} onChange={v => this.props.actions.setValue('help', v)} value={this.state.values.help} />}

                {edit ?
                  <Fragment>
                    <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />

                    {/*edit && <ButtonModal className="SurveySectionModal" modalClassName="AuditModal" icon="fs-industry" overlay={this.state.values.categories && this.state.values.categories.length?this.state.values.categories.length:"*"} buttonText={"Categories"} title="Categories">
                    {categories.map(op => {
                      const ch=(this.state.values.categories && this.state.values.categories.indexOf(op.id)>-1) || (op.id===-1 && (!this.state.values.categories || !this.state.values.categories.length));
    
                      return (
                        <div className="input-group btn-group-toggle" data-toggle="buttons" key={this.props.id+"_"+op.id}>
                          <Radioaddon id={this.props.id+"_"+op.id+"_ch"} checked={ch} icon={ch?"fs-check-square-o":"fs-square-o"} onClick={e=>this.categoryClick(op.id)}> {op.name}</Radioaddon>
                        </div>
                      )
                    })}
                    </ButtonModal>*/}

                    {edit && <CategorySelector id={this.props.id} categories={this.state._categories} categoriesstate={this.state.values.categories} setValue={this.setValue} />}

                    <RiskTextModal edit={edit} name={this.state.values.name} questiontext={this.state.values.questiontext} yestext={this.state.values.yestext} notext={this.state.values.notext} onChange={this.setValue} />

                    <CopyTo onClick={newparent => { cloneRisk(this.props.id, newparent) }} elements={this.props.siblings} />
                    <CopyTo move onClick={newparent => { cloneRisk(this.props.id, newparent, true) }} elements={this.props.siblings} />

                    <Radioaddon id={this.props.id + "_itemstoggle"} checked={this.state.values.showItems !== false} icon={"fa fa-cube"} onClick={e => this.props.actions.setValue({ showItems: !this.state.values.showItems })}>Items?</Radioaddon>

                    <ButtonModal modalClassName="AuditModal" icon="fs-exclamation-triangle" glyph={rlbuttontext} buttonText="Level" title="Risk Level">

                      <InputGroup className="Field">
                        <span className="form-control">Escape Area Type</span>
                        <div className="input-group-append">
                          <BSDrop icon="fs-logout" title={escapeareatypes[this.state.values.escapeareatype || 0].type} right>
                            {escapeareatypes.map(r =>
                              <BSDropItem onClick={e => this.props.actions.setValue("escapeareatype", r.id)} key={r.id}>
                                {r.type}
                              </BSDropItem>
                            )}
                          </BSDrop>
                        </div>
                      </InputGroup>

                      <InputGroup className="Field">
                        <span className="form-control">Risk Level</span>
                        <div className="input-group-append">
                          <BSDrop icon="fs-exclamation-triangle" right title={risklevels[this.state.values.risklevel || 0].text}>
                            {risklevels.map(r =>
                              <BSDropItem onClick={e => this.props.actions.setValue("risklevel", r.id)} key={r.id}>
                                {r.display}{r.text}
                              </BSDropItem>
                            )}
                          </BSDrop>
                        </div>
                      </InputGroup>

                      {this.state.values.risklevel ?
                        <InputGroup className="Field">
                          <span className="form-control">Risk Scope</span>
                          <div className="input-group-append">
                            <BSDrop icon="fs-building-o" right title={riskscope[this.state.values.riskscope !== undefined ? this.state.values.riskscope : 1].text}>
                              {riskscope.map(r =>
                                <BSDropItem onClick={e => this.props.actions.setValue("riskscope", r.id)} key={r.id}>
                                  {r.text}
                                </BSDropItem>
                              )}
                            </BSDrop>
                          </div>
                        </InputGroup>
                        : null}

                    </ButtonModal>
                  </Fragment>
                  :
                  null
                  /*!items && this.state.values.risk==="2" && <AddChildButtons childTypes={[this.props.childTypes.find(t=>t.type==="Item")]} addChild={this.props.actions.addChild} />*/
                }

                {/* {open && <Items children={this.children({types: "Item"})} actions={this.props.actions} />} */}

                {/*Number(this.state.values.risk)===2 && <AttachDocModal id={this.props.id+'_attachDoc'} title={(this.state.values.name  || "Risk")+" Attachments"} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />*/}

                <CircleCheck icon="fs-check" title="Yes" checked={Number(this.state.values.risk) === 2} classNameActive={"active"} name="2" onChange={this.riskClick} />
                <CircleCheck icon="fs-times" title="No" checked={Number(this.state.values.risk) === 1} name="1" onChange={this.riskClick} />

                {edit && <StButtons actions={this.props.actions} />}
              </div>
            </div>
          </div>
          <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

          {/*<div className="card-block childblock" hidden={!open}>*/}
          <ShowContainer className="card-block childblock" refGrandparent show={!!open} headerId={this.props.parentid}>

            {this.children({ props: { edit: this.props.edit }, untypes: "Item" })}

          </ShowContainer>

          {/*</div>*/}
        </div>
      </div>
    )
  }
}

//! add -1 to list and filter in PriorityDrop?
const priorityratings = [
  { id: 0, text: 'Unrated', display: 'P', color: '' },
  { id: 1, text: 'Priority 1 - Fix Immediately', mobiletext: 'Fix Immediately', display: 'P1', color: '#d93e38' },
  { id: 2, text: 'Priority 2 - Fix ASAP', mobiletext: 'Fix ASAP', display: 'P2', color: '#cf6625' },
  { id: 3, text: 'Priority 3 - Fix in regular maintenance', mobiletext: 'Fix in regular maintenance', display: 'P3', color: '#cf8825' }
]

/* Dropdown to select priority of Action */
const PriorityDrop = props => (
  <BSDrop right={true} title={priorityratings[props.value || 0].display} buttonText="Priority">
    {priorityratings.map(r =>
      <BSDropItem onClick={e => props.name ? props.onClick(props.name, r.id) : props.onClick(r.id)} key={r.id}>
        {r.display} {r.text}
      </BSDropItem>
    )}
  </BSDrop>
)

/* Basic Action buttons as used on Measures */
class ActionButtons extends Component {

  actionClick = e => {
    const a = !this.props.values.action;
    return (
      this.props.setValue({
        'na': false,
        'action': a,
        'actionwhen': a ? timeStamp() : undefined,
        'inplace': false,
        'inplacewhen': undefined,
        'closed': false,
        'closedwhen': undefined,
        'completed': false
      })
    )
  }

  inPlaceClick = e => {
    const i = !this.props.values.inplace;

    return (
      this.props.setValue({
        'na': false,
        'closed': false,
        'actionwhen': undefined,
        'closedwhen': undefined,
        'inplace': i,
        'inplacewhen': i ? timeStamp() : undefined,
        'action': false,
        'completed': false
      })
    )
  }

  naClick = e => {
    const i = !this.props.values.na;

    return (
      this.props.setValue({
        'na': i,
        'closed': false,
        'actionwhen': undefined,
        'closedwhen': undefined,
        'inplace': false,
        'inplacewhen': undefined,
        'action': false,
        'completed': false
      })
    )
  }

  render() {
    return (
      <>
        <CircleCheck icon="fs-check" title="In Place" checked={this.props.values.inplace} name="inplace" onChange={this.inPlaceClick} />
        {/*<CircleCheck icon="fs-wrench" title="Action" classNameActive="alertText" active={this.props.values.action} checked={this.props.values.action} name="action" onChange={this.actionClick} />*/}
        <CircleCheck icon="fs-exclamation-triangle" title="Action" classNameActive="alertText" active={this.props.values.action} checked={this.props.values.action} name="action" onChange={this.actionClick} />
        {this.props.na && <CircleCheck icon="fs-ban" title="N/A" active={this.props.values.na} checked={this.props.values.na} name="na" onChange={this.naClick} />}
      </>
    )
  }
}

class MeasureTextModal extends Component {
  constructor(props) {
    super(props);

    //this._bind('show'); //,'addUpdate'); //,'delete');

    this.id = this.props.id || cuid();
  }

  render() {

    if (!this.props.edit) return null;

    return (
      <ButtonModal scroll modalClassName="MDModal" title="Measure Text" buttonText="Edit">
        <div className="form-group">
          <label htmlFor={this.id + '_mrident'}>Risk Identified</label>
          {/*<FormText id={this.id+'_mrident'} name="riskident" className="notes" value={this.props.riskident} update={this.props.onChange} />*/}
          <div>
            <EditableA id={this.id + '_mrident'} name="riskident" onChange={this.props.onChange} editFixedOn>
              {this.props.riskident}
            </EditableA>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor={this.id + '_name'}>Action Text</label>
          {/*<FormText id={this.id+'_name'} name="name" className="notes" value={this.props.name} update={this.props.onChange} />*/}
          <div>
            <EditableA id={this.id + '_name'} name="name" onChange={this.props.onChange} editFixedOn>
              {this.props.name}
            </EditableA>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor={this.id + '_inplace'}>In Place Text</label>
          {/*<FormText id={this.id+'_inplace'} name="inplacetext" className="notes" value={this.props.inplacetext} update={this.props.onChange} />*/}
          <div>
            <EditableA id={this.id + '_inplace'} name="inplacetext" onChange={this.props.onChange} editFixedOn>
              {this.props.inplacetext}
            </EditableA>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor={this.id + '_reportid'}>Report ID</label>
          <FormText id={this.id + '_reportid'} name="reportid" value={this.props.reportid} update={this.props.onChange} />
        </div>
      </ButtonModal>
    )
  }
}

/* Child of a risk - what the user is doing to counter a risk */
class Measure extends Container {

  /*componentDidMount() {
    super.componentDidMount();
    this.addWatcher("_categories");
  }*/

  //Runs when _categories updates
  /*updateCategories = (newstate={}) => {

    const cathide = (!categoryshow(newstate._categories, this.state.values.categories, this.props.id)); //True is risk should be hidden due to category selection
    //if(this.props.check) this.props.check(cathide?3:this.state.values.risk); //Update checked check for RiskSurveySection
    this.passcheck(cathide);

    this.runStateUpdate({...newstate, cathide});
  }*/

  //Update values.cathide when global _categories ior local values.categories change
  catUpdate = (_categories = { _categories: this.state._categories }) => {

    //console.log("Meadure catUpdate: ", this.props.id, _categories);

    const cathide = categoryHider(_categories, this.state.values.categories, this.state.values.cathide, this.setValue, this.updateState, this.props.id);

    this.passcheck(cathide);
  }

  componentDidMount() {
    super.componentDidMount();

    //this.props.registerChangeWatcher(this.updateCategories, "_categories");
    this.props.registerChangeWatcher(this.catUpdate, "_categories"); //Only if values.categories?

    this.addWatcher('_audits');

    //if(this.props.check) this.props.check(this.state.cathide?3:this.state.values.risk);

    //this.passcheck(); //!? Removed when display hidden until categories available
  }

  componentDidUpdate(prevProps, prevState) {
    //If risk level has changed, update check from parent
    if (
      this.state.values.inplace !== prevState.values.inplace
      || this.state.values.action !== prevState.values.action
      || this.state.values.na !== prevState.values.na
      || this.state.values.cathide !== prevState.values.cathide
    ) {
      //this.props.check(this.state.cathide?3:this.state.values.risk);

      this.passcheck();
    }

    if (this.state.values.categories && (!prevState.values.categories || !arraysEqual(this.state.values.categories, prevState.values.categories))) this.catUpdate();
  }

  //If parent passes a "check" function, pass inplace/action value on category/risk change
  //passcheck = (cathide=this.state.cathide) => {
  //!! Is this firing when it needs to????
  passcheck = (cathide = this.state.values.cathide) => {

    //console.log("Measure passcheck: ", this.props.id, cathide);

    if (this.props.check) {
      let m = null;
      if (cathide || this.state.values.inplace || this.state.values.na) m = true;
      else if (this.state.values.action) m = false;
      this.props.check(m);
    }
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    //??if(!categoryshow(this.state._categories, this.state.values.categories, this.props.id) && !(edit || this.props.admin)) return null;
    if (this.state.values.cathide && !(this.props.admin || edit)) return null;

    //Remove concept of closed
    const title = edit ? (this.state.values.inplace ? this.state.values.inplacetext || this.state.values.name : this.state.values.name || "Measure") : (this.state.values.inplace) && this.state.values.inplacetext ? this.state.values.inplacetext : this.state.values.name || "Measure";

    return (
      <MainContext.Consumer>
        {context=>
          <div className={`Measure input-group ${edit ? 'measure-edit' : ''}`} data-toggle="buttons" id={this.props.id}>
            <div className="input-group-prepend btn-group-toggle">
              {/*<ActionButtons id={this.props.id} values={this.state.values} actions={this.props.actions} na={this.state.values.allowna} setValue={this.setValue} />*/}
              <ActionButtons id={this.props.id} values={this.state.values} actions={this.props.actions} na={this.state.values.allowna} setValue={this.setValue} />
            </div>

            <div className={"ztitle form-control Measurename input-group-text" + (this.state.values.inplace ? " inplace" : "") + (this.state.values.action ? " action" + (this.state.values.closed ? " closed" : "") : "")}>
              {edit ?
                (this.state.values.inplace ?
                  <EditableA name="inplacetext" onChange={this.setValue} editFixedOn>
                    {title}
                  </EditableA>
                  :
                  <EditableA name="name" onChange={this.setValue} editFixedOn>
                    {title}
                  </EditableA>)
                :
                title
              }
            </div>

            <div className="input-group-append btn-group-toggle">

              {this.state.values.closed && !this.state.values.completed &&
                <ButtonModal modalClassName="AuditModal" padding scroll icon="fs-clipboard" title="Closed Text" linktext={"Closed (alt action)"}>
                  {this.state.values.closednote}
                </ButtonModal>
              }
              {this.state.values.closed && this.state.values.completed && <span className="form-control closedText input-group-text">Completed <span className="fs-check" /></span>}

              {edit && 
                <>
                  <MeasureTextModal edit={edit} name={this.state.values.name} inplacetext={this.state.values.inplacetext} riskident={this.state.values.riskident} onChange={this.setValue} />
                  <PriorityDrop value={this.state.values.priority} name="priority" onClick={this.props.actions.setValue} />
                  <Radioaddon id={this.props.id + "_allownatoggle"} checked={this.state.values.allowna} icon={"fs-ban"} onClick={e => this.props.actions.setValue({ allowna: !this.state.values.allowna })}>na?</Radioaddon>
                  <CategorySelector id={this.props.id} categories={this.state._categories} categoriesstate={this.state.values.categories} setValue={this.setValue} />
                </>
              }

              {/*!!! Direct to correct pane->section->audit; somehow pass return pane->section->id to setView */}
              <AuditPicker edit={edit} _audits={this.state._audits} name="audit" value={this.state.values.audit} onClick={this.setValue} onViewClick={(section, audit)=>context.setView("survey", "audit", section, audit)} />

              <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} />
              <AttachDocModal id={this.props.id + '_attachDoc'} title={`Attachments for: ${title}`} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />
              {edit && <StButtons actions={this.props.actions} />}
            </div>
          </div>
        }
      </MainContext.Consumer>
    )
  }
}

/* A Measure that references a particular piece of Equipment / one of a selection of Equipment */
class PrecautionMeasure extends Container {

  //Update values.cathide when global _categories ior local values.categories change
  catUpdate = (_categories = { _categories: this.state._categories }) => {
    categoryHider(_categories, this.state.values.categories, this.state.values.cathide, this.setValue, this.updateState, this.props.id);
  }

  componentDidMount() {
    super.componentDidMount();
    //this.addWatcher("_categories");
    this.props.registerChangeWatcher(this.catUpdate, "_categories");

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.values.categories && (!prevState.values.categories || !arraysEqual(this.state.values.categories, prevState.values.categories))) this.catUpdate();
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    //if(!categoryshow(this.state._categories, this.state.values.categories, this.props.id) && !(edit || this.props.admin)) return null;
    if (this.state.values.cathide && !(this.props.admin || edit)) return null;

    //console.log("PrecautionMeaure: ", this.props, this.state.children);

    //if(!edit) {
    //  if(this.props.risk==='0') return null;
    /*if(this.props.risk==='2' && this.state.values.visibility===1) return null;
    if(this.props.risk!=='2' && !this.state.values.visibility) return null;*/
    //}

    //const title=edit?(this.state.values.inplace || this.state.values.closed?this.state.values.inplacetext || this.state.values.name:this.state.values.name || "Measure"):(this.state.values.inplace || this.state.values.closed) && this.state.values.inplacetext?this.state.values.inplacetext:this.state.values.name || "Measure";

    //Remove concept of closed
    const title = edit ? (this.state.values.inplace ? this.state.values.inplacetext || this.state.values.name : this.state.values.name || "Measure") : (this.state.values.inplace) && this.state.values.inplacetext ? this.state.values.inplacetext : this.state.values.name || "Measure";

    return (
      <div className="Measure input-group" data-toggle="buttons" id={this.props.id}>

        <div className="input-group-prepend btn-group-toggle">
          <ActionButtons id={this.props.id} values={this.state.values} actions={this.props.actions} na={this.state.values.allowna} setValue={this.setValue} />
        </div>

        <div className={"ztitle form-control Measurename" + (this.state.values.inplace ? " inplace" : "") + (this.state.values.action ? " action" + (this.state.values.closed ? " closed" : "") : "")}>
          {edit ?
            (this.state.values.inplace ?
              <EditableA name="inplacetext" onChange={this.props.actions.setValue} editFixedOn>
                {/*this.state.values.inplacetext || this.state.values.name*/}
                {title}
              </EditableA>
              :
              <EditableA onChange={this.props.actions.renameMe} editFixedOn>
                {/*this.state.values.name || "Measure"*/}
                {title}
              </EditableA>)
            :
            /*(this.state.values.inplace || this.state.values.closed) && this.state.values.inplacetext?this.state.values.inplacetext:this.state.values.name || "Measure"*/
            title
          }
        </div>

        <div className="input-group-append btn-group-toggle">
          {/*<ClosedButton id={this.props.id} values={this.state.values} actions={this.props.actions} />*/}

          {this.state.values.closed && !this.state.values.completed &&
            <ButtonModal modalClassName="AuditModal" scroll icon="fs-clipboard" title="Closed Text" linktext={"Closed (alt action)"}>
              {this.state.values.closednote}
            </ButtonModal>
          }
          {this.state.values.closed && this.state.values.completed && <span className="form-control closedText">Completed <span className="fs-check" /></span>}

          <ButtonModal modalClassName="AuditModal" scroll icon={this.props.icon} buttonText="Precautions" title={this.state.values.name} onTitleChange={this.props.edit ? this.props.actions.renameMe : null}
            topButtons={edit ? <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} /> : null}
            onShow={this.updateChildren}
          >
            {this.children({ props: { edit } })}
            <div><br />* One or more of the above Precautions are required</div>
          </ButtonModal>

          {edit && <MeasureTextModal edit={edit} name={this.state.values.name} inplacetext={this.state.values.inplacetext} riskident={this.state.values.riskident} onChange={this.getSetValue} />}
          {edit && <PriorityDrop value={this.state.values.priority} name="priority" onClick={this.props.actions.setValue} />}
          {edit && <Radioaddon id={this.props.id + "_allownatoggle"} checked={this.state.values.allowna} icon={"fs-ban"} onClick={e => this.props.actions.setValue({ allowna: !this.state.values.allowna })}>na?</Radioaddon>}

          {/*edit && <VisibilityDrop value={this.state.values.visibility} name="visibility" onClick={this.props.actions.setValue} />*/}

          {edit && <CategorySelector id={this.props.id} categories={this.state._categories} categoriesstate={this.state.values.categories} setValue={this.setValue} />}

          <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} />
          <AttachDocModal id={this.props.id + '_attachDoc'} title={title} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />

          {edit && <StButtons actions={this.props.actions} />}
        </div>
      </div>
    )
  }
}

/* Child of ExtinguisherSection */
class ExtinguisherType extends Container {
  constructor(props) {
    super(props);

    this.state["attachmodalshow"] = false;

    //this._bind('riskClick','catClick','addChildClick'); //'addUserMeasure'

    this.radio = [
      { id: '2', icon: "fs-check", title: "In Use" },
      { id: '1', icon: "fs-times", title: "N/A" }
    ];

    //this.props.registerUpdater(params=>this.updateChildren(params, {extcategories: this.props.values.extcategories}));
  }

  stateUpdate = newstate => {
    getExtinguisherList(this.props.rootid)
      .then(exts => this.props.updateChangeWatchers({ _id: "_extinguishercategories", _extinguishercategories: getExtinguisherCategories(exts) }, true));
  }

  riskClick = (r) => {
    this.props.actions.setValue('risk', (this.state.values.risk === r ? '0' : r));
  }

  //Toggle categories
  catClick = (catid) => {
    let cats = (isArray(this.state.values.extcategories)) ? this.state.values.extcategories.slice() : [];
    const fc = cats.indexOf(catid);
    if (fc > -1) cats.splice(fc, 1);
    else cats.push(catid);

    this.props.actions.setValue('extcategories', cats);
  }

  addChildClick = (type) => {
    const v = type === "Extinguisher" ? { name: this.state.values.name } : undefined;
    this.props.actions.addChild(type, undefined, v);
  }

  render() {

    //console.log("ExtinguisherType render");

    const edit = (this.state.edit || this.props.edit);

    //console.log("Cats; ",this.props.values.extcategories, this.props.extcategories.filter(c=>this.props.values.extcategories.indexOf(c.id)>-1));

    const extcategories = isArray(this.props.extcategories) ? this.props.extcategories : [];

    //const tclass="ztitle form-control"+(this.state.values.risk==='1'?" crossed":(this.state.values.risk==='2'?" bold":""));
    const tclass = "ztitle form-control input-group-text";

    return (
      <div className="Risk" id={this.props.id}>
        <div className="card">
          <div className="card-header">

            <div className={`input-group ${this.props.expanded ? 'open' : ''}`} data-toggle="buttons">
              {/*<div className="input-group-prepend btn-group-toggle">
                {this.radio.map(r=>
                  <Radioaddon key={r.id} checked={this.state.values.risk===r.id} icon={r.icon} onClick={e=>this.riskClick(r.id)}>{r.title}</Radioaddon>
                )}
                </div>*/}

              {edit ?
                <EditableA onChange={this.props.actions.renameMe} editFixedOn className={tclass}>
                  {this.state.values.name || "Extinguisher"}
                </EditableA>
                :
                <span className={tclass}>{this.state.values.name || "Extinguisher"}</span>
              }

              {/*{edit?<AddChildButtons childTypes={this.props.childTypes} addChild={this.addChild} />:null}*/}

              <div className="input-group-append btn-group-toggle">
                {edit && <AddChildButtons edit={edit} childTypes={this.props.childTypes} addChild={this.addChildClick} />}

                {edit ?
                  <Fragment>
                    <span className="input-group-text mobile-hide">Categories:</span>
                    {extcategories.map(c => {
                      const ch = (isArray(this.state.values.extcategories) && this.state.values.extcategories.indexOf(c.id) > -1);
                      return <Radioaddon key={c.id} checked={ch} icon={ch ? "fs-check-square-o" : "fs-square-o"} onClick={e => this.catClick(c.id)}>{c.title}</Radioaddon>
                    })}
                  </Fragment>

                  : <FInfo title="Categories">
                    {extcategories.map(c => {
                      const ch = (isArray(this.state.values.extcategories) && this.state.values.extcategories.indexOf(c.id) > -1);
                      return (ch ? <span key={c.id} className="input-group-text mobile-hide">{c.title}</span> : null)
                    })}
                  </FInfo>
                }

                {edit && <RiskTextModal edit={edit} name={this.state.values.name} questiontext={this.state.values.questiontext} yestext={this.state.values.yestext} notext={this.state.values.notext} onChange={this.setValue} />}

                {<HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} />}
                {<AttachDocModal id={this.props.id + '_attachDoc'} title={this.state.values.name || "Extinguisher"} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />}

                <CircleCheck icon="fs-check" title="In Use" checked={this.state.values.risk === "2"} classNameActive={"active"} name="2" onChange={this.riskClick} />
                <CircleCheck icon="fs-times" title="N/A" checked={this.state.values.risk === "1"} name="1" onChange={this.riskClick} />

                {edit && <StButtons actions={this.props.actions} />}
              </div>
            </div>

          </div>

          <div className="card-block">
            {/*this.props.childTypes.map(childType =>
            this.state.children?this.state.children.filter(c=>c.props.type===childType.type).map(child=>React.cloneElement(child, {extcategories: this.props.extcategories.filter(c=>(this.props.values && this.props.values.extcategories?this.props.values.extcategories.indexOf(c.id)>-1:false)), name: this.props.values.name})):null
          )*/}

            {/*this.AddParametersToChildren({extcategories: (this.props.extcategories || []).filter(c=>(this.state.values && this.state.values.extcategories?this.state.values.extcategories.indexOf(c.id)>-1:false)), name: this.state.values.name}, this.orderChildrenByType())*/}

            {this.children({ props: { extcategories: (this.props.extcategories || []).filter(c => (this.state.values && this.state.values.extcategories ? this.state.values.extcategories.indexOf(c.id) > -1 : false)), name: this.state.values.name }, orderChildrenByType: true })}

            {/*
            this.props.childTypes.map(childType => this.state.children?this.state.children.filter(c=>c.props.type===childType.type):null)
          */}
          </div>
        </div>
      </div>
    )
  }
}

/*
class EscapeRoute extends Container {
  render() {
    return (
      <div className="Measure" id={this.props.id}>
        <div className="input-group btn-group-toggle" data-toggle="buttons">

          <ActionButtons id={this.props.id} values={this.props.values} actions={this.props.actions} />

          <div className={"ztitle form-control Measurename"+(this.props.values.inplace?" inplace":"")+(this.props.values.action?" action"+(this.props.values.closed?" closed":""):"")}>
            {edit?
              (this.props.values.inplace || this.props.values.closed?
              <EditableA name="inplacetext" onChange={this.props.actions.setValue} editFixedOn>
                {this.props.values.inplacetext || this.props.values.name}
              </EditableA>
              :
              <EditableA onChange={this.renameMe} editFixedOn>
                {this.props.values.name || "Measure"}
              </EditableA>)
            :
              (this.props.values.inplace || this.props.values.closed) && this.props.values.inplacetext?this.props.values.inplacetext:this.props.values.name || "Measure"
            }
          </div>

          <ClosedButton id={this.props.id} values={this.props.values} actions={this.props.actions} />

          {edit?<MeasureTextModal edit={edit} className="input-group-addon" name={this.props.values.name} inplacetext={this.props.values.inplacetext} riskident={this.props.values.riskident} onChange={this.props.actions.setValue} />:null}

          {edit?<PriorityDrop value={this.props.values.priority} name="priority" onClick={this.props.actions.setValue} />:null}

          {edit && !this.props.alwaysVisible?
            <VisibilityDrop value={this.props.values.visibility} name="visibility" onClick={this.props.actions.setValue} />
          :null}

          <HelpModal id={this.props.id+"_help"} edit={edit} className="input-group-addon" name="help" onChange={this.setValue} value={this.props.values.help} />
          <AttachDocModal id={this.props.id+'_attachDoc'} docid={this.props.id} className="input-group-addon" attachments={this.props.attachments} />

          {edit?<StButtons actions={this.props.actions} />:null}
        </div>
      </div>
    )
  }
}
*/

class Extinguisher extends Container {
  /*constructor(props) {
    super(props);

    //this._bind('getCategoryRating','updateRating');
  }*/

  stateUpdate = (newstate) => {
    getExtinguisherList(this.props.rootid)
      .then(exts => this.props.updateChangeWatchers({ _id: "_extinguishercategories", _extinguishercategories: getExtinguisherCategories(exts) }, true));
  }

  getCategoryRating = (catid) => {
    if (this.state.values && this.state.values.ratings) {
      const er = this.state.values.ratings.find(r => r && r.cid === catid);
      if (er) {
        return er.rating;
      }
    }
  }

  /*
    Loop through all of the extcategories that have been passed in and save ratings for each category
    in order of category
  */
  updateRating = (category, rating) => {
    if (!this.props.extcategories) return;

    const ratings = (isArray(this.state.values.ratings)) ? this.state.values.ratings : [];

    const rv = (typeof category !== "undefined" && typeof rating !== "undefined") ? { cid: category.id, ctitle: category.title, rid: rating.id, rating: rating.rating } : false;

    const rout = this.props.extcategories.map(c =>
      rv && c.id === rv.cid
        ? rv
        : ratings.find(r => r && r.cid === c.id)
    ).filter(r => r);

    //console.log("rout: ",rout);

    this.props.actions.setValue("ratings", rout);
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    const ec = isArray(this.props.extcategories) ? this.props.extcategories : [];

    return edit ?
      (
        <div className="Audit input-group btn-group-toggle" data-toggle="buttons">
          <div className="input-group-prepend">
            <span className="input-group-text">
              {this.state.values.name || "Extinguisher"}
            </span>
            <span className="input-group-text">Weight:</span>
          </div>
          <FormNumber name="weight" value={this.state.values.weight} update={this.props.actions.setValue} min="0" max="9999" />
          <div className="input-group-prepend input-group-append">
            <span className="input-group-text">KG</span>
            <span className="input-group-text">Volume:</span>
          </div>
          <FormNumber name="volume" value={this.state.values.volume} update={this.props.actions.setValue} min="0" max="9999" />
          <div className="input-group-append">
            <span className="input-group-text">L</span>
            <span className="input-group-text">Ratings:</span>
            {ec.map(c =>
              c.ratings && c.ratings.length ?
                <BSDrop key={c.id} right title={this.getCategoryRating(c.id) || "Select [" + c.title + "]"}>
                  {c.ratings.map(r =>
                    <BSDropItem onClick={e => this.updateRating(c, r)} key={r.id}>
                      {r.rating}
                    </BSDropItem>
                  )}
                </BSDrop>
                :
                <span key={c.id} className="input-group-text">{c.title}</span>
            )}
            <StButtons actions={this.props.actions} />
          </div>
        </div>
      )
      : null;
  }
}

class Precaution extends Container {
  constructor(props) {
    super(props);

    /*this.state={
      precautions: null
    }*/

    //this.state["precautions"]=watchValue('precautions', exts=>{if(this._mounted!==false) this.setState({"precautions": exts})});
    this.watchlist = ["_precautions"];
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    return (
      <div className="Measure" id={this.props.id}>
        <div className="input-group" data-toggle="buttons">

          {this.state._precautions ? <ProgressiveDrop id={`${this.props.id}_pd`} edit={edit} rootname="Precaution:" values={this.state._precautions} name="precautionid" summaryfield="name" value={this.state.values.precautionid} update={this.setValue} /> : null}

          <div className="input-group-append">
            {edit && <StButtons actions={this.props.actions} />}
          </div>
        </div>
      </div>
    )
  }
}

class ExtinguisherPrecaution extends Container {
  constructor(props) {
    super(props);

    //this.state["precautions"]=watchValue('extinguishercategories', exts=>{if(this._mounted!==false) this.setState({"precautions": exts})});
    this.watchlist = ["_extinguishercategories"];
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    //console.log("ExtinguisherPrecaution precautions",this.state.precautions);

    return (
      <div className="Measure" id={this.props.id}>
        <div className="input-group" data-toggle="buttons">

          {this.state._extinguishercategories ? <ProgressiveDrop edit={edit} rootname="Extinguisher Category:" values={this.state._extinguishercategories} name="precautionid" summaryfield="name" value={this.state.values.precautionid} update={this.props.actions.setValue} /> : null}

          <div className="input-group-append">
            {edit && <StButtons actions={this.props.actions} />}
          </div>
        </div>
      </div>
    )
  }
}

/* Child of Equipment - specific type of equipment, eg 1/2 hour firedoor */
class EquipmentType extends Container {
  render() {
    const edit = (this.state.edit || this.props.edit);

    //if(!edit) return null;

    const title = edit ? (this.state.values.inplace ? this.state.values.inplacetext || this.state.values.name : this.state.values.name || "Type") : (this.state.values.inplace) && this.state.values.inplacetext ? this.state.values.inplacetext : this.state.values.name || "Type";

    return (
      <div className={"Measure input-group btn-group-toggle " + (this.state.values.subtype ? " Measure-" + this.state.values.subtype : "")} id={this.props.id} data-toggle="buttons">
        <div className="input-group-prepend btn-group-toggle">
          <ActionButtons id={this.props.id} values={this.state.values} actions={this.props.actions} na={this.state.values.allowna} setValue={this.setValue} />
        </div>

        <div className={"ztitle form-control Measurename input-group-text" + (this.state.values.inplace ? " inplace" : "") + (this.state.values.action ? " action" + (this.state.values.closed ? " closed" : "") : "")}>
          {edit ?
            (this.state.values.inplace ?
              <EditableA name="inplacetext" onChange={this.setValue} editFixedOn>
                {title}
              </EditableA>
              :
              <EditableA onChange={this.props.actions.renameMe} editFixedOn>
                {title}
              </EditableA>)
            :
            title
            /*(this.state.values.inplace || this.state.values.closed) && this.state.values.inplacetext?this.state.values.inplacetext:this.state.values.name || "Measure"*/
          }
        </div>

        <div className="input-group-append">
          {edit && <span className="input-group-text">[TYPE]</span>}

          {this.state.values.closed && !this.state.values.completed &&
            <ButtonModal modalClassName="AuditModal" padding scroll icon="fs-clipboard" title="Closed Text" linktext={"Closed (alt action)"}>
              {this.state.values.closednote}
            </ButtonModal>
          }

          {this.state.values.closed && this.state.values.completed && <span className="form-control closedText input-group-text">Completed <span className="fs-check" /></span>}

          {edit && <MeasureTextModal edit={edit} name={this.state.values.name} inplacetext={this.state.values.inplacetext} riskident={this.state.values.riskident} onChange={this.setValue} />}

          {edit && <PriorityDrop value={this.state.values.priority} name="priority" onClick={this.setValue} />}

          {edit && <Radioaddon id={this.props.id + "_allownatoggle"} checked={this.state.values.allowna} icon={"fs-ban"} onClick={e => this.setValue({ allowna: !this.state.values.allowna })}>na?</Radioaddon>}

          <HelpModal id={this.props.id + "_help"} edit={edit} onChange={v => this.setValue('help', v)} value={this.state.values.help} />
          <AttachDocModal id={this.props.id + '_attachDoc'} title={title + " Attachments"} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />

          {edit && <StButtons actions={this.props.actions} />}
        </div>
      </div>
    )
  }
}

class Equipment extends Container {
  constructor(props) {
    super(props);

    this.state["attachmodalshow"] = false;

    //this._bind('riskClick'); //'addUserMeasure'

    this.radio = [
      { id: '2', icon: "fs-check", title: "In Use" },
      { id: '1', icon: "fs-times", title: "N/A" }
    ];

    this.state["checkedchildren"] = {};
    this.state["allchildrenchecked"] = null;

    //this.headerRef = React.createRef();

    //this.props.registerUpdater(params=>this.updateChildren(params, {risk: this.props.values.risk}));
  }

  riskClick = r => {
    const risk = (this.state.values.risk === r ? '0' : r);
    this.setValue({ risk });

    //if(Number(risk)===2) this.props.expand(this.props.id, true)();
    if (Number(risk) === 2) this.expand(true);
  }

  expand = force => {
    //this.topPos = this.props.expanded?false:this.headerRef.current.offsetTop;
    //this.topPos = this.props.expanded ? false : this.headerRef.current.getBoundingClientRect().top;

    this.props.expand(this.props.id, force === true)();
  }

  setInUse = () => {
    const alwaysinuse = !this.state.values.alwaysinuse;
    this.setValue({ alwaysinuse, risk: alwaysinuse ? "2" : "0" });
  }

  /*updateCategories = (newstate={}) => {

    const cathide = (!categoryshow(newstate._categories, this.state.values.categories, this.props.id)); //True is risk should be hidden due to category selection
    //this.props.check(cathide?3:this.state.values.risk); //Update checked check for RiskSurveySection

    this.runStateUpdate({...newstate, cathide});
  }*/

  //Update values.cathide when global _categories ior local values.categories change
  catUpdate = (_categories = { _categories: this.state._categories }) => {
    //??const cathide = categoryHider(_categories, this.state.values.categories, this.state.values.cathide, this.setValue, this.updateState, this.props.id);

    //this.props.check(cathide?3:this.state.values.risk); //Update checked check
    this.updateCheck();
  }

  componentDidMount() {
    super.componentDidMount();
    //this.addWatcher("_categories");
    //this.props.registerChangeWatcher(this.updateCategories, "_categories");
    this.props.registerChangeWatcher(this.catUpdate, "_categories");

    //this.props.check(this.state.values.cathide?3:this.state.values.alwaysinuse?"2":this.state.values.risk);
    this.updateCheck();
  }

  //Value passed from child when category or inplace/action value changes (including at initial display)
  childcheck = childid => value => {

    if (this._mounted !== false) this.setState(st => {

      const checkedchildren = { ...st.checkedchildren, [childid]: value };
      //const allchildrenchecked = !(st.elements.find(el=>checkedchildren[el]===false));

      let allchildrenchecked = true;
      Object.values(checkedchildren).some(c => {
        if (c === null) {
          allchildrenchecked = null;
          return true;
        }
        if (c === false) {
          allchildrenchecked = false;
        }
        return false;
      });

      return ({
        checkedchildren,
        allchildrenchecked
      })
    })
  }

  updateCheck = () => {

    const checkval =
      this.state.values.cathide
        ? 3
        : this.state.values.alwaysinuse
          ? this.state.values?.sectionok
          : this.state.values.risk

    this.props.check(checkval);

    //if(this.props.id==='Equipment-ckgj5zq4z0032k2zu820u4tvq') console.log("uC: ", checkval);
  }

  componentDidUpdate(prevProps, prevState) {

    //If risk level has changed, update check from parent
    if (prevState.values.risk !== this.state.values.risk || (this.state.values.alwaysinuse && this.state.values?.sectionok !== prevState.values.sectionok)) {
      this.updateCheck();
    }

    //Fix concertina scroll up
    //scrollfix(this.props.expanded, prevProps.expanded, this.topPos, this.headerRef);

    if (this.state.values.categories && (!prevState.values.categories || !arraysEqual(this.state.values.categories, prevState.values.categories))) this.catUpdate();

    if (this.state.checkedchildren
      && Object.keys(this.state.checkedchildren).length === this.state?.elements?.length
      && this.state.values?.sectionok !== this.state.allchildrenchecked) {
      this.setValue({ sectionok: this.state.allchildrenchecked }, true);
    }
  }

  /*componentDidUpdate(prevProps, prevState) {
    //If risk level has changed, update check from parent
    if(prevState.values.risk!==this.state.values.risk) {
      this.props.check(this.state.cathide?3:this.state.values.alwaysinuse?"2":this.state.values.risk);
    }
  }*/

  render() {
    const edit = (this.state.edit || this.props.edit);

    //if(!categoryshow(this.state._categories, this.state.values.categories, this.props.id) && !edit) return null;
    if (this.state.values.cathide && !(this.props.admin || edit)) return null;

    const risk = Number(this.state.values.risk);

    return (
      <div className="Risk Equipment" id={this.props.id}>
        <div className="card">
          <div className={`card-header ${this.state.values.risk > 1 ? 'liverisk' : ''}`}>

            <div className={`input-group button-group-toggle ${this.props.expanded ? 'open' : ''}`} data-toggle="buttons">
              {/*<div className="input-group-prepend btn-group-toggle">
                {this.radio.map(r=>
                  <Radioaddon key={r.id} checked={this.state.values.risk===r.id} icon={r.icon} onClick={e=>this.riskClick(r.id)}>{r.title}</Radioaddon>
                )}
                </div>*/}

              <div className={"ztitle form-control input-group-text" + (risk === 1 ? " crossed" : (risk === 2 ? " bold" : ""))} onClick={() => !edit && this.expand()}>
                {edit ?
                  <EditableA onChange={this.props.actions.renameMe} editFixedOn>
                    {this.state.values.name || "Precaution"}
                  </EditableA>
                  :
                  <span>{this.state.values.name || "Precaution"} {risk === 2 && <OpenCaret open={this.props.expanded} />}</span>
                }
              </div>

              <div className="input-group-append">
                {edit && <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />}

                {edit && <CategorySelector id={this.props.id} categories={this.state._categories} categoriesstate={this.state.values.categories} setValue={this.setValue} />}

                {/*edit && <Radioaddon id={this.props.id+"_itemstoggle"} checked={this.state.values.showItems!==false} icon={"fs-circle-o"} onClick={e=>this.props.actions.setValue({showItems: !this.state.values.showItems})}></Radioaddon>*/}

                {/*!{edit && <Radioaddon id={this.props.id+"_itemstoggle"} checked={this.state.values.showItems!==false} icon={"fa fa-cube"} onClick={e=>this.props.actions.setValue({showItems: !this.state.values.showItems})}>Items?</Radioaddon>}*/}

                {edit && <RiskTextModal edit={edit} name={this.state.values.name} questiontext={this.state.values.questiontext} yestext={this.state.values.yestext} notext={this.state.values.notext} onChange={this.setValue} />}

                {/*!edit && !items && <AddChildButtons childTypes={[this.props.childTypes.find(t=>t.type==="Item")]} addChild={this.props.actions.addChild} />*/}

                {/*!{(edit || (this.state.values.risk==="2" && this.props.expanded)) && <Items children={this.children({types: "Item"})} actions={this.props.actions} />} */}

                {edit && <Radioaddon checked={this.state.values.alwaysinuse} icon={"fs-check"} overlay={<span className={this.state.values.alwaysinuse ? 'fs-check' : 'fs-times'} />} onClick={this.setInUse}>Fixed In Use</Radioaddon>}

                {(edit || (risk === 2 && this.props.expanded)) && <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} />}
                {/*this.state.values.risk==="2" && <AttachDocModal id={this.props.id+'_attachDoc'} title={this.state.values.name  || "Precaution"} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />*/}

                {edit && <CopyTo onClick={newparent => { cloneRisk(this.props.id, newparent) }} elements={this.props.siblings} />}
                {edit && <CopyTo move onClick={newparent => { cloneRisk(this.props.id, newparent, true) }} elements={this.props.siblings} />}

                {this.state.values.alwaysinuse
                  //?<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={()=>!edit && this.props.expand(this.props.id)()} />
                  //?<ExpandButton expanded={this.props.expanded} onClick={()=>!edit && this.props.expand(this.props.id)()} />
                  ? <CircleCheck
                    icon={this.state.values?.sectionok ? 'fs-check' : 'fs-exclamation-triangle'}
                    title={this.state.values?.sectionok || this.state.values?.sectionok === false ? 'In Use' : 'Check'}
                    titleClassName={this.state.values?.sectionok === null ? 'editdatecheck' : ''}
                    checked={this.state.values?.sectionok || this.state.values?.sectionok === false}
                    classNameActive={this.state.values?.sectionok === false ? 'alertText' : "active"}
                    name="2"
                    onChange={() => !edit && this.expand()}
                  />
                  : <>
                    <CircleCheck icon="fs-check" title="In Use" checked={risk === 2} classNameActive={"active"} name="2" onChange={this.riskClick} />
                    <CircleCheck icon="fs-times" title="N/A" checked={risk === 1} name="1" onChange={this.riskClick} />
                  </>
                }

                {edit && <StButtons actions={this.props.actions} />}
              </div>
            </div>

          </div>
          {/*this.props.values.risk==='2' || edit?<div className="card-block">{children}</div>:""*/}
          {/*<div className="card-block" hidden={!((Number(this.state.values.risk)>1 && this.props.expanded) || edit)}>*/}
          <ShowContainer className="card-block childblock" show={((Number(this.state.values.risk) > 1 && this.props.expanded) || edit)} headerId={this.props.parentid}>
            <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />

            {this.children({
              props: { edit: this.props.edit },
              propsIdFunc: { check: this.childcheck },
              untypes: "Item"
            })}

            {/*this.children({props: {edit: this.props.edit, expanded: (Number(this.state.values.risk)>1 && this.props.expanded) || edit }, untypes: "Item"})*/}
          </ShowContainer>
          {/*</div>*/}
        </div>
      </div>
    )
  }
}


/**
 * (Optional) position of AssessmentData value field in report
 */
const reportpos = [
  { id: '', title: "-" },
  { id: "logo", title: "Logo" },
  { id: "siteimage", title: "Site Image" },
  { id: "heading", title: "Heading" },
  { id: "subtitle", title: "Subtitle" },
  { id: "centre", title: "Centre" },
  { id: "bottom", title: "Bottom" },
  { id: "footer", title: "Footer" }
];

const certificatepos = [
  { id: '', title: "-" },
  { id: 'name', title: "Name" },
  { id: 'date', title: "Date" },
  { id: 'company', title: "Company" },
  { id: 'address', title: "Address" }
]

//const hiddenicon = val => val?"fa fa-eye-slash":"fs-eye";

const vfshow = [
  { id: '', title: 'App, Report Main', icon: ['fa fa-laptop', 'fs-clipboard'] },
  { id: 'app', title: 'App', icon: ['fa fa-laptop'] },
  { id: 'report', title: 'Report Main', icon: ['fs-clipboard'] },
  { id: 'hidden', title: 'Hidden', icon: 'fa fa-eye-slash' }
];

/**
 * 
 */
/*
const maxpeople = [
  {id: '', title: "-"},
  {id: "logo", title: "Logo"},
  {id: "siteimage", title: "Site Image"},
  {id: "heading", title: "Heading"},
  {id: "subtitle", title: "Subtitle"},
  {id: "centre", title: "Centre"},
  {id: "bottom", title: "Bottom"},
  {id: "footer", title: "Footer"}
];
*/

/*
A Value field is like a measure, but takes a value in some format
*/
class ValueField extends Component {
  constructor(props) {
    super(props);

    this.id = this.props.id || cuid();
  }

  render() {

    const edit = this.props.edit;

    //if(typeof this.props.risk!=='undefined' && !edit && !this.props.alwaysVisible) {
    //  if(this.props.risk==='0') return null;
    //if(this.props.risk==='2' && this.props.visibility===1) return null;
    //if(this.props.risk!=='2' && !this.props.visibility) return null;
    //}

    const showpos = (this.props.values || {}).showpos;
    if (!edit && (showpos === 'hidden' || showpos === 'report')) return null;

    return (
      <div className={"ValueField input-group"} data-toggle="buttons" id={this.props.id}>
        <div className="input-group-prepend">
          {edit ?
            <FInfo title="App Title" className="form-control">
              <EditableA className="FInfoMain ztitle input-group-text ValueField-title" onChange={this.props.actions.renameMe} editFixedOn={true}>
                {this.props.values.name || "Value Field"}
              </EditableA>
            </FInfo>
            :
            <span className="ztitle input-group-text ValueField-title">{this.props.values.name || "Value Field"}</span>
          }
          {edit && this.props.report &&
            <FInfo title="Alt Report Title" className="form-control">
              <EditableA className="FInfoMain ztitle input-group-text ValueField-title" name="reportTitle" onChange={this.props.actions.setValue} editFixedOn={true}>
                {this.props.values.reportTitle || ""}
              </EditableA>
            </FInfo>
          }
        </div>

        {this.props.children}

        {(edit || this.props.values.help) && <div className="input-group-append">
          {edit &&
            <ButtonModal modalClassName="AuditModal" scroll icon="fs-pencil" buttonText="Edit" title="Field Parameters">
              <div className="form-group">
                <label htmlFor={this.id + '_name'}>Title</label>
                <FormText id={this.id + '_name'} name="name" className="notes" value={this.props.values.name} update={this.props.actions.setValue} />
              </div>
              <div className="form-group">
                <label htmlFor={this.id + '_unit'}>Unit</label>
                <FormText id={this.id + '_unit'} name="unit" className="notes" value={this.props.values.unit} update={this.props.actions.setValue} />
              </div>
              <div className="form-group">
                <label htmlFor={this.id + '_placeholder'}>Placeholder</label>
                <FormText id={this.id + '_placeholder'} name="placeholder" className="notes" value={this.props.values.placeholder} update={this.props.actions.setValue} />
              </div>
              {/*<div className="form-group">
                  <label htmlFor={this.id+'_reportid'}>Report ID</label>
                  <FormText id={this.id+'_reportid'} name="placeholder" value={this.props.values.reportid} update={this.props.actions.setValue} />
                </div>*/}
            </ButtonModal>
          }

          {this.props.report && edit &&
            <Radioaddon checked={this.props.values.gridview} icon={"fa fa-th-list"} overlay={<span className={this.props.values.gridview ? 'fs-check' : 'fs-times'} />} onClick={e => this.props.actions.setValue({ gridview: !this.props.values.gridview })}>Grid View?</Radioaddon>
          }

          {this.props.report && vfshow && edit &&
            <BSDrop buttonText="Show" icon={((vfshow || []).find(s => s.id === (this.props.values.showpos || '')) || {}).icon} right>
              {(vfshow || []).map(s =>
                <BSDropItem onClick={e => this.props.actions.setValue({ "showpos": s.id })} key={s.id}>
                  {/*s.title*/} {ensureArray(s.icon).map((ic, i) => <span key={i} className={ic} />)} {s.title}
                </BSDropItem>
              )}
            </BSDrop>
          }

          {this.props.report && reportpos && edit &&
            <BSDrop buttonText="Cover Pos" title={((reportpos || []).find(s => s.id === (this.props.values.reportpos || '')) || {}).title} right>
              {(reportpos || []).map(s =>
                <BSDropItem onClick={e => this.props.actions.setValue({ "reportpos": s.id })} key={s.id}>
                  {s.title}
                </BSDropItem>
              )}
            </BSDrop>
          }

          {this.props.report && reportpos && edit &&
            <BSDrop buttonText="Cert Pos" title={((certificatepos || []).find(s => s.id === (this.props.values.certificatepos || '')) || {}).title} right>
              {(certificatepos || []).map(s =>
                <BSDropItem onClick={e => this.props.actions.setValue({ "certificatepos": s.id })} key={s.id}>
                  {s.title}
                </BSDropItem>
              )}
            </BSDrop>
          }

          {/*this.props.report && edit && <Radioaddon id={this.props.id+"_hiddentoggle"} checked={this.props.values.hidden} icon={hiddenicon(this.props.values.hidden)} onClick={e=>this.props.actions.setValue({hidden: !this.props.values.hidden})}>{this.props.values.hidden?"Hide":"Show"}</Radioaddon>*/}

          <HelpModal id={this.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.props.values.help || ''} />
          {/*<AttachDocModal id={this.id+'_attachDoc'} title={(this.props.values.name  || "Risk")+" Attachments"} note={this.props.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.props.stubs} />*/}

          {edit && <StButtons actions={this.props.actions} />}
        </div>}
      </div>

    )
  }
}

class AuditCalcField extends Container {

  render() {
    const edit = (this.state.edit || this.props.edit);

    return (
      <ValueField edit={edit} report={this.props.report} values={this.state.values} actions={this.props.actions} id={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} risk={this.props.risk}>
        <CalcField className="form-control" unit={this.state.values.unit} placeholder={this.state.values.placeholder} name="value" update={this.setValue} value={this.state.values.value} />
      </ValueField>
    )
  }
}

class AuditTextField extends Container {
  render() {
    const edit = (this.state.edit || this.props.edit);

    return (
      <ValueField edit={edit} report={this.props.report} values={this.state.values} actions={this.props.actions} id={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} risk={this.props.risk}>
        {/*<FormText placeholder={this.state.values.placeholder} name="value" update={this.setValue} value={this.state.values.value} />*/}
        <EditableA onChange={v => this.setValue({ value: v })} editFixedOn className="form-control">
          {this.state.values.value}
        </EditableA>
      </ValueField>
    )
  }
}

class AuditTextAreaField extends Container {
  render() {
    const edit = (this.state.edit || this.props.edit);

    return (
      <ValueField edit={edit} report={this.props.report} values={this.state.values} actions={this.props.actions} id={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} risk={this.props.risk}>
        <FormTextArea placeholder={this.state.values.placeholder} name="value" update={this.props.actions.setValue} value={this.state.values.value} />
      </ValueField>
    )
  }
}

class AuditDateField extends Container {
  render() {
    const edit = (this.state.edit || this.props.edit);

    return (
      <ValueField edit={edit} report={this.props.report} values={this.state.values} actions={this.props.actions} id={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} risk={this.props.risk}>
        <FormText type="date" placeholder={this.state.values.placeholder} name="value" update={this.props.actions.setValue} value={this.state.values.value} />
        {/*<div className="input-group-append">
          <FButton icon="fs-calendar" onClick={e=>this.props.actions.setValue("value", new Date().toISOString().substr(0, 10))}>Today</FButton>
        </div>*/}
      </ValueField>
    )
  }
}

class AuditImageField extends Container {
  render() {
    const edit = (this.state.edit || this.props.edit);

    return (
      <ValueField edit={edit} report={this.props.report} values={this.state.values} actions={this.props.actions} id={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} risk={this.props.risk}>
        <FormImage title={this.state.values.name} className="form-control" placeholder={this.state.values.placeholder} name="value" update={this.props.actions.setValue} value={this.state.values.value} attachments={this.props.attachments} stubs={this.state._attachments} />
      </ValueField>
    )
  }
}

class AuditLocationField extends Container {
  render() {
    const edit = (this.state.edit || this.props.edit);

    return (
      <ValueField edit={edit} report={this.props.report} values={this.state.values} actions={this.props.actions} id={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} risk={this.props.risk}>
        <FInfo title="Reference" className="form-control">
          <FormText id={this.props.id + '_name'} name="name" className="FInfoMain" value={this.state.values.name} update={this.props.actions.setValue} placeholder="Item Reference" />
        </FInfo>
        <Location actions={this.props.actions} locationHierachy={locationHierachy} _locations={this.state._locations} selectedLocation={this.state.values.selectedLocation} />
      </ValueField>
    )
  }
}

class SiteTextField extends Container {

  updateSiteData = sitedata => {

    //!!Test
    if (sitedata && sitedata.values) {
      const f = siteDataFields.reduce((obj, item) =>
        this.state.values[item.name] !== sitedata.values[item.name]
          ? { ...obj, [item.name]: sitedata.values[item.name] }
          : obj
        , {});

      if (Object.keys(f).length) {
        this.props.actions.setValue(f);
      }
    }
  }

  componentDidMount() {
    super.componentDidMount();

    this.props.registerChangeWatcher(this.updateSiteData, "site");
    this.updateSiteValue = (n, v) => updateSiteValue("site", n, v);
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    return (
      <ValueField edit={edit} report={this.props.report} values={this.state.values} actions={this.props.actions} id={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} risk={this.props.risk}>
        <FormText placeholder={this.state.values.placeholder} name={this.state.values.valuefield || "value"} update={this.updateSiteValue} value={this.state.values[this.state.values.valuefield || ""] || ""} />
        {edit &&
          <div className="input-group-append">
            <BSDrop icon="fs-clipboard" title={this.state.values.valuefield ? (siteDataFields.find(s => s.name === this.state.values.valuefield) || {}).title : "Select Field..."} right>
              {siteDataFields.map(s =>
                <BSDropItem onClick={e => this.props.actions.setValue({ "valuefield": s.name, "name": s.title })} key={s.name}>
                  {s.title}
                </BSDropItem>
              )}
            </BSDrop>
          </div>
        }
      </ValueField>
    )
  }
}

class SiteTextAreaField extends Container {

  updateSiteData = sitedata => {
    //console.log("SiteTextAreaField updateSiteData");
    /*if(sitedata && sitedata.values) {
      this.props.actions.setValue(siteDataFields.reduce((obj, item) => ((obj[item.name] = sitedata.values[item.name], obj)) ,{}));
    }*/

    if (sitedata && sitedata.values) {
      const f = siteDataFields.reduce((obj, item) =>
        this.state.values[item.name] !== sitedata.values[item.name]
          ? { ...obj, [item.name]: sitedata.values[item.name] }
          : obj
        , {});

      if (Object.keys(f).length) {
        this.props.actions.setValue(f);
      }
    }

  }

  componentDidMount() {
    super.componentDidMount();

    this.props.registerChangeWatcher(this.updateSiteData, "site");
    this.updateSiteValue = (n, v) => updateSiteValue("site", n, v);
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    return (
      <ValueField edit={edit} report={this.props.report} values={this.state.values} actions={this.props.actions} id={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} risk={this.props.risk}>
        <FormTextArea placeholder={this.state.values.placeholder} name={this.state.values.valuefield || "value"} update={this.updateSiteValue} value={this.state.values[this.state.values.valuefield || ""] || ""} />
        {edit &&
          <div className="input-group-append">
            <BSDrop icon="fs-clipboard" title={this.state.values.valuefield ? (siteDataFields.find(s => s.name === this.state.values.valuefield) || {}).title : "Select Field..."} right>
              {siteDataFields.map(s =>
                <BSDropItem onClick={e => this.props.actions.setValue({ "valuefield": s.name, "name": s.title })} key={s.name}>
                  {s.title}
                </BSDropItem>
              )}
            </BSDrop>
          </div>
        }
      </ValueField>
    )
  }
}

/*
Multi field includes any number of other field types which can be duplicated
*/

/*class AuditMultiField extends Container {

}*/

class Audit extends Container {
  render() {
    const edit = (this.state.edit || this.props.edit);

    //if(this.state.edit)
    return (
      <div className="Audit input-group" style={{ display: edit || this.props.live ? "" : "none" }} data-toggle="buttons">
        <div className={"ztitle form-control " + (this.props.live ? "input-group-text" : "")}>
          {this.props.live
            ? this.state.values.name || "Audit"
            : <EditableA onChange={this.props.actions.renameMe} editFixedOn={true}>
              {this.state.values.name || "Audit"}
            </EditableA>
          }
        </div>

        <div className="input-group-append btn-group-toggle">
          {this.props.live &&
            <div className="input-group-text">
              Created: {this.props.created.user} {ukdate(this.props.created.when)}<br />
              Updated: {this.state.user} {ukdate(this.state.when)}
            </div>
          }

          <ButtonModal modalClassName="AuditModal" scroll icon="fs-clipboard" title={this.state.values.name || "Audit"} buttonText="Edit" onTitleChange={this.props.live ? null : this.props.actions.renameMe}
            topButtons={edit && !this.props.live ? <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} /> : null}
          >

            <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />

            {this.children()}
          </ButtonModal>

          <SureButton key="delete" icon="fs-trash" buttonText="Delete" onClick={this.props.actions.deleteMe}>Delete?</SureButton>
        </div>
      </div>
    )
    //else return null;
  }
}


//!Extract from Item
/*class Location extends Component {

}*/

class Location extends Component {
  //constructor(props) {
  //	super(props);

  /*this.state = {
    locationHierachy: this.props.locationHierachy,
    editLocation: {}
  }

  this.clickOption = (lh,l) => e => {
    const locations=Object.assign({}, (this.props.selectedLocation || {}), {[lh]: l});
    //console.log("clickOption: ", lh, l, locations);
    return this.props.actions.setValue({"selectedLocation": locations});
  }

  this.setEdit = (lh,l,v) => e => {
    //console.log("setEdit: ", lh, l, v);
    //this.setState({"editLocation": Object.assign({}, this.state.selectedLocation, {[lh]: (v || typeof v==="undefined")?{id: l || cuid(), title: v}:null})});
    this.setState({"editLocation": {[lh]: (v || typeof v==="undefined")?{id: l || cuid(), title: v}:null}});
  }

  this.editLocation = (lh,l,p) => (n,v) => {
    if(!v) {
      this.setState({"editLocation": {}});
    }
    else {
      const lid = l || cuid();

      const nl = Object.assign(
        {}, 
        (this.props._locations || {}),
        {[lh]: ((this.props._locations || {})[lh] || []).filter(el=>el && el.id!==l).concat({id: lid, title: v, parent: p})}
      );

      //console.log("editLocation update: ", this.state._locations, nl, ((this.state._locations || {})[lh] || []).filter(el=>el && el.id!==l));

      this.props.actions.updateSite({"_locations": nl});
      this.props.actions.setValue({"selectedLocation": Object.assign({}, this.props.selectedLocation || {}, {[lh]: lid})});
      this.setState({"editLocation": {}});
    }
  }

  //!Should cycle through and remove orphaned children
  this.deleteLocation = (lh,l) => e => {
    const loc = Object.assign(
      {}, 
      (this.props._locations || {}), 
      {[lh]: ((this.props._locations || {})[lh] || []).filter(el=>el && el.id!==l)}
    );

    this.props.actions.updateSite({"_locations": loc});

    console.log("dL", lh, l, loc);
  }
  */

  //}

  render() {

    //let parentid="root";

    //console.log("Location _locations: ", this.props._locations);

    return (
      <div className={"Location btn-group " + (this.props.className || "")}>

      </div>
    )

    /*return (
      <div className={"Location btn-group "+(this.props.className || "")}>
        {this.props.locationHierachy.map((lh, level)=>{
          if(!parentid || (typeof this.props.levels!=="undefined" && (level+1>this.props.levels))) return null;

          //Get current parentid (if any) and set parent for next dropdown
          const currentParentId=parentid;

          //Option list for this dropdown
          const options=((this.props._locations || {})[lh.id] || []).filter(l=>currentParentId==="root" || l.parent===currentParentId);
          //console.log("parentid, ufo, options: ", currentParentId, this.state._locations[lh.id], options);

          //Selected option
          const selectedLocation=options.find(loc=>loc.id===(this.props.selectedLocation || {})[lh.id]) || {};
          parentid=selectedLocation.id;

          //Create/edit 
          const editLocation=this.state.editLocation[lh.id] || {};

          //const selectedLocation=().title;

          return (
            <FInfo title={lh.title} key={lh.id}>
              {editLocation.id || !options.length
                ?
                <FormText name="new" className="notes" placeholder={"Enter new"} value={editLocation.title} update={this.editLocation(lh.id, editLocation.id, currentParentId)} autoFocus={editLocation.id} />
                :
                <BSDrop title={selectedLocation.title || "Select..."} onClear={parentid && this.clickOption(lh.id, null)} onLongPress={this.setEdit(lh.id, selectedLocation.id, selectedLocation.title)}>
                  {options.map(l =>
                    <BSDropItem onClick={this.clickOption(lh.id, l.id)} key={l.id}
                    addonButton={<button className="addon-button" onMouseDown={this.deleteLocation(lh.id, l.id)}><span className="fs-trash" /></button>}
                    >
                      {l.title}
                    </BSDropItem>
                  )}
                  <BSDropItem onClick={this.setEdit(lh.id)}>
                    <span className="fs-plus-circle" /> Add New...
                  </BSDropItem>
                </BSDrop>
              }
            </FInfo>
          )
        })}

      </div>
    )*/
  }
}

/*addonButton={<SureButton className="addon-button" icon="fs-trash" onClick={this.deleteLocation(lh.id, l.id)} buttonText="Delete">Delete?</SureButton>}*/

/*addonClick={e=>this.clickOption(lh.id, l.id)().then(()=>this.setEdit(lh.id, l.id, l.title)())}*/

/*

{selectedLocation.id
  ?<div className="input-group-append">
    {selectedLocation.id && <SureButton key="delete" icon="fs-trash" onClick={this.deleteLocation(lh.id, selectedLocation.id)} buttonText="Delete">Delete?</SureButton>}

    <FButton icon="fs-pencil" onClick={this.setEdit(lh.id, selectedLocation.id, selectedLocation.title)}>
      Edit
    </FButton>
  </div>
  :null
}

*/


/*
//! Don't delete
class Item extends Container {

  componentDidMount() {
    super.componentDidMount();

    this.watchlist=["_locations"];
  }

  render () {
    //console.log("this.state.editLocation: ", this.state.editLocation);

    const children=this.children({props: {live: true}}) || [];

    //console.log("Item Children: ", this.props.children, [].concat(this.props.children).length, this.props.children?[].concat(this.props.children).length:0);

    return (
      <div className="Measure input-group" id={this.props.id} data-toggle="buttons" hidden={this.props.hidden}>

        <FInfo title="Reference" className="form-control">
          <FormText id={this.props.id+'_name'} name="name" className="FInfoMain" value={this.state.values.name} update={this.props.actions.setValue} placeholder="Item Reference" />
        </FInfo>
        <Location actions={this.props.actions} locationHierachy={locationHierachy} _locations={this.state._locations} selectedLocation={this.state.values.selectedLocation} />

        <div className="input-group-append btn-group-toggle">
          <ButtonModal modalClassName="AuditModal" scroll icon={"fs-clipboard"} title={this.state.values.name} buttonText="Audits" overlay={children.length}>
            <Auditor title="Item Audits" actions={this.props.actions} id={this.props.id} type="Item" itemid={this.props.parentid}>
              {children}
            </Auditor>
          </ButtonModal>

          <AttachDocModal id={this.props.id+'_attachDoc'} title={this.state.values.name+" Attachments"} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />

          <StButtons actions={this.props.actions} />
        </div>
      </div>
    )
  }
}
*/

//onShow={this.updateChildren}

/*
//!!Don't delete

class Items extends Component {
  constructor(props) {
    super(props);

    this.itemtype = [
      {type: 'Item', class: Item, title: 'Item', icon: (this.props.icon || 'fa fa-cube'), childTypes: []}
    ]
  }


  render() {

    return (
      <ButtonModal modalClassName="AuditModal" scroll icon={"fa fa-cube"} title="Items" buttonText="Items" overlay={[].concat(this.props.children).length}
        topButtons={<AddChildButtons childTypes={this.itemtype} addChild={this.props.actions.addChild} />}
      >
        {this.props.children}
      </ButtonModal>
    )
  }
}
*/

const lz = num => num < 10 ? `0${num}` : num;
const ukdate = ms => { if (!ms) return ''; const d = new Date(ms); return `${lz(d.getDate())}/${lz(d.getMonth() + 1)}/${d.getFullYear().toString().substring(2)}`; }


/* Categories / Escape Routes */
class Categories extends Container {
  constructor(props) {
    super(props);

    this.watchChildren=true;
    this.catupdate=false;
  }

  /*updateCategoryData = cid => cvalues => {
    this.setLocal(l => ({ categories: { ...l.categories, [cid]: cvalues } }));

    if (this.local?.categories && Object.keys(this.local.categories).length === this.state?.elements.length) {

      //Section is OK if at least one category is checked
      const sectionok = !!Object.values(this.local.categories).find(c => c.inplace);
      this.props.updateSectionOK(sectionok);
    }
  }*/

  defaultClick = childid => () => Promise.all(this.state.elements.map(el => this.props.actions.setChildValue(el, { default: childid === el })));

  /*componentDidMount = async () => {
    super.componentDidMount();

    //console.log("categories: ", await getCategories(this.props.rootid));

    //this.props.registerRootWatcher(doc => getCategories(this.props.rootid), "_categories", this.props.rootid);
    //if(this.state.values._categories) this.props.updateChangeWatchers({ _id: "_categories", _categories: this.state.values._categories }, true);
  }*/

  /*stateUpdate = (newstate, oldstate) => {
    console.log("Categories state update: ", newstate, oldstate);
  }*/

  runDelayedUpdate = (_categories) => {
    if(!this.catupdate) {
      this.catupdate = true;
      this.props.updateChangeWatchers({ _id: "_categories", _categories }, true);
      return;
    }

    window.clearTimeout(this.catupdate);

    this.catupdate = window.setTimeout(()=>{
      if(JSON.stringify(_categories) !== JSON.stringify(this.state.values._categories)) {
        this.props.updateChangeWatchers({ _id: "_categories", _categories }, true);
        console.log("Update categories..!");
      }
    }, 2000);
  }

  newUpdateCategoryData = () => {

    if(this.state.elements.some(el=>
      !this._childdata[el]
      ||
      (this._childdata[el].elements || []).some(ea=>!this._childdata[ea])
    )) return;

    const _categories = {
      id: this.props.id,
      type: this.props.type,
      children: this.state.elements.map(el=>({
        ...this._childdata[el],
        id: this._childdata[el]._id,
        name: this._childdata[el]?.values?.name,
        children: (this._childdata[el]?.elements || []).map(ea=>({
          ...this._childdata[ea], 
          id: this._childdata[ea].id,
          name: this._childdata[ea]?.values?.name
        }))
      }))
    }

    this.runDelayedUpdate(_categories);

    /*if(JSON.stringify(_categories) !== JSON.stringify(this.state.values._categories)) {
      //this.setValue({ _categories }); //! Insert directly into database??

      this.props.updateChangeWatchers({ _id: "_categories", _categories }, true);

      //console.log("Categories update: ", _categories);
      //this.props.updateChangeWatchers({ _id: "_categories", _categories }, true); //!to componentDidUpdate
      //console.log("New categories: ", JSON.stringify(_categories), JSON.stringify(this.state.values._categories));
    }*/
  }

  //Triggered by watchChildren
  childDidUpdate = (id, doc) => {
    //console.log("Child update: ", id, doc, this._childdata);

    this.newUpdateCategoryData();
  }

  componentDidUpdate(prevProps, prevState) {
    /*if(
      this.state.values._categories && 
      (!prevState.values._categories || JSON.stringify(this.state.values._categories) !== JSON.stringify(prevState.values._categories))
    ) {
      this.props.updateChangeWatchers({ _id: "_categories", _categories: this.state.values._categories }, true);
      console.log("categories - cDU: ", this.state.values._categories);
    }*/
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    //console.log("Categories state: ",this.state);
    //console.log("Child data: ", this._childdata);

    return (
      <div className="Categories" id={this.props.id}>
        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />
        {edit ?
          <div className="categorynew">
            <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />
          </div>
          : null}
        <div className="CategoryList">
          {this.children({
            props: { defaultClick: this.defaultClick, edit },
            /*propsIdFunc: { updateCategoryData: this.updateCategoryData }*/
          })}
        </div>
      </div>
    )
  }
}

const checkicon = val => val ? "fs-check-square-o" : "fs-square-o";

class Category extends Container {
  constructor(props) {
    super(props);

    this.radio = [
      { id: true, icon: checkicon, title: "" }
    ];

    //this._bind('updateCategories');

    //! Need to make this detick on siblings - go via Categories, rootwatcher - could also make first child default
    //this.defaultClick = e => this.props.actions.setValue({default: !this.state.values.default});
  }

  categoryClick = r => this.setValue('inplace', (this.state.values.inplace !== r));

  componentDidMount() {
    super.componentDidMount();

    //this.props.updateCategoryData(this.state.values);
  }

  /*componentDidUpdate(prevProps, prevState) {

    if (JSON.stringify(this.state?.values) !== JSON.stringify(prevState?.values)) {
      this.props.updateCategoryData(this.state.values);
    }
  }*/

  render() {
    const edit = (this.state.edit || this.props.edit);

    //if (!edit && this.state.values.hidden) return null;

    return (
      <div className={"card card-edit Category " + (edit ? "" : "live")} hidden={!edit && this.state.values.hidden}>
        <div className="card-header input-group btn-group-toggle" data-toggle="buttons">
          {/*{edit?<div className={"input-group-addon icon "+this.props.icon} />:null}*/}

          <div className="input-group-prepend btn-group-toggle" data-toggle="buttons">
            {this.radio.map(r =>
              <Radioaddon key={r.id} checked={this.state.values.inplace === r.id} icon={r.icon(this.state.values.inplace)} onClick={e => this.categoryClick(r.id)}>{r.title}</Radioaddon>
            )}
          </div>

          {edit ?
            <EditableA className="ztitle form-control title input-group-text" onChange={this.props.actions.renameMe} editFixedOn>
              {this.state.values.name || "Category"}
            </EditableA>
            :
            <span className={"ztitle form-control title input-group-text " + (this.state.values.inplace ? "selected" : "")}>{this.state.values.name || "Category"}</span>
          }

          <div className="input-group-append">
            {edit && <Radioaddon checked={this.state.values.hidden} icon={checkicon(this.state.values.hidden)} onClick={() => this.setValue('hidden', !this.state.values.hidden)}>Hide?</Radioaddon>}
            {edit && <Radioaddon checked={this.state.values.default} icon={checkicon(this.state.values.default)} onClick={this.props.defaultClick(this.props.id)}>Default</Radioaddon>}
            {/*edit && <CircleCheck icon="fs-check" title="Default" checked={this.state.values.default} classNameActive={"active"} name="default" onChange={this.defaultClick} />*/}
            {edit && <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />}

            <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />

            <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} />

            {edit && <StButtons actions={this.props.actions} />}
          </div>

        </div>
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />
        <div className="card-block" hidden={!edit}>
          {this.children({ props: { edit } })}
        </div>
      </div>
    )
  }
}

//!Should be editable
const escapeareatypes = [
  { id: 0, type: "Default" },
  { id: 1, type: "Seated Area" }
];

class EscapeArea extends Container {
  constructor(props) {
    super(props);

    //this._bind('updateCategories');

    this.routes = [
      { title: "2+", name: "escm", zones: ["H", "M", "L"] },
      { title: "1", name: "esc1", zones: ["H", "M", "L"] }
    ];
  }

  update = (name, value, subname) => {
    this.setValue(name, Object.assign({}, this.state.values[name] || {}, { [subname]: value }));
  }

  /*updateCategories() {

    getCategories(this.props.rootid)
    .then(cats=>this.props.updateChangeWatchers({_id: "categories", categories: cats}, true));
  }*/

  render() {

    const es = { escm: this.state.values.escm || { H: "", M: "", L: "" }, esc1: this.state.values.esc1 || { H: "", M: "", L: "" } }

    return (

      <div className="EscapeArea" id={this.props.id}>
        <div className="input-group" data-toggle="buttons">
          <EditableA onChange={this.props.actions.renameMe} className="ztitle form-control AreaName" editFixedOn={true}>
            {this.state.values.name || "Area Name"}
          </EditableA>

          <div className="input-group-append">
            <BSDrop icon="fs-logout" title={escapeareatypes[this.state.values.escapeareatype || 0].type} right>
              {escapeareatypes.map(r =>
                <BSDropItem onClick={e => this.props.actions.setValue("escapeareatype", r.id)} key={r.id}>
                  {r.type}
                </BSDropItem>
              )}
            </BSDrop>

            <span className="input-group-text">Routes (m):</span>
          </div>
          {this.routes.map(r => [
            <div key={r.name} className="input-group-prepend input-group-append">
              <span className="input-group-text"><span className="fs-logout" />{r.title}</span>
              {r.zones.map(z => [
                <span key={z + "t"} className="input-group-text input-group-prepend input-group-append">{z}</span>,
                <FormText key={z + "f"} name={r.name} value={es[r.name][z]} className="routelength" update={(name, value) => this.update(name, value, z)} />
              ])}
            </div>
          ])}

          {/*<HelpModal id={this.props.id+"_help"} edit={edit} className="input-group-addon" onChange={v=>this.getSetValue('help',v)} value={this.props.values.help} />*/}
          <div className="input-group-append">
            <StButtons actions={this.props.actions} />
          </div>
        </div>
      </div>
    )
  }
}

/*
Copies Audits for a given element ID, provides them for selection and adds selected audit as a child
*/
/*Don't delete
class Auditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemid: null,
      audits: null
    }
  }

  getAudits = (itemid=this.props.itemid) => {
    if(!itemid) return;

    //console.log("Auditor getAudits: ", itemid, this.props);

    //const ga = rid => 
      getAudits(itemid, this.props.noparent?false:undefined)
      //.catch(err=>console.log("Auditor error (getAudits): ", itemid, err))
      .then(audits=>this._mounted!==false && this.setState({audits: audits, itemid}))
      .catch(err=>console.log("Auditor error (getAudits): ", itemid, err))

     //if(this.props.parentroot)
      //getParentId(itemid)
      //.then(ga) //Start looking for Audits at the itemid parent level
      //.catch(err=>"Audit not found")
    //else
    //ga(itemid);
  }


  //static getDerivedStateFromProps(nextProps, prevState) {
  // nextProps.itemid!==prevState.itemid?{itemid: nextProps.itemid}:null
  //}

  componentDidMount() {
    this.getAudits();
  }

  componentDidUpdate(nextProps, prevState) {
    if(nextProps.itemid!==prevState.itemid) {
      this.getAudits(nextProps.itemid);
    }
  }

  componentWillUnmount() {
    this._mounted=false;
  }

  addAudit = (auditid) => {
    console.log("Add Audit: ",this.props.id, auditid);
    addAudit(this.props.id, auditid)
    .catch(err=>{
      console.log("add Audit failed: ",err);
    })
  }

  render() {

    //console.log("Auditor: ",this.props.id, this.props.itemid, this.state.audits);

    return (
      <div className="card card-edit Auditor">
        <div className="card-header input-group">

          <span className="form-control input-group-text">{this.props.title}</span>

          <div className="input-group-append">
            {this.state.audits?
              <BSDrop className="input-group-append" icon="fs-clipboard" right title="+" buttonText="Audit">
                {this.state.audits.map(a => 
                <BSDropItem onClick={e=>this.addAudit(a.id)} key={a.id}>
                  {a.name}
                </BSDropItem>
                )}
              </BSDrop>
            :null}
          </div>
            
        </div>
        <div className="card-block">
          {this.props.children}
        </div>
      </div>
    )
  }
}
*/

const locationHierachy = [
  { id: "cjlz22fke0000OK__4bw93zzo", title: "Building/Area" },
  { id: "cjlz22fke0001OK__545ef6dm", title: "Level/Section" },
  { id: "cjlz22fke0002OK__jxvn0mgr", title: "Room/Position" }
];


/**
 * Locations stuff
 */

/*const locationTitles = {
  Building: "Building/Area",
  Level: "Level/Section",
  Room: "Room/Position"
}*/

class LocationSurvey extends Survey {
  constructor(props) {
    super(props);

    //this.props.registerRootWatcher(doc=>getRiskActions(this.props.rootid), "_riskactions", this.props.rootid);

    //console.log("_locations: ", getLocations(this.props.rootid));

    this.state["levels"] = 0;
    this.state["buildingname"] = "";

  }

  expandIntro = () => this.setState({ introcollapsed: false });

  updateWiz = (n, v) => this.setState({ [n]: v });

  //Assuming only Building child
  newBuilding = (type = 'Building') => {
    const buildingname = this.state.buildingname || `${this.props.childTypes.find((typestype => typestype.type === type) || {}).title || type} ${this.state.elements.length + 1}`;

    this.addChildAndFlag(type, undefined, { name: buildingname }, undefined, { levels: Number(this.state.levels) });

    this.setState({ levels: 0, buildingname: "" });
  }

  updateSectionOK = () => {
    const hasBuildings = this.state?.children?.length;
    this.props.updateSectionOK(!!hasBuildings);
  }

  componentDidMount() {
    super.componentDidMount();

    this.updateSectionOK();
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state?.children.length !== prevState.children?.length) this.updateSectionOK();
  }

  render() {
    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit);

    return (
      <div id={this.props.id} className="LocationSurvey">

        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

        {edit &&
          <div className="input-group">
            <span className="form-control input-group-text" />

            <HelpModal id={this.props.id + "_uobhelp"} edit={edit} name="usehelp" onChange={this.props.actions.setValue} value={this.state.values.usehelp} buttonText="Use Help" title="Use of Building Help" />
            <Radioaddon checked={this.state.values.hideuse} icon={"fs-room-1"} overlay={<span className={this.state.values.hideuse ? 'fs-check' : 'fs-times'} />} onClick={e => this.setValue({ hideuse: !this.state.values.hideuse })}>Hide Use Of Building?</Radioaddon>

            <HelpModal id={this.props.id + "_conhelp"} edit={edit} name="constructionhelp" onChange={this.props.actions.setValue} value={this.state.values.constructionhelp} buttonText="Construction Help" title="Building Constrcution Help" />
            <Radioaddon checked={this.state.values.hideconstruction} icon={"fs-room-1"} overlay={<span className={this.state.values.hideconstruction ? 'fs-check' : 'fs-times'} />} onClick={e => this.setValue({ hideconstruction: !this.state.values.hideconstruction })}>Hide Construction?</Radioaddon>

            <Radioaddon checked={this.state.values.hiderooms} icon={"fs-room-1"} overlay={<span className={this.state.values.hiderooms ? 'fs-check' : 'fs-times'} />} onClick={e => this.setValue({ hiderooms: !this.state.values.hiderooms })}>Hide Rooms?</Radioaddon>
          </div>
        }

        <ButtonModal title="Add A Building" buttonText="Add A Building" addButton buttonOK={this.newBuilding} buttonOKDisabled={this.state.levels ? false : true}>
          <div className="addWizard">

            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Building name: </span>
              </div>
              <FormText name="buildingname" value={this.state.buildingname || ""} update={this.updateWiz} />
            </div>

            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Number of floors: </span>
              </div>
              <FormNumber name="levels" value={this.state.levels || ""} update={this.updateWiz} min="0" max="200" />
            </div>
          </div>
        </ButtonModal>

        {/*edit?
          <EditableA className="ztitle form-control" name="name" onChange={this.updateName} editFixedOn={true}>
            {this.state.values.name || "Building Summary"}
          </EditableA>
          :
          <div className={"ztitle form-control title "} >{this.state.values.name || "Building Summary"}</div>
        */}

        <Namer className="sectionTitle" edit={edit} name="name" value={this.state.values.name} defaultName="Building Summary" onChange={this.setValue} />

        <div className="SurveySections" id={this.props.id}>
          {this.children({
            props: {
              expand: this.expand,
              hiderooms: this.state.values.hiderooms,
              usehelp: this.state.values.usehelp,
              hideuse: this.state.values.hideuse,
              constructionhelp: this.state.values.constructionhelp,
              hideconstruction: this.state.values.hideconstruction
            },
            propsId: this.state.expanded ? { [this.state.expanded]: { expanded: true } } : false,
            orderChildrenByType: true
          })}
        </div>
      </div>
    )
  }
}

/*const Hider = ({ show = false, children, className = 'card-block' }) => (
  <div className={`Hider ${className} ${show ? 'open' : 'closed'}`}>
    {children}
  </div>
)*/

class LocationBuilding extends Container {

  componentDidMount() {
    super.componentDidMount();

    if (this.props.newchild) {
      this.props.expand(this.props.id)();
      //console.log("LocationBuilding Newchild ",this.props);

      if (this.props.levels && !this.state.elements.length) {
        //console.log("adding children...", this.props.childTypes);
        Promise.all([...Array(this.props.levels)].map((l, i) => this.props.actions.addChild(this.props.childTypes[0].type, undefined, { name: `${this.props.childTypes[0].title} ${i}` })));
      }
    }
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    const children = this.children({ props: { hiderooms: this.props.hiderooms } });

    return (
      <div className="LocationBuilding SurveySection SubSection card card-edit">
        <div className="input-group card-header edit" data-toggle="buttons">
          <div className="form-control input-group-text" onClick={this.props.expand(this.props.id)}>
            {this.props.expanded ?
              <EditableA className="ztitle toplevel" name="name" onChange={this.props.actions.setValue} editFixedOn={true} onClick={e => { if (!this.props.expanded) this.props.expand(this.props.id)(e) }} editOnRender={this.props.newchild}>
                {this.state.values.name || "Building"}
              </EditableA>
              :
              <span className="ztitle toplevel">
                {this.state.values.name || "Building"}
              </span>
            }
          </div>

          <div className="input-group-append">
            {(this.props.expanded || edit) && <AddChildButtons childTypes={this.props.childTypes} addChild={this.addChildAndFlag} />}
            {/*(this.props.expanded || edit) && <AttachDocModal id={this.props.id+'_attachDoc'} title={(this.state.values.name || "Floor")+" Attachments"} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />*/}
            {(this.props.expanded || edit) && <StButtons actions={this.props.actions} up={false} down={false} />}
            {!this.props.expanded &&
              <FInfo title={"Floor" + (children && children.length && children.length === 1 ? "" : "s")} className="fullsize bold">
                {children && children.length}
              </FInfo>
            }

            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
        {/*<div className={"card-block"} hidden={!(this.props.expanded || edit)}> */}
        {/*<Hider show={(this.props.expanded || edit)}>*/}
        <ShowContainer className="card-block" show={(this.props.expanded || edit)}>
          <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.setValue} value={this.state.values.intro} />

          {!this.props.hideuse && <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Use of building: </span>
              {this.props.usehelp && <ButtonModal modalClassName="" icon={"fs-help"} title="Use of Building Help" buttonClass="small" vcenter>{this.props.usehelp}</ButtonModal>}
            </div>
            <FormTextArea name="buildinguse" value={this.state.values.buildinguse || ""} update={this.setValue} />
          </div>}

          {!this.props.hideconstruction && <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Construction: </span>
              {this.props.constructionhelp && <ButtonModal modalClassName="" icon={"fs-help"} title="Building Construction" buttonClass="small" vcenter>{this.props.constructionhelp}</ButtonModal>}
            </div>
            <FormTextArea name="buildingcons" value={this.state.values.buildingcons || ""} update={this.setValue} />
          </div>}

          {children}

        </ShowContainer>
        {/*</Hider>*/}
        {/*</div>*/}
      </div>
    )
  }
}

class LocationLevel extends Container {
  render() {
    //const edit = (this.state.edit || this.props.edit);

    return (
      <div className="LocationLevel Risk" id={this.props.id}>
        <div className="card">
          <div className="card-header ">
            <div className="input-group" data-toggle="buttons">
              <EditableA onChange={this.props.actions.renameMe} editFixedOn className="ztitle form-control solid">
                {this.state.values.name || "Floor"}
              </EditableA>

              <div className="input-group-append">
                {!this.props.hiderooms && <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />}

                {/*<AttachDocModal id={this.props.id+'_attachDoc'} title={(this.state.values.name || "Floor")+" Attachments"} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />*/}
                <StButtons actions={this.props.actions} up={false} down={false} />
              </div>
            </div>
          </div>
          <div className="card-block">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Description: </span>
              </div>
              <FormTextArea name="description" value={this.state.values.description || ""} update={this.setValue} />
            </div>
            {/*<InlineHelp id={this.props.id+"_intro"} edit={edit} name="intro" onChange={this.setValue} value={this.state.values.intro} />*/}
            {this.children()}
          </div>
        </div>
      </div>
    )
  }
}

class LocationRoom extends Container {
  render() {
    return (
      <div className="LocationRoom Measure input-group" data-toggle="buttons" id={this.props.id}>
        <div className="ztitle form-control Measurename">
          <EditableA onChange={this.props.actions.renameMe} editFixedOn>
            {this.state.values.name || "Room"}
          </EditableA>
        </div>

        <div className="input-group-append btn-group-toggle">
          {/*<AttachDocModal id={this.props.id+'_attachDoc'} title={this.state.values.name+" Attachments"} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />*/}

          <StButtons actions={this.props.actions} up={false} down={false} />
        </div>
      </div>
    )
  }
}

class LicenseSurvey extends Survey {

  isLicenseClick = n => {
    this.props.actions.setValue({ 'licenses': this.state.values.licenses === n ? null : n });
  }

  componentDidMount() {
    super.componentDidMount();

    const rWA = doc => getLicenceActions(this.props.rootid);
    this.props.registerRootWatcher(rWA, "_licenceactions", this.props.rootid);
  }

  render() {
    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit);

    return (
      <div id={this.props.id} className="LicenseSurvey">

        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

        <div className="SurveySections">
          <div className="card card-edit">
            <div className={"card-header input-group " + (this.state.values.licenses ? "liverisk" : "")}>

              {/*<div className="input-group" data-toggle="buttons">*/}

              {/*<span className="form-control input-group-text">Licenses</span>*/}
              {edit ?
                <EditableA className="form-control input-group-text" name="name" onChange={this.props.actions.setValue} editFixedOn={true}>
                  {this.state.values.name || "Licences"}
                </EditableA>
                :
                <div className={"form-control title input-group-text "} >{this.state.values.name || "Licences"}</div>
              }

              <div className="input-group-append">

                {(this.props.expanded || edit) && <HelpModal id={this.props.id + "_help"} edit={edit} onChange={v => this.props.actions.setValue('help', v)} value={this.state.values.help} />}

                <CircleCheck icon="fs-check" title="Yes" checked={this.state.values.licenses === true} classNameActive={"active"} name={true} onChange={this.isLicenseClick} />
                <CircleCheck icon="fs-times" title="No" checked={this.state.values.licenses === false} name={false} onChange={this.isLicenseClick} />

              </div>
              {/*</div>*/}
            </div>
            <div className="card-block" hidden={this.state.values.licenses !== true}>

              <InlineHelp id={this.props.id + "_intro2"} edit={edit} name="intro2" onChange={this.props.actions.setValue} value={this.state.values.intro2} collapsed={this.state.introcollapsed} expand={this.expandIntro} />

              <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} addButton />

              <div className="SurveySections" id={this.props.id}>
                {this.children()}
              </div>

            </div>
          </div>
        </div>

      </div>

    )
  }
}

class License extends Container {

  setCompliant = (name) => {
    this.props.actions.setValue('compliant', name)
  }

  render() {
    const edit = (this.state.edit || this.props.edit);

    return (
      <div className="Measure input-group" data-toggle="buttons" id={this.props.id}>

        <div className={"ztitle form-control toplevel"}>
          <EditableA onChange={this.props.actions.renameMe} editFixedOn>
            {this.state.values.name || "New License"}
          </EditableA>
        </div>

        <div className="form-container"><span className="input-group-text">Expires:</span><FormText type="date" name="expires" update={this.props.actions.setValue} value={this.state.values.expires} /></div>

        <div className="input-group-append btn-group-toggle">
          <span className="input-group-text">Fire safety compliant? </span>

          <CircleCheck icon="fs-check" title={'Yes'} checked={this.state.values.compliant === 'yes'} name={'yes'} onChange={this.setCompliant} />
          <CircleCheck icon="fs-times" title={'No'} checked={this.state.values.compliant === 'no'} name={'no'} onChange={this.setCompliant} />
          <CircleCheck icon="fs-ban" title={'N/A'} checked={this.state.values.compliant === 'na'} name={'na'} onChange={this.setCompliant} />

          <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} />
          <AttachDocModal id={this.props.id + '_attachDoc'} title={`Attachments for : ${this.state.values.name}`} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />

          <StButtons actions={this.props.actions} up={edit} down={edit} />
        </div>
      </div>
    )
  }
}


class LoginPane extends Component {
  constructor(props) {
    super(props);

    this.state = { email: this.props.email || '', password: "", message: "", loggingin: false, showpassword: false }

    /*this.changeField = this.changeField.bind(this);
    this.login = this.login.bind(this);*/
  }

  changeField = (field, value) => {
    //!Add check for well-formed email address
    this.setState({ [field]: value });
  }

  componentWillUnmount() {
    this._mounted = false; //This is bad
  }

  login = async e => {
    this.setState({ message: "", loggingin: true });

    /*const li =*/ await this.props.userlogin(this.state.email, this.state.password, this.props.adminsite);
    /*.then(()=>{ //!!remove??
      this.props.login();
    })*/
    //.catch(err=>this.setState({message: "Login error: "+err.message, loggingin: false})); !!
    //.then(()=>this.setState({loggingin:false}));
    //this.props.login();

    if (this._mounted !== false) this.setState({ loggingin: false });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (!this.state.email && this.props.email && prevProps.email !== this.props.email) {
      this.setState({ email: this.props.email });
    }
  }

  keyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.login(e);
    }
  }

  render() {
    const ie = isItIE();

    //console.log("LoginPane props, state: ", this.props, this.state);

    return (
      <form id={this.props.adminsite ? 'adminloginform' : 'loginform'}>

        {/*<Logo />*/}

        <div className="Login">
          {ie &&
            <div className="alert alert-warning">
              <div>
                <span className="fs-exclamation-triangle" /> {`${process.env.APPNAME || 'Firesmart'}`} will not work on your browser. Please switch to <a href={`microsoft-edge:${window.location.href}`}>Microsoft Edge</a> or Google Chrome.
            </div>
            </div>}

          <img src={fslogo} alt={`${process.env.APPNAME || 'Firesmart'} Logo`} />

          <div className="Login-main">
            {(this.props.message || this.state.message) && <div className="alert alert-primary">{this.props.message || this.state.message}</div>}

            {this.props.adminsite &&
              <div className="form-group">
                <label htmlFor="loginadminsite">Admin Site Login</label>
                {/*<FormText name="email" type="email" value={this.state.email} update={this.changeField} autoComplete />*/}
                <input id="loginadminsite" type="text" name="adminsitename" defaultValue={this.props.adminsitename} className="form-control" readOnly />
              </div>
            }

            <div className="form-group">
              <label htmlFor="loginemail">{this.props.adminsite ? 'Username' : 'Email'}</label>
              {/*<FormText name="email" type="email" value={this.state.email} update={this.changeField} autoComplete />*/}
              <input id="loginemail" type="email" name="email" value={this.state.email} className="form-control" onChange={e => this.setState({ "email": e.target.value })} />
            </div>

            <div className="form-group">
              <label htmlFor="loginpassword">Password</label>
              {/*<FormText name="password" type="password" value={this.state.password} update={this.changeField} autoComplete />*/}
              <input id="loginpassword" type={this.state.showpassword?"text":"password"} name="password" value={this.state.password} className="form-control" onChange={e => this.setState({ "password": e.target.value })} onKeyPress={this.keyPress} />
              
            </div>

            <div className="form-check form-check-inline">
              <input id="showpassword" type="checkbox" name="showpassword" value={this.state.showpassword} className="form-check-input" onChange={e => this.setState(v=>({"showpassword": !v.showpassword }))} />
              <label className="form-check-label" htmlFor="showpassword">Show Password?</label>
            </div>


          </div>
          <div className="Login-footer">
            <button type="button" className="btn btn-danger" style={lbcol?{backgroundColor: lbcol, borderColor: lbcol}:{}} onClick={this.login}><span className={this.state.loggingin ? "fs-spinner fs-pulse" : ""} /> Log In</button>
          </div>

          <div className="Login-subfooter">
            {/*<a href={`${adminserver}/users/resetpassword${this.state.email ? `?email=${this.state.email}` : ''}`}>Lost your password</a>*/}
            <a href={`/users/resetpassword${this.state.email ? `?email=${this.state.email}` : ''}`}>Lost your password</a>
          </div>

        </div>
      </form>
    )
  }
}

//const OrgPane = props => renderitems(neworgtypes,orgitems,"organisation",userDB,userDB_sync);

class OrgPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: false,
    }

  }

  load = () => {
    userItems(orgtypes, "organisation", { functions: { loadSite: this.props.loadSite } })
      .then(items => {
        //console.log("userItems return: ", items);
        //console.log("Orgpane items: ", items);
        if (this._mounted !== false) this.setState({ items: items })
      })
      .catch(err => {
        console.log("Failed to render items!", err);
        this.setState({ items: <div className="alert alert-danger">Error loading items!</div> })
      })
  }

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {

    if (this.state.items) {
      return this.state.items;
    }
    else return (
      <Loading />
    )
  }
}

class SitePane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      siteid: false,
      items: false,
    }

    /*this.updateItems = () => {
      if(this.state)
        this.setState({items: false},res=>{
          this.renderItems();
        });
      else this.renderItems();
    }*/
  }

  renderItems = async (siteid) => {
    //console.log("Render site items: ", siteid, this.state.siteid);

    const items = await siteItems(sitetypes, "site", {
      functions: {
        loadSurvey: this.props.loadSurvey,
        addSurvey: this.props.addSurvey,
        cloneSurvey: this.props.cloneSurvey,
        importSurvey: this.props.importSurvey,
        downloadSurvey: this.props.downloadSurvey,
        deleteSurvey: this.props.deleteSurvey,
      },
      ...(this.props.props || {}),
      siteid: siteid || this.props.siteid
    })

    //console.log("Site items! ", items);

    if (this._mounted !== false) this.setState({ items }) //U G L Y
  }

  /*componentWillReceiveProps(newProps) {
    //console.log("SitePane componentWillReceiveProps");
    if(this.state && newProps.siteid && newProps.siteid!==this.props.siteid) this.updateItems();
  }*/

  componentDidUpdate(prevProps, prevState) {

    if (this.props.siteid !== prevProps.siteid || (this.props.siteid && !prevProps.siteid)) {
      //this.setState({siteid: this.props.siteid, items: false});
      this.renderItems(this.props.siteid);
    }

    if (this.props.admin !== prevProps.admin && prevState.items && prevState.items.length) {
      this.setState({ items: [React.cloneElement(prevState.items[0], { admin: this.props.admin }, null)] });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  /*static getDerivedStateFromProps(newProps, oldState) {
    return (newProps.siteid && oldState.siteid!==newProps.siteid)?{siteid: newProps.siteid, items: false}:null;
  }*/

  //static getDerivedStateFromProps()

  componentDidMount() {
    this.renderItems(this.props.siteid);
  }

  render() {
    //const siteid=this.props.siteid;

    //console.log("SitePane: ", this.props, this.state);

    const siteid = this.state.siteid || '[site]';

    return (
      <div className="SitePane" id={"sitepane-" + siteid}>
        <FSMarkdown markup={homeintro} />

        {this.state.items || <Loading />}
      </div>
    )
  }
}

/*
const renderlist={};

const rerender = title => {
  console.log("Rerender!",title)
  if(typeof renderlist[title]==="function") {
    console.log("Executing rerender...")
    renderlist[title]();
  }
}
*/

class RiskPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: false,
      surveyid: this.props.surveyid
    }

    this.types = this.props.types;
  }

  componentDidMount() {
    this.renderItems(this.props.surveyid);
  }

  renderItems = async (surveyid) => {

    const items = await siteItems(this.types, surveyRootId(this.types, surveyid || this.props.surveyid), this.props.props);
    if (items && this._mounted !== false) this.setState({ items });

  }

  componentDidUpdate(prevProps = {}, prevState = {}) {

    if (this._mounted === false) return;

    if ((this.props.surveyid !== prevProps.surveyid && this.props.surveyid) || !(prevState.items && prevState.items.length)) {
      this.renderItems(this.props.surveyid);
    }
    else {
      const np = JSON.stringify(this.props.props) !== JSON.stringify(prevProps.props)

      if (np) {
        if (prevState.items && prevState.items.length) {
          this.setState({ items: [React.cloneElement(prevState.items[0], this.props.props || {}, null)] });
        }
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false; //This is bad
  }

  render() {
    return (
      <div className="SitePane">
        {this.state.items || <Loading />}
      </div>
    )
  }
}

//const isin = (thing, list) => list.indexOf(thing)>-1;

//const isAreaMeasure = item => ['AreaEquipment','AreaExtinguisher'].includes(item.type);

const isMeasure = item => item.type === 'Measure' || item.type === 'PrecautionMeasure' || item.type === 'Type'; // || isAreaMeasure(item);

const Til = props => {

  //console.log("Til: ",props);

  return (
    <div>
      {(isMeasure(props.items)) && props.items.values.inplace !== true ? //|| isAreaMeasure(props.items)
        <div className={"asmMeasure" + (props.items.values.closed ? " closed" : "")}>
          {props.items.values.closed ? <span className="asmClosed"><span className="fs-check-square-o" /> Closed</span> : <button className="asmCloseButton" onClick={e => props.closeClick(props.items.id)}><span className="fs-square-o" /> Close</button>}
          <span>{props.items.values.closed ? (props.items.values.inplacetext || props.items.values.name) : props.items.values.name} <strong>Priority: {props.items.values.priority}</strong></span>
        </div>
        :
        <div>{props.items.values.questiontext || props.items.values.name} [{props.items.type}]</div>
      }
      <ul>
        {props.items.children.map(child =>
          <li key={child.id}><Til items={child} closeClick={props.closeClick} /></li>
        )}
      </ul>
    </div>
  )
}


const aspratings = priorityratings.filter(pr => pr.id).concat({ id: -1, text: 'Closed actions', display: 'X', color: '#888' });

const mat_cons = ['', 'Slightly Harmful', 'Harmful', 'Extremely Harmful']
const mat_haz = ['', 'Low', 'Medium', 'High']
const mat_rl = ['', 'Trivial Risk', 'Tolerable Risk', 'Moderate Risk', 'Substantial Risk', 'Intolerable Risk']

const mal_rl_color = ['', '#bfff00', '#fff8d8', '#ffdd76', '#ffc000', '#dc3545']

const grl = (hazard, cons) => hazard && cons ? hazard + cons - 1 : 0;

const Matrix = ({ hazard, cons, riskLevels = [] } = {}) => (
  <div className="Matrix">
    {mat_cons.map((c, ci) =>
      <div key={ci}>{c}</div>)
    }

    {mat_haz.map((h, hi) =>
      hi
        ? mat_cons.map((c, ci) =>
          c
            ? <div key={`${hi}_${ci}`} className={`rl${grl(hi, ci)} ${hi === hazard && ci === cons ? 'active' : ''}`} style={{ backgroundColor: (riskLevels[grl(hi, ci)] || {}).color || mal_rl_color[grl(hi, ci)] }}>{mat_rl[grl(hi, ci)]}</div>
            : <div key={`${hi}_${ci}`}>{h}</div>
        )
        : ''
    )}
  </div>
)

const MButtons = ({ list, name, value, onChange }) => (
  <div className="MButtons">
    <div>
      {list.filter(v => v).map((l, li) =>
        <CircleCheck key={`${name}_${li + 1}`} icon="fs-check" inline title={l} checked={li + 1 === value} name={name} onChange={() => onChange(name, li + 1 === value ? null : li + 1)} />
      )}
    </div>
  </div>
)

const mrSetValue = (setValue, riskLevels, lev) => (n, v) => setValue('riskLevels', mat_rl.map((rl, i) => (
  rl
    ? lev === i
      ? { color: mal_rl_color[i], ...(riskLevels[i] || {}), rl, [n]: v }
      : riskLevels[i] || { rl, color: mal_rl_color[i] }
    : {}
)))

const MatrixRiskLevels = ({ actions = {}, riskLevels = [] } = {}) => {

  return (
    <ButtonModal modalClassName="AuditModal" scroll icon="fs-clipboard" title="Risk Levels" buttonText="Levels">
      {mat_rl.map((rl, i) => {

        const rlev = riskLevels[i] || {};

        return (
          rl
            ? <div className="input-group" key={i}>
              <div className="input-group-prepend">
                <span className="input-group-text" style={{ backgroundColor: (rlev.color || mal_rl_color[i]) }}>{rl}</span>
                <FormText placeholder="Color" name="color" update={mrSetValue(actions.setValue, riskLevels, i)} value={rlev.color || mal_rl_color[i]} />
              </div>
              <FormTextArea placeholder="Action & Timescale" name="description" update={mrSetValue(actions.setValue, riskLevels, i)} value={rlev.description} />
            </div>
            : ''
        )
      })}
    </ButtonModal>
  )
}

/* Get a today's date in a format suitable for <input> */
const nowInputValue = () => {
  var local = new Date();
  local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};

//Action Types
const atypes = [
  '_riskactions',
  '_precautionmeasures',
  '_extinguishers',
  '_escaperoutes',
  '_licences',
  '_sitesections'
];


class ActionSummary extends Container {
  constructor(props) {
    super(props);

    this.state['reportloading'] = false;
    this.state["expanded"] = this.props.expandid || false;
    this.state["introcollapsed"] = false;

    this.state["assessmentcompletedid"] = false;
  }

  //Filter incoming areaActions to remove irrelevant measures/other elements
  //This would be a global action, but there is only ever one ActionSummaryPane
  actionFilter = (item, risk) => {

    if (!item || !item.type) return false;

    //console.log("af: ",item,risk);

    if (isMeasure(item)) { //If Measure, filter out measures hidden by different Risk selections //|| isAreaMeasure(item)
      /*if(item.id==="Measure-ckd8t4bw9005sndzu83fc1o9b") {
        console.log("item: ", item, this.state._categories, categoryshow(this.state._categories, item.values.categories));
      }*/
      /*??if(!categoryshow(this.state._categories, item.values.categories, this.props.id)) {
        //console.log("Category reject item: ", item);
        return false;
      }*/
      if (item.values.cathide) return false;
      if (
        (
          (Number(risk) === 2) ||
          (typeof risk === "undefined")
        )
        &&
        (
          (
            (item.values.action || ((item.values.priority === 1 || item.values.priority === 2) && !item.values.na))
            && (item.values.inplace !== true)
          )
          ||
          (item.children && item.children.length)
        )
      ) {
        const fc = item.children.map(c => this.actionFilter(c)).filter(c => typeof c === "object");
        return fc.length
          ? Object.assign({}, item, { children: fc })
          : item;
        //return item;
      }
      return false;
    }
    //??else if(item.type==="SurveySection" && this.state && this.state._categories && !categoryshow(this.state._categories, item.values.categories, this.props.id)) return false; //Hide SurveySections hidden by Category selections
    else if (item.type === "SurveySection" && this.state && this.state._categories && item.values.cathide) return false; //Hide SurveySections hidden by Category selections
    else {
      //Pass "risk" value only if this item sets it
      const r = ["Risk", "Equipment"].includes(item.type) ? item.values.risk : undefined;

      //??if(!categoryshow(this.state._categories, item.values.categories, this.props.id)) return false;
      if (item.values.cathide) return false;

      //console.log("Category check: ", item.id, this.state._categories, item.values.categories);

      //Filter out prototype Audits then recurse actionFilter, filtering empty returns
      const fc = item.children.filter(c => !(c.type === "Audit" && item.type !== "Item")).map(c => this.actionFilter(c, r)).filter(c => typeof c === "object");
      return fc.length
        ? Object.assign({}, item, { children: fc })
        : false;
    }
  }

  //Extract measures (including audit measures) from actionFilter list
  getMeasures = (items, pane) => {

    const measures = [];

    const rec = (item, titles = [], audit = false, imgparent = false) => {
      const o = []; //, ms=[];

      const title = item && item.values && (item.values.questiontext || item.values.name);

      const outtitle = title
        ? audit && titles.length
          ? titles.slice(0, -1)
          : titles.concat(title)
        : titles;

      const attimage = item._attachments && Object.keys(item._attachments).find(key => item._attachments[key] && item._attachments[key].content_type.startsWith("image"));

      const imp = attimage ? { docid: item.id, imgid: attimage } : imgparent;

      item.children && item.children.forEach(c => {
        if (isMeasure(c)) {

          //ms.push(c);
          if (imp) this.getImgAttachment(imp);
          if (c._attachments) {
            const mattimage = Object.keys(c._attachments).find(key => c._attachments[key] && c._attachments[key].content_type.startsWith("image"))

            if (mattimage) this.getImgAttachment({ docid: c.id, imgid: mattimage });
          }

          //Is this measure immediately below a Section?
          const highlevel = c.grandparentid === c.rootid;

          const m = {
            id: c.id,
            //title: audit && titles.length?titles[titles.length-1]:title, 
            title: c.values.name,
            titlepath: audit ? outtitle.concat(title + " " + ukdate(c.when)) : titles,
            measure: c,
            priority: (c && c.values && c.values.closed ? -1 : ((c && c.values && c.values.priority) || 2)),
            imgparent: imp,
            section: highlevel ? c.parentid : c.grandparentid,
            parentid: highlevel ? c.id : c.parentid,
            closeable: true,
            pane
          };

          measures.push(m);
        }
        else o.push(c);
      });

      o.forEach(oo => rec(oo, outtitle, (oo && oo.type === "Audit"), imp));
    }

    rec(items);

    return measures;
  }

  //Passes action list through filter before setting state
  runFilter = (type, pane) => (actions, returnmeasures = false) => {

    this.runStateUpdate({ [`${type}_raw`]: actions });

    const caf = this.actionFilter(actions[type]);

    //console.log("af: ", actions, caf);

    const m = this.getMeasures(caf, pane);
    if (returnmeasures) return m;

    this.runStateUpdate({ [type]: m });

    //this.props.actions.setValue({[type]: m});

    this.updateMeasureList({ [type]: m });
  }

  //Extinguisher Calculator

  //Filter Locations hierarchy and build virtual measures
  runECFilter = locations => {

    const m = [];

    const loc = locations._locations || {};

    //console.log("EC Filter: ", loc);

    (loc || []).forEach(b => { //Buildings
      b && /*!b.extexplanation &&*/ (b.levels || []).forEach(l => {
        if (l) {

          //if(l.hasSubActions) console.log("EC subactions: ", l.ecsubfields);

          if ((l.extinguisherpriority && !b.extexplanation) || l.hasSubActions) {
            const s = l.shortfall || 0;

            m.push({
              id: l.id,
              priority: l.extinguisherpriority || 2,
              questiontext:
                [
                  l.extinguisherpriority && s !== true ? `\n- Purchase and install additional extinguishers to cover the shortfall: ${s} Extinguisher${s === 1 ? "" : "s"} (13A equiv)` : '',
                  l.extinguisherpriority && s === true ? '\n- The area of this floor has not been entered - cannot check A-rated extinguisher coverage' : '',
                  l.hasSubActions && `\n- ${Object.values(l.ecsubfields || {}).join('\n- ')}`
                ].filter(v => v).join(''),
              titlepath: [],
              title: [b.name, l.name].join('> '),
              riskident: [
                l.extinguisherpriority
                  ? ((typeof l.area === 'undefined' || l.area === '')
                    ? 'Extinguisher data missing'
                    : 'There is a shortage in A-rated extinguishers') + `: ${b.name}> ${l.name}`
                  : '',
                l.hasSubActions ? 'Extinguisher Actions Required' : ''
              ].filter(v => v).join(', '),
              measure: l.measure || {},
              rootid: `EquipmentSurvey-${idFromSurveyRoot(this.props.rootid)}`, //`ec_${loc.rootid}`,
              parentid: b.id,
              pane: 'equipment',
              section: b.section
            })
          }
        }
      })
    })

    this.runStateUpdate({ '_extinguishers': m });
    //this.props.actions.setValue({_extinguishers: m});
    this.updateMeasureList({ _extinguishers: m });

    //console.log("_extinguishers: ", m);
  }

  runEscapeRouteFilter = escaperoutes => {

    //? const gs = (((escaperoutes || {})._escaperoutes || {}).globalsummary || []);
    const ls = (((escaperoutes || {})._escaperoutes || {}).locations || []);
    const m = [];

    ls.forEach(l => {

      (l.routes || []).forEach(r => {
        if (r) {

          if (r.routeOK) return;

          const rs = r.routesummary || {};
          let rout = [];

          let priority = 999;

          //Subfields
          routecats.forEach(rc => {
            const rso = typeof rs[rc.id] === 'object';

            if (rs[rc.id] === null) {
              if (rc.priority < priority) priority = rc.priority;

              rout.push(rc.title + ' - question unanswered');
            }
            else if (rs[rc.id] === false || rso) {
              if (rc.priority < priority) priority = rc.priority;

              rout.push(rc.problem + (rso ? ': ' + Object.values(rs[rc.id]).join(', ') : ''));
            }
          })

          if (!r.lengthok) {
            if (r.lengthok === false) rout.push('Route is too long');
            else if (r.lengthok === null) rout.push('Route length has not been entered');
            priority = 1;
          }

          //if(rout.length) rout=[r.name].concat(rout);

          if (priority < 999) m.push({
            id: r.id,
            priority: priority,
            questiontext: rout.join('\n'),
            titlepath: ['Escape Routes'],
            title: l.name,
            riskident: `Problems on Escape Route: ${l.name}> ${r.name}`,
            measure: l || r.measure || {},
            rootid: ((escaperoutes || {})._escaperoutes || {}).rootid,
            pane: 'equipment',
            section: ((escaperoutes || {})._escaperoutes || {}).section
          })

        }

      })

    })

    //console.log("er m: ", m);

    this.runStateUpdate({ '_escaperoutes': m });

    //this.props.actions.setValue({_escaperoutes: m});

    this.updateMeasureList({ _escaperoutes: m });
  }

  runLicenceFilter = licences => {
    let m = [];

    //console.log("licences: ", licences);

    if (licences._licenceactions && licences._licenceactions.values && licences._licenceactions.values.licenses) {
      m = licences._licenceactions.children.filter(lc => ((lc || {}).values || {}).compliant === 'no').map(lc => ({
        id: lc.id,
        priority: 2,
        questiontext: 'Ensure fire safety compliance',
        titlepath: ['Licences'],
        title: lc.values.name,
        riskident: '',
        measure: lc.measure,
        rootid: licences._licenceactions.rootid,
        pane: 'assinfo',
        section: 'LicensesSection*'
      }))
    }

    this.runStateUpdate({ '_licences': m });

    //this.props.actions.setValue({_licences: m});

    this.updateMeasureList({ _licences: m });
  }

  runSectionFilter = sitesections => {
    //console.log("sitesections filter: ", sitesections);

    let m = [];

    const ss = (sitesections?._sitesections || []).filter(s => !s.ok);

    m = ss.map(s => ({
      id: s.id,
      priority: 1,
      titlepath: [],
      title: `Incomplete Section`,
      questiontext: `Please complete your ${s.title} section`,
      riskident: '',
      pane: s.pane,
      section: s.id,
      measure: {},
      sectioncheck: true
    }))


    this.runStateUpdate({ '_sitesections': m });

    //this.props.actions.setValue({_licences: m});

    this.updateMeasureList({ _sitesections: m });
  }

  //this.props.registerChangeWatcher(this.runEscapeRouteFilter, "_escaperoutes");


  //getMeasureList = (risks, precautions, extinguishers, escaperoutes, licenses) => 
  getMeasuresList = measures => {
    //const measures=[].concat(risks || [],precautions || [], extinguishers || [], escaperoutes || [], licenses || []);

    return aspratings.map(pr => (
      {
        id: pr.id,
        title: pr.text,
        mobiletitle: pr.mobiletext,
        color: pr.color,
        measures: measures.filter(m => m.priority === pr.id)
      }
    ))
  }

  updateMeasureList = async (mobj = {}) => {

    if (this._mounted === false) return;

    //console.log("uML mobj: ", mobj);

    this.setState({ measures: false });

    let measureArray = [];
    const measureObj = {};

    atypes.forEach(t => {
      measureObj[t] = mobj[t] || this.state[t];
      if (measureObj[t]) measureArray = measureArray.concat(measureObj[t]);
    })

    /*
    const _riskactions = mobj._riskactions?mobj._riskactions:this.state._riskactions;
    const _precautionmeasures = mobj._precautionmeasures?mobj._precautionmeasures:this.state._precautionmeasures;
    const _extinguishers = mobj._extinguishers?mobj._extinguishers:this.state._extinguishers;
    const _escaperoutes =  mobj._escaperoutes?mobj._escaperoutes:this.state._escaperoutes;
    const _licences = mobj._licences?mobj._licences:this.state._licences;
    */

    if (measureObj._riskactions && measureObj._precautionmeasures && this.props.selected === true) //_extinguishers && _escaperoutes && _licences
    {

      const s = await getSections(this.props.rootid, sectiontypelist) || [];

      const measures = this.getMeasuresList(
        measureArray.concat(s)
        /*_riskactions, 
        _precautionmeasures, 
        _extinguishers, 
        _escaperoutes, 
        _licences*/
      );

      if (this._mounted !== false) {
        this.setState({ measures });

        //if(JSON.stringify(_riskactions))

        const updateObj = {};
        let doUpdate = false;

        atypes.forEach(t => {
          if (measureObj[t] && JSON.stringify(measureObj[t]) !== JSON.stringify(this.state.values[t])) {
            updateObj[t] = measureObj[t];
            doUpdate = true;
            //console.log("Update ", t, measureObj[t], this.state.values[t]);
          }
        })

        if (!this.state.values['_sitesections'] || JSON.stringify(s) !== JSON.stringify(this.state.values['_sitesections'])) {
          updateObj['_sitesections'] = s;
          doUpdate = true;
        }

        if (doUpdate) {
          console.log("Write to database!");
          this.props.actions.setValue(updateObj);
        }
      }
    }
  }

  reportButtonNew = async e => {
    //this.setState({reportloading: true});

    this.setState({ generating: true, generatingtext: '' });

    const response = await fetch(`${adminserver}/sites/report?site=${this.props.siteid}&survey=${this.props.surveyid}`, { mode: "cors", cache: "no-cache" })
    //const response = await fetch(`http://localhost:3010/sites/report?site=${this.props.siteid}&survey=${this.props.surveyid}`, {mode: "cors", cache: "no-cache"})
    const res = await response.json();

    //this.setState({reportloading: false});
    console.log("Fetch result: ", res);

    this.setState({ generatingtext: res.result });

    //window.open(`/admin/sites/report?site=${this.props.siteid}&kit&survey=${this.props.surveyid}`);
  }

  testReportButton = e => {
    window.open(`${adminserver}/sites/report?site=${this.props.siteid}&kit&survey=${this.props.surveyid}`);
  }

  getImgAttachment = docimg => {
    siteAttachments(docimg.docid).get()
      .then(imgs => {
        if (imgs) {
          //const i = {["img_"+docimg.docid]: URL.createObjectURL(img)};

          //console.log("getImgAttachment: ", docimg, i);

          //if(this._mounted!==false) this.setState(i);
        }
      })
  }

  downloadDoc = (att) => {
    //Grab ObjectURL direct from previmgs if it's already buffered
    //console.log("downloadDoc: ",att);

    if (att) {
      let link = document.createElement('a');
      link.setAttribute('href', att.img || URL.createObjectURL(att.data));
      link.setAttribute('download', att.id);
      link.click();
    }
  }

  updateValue = id => (n, v) => updateSiteValue(id, { [n]: v });

  getParentImg = (imgparent) => ((this.state["atts_" + imgparent.docid] || []).find(a => a && (a.content_type || "").startsWith("image")) || {}).img;


  //editClick = e => this.props.setEdit(!(this.state.edit || this.props.edit));

  //this.expand = id => e => this.setState({expanded: this.state.expanded===id?false:id, introcollapsed: true});
  expand = id => e => {

    if (!id) return;

    const expanded = this.state.expanded === id ? false : id;

    if (this.props.onExpand) this.props.onExpand(expanded);

    this.setState({ expanded, introcollapsed: true }, () => {
      const el = document.getElementById(id);
      //console.log("siv: ", id, el);
      if (el) el.scrollIntoView();
    });
  }

  expandIntro = () => this.setState({ introcollapsed: false });

  //!
  updateCategories = _categories => {
    //console.log("AS categories: ", _categories);

    if (this._mounted !== false) this.setState(_categories, () => {

      const newmeasures = {};

      //Retrieves measures from runFilter and pass to updateMeasureList simultaneously
      if (this.state._riskactions_raw) {
        const _riskactions = this.runFilter("_riskactions", "risk")(this.state._riskactions_raw, true);
        newmeasures._riskactions = _riskactions;
      }
      if (this.state._precautionmeasures_raw) {
        const _precautionmeasures = this.runFilter("_precautionmeasures", "equipment")(this.state._precautionmeasures_raw, true);
        newmeasures._precautionmeasures = _precautionmeasures;
      }

      this.runStateUpdate(newmeasures);
      this.updateMeasureList(newmeasures);
    })
  }

  //Find "assessment completed" element
  siteinfoFilter = siteinfo => {
    const sichildren = siteinfo?._siteinfo?.children;

    if(sichildren) {
      var acel = sichildren.find(el=>el.type="DateField" && el.type && ['assessmentcompleted','dateofassessment','assessmentdate','completiondate','datecompleted'].includes(el?.values?.name.replace(/[^\w]/gi, '').toLowerCase()));

      if(acel) this.setState({ assessmentcompletedid: acel.id, assessmentcompletedvalue: acel.values.value });
    }
  }

  setassessmentcompleted = e => {

    const val = e.type==="click" ? new Date().toISOString().split('T')[0] : e.target.value;

    //const isDate = val && val.getTime() !== 0;
    const todaysDate = new Date(val).toDateString() === new Date().toDateString();

    this.setState({ assessmentcompletedvalue: val, assessmentcompletedtoday: todaysDate });
    this.updateValue(this.state.assessmentcompletedid)('value', val); 
  }

  componentDidMount() {
    super.componentDidMount();
    //this.addWatcher("_categories");
    this.props.registerChangeWatcher(this.updateCategories, "_categories");
    this.props.registerChangeWatcher(this.runFilter("_riskactions", "risk"), "_riskactions");
    this.props.registerChangeWatcher(this.runFilter("_precautionmeasures", "equipment"), "_precautionmeasures");
    this.props.registerChangeWatcher(this.runECFilter, "_locations");
    this.props.registerChangeWatcher(this.runEscapeRouteFilter, "_escaperoutes");
    this.props.registerChangeWatcher(this.runLicenceFilter, "_licenceactions");
    this.props.registerChangeWatcher(this.siteinfoFilter, "_siteinfo");
    //this.props.registerChangeWatcher(this.runSectionFilter, "_sitesections");

    this.updateMeasureList();
  }

  componentDidUpdate(prevProps) {

    //this.props.selected
    if (prevProps.selected !== true && this.props.selected === true) {
      this.closeGenPopup();
      this.updateMeasureList();
    }
  }

  closeGenPopup = () => this.setState({ generating: false, generatingtext: '' });

  render() {
    const edit = (this.state.edit || this.props.edit || this.props.admin);

    //console.log("Actions: ", this.state._riskactions, this.state._precautionmeasures);

    //console.log("Actions: ", this.getMeasureList(this.state._riskactions, this.state._precautionmeasures));

    //console.log("ActionSummary: ", this.state);

    //const measures = this.getMeasureList(this.state.values._riskactions, this.state.values._precautionmeasures, this.state.values._extinguishers, this.state.values._escaperoutes, this.state.values._licences);

    const measures = this.state.measures;

    //console.log("Measure: ", measures);

    //const actualname = localData('actualname');


    return (
      <div className="ActionSummaryPane" id={this.props.id}>
        {/*<div className="surveyedit btn-group-toggle" data-toggle="buttons">
          <CheckButton title={edit?"Editing":"Edit"} iconafter={edit?"fs-exclamation-triangle":null} checked={edit} onChange={this.editClick} />
        </div>*/}

        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

        <div className="SurveySections">

          {measures
            ? measures.map((ml, i) => {

              const mlength = (ml.measures || []).length;

              return (
                <div className={`SurveySection card card-edit ${(ml.id === -1 ? 'inactive' : '')} ${mlength ? '' : 'SSempty'}`} key={ml.id}>
                  <div className="input-group card-header" onClick={this.expand(mlength && ml.id)}>
                    <div className="input-group-prepend">
                      <span className="icon fs-exclamation-triangle" style={{ color: ml.color || '#ccc' }} />
                    </div>
                    <div className={`form-control ztitle title input-group-text${this.state.expanded === ml.id ? ' open' : ''}`} >
                      <span className="mobile-hide">{ml.title}</span><span className="desktop-hide">{ml.mobiletitle || ml.title}</span>
                    </div>
                    <div className="input-group-append" >
                      <div className="input-group-text actioncount ztitle" style={{ color: ml.color || '#ccc' }}>
                        {mlength ? `View ${mlength} Action${mlength === 1 ? '' : 's'}` : 'No Actions'}
                      </div>
                      {/*mlength>0 && <FButton icon={this.state.expanded===ml.id?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.expand(ml.id)} />*/}
                      {mlength > 0 && <ExpandButton expanded={this.state.expanded === ml.id} onClick={this.expand(ml.id)} />}
                    </div>
                  </div>
                  {/*<div className="card-block" hidden={this.state.expanded!==ml.id}>*/}
                  <ShowContainer className="card-block" show={(this.state.expanded === ml.id)}>
                    {ml.measures.map(m => {
                      const riskident = m.riskident || ((m.measure || {}).values || {}).riskident;

                      //const title = [m.titlepath.join("> "),m.title,riskident].filter(t=>t).join('> ');

                      const st = [m.titlepath.join("> "), m.title, riskident].filter(t => t);
                      const title = st.pop();
                      //const ptitle = st.join('> ');

                      const review = () => this.props.setView('survey', m.pane, m.section, m.parentid || m.id, m.id);

                      return (
                        <div className="SummaryMeasure" key={m.id}>
                          <div className="gr1">
                            <div className="summarytitle head">Risk Identified</div><div className="head">{title}</div>

                            <div className="summarytitle head">Action Required</div>
                            <div className="head">
                              {m.measure && (m.measure.questiontext || m.questiontext || m.measure.name).split('\n').map((item, key) => <span key={key}>{item}<br /></span>)}
                            </div>

                            {m.measure && Object.keys(m.measure).length > 0 &&
                              <Fragment>
                                <div className="summarytitle">Notes</div><div>
                                  {/*<FormTextArea name="note" update={this.updateValue(m.id)} value={((m.measure || {}).values || {}).note || ""} autoupdate />*/}
                                  <EditableA name="note" onChange={this.updateValue(m.id)} editFixedOn>
                                    {((m.measure || {}).values || {}).note || ""}
                                  </EditableA>
                                </div>
                              </Fragment>
                            }

                            {Object.keys((m.measure || {})._attachments || {}).length > 0
                              ? <div className="sp">
                                <AttachmentView id={this.props.id + '_attachmentView'} actions={siteActions(m.id)} docid={m.id} attachments={siteAttachments(m.id)} stubs={m.measure._attachments} />
                              </div>
                              : ''}

                          </div>

                          {m.priority === -1
                            ? <div className="closedStuff">
                              {(((m.measure || {}).values || {}).completed) && <div>{(((m.measure || {}).values || {}).inplacetext)}</div>}
                              {(((m.measure || {}).values || {}).completed) && <div>{(((m.measure || {}).values || {}).note || "")}</div>}

                              {/*! re-open button */}

                              <div className="cpform">
                                <button className="btn bluebutton" onClick={() => siteActions(m.id).setValue({ 'closed': false, 'closedwhen': undefined, 'completed': false })} >Re-open</button>
                              </div>
                            </div>

                            : <div className="cpform btn-group">

                              {/*!! Review button - move tab open right up to top level; should be able to pass props to generated content(??) */}

                              <div>
                                <button type="button" className="btn bluebutton" onClick={review}>{m.sectioncheck ? <span>Complete <span style={{ color: '#f00', marginLeft: '0.5em' }} className="fa fa-play" /></span> : 'Update Question'}</button>
                              </div>

                              {m.closeable && <ButtonModal hideCloseIcon modalClassName="ASModal" standardButton buttonClass="bluebutton" buttonText={"Alternative Action"} title="Alternative Action Taken" buttonCancel={true} buttonOKDisabled={!(((m.measure || {}).values || {}).note || false)} buttonOK={() => siteActions(m.id).setValue({ 'closed': true, 'closedwhen': timeStamp(), 'completed': false })} >

                                <div className="AS-info">{m.measure.questiontext || m.questiontext || m.measure.name}</div>

                                <FormTextArea placeholder={"Alternative action taken to manage this risk (required)"} name="note" update={this.updateValue(m.id)} value={((m.measure || {}).values || {}).note || ""} rows={3} />

                                <div className="input-group">
                                  <FormText name="completedBy" value={((m.measure || {}).values || {}).completedBy || actualname || ''} update={this.updateValue(m.id)} placeholder="Name?" />
                                  <FormText type="date" name="completedDate" value={((m.measure || {}).values || {}).completedDate || nowInputValue()} update={this.updateValue(m.id)} placeholder="Date?" />
                                </div>
                              </ButtonModal>}

                              {m.closeable && <ButtonModal hideCloseIcon modalClassName="ASModal" standardButton buttonClass="bluebutton" buttonText={"Action Completed"} title="Completed Action" buttonCancel={true} buttonOK={() => siteActions(m.id).setValue({ 'closed': true, 'closedwhen': timeStamp(), 'completed': true, completedBy: ((m.measure || {}).values || {}).completedBy || actualname || '', completedDate: ((m.measure || {}).values || {}).completedDate || nowInputValue() })} >

                                <div className="AS-info">{((m.measure || {}).values || {}).inplacetext}</div>

                                <FormTextArea placeholder={"Notes (optional)"} name="note" update={this.updateValue(m.id)} value={((m.measure || {}).values || {}).note || ""} rows={3} />

                                <div className="input-group">
                                  <FormText name="completedBy" value={((m.measure || {}).values || {}).completedBy || actualname || ''} update={this.updateValue(m.id)} placeholder="Name?" />
                                  <FormText type="date" name="completedDate" value={((m.measure || {}).values || {}).completedDate || nowInputValue()} update={this.updateValue(m.id)} placeholder="Date?" />
                                </div>
                              </ButtonModal>}

                            </div>

                          }

                        </div>
                      )

                    })}

                  </ShowContainer>
                  {/*</div>*/}


                </div>
              )
            }
            )
            : <Loading />
          }

          <div className={"RiskMatrix SurveySection card card-edit"} style={{ zIndex: this.state.rmpopup ? 20 : '' }}>
            <div className="input-group card-header" onClick={this.expand("rle")}>
              <div className="input-group-prepend">
                <span className="icon ion-md-grid" />
              </div>
              <div className={"form-control ztitle title input-group-text"}>
                Risk Matrix
            </div>
              <div className="input-group-append">

                {edit && <MatrixRiskLevels actions={this.props.actions} riskLevels={this.state.values.riskLevels} />}

                <div className="input-group-text actioncount ztitle">
                  {this.state.values.haz > 0 && this.state.values.cons > 0 ? mat_rl[grl(this.state.values.haz, this.state.values.cons)] : 'Unanswered'}
                </div>
                {/*<FButton icon={this.state.expanded==="rle"?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.expand("rle")} />*/}
                {this.state.expanded === "rle" && <HelpModal id={this.props.id + "_matrixhelp"} edit={edit} name="matrixhelp" showState={s => this.setState({ rmpopup: s })} onChange={this.props.actions.setValue} value={this.state.values.matrixhelp} />}
                <ExpandButton expanded={this.state.expanded === "rle"} onClick={this.expand("rle")} />
              </div>
            </div>
            {/*<div className="card-block" hidden={this.state.expanded!=="rle"}>*/}
            <ShowContainer className="card-block" show={(this.state.expanded === "rle")}>
              <div className="SummaryMeasure">
                <ReportIntro title="Risk Matrix Section Intro" buttonText={"Section Report Intro"} id={this.props.id + "_matrixsectionreportintro"} edit={edit} name="matrixsectionreportintro" onChange={this.props.actions.setValue} value={this.state.values.matrixsectionreportintro} />
                <InlineHelp id={this.props.id + "_matrixintro"} edit={edit} name="matrixintro" onChange={this.props.actions.setValue} value={this.state.values.matrixintro} />

                <ReportIntro title="Hazard Level Report Intro" buttonText={"Haz Report Intro"} id={this.props.id + "_hazreportintro"} edit={edit} name="hazreportintro" onChange={this.props.actions.setValue} value={this.state.values.hazreportintro} />
                <InlineHelp id={this.props.id + "_matrixhazintro"} edit={edit} name="matrixhazintro" onChange={this.props.actions.setValue} value={this.state.values.matrixhazintro} />
                <HelpModal id={this.props.id + "_matrixhazhelp"} className="helpright" edit={edit} name="matrixhazhelp" onChange={this.props.actions.setValue} value={this.state.values.matrixhazhelp} />
                <MButtons list={mat_haz} name="haz" value={this.state.values.haz} onChange={this.props.actions.setValue} />

                <InlineHelp id={this.props.id + "_matrixconsintro"} edit={edit} name="matrixconsintro" onChange={this.props.actions.setValue} value={this.state.values.matrixconsintro} />

                <ReportIntro title="Consequence Level Report Intro" buttonText={"Cons Report Intro"} id={this.props.id + "_consreportintro"} edit={edit} name="consreportintro" onChange={this.props.actions.setValue} value={this.state.values.consreportintro} />
                <HelpModal id={this.props.id + "_matrixconshelp"} className="helpright" edit={edit} name="matrixconshelp" onChange={this.props.actions.setValue} value={this.state.values.matrixconshelp} />
                <MButtons list={mat_cons} name="cons" value={this.state.values.cons} onChange={this.props.actions.setValue} />

                <ReportIntro title="Matrix Intro" buttonText="Matrix Intro" id={this.props.id + "_matrixreportintro"} edit={edit} name="matrixreportintro" onChange={this.props.actions.setValue} value={this.state.values.matrixreportintro} />
                <Matrix hazard={this.state.values.haz} cons={this.state.values.cons} riskLevels={this.state.values.riskLevels} />
              </div>
            </ShowContainer>
            {/*</div>*/}
          </div>

          {(edit || this.state.values.esshow) &&
            <div className={"ExecutiveSummary SurveySection card card-edit"}>
              <div className="input-group card-header" onClick={this.expand("es")}>
                <div className={"form-control ztitle title input-group-text"}>
                  Executive Summary
            </div>
                <div className="input-group-append">

                  {edit && <Radioaddon checked={this.state.values.esshow} icon={"fs-clipboard"} overlay={<span className={this.state.values.esshow ? 'fs-check' : 'fs-times'} />} onClick={e => this.props.actions.setValue({ esshow: !this.state.values.esshow })}>Show?</Radioaddon>}

                  {this.state.expanded === "es" && <HelpModal id={this.props.id + "_eshelp"} edit={edit} name="eshelp" onChange={this.props.actions.setValue} value={this.state.values.eshelp} />}

                  {/*<FButton icon={this.state.expanded==="rle"?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.expand("es")} />*/}
                  <ExpandButton expanded={this.state.expanded === "rle"} onClick={this.expand("es")} />
                </div>
              </div>
              {/*<div className="card-block" hidden={this.state.expanded!=="es"}>*/}
              <ShowContainer className="card-block" show={(this.state.expanded === "es")}>

                <InlineHelp id={this.props.id + "_esintro"} edit={edit} name="esintro" onChange={this.props.actions.setValue} value={this.state.values.esintro} />

                <ReportIntro id={this.props.id + "_esreportintro"} edit={edit} name="esreportintro" onChange={this.props.actions.setValue} value={this.state.values.esreportintro} />

                <FormTextArea name="executivesummary" value={this.state.values.executivesummary} update={this.props.actions.setValue} placeholder="- Executive Summary -" />

              </ShowContainer>
              {/*</div>*/}
            </div>
          }

        </div>

        <div>
          <div className="input-group standalone">
            <div className="input-group-prepend">
              <span className="input-group-text">Assessment Completed:</span>
            </div>
            <input type="date" value={this.state.assessmentcompletedvalue || ''} onChange={this.setassessmentcompleted}></input>
            <div className="input-group-appead">
              {this.state.assessmentcompletedtoday
                ?<span className="input-group-text">(today)</span>
                :<button className="btn btn-link" onClick={this.setassessmentcompleted} >Set to today</button>
              }
            </div>
          </div>
        </div>

        <div className="reportButton">
          {/*<Detector render={({ online }) => (
            
          {*/}
          <button type="button" className="btn bluebutton2" disabled={!(measures && this.props.online && !this.props.syncing)} onClick={this.reportButtonNew}>
            {this.props.online && !this.props.syncing
              ? measures
                ? 'Generate Report'
                : <span>Loading Actions… <Loading title={false} /></span>
              : this.props.syncing ? 'Data syncing, please wait...' : 'Offline - Internet connection required'
            }
          </button>
          {/*
            )} />*/}
        </div>

        <Modal
          icon="fs-clipboard"
          title="Generating Report"
          show={this.state.generating}
          hideModal={this.closeGenPopup}
        >
          {this.state.generatingtext || <div>
            Report request sent to server. Please wait…
          </div>}
        </Modal>

        {process.env.REACT_APP_TEST_REPORT && process.env.REACT_APP_TEST_REPORT === "true" && !this.props.syncing && <div className="reportButton">
          <button type="button" className="btn btn-success" onClick={this.testReportButton}><span className="fs-clipboard" /> Test Report </button>
        </div>}

        {/*<div>
              <br />
              <a href={`http://localhost:3010/sites/report?site=${this.props.siteid}&survey=${this.props.surveyid}&kit`}>DEV PDF</a>
              <br />
              <a href={`http://localhost:3010/sites/report?site=${this.props.siteid}&survey=${this.props.surveyid}`}>DEV TEST ATTACH</a>
        </div>*/}

      </div>
    )
  }
}


class SiteInfo extends Container {
  constructor(props) {
    super(props);

    this.state["expanded"] = this.props.expandid || false;
    this.state["exitem"] = false;
    this.state["introcollapsed"] = false;

  }

  //editClick = e => this.props.setEdit(!(this.state.edit || this.props.edit));

  expand = (id, force = false, exitem, itemid) => e => {
    const expanded = (this.state.expanded === id && force !== true) ? false : id;
    if (this.props.onExpand) this.props.onExpand(expanded, itemid);
    this.setState({ expanded, introcollapsed: true, exitem }); //, ()=>scrollTo(id)
  }

  expandIntro = () => this.setState({ introcollapsed: false });

  /*openByPrefix = (id, exitem) => {
    const el=this.state.elements.find(eid=>eid.startsWith(id));
    if(el) this.expand(el, true, exitem)();
  }*/

  componentDidMount() {
    super.componentDidMount();

    scrollTo(this.props.itemid);
  }

  componentDidUpdate(prevProps, prevState) {
    //!update on expanded change
    if (this.props.expandid && prevProps.expandid !== this.props.expandid) {

      const eid = this.props.expandid.split('*');
      let exp;

      if (eid.length) {
        exp = this.state.elements.find(el => el.startsWith(eid[0]));
      }
      else exp = this.props.expandid;

      this.expand(exp, true)();
    }

    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.setState({ admin: false });
    }
  }

  render() {
    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    //console.log("Survey props: ", this.props);

    return (
      <div id={this.props.id} className="SiteInfo">
        {/*<div className="surveyedit btn-group-toggle" data-toggle="buttons">
          <CheckButton title={edit?"Editing":"Edit"} iconafter={edit?"fs-exclamation-triangle":null} checked={edit} onChange={this.editClick} />
        </div>*/}

        {this.props.admin &&
          <div className="rightButton">
            <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />
          </div>
        }

        {edit && <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />}

        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

        <div className="SurveySections" id={this.props.id}>
          {this.children({
            props: { expand: this.expand, itemid: this.props.itemid, admin: this.props.admin },
            propsId: this.state.expanded ? { [this.state.expanded]: { expanded: true, exitem: this.state.exitem } } : false
          })}
        </div>
      </div>
    )
  }
}

class AssDataSection extends Container {

  componentDidMount() {
    super.componentDidMount();

    siteItems(assinfotypes, surveyRootId(assinfotypes, idFromSurveyRoot(this.props.rootid)), { edit: this.state.admin, updateEditDate: this.updateEditDate })
      .then(items => {
        if (this._mounted !== false) this.setState({ items: items });
      })
  }

  adminChange = () => {
    const admin = !this.state.admin;
    this.setState({ admin, items: [React.cloneElement(this.state.items[0], { edit: admin }, null)] });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.adminChange();
    }

  }

  /**
   * Update Edit Date from child
   * @param {string} newdate 
   */
  updateEditDate = (newdate) => {
    if (this._mounted !== false) {

      this.setState({ editdate: newdate })
    };
    if (this.state.values.sectionok !== true) {
      this.setValue({ sectionok: true });
    }
  }

  render() {

    const edit = (this.state.edit || this.props.edit || this.state.admin);

    return (
      <div className="AssDataSection SurveySection card card-edit" id={this.props.id}>
        <div className="input-group card-header live">
          {/*<div className="input-group-prepend" onClick={this.props.expand(this.props.id)}>
            <div className={"icon "+(this.state.values.icon || this.props.icon)} />
            </div>*/}
          <div className="input-group-prepend">
            {edit
              ? <Icondrop icon={this.state.values.icon || this.props.icon} name="icon" onClick={this.setValue} />
              : <div className={"icon " + (this.state.values.icon || this.props.icon)} />
            }
          </div>
          {edit ?
            <EditableA className="ztitle form-control input-group-text" name="name" onChange={this.setValue} editFixedOn={true}>
              {this.state.values.name || "Assessment Data"}
            </EditableA>
            :
            <div className={"ztitle form-control title input-group-text "} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Assessment Data"}</div>
          }
          <div className="input-group-append">
            {/* <HelpModal id={this.props.id+"_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} /> */}
            {edit && <StButtons actions={this.props.actions} />}
            <EditDate editdate={this.state.values.sectionok && this.state.editdate} actions={this.props.actions} expanded={this.props.expanded} />
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={this.adminChange} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
        <div hidden={!(this.props.expanded || edit)} className="card-block">
          {/* <InlineHelp id={this.props.id+"_intro"} edit={this.state.edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id+"_reportintro"} edit={this.state.edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />*/}
          {this.state.items}
        </div>
      </div>
    )
  }
}

class CategorySection extends Container {

  /*updateSectionOK = sectionok => {
    if (!!this.state?.values?.sectionok !== sectionok) {
      this.setValue({ sectionok }, true);
    }
  }*/

    /**
   * Update Edit Date from child
   * @param {string} newdate 
   */
     updateEditDate = (newdate) => {
      if (this._mounted !== false) {
  
        this.setState({ editdate: newdate })
      };
      if (this.state.values.sectionok !== true) {
        this.setValue({ sectionok: true });
      }
    }

  componentDidMount() {
    super.componentDidMount();
    siteItems(categorytypes, surveyRootId(categorytypes, idFromSurveyRoot(this.props.rootid)), { edit: this.state.admin, updateEditDate: this.updateEditDate })
      .then(items => {
        if (this._mounted !== false) this.setState({ items: items });
      })
  }

  adminChange = () => {
    const admin = !this.state.admin;
    this.setState({ admin, items: [React.cloneElement(this.state.items[0], { edit: admin, updateEditDate: this.updateEditDate }, null)] });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.adminChange();
    }

  }

  render() {

    const edit = (this.state.edit || this.props.edit || this.state.admin);

    return (
      <div className="CategorySection SurveySection card card-edit" id={this.props.id}>
        <div className="input-group card-header live">
          <div className="input-group-prepend">
            {edit
              ? <Icondrop icon={this.state.values.icon || this.props.icon} name="icon" onClick={this.setValue} />
              : <div className={"icon " + (this.state.values.icon || this.props.icon)} />
            }
          </div>
          {edit ?
            <EditableA className="ztitle form-control input-group-text" name="name" onChange={this.setValue} editFixedOn={true}>
              {this.state.values.name || "Operations"}
            </EditableA>
            :
            <div className={"ztitle form-control title input-group-text "} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Operations"}</div>
          }
          <div className="input-group-append">
            {/* <HelpModal id={this.props.id+"_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} /> */}
            {edit && <StButtons actions={this.props.actions} />}
            <EditDate editdate={this.state?.values?.sectionok && this.state.editdate} actions={this.props.actions} expanded={this.props.expanded} />
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={this.adminChange} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
        {/*<div hidden={!(this.props.expanded || edit)} className="card-block">*/}
        <ShowContainer className={"card-block"} show={(this.props.expanded || edit)}>
          {/*<InlineHelp id={this.props.id+"_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
          <ReportIntro id={this.props.id+"_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />*/}
          {this.state.items}
        </ShowContainer>
        {/*</div>*/}
      </div>
    )
  }
}

class LicensesSection extends Container {
  componentDidMount() {
    super.componentDidMount();
    siteItems(licensetypes, surveyRootId(licensetypes, idFromSurveyRoot(this.props.rootid)), { section: this.props.id, edit: this.state.admin, updateEditDate: this.updateEditDate })
      .then(items => {
        if (this._mounted !== false) this.setState({ items: items });
      })
  }

  adminChange = () => {
    const admin = !this.state.admin;
    this.setState({ admin, items: [React.cloneElement(this.state.items[0], { edit: admin }, null)] });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.adminChange();
    }
  }

  render() {

    const edit = (this.state.edit || this.props.edit || this.state.admin);

    return (
      <div className="LicensesSection SurveySection card card-edit" id={this.props.id}>
        <div className="input-group card-header live">
          <div className="input-group-prepend">
            {edit
              ? <Icondrop icon={this.state.values.icon || this.props.icon} name="icon" onClick={this.setValue} />
              : <div className={"icon " + (this.state.values.icon || this.props.icon)} />
            }
          </div>
          {edit ?
            <EditableA className="ztitle form-control input-group-text" name="name" onChange={this.setValue} editFixedOn={true}>
              {this.state.values.name || "Licences"}
            </EditableA>
            :
            <div className={"ztitle form-control title input-group-text "} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Licences"}</div>
          }
          <div className="input-group-append">
            {/* <HelpModal id={this.props.id+"_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} /> */}
            {edit && <StButtons actions={this.props.actions} />}
            <EditDate editdate={this.state.editdate} actions={this.props.actions} expanded={this.props.expanded} />
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={this.adminChange} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
        {/*<div hidden={!(this.props.expanded || edit)} className="card-block">*/}
        <ShowContainer className={"card-block"} show={(this.props.expanded || edit)}>
          {/*<InlineHelp id={this.props.id+"_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
          <ReportIntro id={this.props.id+"_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />*/}
          {this.state.items}
        </ShowContainer>
        {/*</div>*/}
      </div>
    )
  }
}

class LocationSection extends Container {

  updateSectionOK = sectionok => {
    if (!!this.state?.values?.sectionok !== sectionok) {
      this.setValue({ sectionok }, true);
    }
  }

  componentDidMount() {
    super.componentDidMount();
    siteItems(locationtypes, surveyRootId(locationtypes, idFromSurveyRoot(this.props.rootid)), { updateEditDate: this.updateEditDate, updateSectionOK: this.updateSectionOK })
      .then(items => {
        if (this._mounted !== false) this.setState({ items: items });
      })

  }

  adminChange = () => {
    const admin = !this.state.admin;
    this.setState({ admin, items: [React.cloneElement(this.state.items[0], { edit: admin }, null)] });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.adminChange();
    }

  }

  render() {

    const edit = (this.state.edit || this.props.edit || this.state.admin);

    return (
      <div className="LocationSection SurveySection card card-edit" id={this.props.id} style={{ zIndex: this.state.rmpopup ? 20 : '' }}>
        <div className="input-group card-header live">
          <div className="input-group-prepend">
            {edit
              ? <Icondrop icon={this.state.values.icon || this.props.icon} name="icon" onClick={this.setValue} />
              : <div className={"icon " + (this.state.values.icon || this.props.icon)} />
            }
          </div>
          {edit ?
            <EditableA className="ztitle form-control input-group-text" name="name" onChange={this.setValue} editFixedOn={true}>
              {this.state.values.name || "Locations"}
            </EditableA>
            :
            <div className={"ztitle form-control title input-group-text "} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Locations"}</div>
          }
          <div className="input-group-append">
            {edit && <StButtons actions={this.props.actions} />}
            <EditDate editdate={this.state?.values?.sectionok && this.state.editdate} actions={this.props.actions} expanded={this.props.expanded} />
            <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} showState={s => this.setState({ rmpopup: s })} />
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={this.adminChange} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>
        {/*<div hidden={!(this.props.expanded || edit)} className="card-block">*/}
        <ShowContainer className={"card-block childblock"} show={(this.props.expanded || edit)}>
          {/*<InlineHelp id={this.props.id+"_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
          <ReportIntro id={this.props.id+"_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />*/}
          {this.state.items}
        </ShowContainer>
        {/*</div>*/}
      </div>
    )
  }
}


class AssessmentData extends Container {

  constructor(props) {
    super(props);

    this.watchChildren=true;

  }

  //Triggered by watchChildren
  childDidUpdate = (id, doc) => {
    //console.log("Child update: ", id, doc, this._childdata);

    if(this.state.elements.some(el=>
      !this._childdata[el]
      ||
      (this._childdata[el].elements || []).some(ea=>!this._childdata[ea])
    )) return;

    const _siteinfo = {
      id: this.props.id,
      type: this.props.type,
      children: this.state.elements.map(el=>({
        ...this._childdata[el],
        id: this._childdata[el]._id,
        name: this._childdata[el]?.values?.name
      }))
    }

    this.props.updateChangeWatchers({ _id: "_siteinfo", _siteinfo }, true);
    
  }


  stateUpdate = (newstate, oldstate) => {
    console.log("AssessmentData state update: ", newstate, oldstate);
  }

  render() {

    const edit = (this.state.edit || this.props.edit);

    return (
      <div className="AssessmentData" id={this.props.id}>

        <InlineHelp id={this.props.id + "intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

        {edit && <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />}

        {this.children({ props: { report: true, edit } })}

      </div>
    )
  }
}

class Log extends Container {

  constructor(props) {
    super(props);

    this.state["expanded"] = false; //this.props.expandid ||
    this.state["introcollapsed"] = false;

  }

  //editClick = e => this.props.setEdit(!(this.state.edit || this.props.edit));

  expand = (id, force = false) => e => {
    const expanded = (this.state.expanded === id && force !== true) ? false : id;
    this.setState({ expanded, introcollapsed: true });
  }

  expandIntro = () => this.setState({ introcollapsed: false });

  componentDidMount() {
    super.componentDidMount();

    scrollTo(this.props.itemid);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.setState({ admin: false });
    }
  }

  logReport = populated => e => {
    window.open(`${adminserver}/sites/log?site=${this.props.siteid}&survey=${this.props.surveyid}${populated ? '&populated' : ''}`);
    //window.open(`http://localhost:3010/sites/log?site=${this.props.siteid}&survey=${this.props.surveyid}`);
  }

  render() {
    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    //console.log("Survey props: ", this.props);

    return (
      <div id={this.props.id} className="Log">
        {/*<div className="surveyedit btn-group-toggle" data-toggle="buttons">
          <CheckButton title={edit?"Editing":"Edit"} iconafter={edit?"fs-exclamation-triangle":null} checked={edit} onChange={this.editClick} />
        </div>*/}

        {this.props.admin &&
          <div className="rightButton">
            <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />
          </div>
        }

        {edit &&
          <div className="lognew">
            {this.props.childTypes.map(childType =>
              <IconButton glyph="+" key={childType.type} onClick={e => this.props.actions.addChild(childType.type)} icon={childType.icon} title={childType.title} />
            )}
          </div>
        }

        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

        <div><FButton onClick={this.logReport(true)} addButton icon="fa fa-print">{process.env.REACT_APP_BUTTON_PRINT_LOG_BOOK || 'Fire Log Book'}</FButton></div>

        {!process.env.REACT_APP_BUTTON_HIDE_PRINT_BLANK_LOG_BOOK && <div><FButton onClick={this.logReport(false)} addButton icon="fa fa-print">{`${process.env.BUTTON_PRINT_LOG_BOOK || `Fire Log Book`} BLANK`}</FButton></div>}

        <Namer className="sectionTitle" edit={edit} name="title" value={this.state.values.title} defaultName="Logs" onChange={this.setValue} />

        <div className="SurveySections" id={this.props.id}>
          {this.children({
            props: { expand: this.expand, siteid: this.props.siteid, surveyid: this.props.surveyid, admin: this.props.admin },
            propsId: this.state.expanded ? { [this.state.expanded]: { expanded: true, exitem: this.state.exitem } } : false,
            orderChildrenByType: true
          })}
        </div>
      </div>
    )
  }
}

//Remove a key from an object
const deleteKey = (obj, id) => {
  const {[id]: _, ...objout} = obj;
  return objout;
}

class NewAudit extends Container {

  constructor(props) {
    super(props);

    this.state["expanded"] = this.props.expandid || false;
    this.state["introcollapsed"] = false;

  }

  expand = (id, force = false, exitem) => e => {
    const expanded = (this.state.expanded === id && force !== true) ? false : id;
    this.setState({ expanded, introcollapsed: true, exitem });
  }

  expandIntro = () => this.setState({ introcollapsed: false });

  //Makes _audits available throughout the app
  updateAuditWatcher = (_audits) => {
    this.props.updateChangeWatchers({ _id: "_audits", _audits }, true);
  }

  componentDidMount() {
    super.componentDidMount();

    if(this.state.values._audits) {
      this.updateAuditWatcher(this.state.values._audits);
    }

    scrollTo(this.props.itemid);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.expandid !== this.props.expandid || prevProps.itemid !== this.props.itemid) {
      this.expand(this.props.expandid, true, this.props.itemid)();
    }

    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.setState({ admin: false });
    }

    //console.log("Audits: ", this.props.id, this.state.values._audits);
  }

  updateLogList = id => (_auditlist, name) => { //!Delete section
    
    const _audits = _auditlist===false
      ?deleteKey(this.state.values._audits || {}, id)
      :{...(this.state.values._audits || {}), [id]: {name, audits: _auditlist}};

    this.setState({_audits});

    if((this.state.elements || []).length===(Object.keys(_audits || {}).length) && JSON.stringify(_audits)!==JSON.stringify(this.state.values._audits)) {
      this.updateAuditWatcher(_audits);
      this.setValue({_audits});
    }
  }

  render() {
    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit || (this.props.admin && this.state.admin));

    //console.log("Survey props: ", this.props);

    return (
      <div id={this.props.id} className="Log">
        {/*<div className="surveyedit btn-group-toggle" data-toggle="buttons">
          <CheckButton title={edit?"Editing":"Edit"} iconafter={edit?"fs-exclamation-triangle":null} checked={edit} onChange={this.editClick} />
        </div>*/}

        {this.props.admin &&
          <div className="rightButton">
            <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />
          </div>
        }

        {edit &&
          <div className="lognew">
            {this.props.childTypes.map(childType =>
              <IconButton glyph="+" key={childType.type} onClick={e => this.props.actions.addChild(childType.type)} icon={childType.icon} title={childType.title} />
            )}
          </div>
        }

        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

        <Namer className="sectionTitle" edit={edit} name="title" value={this.state.values.title} defaultName="Audits" onChange={this.setValue} />

        <div className="SurveySections" id={this.props.id}>
          {this.children({
            props: { expand: this.expand, siteid: this.props.siteid, surveyid: this.props.surveyid, admin: this.props.admin },
            propsId: this.state.expanded ? { [this.state.expanded]: { expanded: true, exitem: this.state.exitem } } : false,
            orderChildrenByType: true,
            propsIdFunc: {updateLogList: this.updateLogList}
          })}
        </div>
      </div>
    )
  }
}

const AuditPicker = ({ edit=false, _audits={}, name, value, onClick, onViewClick }) => {

  const akeys = Object.keys(_audits);

  return (
    edit?
      <BSDrop icon="fs-clipboard" buttonText="Audit" right overlay={value?.audit?<span className="fs-check"/>:''}>
        <>
        <BSDropItem onClick={e => name ? onClick(name, undefined) : onClick(undefined)} key="none">
            [None]
        </BSDropItem>
        {akeys?.filter(as=>Object.keys(_audits[as]?.audits || {}).length).map(as => {
          
          const askeys = Object.keys(_audits[as]?.audits || {});

          return (
            <React.Fragment key={as}>
              <BSDropHeading>
                {_audits[as]?.name}
              </BSDropHeading>
              {askeys.map(a =>
                <BSDropItem onClick={e => name ? onClick(name, {section: as, audit: a}) : onClick({section: as, audit: a})} key={a} active={value?.section===as && value?.audit===a}>
                  { _audits[as]?.audits[a].name }
                </BSDropItem>
              )}
            </React.Fragment>
          )
        })}
        </>
      </BSDrop>
    :value?.audit
      ?<FButton icon="fs-clipboard" onClick={()=>{onViewClick(value.section, value.audit)}}>Audit</FButton>
      :null
  )
}

//_audits[value.section].audits[value.audit]

class AuditSection extends Container {

  //Add a new LogAudit
  addChild = async type => this.props.actions.addChild(type, undefined, type === 'AuditAudit' && this.state.admin ? { ptype: true } : undefined)

  deleteMe = () => {
    this.props.updateLogList(false);
    this.props.actions.deleteMe();
  }

  renameMe = async name => {
    this.props.actions.renameMe(name);
    this.props.updateLogList(this.liveAudits(), name);
  }

  addLog = id => {
    this.props.actions.cloneChild(id, { ptype: false })
  }

  //Get list of audits - include non-prototype for elements count check
  updateLogList = id => (name, ptype) => {

    this.runStateUpdate({ elements: this.state.elements });

    if(this._mounted!==false) {
      this.setState(st=>({_audits: (name===false?deleteKey(st._audits, id):{...st._audits, [id]: {name, ptype} } ) }) );
    }

    //this.runStateUpdate({ elements: this.state.elements });
  }

  liveAudits = (_audits) => Object.keys(_audits || this.state?._audits || {}).reduce((a,k)=>({...a, ...(this.state._audits[k].ptype?{}:{[k]: this.state._audits[k]})}),{});

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.setState({ admin: false });
    }

    //console.log("Audit update ", this.props.id);

    if( ((this.state._audits && Object.keys(this.state._audits).length === (this.state.elements || []).length)) || !this.state.elements ) {
      if( !prevState._audits || ( JSON.stringify(this.state._audits) !== JSON.stringify(prevState._audits) ) ) {

        //const _audits = Object.keys(this.state._audits).reduce((a,k)=>({...a, ...(this.state._audits[k].ptype?{[k]: this.state._audits[k]}:{})}),{});
        const _audits = this.liveAudits();
        this.props.updateLogList(_audits, this.state?.values?.name);
      }
    }

    if(this.props.exitem!==prevProps.exitem) {
      this.setState({ exitem: this.props.exitem });
    }

  }

  render() {

    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit || this.state.admin);

    const children = this.children({
      props: {
        siteid: this.props.siteid,
        surveyid: this.props.surveyid,
        edit: this.state.admin
      },
      propsId: this.state.exitem ? { [this.state.exitem]: { expanded: true } } : false,
      propsIdFunc: {
        updateLogList: this.updateLogList
      }
    });

    //console.log("LogSection children: ", children);

    //console.log("AuditSection childinfo: ", this.state.childinfo);


    const actions = {...this.props.actions, deleteMe: this.deleteMe};

    return (
      <div className="LogSection SurveySection card card-edit" id={this.props.id} style={{ zIndex: this.state.rmpopup ? 20 : '' }}>
        <div className={`input-group card-header ${edit ? 'edit' : 'live'}`}>
          <div className="input-group-prepend" onClick={this.props.expand(this.props.id)}>
            <div className={"icon " + (this.state.values.icon || this.props.icon)} />
          </div>
          {edit
            ? <EditableA className="ztitle form-control input-group-text" onChange={this.renameMe} editFixedOn={true}>
              {this.state.values.name || "Section"}
            </EditableA>
            : <div className={"ztitle form-control title input-group-text"} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Section"}</div>
          }

          <div className="input-group-append">
            {edit && <StButtons actions={actions} />}
            {edit
              ? <AddChildButtons childTypes={this.props.childTypes} addChild={this.addChild} />
              : this.props.expanded &&

              <BSDrop title={<span className="fs-clipboard" />} buttonText="Create Audit" right showState={s => this.setState({ rmpopup: s })}>
                {this.state?.childinfo?.map(c =>
                  c?.values?.ptype &&
                  <BSDropItem onClick={() => this.addLog(c.id)} key={c.id}>
                    {c?.name}
                  </BSDropItem>
                )}
              </BSDrop>
            }
            <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} showState={s => this.setState({ rmpopup: s })} />
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>

        <div className={"card-block"} hidden={!(this.props.expanded || edit)}>
          <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />
          {/*<ReportIntro id={this.props.id+"_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />*/}
          {children}
        </div>
      </div>
    )
  }
}

class LogSection extends Container {

  //Add a new LogAudit
  addChild = type => this.props.actions.addChild(type, undefined, type === 'LogAudit' && this.state.admin ? { ptype: true } : undefined)

  addLog = id => {
    console.log("Clone child ", id);

    this.props.actions.cloneChild(id, { ptype: false })
  }

  updateLogList = () => {
    this.runStateUpdate({ elements: this.state.elements });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.admin !== this.props.admin && this.state.admin) {
      this.setState({ admin: false });
    }
  }

  render() {

    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit || this.state.admin);

    const children = this.children({
      props: {
        updateLogList: this.updateLogList,
        siteid: this.props.siteid,
        surveyid: this.props.surveyid,
        edit: this.state.admin
      }
    });

    //console.log("LogSection children: ", children);

    return (
      <div className="LogSection SurveySection card card-edit" id={this.props.id} style={{ zIndex: this.state.rmpopup ? 20 : '' }}>
        <div className={`input-group card-header ${edit ? 'edit' : 'live'}`}>
          <div className="input-group-prepend" onClick={this.props.expand(this.props.id)}>
            <div className={"icon " + (this.state.values.icon || this.props.icon)} />
          </div>
          {edit
            ? <EditableA className="ztitle form-control input-group-text" name="name" onChange={this.props.actions.setValue} editFixedOn={true}>
              {this.state.values.name || "Section"}
            </EditableA>
            : <div className={"ztitle form-control title input-group-text"} onClick={this.props.expand(this.props.id)}>{this.state.values.name || "Section"}</div>
          }

          <div className="input-group-append">
            {edit && <StButtons actions={this.props.actions} />}
            {edit
              ? <AddChildButtons childTypes={this.props.childTypes} addChild={this.addChild} />
              : this.props.expanded &&

              <BSDrop title={<span className="fs-clipboard" />} buttonText="Create Logsheet" right showState={s => this.setState({ rmpopup: s })}>
                {children.map(c =>
                  ((((c || {}).props || {}).state || {}).values || {}).ptype &&
                  <BSDropItem onClick={() => this.addLog(c.props.id)} key={c.key}>
                    {c.props.state.values.name}
                  </BSDropItem>
                )}
              </BSDrop>
            }
            <HelpModal id={this.props.id + "_help"} edit={edit} name="help" onChange={this.props.actions.setValue} value={this.state.values.help} showState={s => this.setState({ rmpopup: s })} />
            {this.props.admin && <FButton icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={() => this.setState({ admin: !this.state.admin })} />}
            {/*<FButton icon={this.props.expanded?"fs-minus-square-o":"fs-plus-square-o"} vcenter onClick={this.props.expand(this.props.id)} />*/}
            <ExpandButton expanded={this.props.expanded} onClick={this.props.expand(this.props.id)} />
          </div>
        </div>

        <div className={"card-block"} hidden={!(this.props.expanded || edit)}>
          <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />
          {/*<ReportIntro id={this.props.id+"_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />*/}
          {children}
        </div>
      </div>
    )
  }
}


class LogAudit extends Container {

  static contextType = MainContext;

  renameMe = async name => {
    await this.props.actions.renameMe(name);
    this.props.updateLogList(name, this.state?.values?.ptype);
  }

  deleteMe = async () => {
    await this.props.actions.deleteMe();
    this.props.updateLogList(false, this.state?.values?.ptype);
  }

  componentDidMount = () => {
    super.componentDidMount();

    this.props.updateLogList(this.state?.values?.name, this.state?.values?.ptype);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.expanded && (this.props.expanded !== prevProps.expanded)) {
      this.setState({showLog: true});
    }
  }

  addRow = async () => {
    if (!this.state.elements && this.state?.elements?.length) {
      console.log("Nothing to clone");
      return;
    }

    const newId = await this.props.actions.cloneChild(this.state.elements[0], { live: true });
    this.setState({ newrow: newId, showLog: true });
  }

  /**
   * Get fields from ptype child
   */
  registerFields = logfields => {

    this.setState({ logfields });
  }

  scrollBottom = e => {
    var logmodal = document.getElementById(`${this.props.id}_logmodal`); //document.getElementById("your_div");
    if (logmodal) {
      var cardbody = logmodal.querySelector('.card-block')
      if (cardbody) {
        cardbody.scrollTop = cardbody.scrollHeight;
      }
    }
  }

  showLog = () => {
    this.setState({ showLog: true }, () => this.scrollBottom());
  }

  hideLog = () => {
    this.setState({ showLog: false });

    if(this.props.expanded) this.context.prevView();
  }

  entryDate = (entryDate) => {
    if (entryDate.when > ((this.state.entryDate || {}).when || 0)) this.setState({ entryDate });
  }

  cancelNewRow = () => this.setState({ newrow: null });

  logReport = () => window.open(`${adminserver}/sites/log?site=${this.props.siteid}&survey=${this.props.surveyid}&log=${this.props.id}&populated`);

  render() {

    const edit = (this.state.edit || this.props.edit);
    const ptype = this.state.values.ptype;

    const elementCount = this.state.elements ? this.state.elements.length : 0;

    const children = this.children({
      props: { entryDate: this.entryDate, edit },
      propsId: {
        [this.state.newrow]: { newrow: true, cancelNewRow: this.cancelNewRow },
        [elementCount && this.state.elements[0]]: { ptype: true, registerFields: this.registerFields, nodelete: (elementCount > 1) }
      }
    });

    const eltype = this.props.type==="LogAudit"?"Log":"Audit";

    //if(this.state.edit)
    return (
      <div id={this.props.id} className={`LogAudit input-group ${ptype ? 'ptype' : ''}`} style={{ display: ((ptype && !edit) || (!ptype && edit)) ? 'none' : '' }} data-toggle="buttons">
        <div className={"ztitle form-control input-group-text"} onClick={this.showLog}>
          {ptype && edit
            ? <EditableA onChange={this.renameMe} editFixedOn={true}>
              {this.state.values.name || eltype}
            </EditableA>
            : this.state.values.name || eltype
          }
        </div>

        <div className="input-group-append btn-group-toggle">
          {!ptype && this.state.entryDate &&
            <div className="input-group-text">
              Updated: {ukdate(this.state.entryDate.when)}
            </div>
          }

          {elementCount > 0 && !ptype && <FButton onClick={this.addRow} icon="fs-plus-circle">Add Entry</FButton>}

          <FButton icon="fs-clipboard" onClick={this.showLog}>View</FButton>
        </div>

        <Modal id={`${this.props.id}_logmodal`} show={this.state.showLog} hideModal={this.hideLog} className="AuditModal" scroll icon="fs-clipboard" title={this.state.values.name || eltype} onTitleChange={this.state.values.live || elementCount > 1 ? null : this.renameMe}
          topButtons={<>
            {edit && ptype && !children.length && <AddChildButtons key="add" childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />}
            {!ptype && eltype==="Log" && <FButton onClick={this.logReport} icon="fa fa-print">Print Log</FButton>}
            <SureButton key="delete" icon="fs-trash" buttonText="Delete" onClick={this.deleteMe}>Sure?</SureButton>
          </>}
          buttonOK={this.hideLog}
        >
          <InlineHelp id={this.props.id + "_intro"} edit={ptype && edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />
          <ReportIntro id={this.props.id + "_reportintro"} edit={ptype && edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />

          <div className="LogGrid" style={{ gridTemplateColumns: `repeat(${(this.state.logfields || []).length}, 1fr) 3em` }}>
            {(this.state.logfields || []).map((f, i) =>
              typeof f === "object"
                ? <div key={f + '_' + i} className="LogGridHead multi">
                  <div>{f.name}</div>
                  <div>{f.subname1}</div><div>{f.subname2}</div>
                </div>
                : <div key={f + '_' + i} className="LogGridHead">{f}</div>
            )}
            <div className="LogGridHead" > </div>
            {children}
          </div>

          {elementCount > 0 && !ptype && <FButton onClick={this.addRow} icon="fs-plus-circle" className="LogAudit-addrow">Add Entry</FButton>}

        </Modal>

      </div>
    )
    //else return null;
  }
}

class LogAuditRow extends Container {

  updateMaster = () => {
    if (this.state.fields && Object.keys(this.state.fields).length === (this.state.elements || []).length) {
      const fields = (this.state.elements || []).map(el => this.state.fields[el]);
      this.props.registerFields(fields);
    }
  }

  registerField = id => field => {

    this.setState(oldState => {
      var fields;

      if(field===false) {
        const {[id]: removed, ...fs} = (oldState.fields || {});
        fields = { ...fs || {} };
      }
      else fields = { ...(oldState.fields || {}), [id]: field };

      return {fields};
    },
      this.updateMaster
    )
  }

  componentDidMount() {
    super.componentDidMount();

    this.props.entryDate({ who: this.state.user, when: this.state.when });
  }

  render() {

    //!Register Fields not working because children not passed..!
    //if(!(this.state.edit || this.props.edit) && this.props.ptype) return null;

    const edit = (this.state.edit || this.props.edit);
    const ptype = this.props.ptype;

    if (!(this.state.edit || this.props.edit) && this.props.ptype) {
      return (
        <div style={{ display: 'none' }}>
          {this.children({
            props: { ptype, edit },
            propsIdFunc: { registerField: this.registerField }
          })}
        </div>
      )
    }

    return (
      <>
        {this.children({
          props: { row: true, ptype, edit },
          propsIdFunc: { registerField: this.registerField }
        })}

        <div className="input-group-append" data-id={this.props.id}>
          <div>
            <ButtonModal modalClassName="AuditModal" showOnRender={this.props.newrow} scroll padding icon="fs-clipboard" title={this.state.values.ptype ? "Prototype" : `${this.props.type==='LogAuditRow'?'Log Row':'Audit Item'}`} buttonText="View"
              topButtons={<Fragment>
                {edit && ptype && <AddChildButtons childTypes={this.props.childTypes} addChild={this.props.actions.addChild} />}
                {!this.props.nodelete && <SureButton key="delete" icon="fs-trash" buttonText="Delete" onClick={this.props.actions.deleteMe}>Delete?</SureButton>}
              </Fragment>}
            >
              <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />
              {this.children({
                props: { ptype },
                propsIdFunc: { registerField: this.registerField }
              })}
            </ButtonModal>

            {/*<StButtons actions={this.props.actions} /> */}

          </div>
        </div>
      </>
    )
  }
}

//className={`LogAuditRow input-group ${ptype?'ptype':''}`}

/*
  Log audit fields - designed to be displayed horizontally or vertically
*/

const LogField = ({ id, actions, values, value, prepend, children = [], append, row = false, fill = false, edit = false, registerField } = {}) => {

  const rename = async name => {
    await actions.renameMe(name);
    registerField({name});
  }

  const deleteMe = async () => {
    await actions.deleteMe();
    registerField({name: false});
  }

  //Display only in row
  return row
    ? (
      <div><div>{typeof value === "undefined" ? values.value : value}</div></div>
    )
    : (
      <div className="LogField">
        <InlineHelp id={id + "_intro"} edit={edit} name="intro" onChange={actions.setValue} value={values.intro || ''} />

        <div className="input-group input-group-edit">
          <div className="input-group-prepend">
            {edit ?
              <EditableA onChange={rename} editFixedOn>
                {values.name || ""}
              </EditableA>
              :
              <span className={'input-group-text'}>{values.name || ""}</span>
            }
            {prepend}
          </div>
          {children}
          {/*<span className="form-control" />*/}
          <div className={`input-group-append ${fill ? 'fill' : ''}`}>
            {append}
            {edit && <StButtons actions={{...actions, deleteMe}} />}
          </div>
        </div>
      </div>
    )
}

class LogDateField extends Container {

  componentDidMount() {
    super.componentDidMount();

    if (this.props.ptype) this.registerField();
  }

  registerField = ({name}={}) => {
    this.props.registerField(typeof name==='undefined'?this.state.values.name:name);
  }

  render() {

    const ptype = this.props.ptype;

    return (
      <LogField
        id={this.props.id}
        actions={this.props.actions}
        edit={ptype}
        values={this.state.values}
        value={ukdate(this.state.values.value)}
        row={this.props.row}
        registerField={this.registerField}
        fill
      >
        <FormText type="date" placeholder={this.state.values.placeholder} name="value" update={this.props.actions.setValue} value={this.state.values.value} formcontrol={false} />
        {/* <FButton icon="fs-calendar" onClick={e=>this.props.actions.setValue("value", new Date().toISOString().substr(0, 10))}>Today</FButton> */}
      </LogField>
    )
  }
}

class LogTextField extends Container {

  componentDidMount() {
    super.componentDidMount();

    if (this.props.ptype) this.registerField();
  }

  registerField = ({name}={}) => {
    this.props.registerField(typeof name==='undefined'?this.state.values.name:name);
  }

  render() {

    const ptype = this.props.ptype;

    return (
      <LogField
        id={this.props.id}
        actions={this.props.actions}
        edit={ptype}
        values={this.state.values}
        row={this.props.row}
        registerField={this.registerField}
      >
        <FormText placeholder={this.state.values.placeholder} name="value" update={this.props.actions.setValue} value={this.state.values.value} />
      </LogField>
    )
  }
}

class LogCheck extends Container {

  componentDidMount() {
    super.componentDidMount();

    if (this.props.ptype) this.registerField();
  }

  registerField = ({name}={}) => {
    this.props.registerField(typeof name==='undefined'?this.state.values.name:name);
  }

  checkswitch = (currentVal, checkVal) => currentVal === checkVal ? undefined : checkVal;

  vi = {
    true: <span className="fs-check" />,
    false: <span className="fs-times" />,
    undefined: ''
  }

  render() {

    //const value=this.state.values.value===true

    const ptype = this.props.ptype;

    return (
      <LogField
        id={this.props.id}
        actions={this.props.actions}
        edit={ptype}
        values={this.state.values}
        value={this.vi[this.state.values.value]}
        row={this.props.row}
        registerField={this.registerField}
        fill
      >
        <CircleCheck icon="fs-check" title="Yes" checked={this.state.values.value === true} classNameActive={"active"} name="logCheck" onChange={() => this.props.actions.setValue({ value: this.checkswitch(this.state.values.value, true) })} />
        <CircleCheck icon="fs-times" title="No" checked={this.state.values.value === false} classNameActive={"active"} name="logCheck" onChange={() => this.props.actions.setValue({ value: this.checkswitch(this.state.values.value, false) })} />
      </LogField>
    )
  }
}

class LogTextCheck extends Container {

  componentDidMount() {
    super.componentDidMount();

    if (this.props.ptype) this.registerField();
  }

  registerField = ({name,texttitle,checktitle}={}) => {
    this.props.registerField({ name: typeof name==='undefined'?this.state.values.name:name, subname1: typeof texttitle==='undefined'?this.state.values.texttitle:texttitle, subname2: typeof checktitle==='undefined'?this.state.values.checktitle:checktitle });
  }

  setName = (n,v) => {
    this.props.actions.setValue(n,v);
    this.registerField({[n]:v});
  }

  checkswitch = (currentVal, checkVal) => currentVal === checkVal ? undefined : checkVal;

  vi = {
    true: <span className="fs-check" />,
    false: <span className="fs-times" />,
    undefined: ''
  }

  render() {

    //const value=this.state.values.value===true

    const value = (
      <div className="TextCheck">
        <span>{this.state.values.text}</span>
        <span>{this.vi[this.state.values.value]}</span>
      </div>
    )

    const ptype = this.props.ptype;

    return (
      <LogField
        id={this.props.id}
        actions={this.props.actions}
        edit={ptype}
        values={this.state.values}
        value={value}
        row={this.props.row}
        registerField={this.registerField}
      >
        {ptype
          ? <FormText placeholder={"Text Title?"} name="texttitle" update={this.setName} value={this.state.values.texttitle} />
          : <span className="input-group-text">{this.state.values.texttitle}</span>
        }
        <FormText placeholder={this.state.values.placeholder} name="text" update={this.props.actions.setValue} value={this.state.values.text} />

        {ptype
          ? <FormText placeholder={"Check Title?"} name="checktitle" update={this.setName} value={this.state.values.checktitle} />
          : <span className="input-group-text">{this.state.values.checktitle}</span>
        }
        <CircleCheck icon="fs-check" title="Yes" checked={this.state.values.value === true} classNameActive={"active"} name="logCheck" onChange={() => this.props.actions.setValue({ value: this.checkswitch(this.state.values.value, true) })} />
        <CircleCheck icon="fs-times" title="No" checked={this.state.values.value === false} classNameActive={"active"} name="logCheck" onChange={() => this.props.actions.setValue({ value: this.checkswitch(this.state.values.value, false) })} />
      </LogField>
    )
  }
}

/**
 * Base class for Fire Action Plan Builder
 */
class ActionPlanner extends Container {

  updatePlanner = (id, name, del) => {
    this.setState((prevState) => {
      let templates = [];

      if (del) templates = (prevState.templates || []).filter(t => t.id !== id);
      else if (!prevState.templates || prevState.templates.length === 0) templates = [{ id, name }];
      else {
        const tid = prevState.templates.find(t => t.id === id);
        if (tid) templates = prevState.templates.map(t => t.id === id ? { id, name } : t);
        else templates = prevState.templates.concat({ id, name })
      }

      return { templates };
    })
  }

  /*
  Pass to templates to allow cloning of fields to new child
  */
  addCloner = (id, cloner) => {
    this.setState((prevState) => {
      const p = prevState.cloners || {};

      return ({ cloners: { ...p, [id]: cloner } });
    })
  }

  addFireActionPlan = pid => {
    const id = cuid();
    const values = this.state.cloners[pid]();

    //this.props.actions.addChild('FireActionPlan', id, values);

    this.addChildAndFlag('FireActionPlan', id, values);
    this.props.updateSectionOK(true);
  }

  //Pass update to check/OK section to parent
  updateSectionOK = () => {
    const hasPlans = this.state?.children?.find(c => c && c.props.type === 'FireActionPlan');
    this.props.updateSectionOK(!!hasPlans);
  }

  componentDidMount() {
    super.componentDidMount();

    this.updateSectionOK();
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state?.children.length !== prevState.children?.length) this.updateSectionOK();
  }

  /*addTemplate = async () => {
    const id = cuid();
    const name = 'New Fire Action Plan Template';
    const values = {name};

    await this.props.actions.addChild('APTemplate', id, values);

    this.updatePlanner(id, name);
  }*/

  render = () => {
    //const edit=this.props.values.editmode;
    const edit = (this.state.edit || this.props.edit);

    const children = this.children({
      untypes: (edit ? 'FireActionPlan' : 'APTemplate'),
      props: { updatePlanner: this.updatePlanner, addCloner: this.addCloner, edit, sectionicon: this.props.sectionicon },
      propsId: this.state.newchildprops || {}
    });

    const hasPlans = children.find(c => c && c.props.type === 'FireActionPlan');
    const planTypeCount = children.filter(c => c && c.props.type === 'APTemplate').length;

    return (
      <div id={this.props.id} className="ActionPlanner">

        <InlineHelp id={this.props.id + "_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} collapsed={this.state.introcollapsed} expand={this.expandIntro} />
        <ReportIntro id={this.props.id + "_reportintro"} edit={edit} name="reportintro" onChange={this.props.actions.setValue} value={this.state.values.reportintro} />


        {edit && <AddChildButtons childTypes={this.props.childTypes?.filter(ct => ct.type === 'APTemplate').map(c=>({...c, icon: this.props.sectionicon}))} addChild={this.addChildAndFlag} />}


        <InlineHelp id={this.props.id + "_intro2"} edit={edit} name="intro2" onChange={this.props.actions.setValue} value={this.state.values.intro2} collapsed={this.state.introcollapsed} expand={this.expandIntro} />

        {/*!edit && <AddChildButtons childTypes={this.props.childTypes.filter(ct=>ct.type==='FireActionPlan')} addChild={this.props.actions.addChild} />*/}

        {this.props.allowAttachments !== false &&
          <div style={{ textAlign: 'right' }}>
            <AttachDocModal id={`${this.props.id}_attachDoc`} title={`Attachments for: ${this.state.values.name || 'Evacuation Plan'}`} note={this.state.values.note} actions={this.props.actions} docid={this.props.id} attachments={this.props.attachments} stubs={this.state._attachments} />
          </div>
        }

        {hasPlans && <Namer className="sectionTitle" edit={edit} name="title" value={this.state.values.title} defaultName="Evacuation Plans" onChange={this.setValue} />}

        <div className="SurveySections" id={this.props.id}>
          {/*this.children(edit?{untypes: 'FireActionPlan', props: {updatePlanner: this.updatePlanner}}:{untypes: 'APTemplate'})*/}
          {children}
        </div>

        {!edit && (this.state.templates || []).map(t =>
          <FButton addButton glyph="+" key={t.id} onClick={() => this.addFireActionPlan(t.id)}>{`Add${hasPlans && planTypeCount === 1 ? ' Another' : ''} ${t.name}`}</FButton>
        )}

      </div>

    )
  }
}

const APField = ({ fielddata = {}, updateField, actions, live }) => (
  <div className="APField input-group">
    <div className="input-group-prepend">
      {live
        ? fielddata.name
          ? <span className={`form-control APtitle${fielddata.readonly ? ' readonly' : ''}`}>{fielddata.name}</span>
          : ''
        : <FormText name="name" value={fielddata.name} update={updateField} placeholder="Field Name?" className="APtitle" />
      }
    </div>
    {live && fielddata.readonly
      ? fielddata.value
        ? <span className="input-group-text readonly">{fielddata.value}</span>
        : ''
      : <FormCombo values={fielddata.values} name="value" valuesname={live ? false : "values"} value={fielddata.value || ''} update={updateField} />
    }
    {actions && <div className="input-group-append">
      <FButton icon='fs-pencil' overlay={fielddata.readonly ? 'X' : ''} onClick={() => updateField('readonly', !fielddata.readonly)}>{fielddata.readonly ? 'Read Only' : 'Editable'}</FButton>
      <FButton icon='fa fa-bold' overlay={fielddata.bold ? 'Y' : 'N'} onClick={() => updateField('bold', !fielddata.bold)}>{fielddata.bold ? 'Bold' : 'Plain'}</FButton>
      <StButtons actions={actions} />
    </div>}
  </div>
)

/**
 * Fire Action Plan templater
 */
class APTemplate extends Container {

  componentDidMount = () => {
    super.componentDidMount();
    this.props.updatePlanner(this.props.id, this.state.values.name);
    this.props.addCloner(this.props.id, this.cloneMe);
  }

  cloneMe = () => {

    return ({
      name: this.state.values.name,
      toptext: this.state.values.toptext,
      topreadonly: this.state.values.topreadonly,
      fields_main: this.state.values.fields_main,
      fields_lower: this.state.values.fields_lower,
      bottomtext: this.state.values.bottomtext,
      bottomreadonly: this.state.values.bottomreadonly,
    })
  }

  //!Won't update remote client
  updateName = (n, v) => {
    this.props.updatePlanner(this.props.id, v);
    this.props.actions.setValue(n, v);
  }

  //!
  deleteMe = () => {
    this.props.updatePlanner(this.props.id, null, true);
    this.props.actions.deleteMe();
  }

  fieldTemplate = (vals = {}) => ({
    id: cuid(),
    name: '',
    values: [],
    value: null,
    ...vals
  })

  addField = section => {
    const sec = `fields_${section}`;
    const fields = this.state.values[sec] || [];
    const newfield = this.fieldTemplate();

    this.setValue({ [sec]: fields.concat(newfield) });
  }

  updateField = (section, id) => (name, value) => {

    const sec = `fields_${section}`;
    const fields = this.state.values[sec] || [];
    const newfields = fields.map(f => f.id === id ? { ...f, ...(typeof name === 'object' ? name : { [name]: value }) } : f);

    this.props.actions.setValue({ [sec]: newfields });
  }

  move = (section, id, dir) => () => {
    const sec = `fields_${section}`;
    const fields = (this.state.values[sec] || []).slice();

    const idp = fields.findIndex(f => f.id === id);

    const swel = fields[idp + dir];
    if (typeof swel === 'undefined') return;

    const item = fields[idp];

    fields[idp] = swel;
    fields[idp + dir] = item;

    this.props.actions.setValue({ [sec]: fields });
  }

  delete = (section, id) => () => {
    const sec = `fields_${section}`;
    const fields = this.state.values[sec] || [];

    this.props.actions.setValue({ [sec]: fields.filter(f => f.id !== id) });
  }

  factions = (section, id) => ({
    moveUp: this.move(section, id, -1),
    moveDown: this.move(section, id, 1),
    deleteMe: this.delete(section, id)
  })

  sections = [
    { id: 'main', title: 'Main Fields' },
    { id: 'lower', title: 'Lower Fields' }
  ];

  render = () => {

    const edit = (this.state.edit || this.props.edit);

    return (
      <div className={`APTemplate input-group`} data-toggle="buttons" hidden={this.props.hidden}>

        {edit ?
          <EditableA className="ztitle form-control" name="name" onChange={this.updateName} editFixedOn={true}>
            {this.state.values.name || "Action Planner"}
          </EditableA>
          :
          <div className={"ztitle form-control title "} >{this.state.values.name || "Action Planner"}</div>
        }

        <div className="input-group-append">
          <ButtonModal modalClassName="APTemplateModal" scroll padding icon={this.props.sectionicon || 'fs-clipboard'} showOnRender={this.props.newchild} title={this.state.values.name} buttonText="Edit"
            onTitleChange={this.props.actions.renameMe}
            topButtons={<SureButton icon="fs-trash" buttonText="Delete" onClick={this.deleteMe}>Delete?</SureButton>}
          >
            {/*<InlineHelp id={this.props.id+"_intro"} edit={edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />*/}
            <div className="form-group">
              <label>Organisation Name</label>
              <div className="input-group">
                <FormText name="toptext" value={this.state.values.toptext} update={this.props.actions.setValue} placeholder="" />
                <div className="input-group-append">
                  <FButton icon='fs-pencil' overlay={this.state.values.topreadonly ? 'X' : ''} onClick={() => this.setValue('topreadonly', !this.state.values.topreadonly)}>{this.state.values.topreadonly ? 'Read Only' : 'Editable'}</FButton>
                </div>
              </div>
            </div>

            {this.sections.map(s =>
              <div className="card card-edit" key={s.id}>
                <div className="card-header input-group">
                  <div className="input-group-prepend">

                  </div>
                  <span className="input-group-text form-control">{s.title}</span>
                  <div className="input-group-append">
                    <FButton icon="fa fa-indent" onClick={() => this.addField(s.id)} glyph='+' >Add Field</FButton>
                  </div>
                </div>
                <div className="card-block">
                  {(this.state.values[`fields_${s.id}`] || []).map(f =>
                    <APField key={f.id} fielddata={f} updateField={this.updateField(s.id, f.id)} actions={this.factions(s.id, f.id)} />
                  )}
                </div>
              </div>
            )}

            <div className="form-group">
              <div className="input-group">
                <label className="input-group-text form-control">Bottom Text</label>
                <div className="input-group-append">
                  <FButton icon='fs-pencil' overlay={this.state.values.bottomreadonly ? 'X' : ''} onClick={() => this.setValue('bottomreadonly', !this.state.values.bottomreadonly)}>{this.state.values.bottomreadonly ? 'Read Only' : 'Editable'}</FButton>
                </div>
              </div>
              <FormTextArea name="bottomtext" value={this.state.values.bottomtext} update={this.props.actions.setValue} placeholder="Bottom text" />
            </div>

          </ButtonModal>
        </div>

        {/* <StButtons actions={this.props.actions} /> - might be useful to implement*/}
      </div>
    )
  }
}

/**
 * Live Fire Action Plan
 */
class FireActionPlan extends Container {

  updateField = (section, id) => (name, value) => {

    const sec = `fields_${section}`;
    const fields = this.state.values[sec] || [];
    const newfields = fields.map(f => f.id === id ? { ...f, ...(typeof name === 'object' ? name : { [name]: value }) } : f);

    this.props.actions.setValue({ [sec]: newfields });
  }

  sections = [
    { id: 'main', title: 'Main Fields' },
    { id: 'lower', title: 'Lower Fields' }
  ];

  render = () => {

    return (
      <div className={`FireActionPlan input-group`} data-toggle="buttons" hidden={this.props.hidden}>

        <div className={"ztitle form-control input-group-text title toplevel"} onClick={() => { if (this.state.view) this.state.view() }}>{this.state.values.name || "Action Planner"}</div>

        <div className="input-group-append">
          {this.state.when!==0 && this.state.when && <span className="input-group-text editdate">Last Edit<br />{ukdate(this.state.when)}</span>}
          <ButtonModal getShow={s => this.setState({ view: s })} modalClassName="APTemplateModal" showOnRender={this.props.newchild} scroll padding icon={this.props.sectionicon || 'fs-clipboard'} title={this.state.values.name} buttonText="Edit"
            onTitleChange={this.props.actions.renameMe}
            topButtons={<SureButton icon="fs-trash" buttonText="Delete" onClick={this.props.actions.deleteMe}>Delete?</SureButton>}
          >
            {/*<InlineHelp id={this.props.id+"_intro"} edit={this.state.edit} name="intro" onChange={this.props.actions.setValue} value={this.state.values.intro} />*/}
            <div className="form-group">
              <label className="APtitle">Organisation Name</label>
              {this.state.values.topreadonly
                ? <div className="readonly">{this.state.values.toptext}</div>
                : <FormText name="toptext" value={this.state.values.toptext} update={this.props.actions.setValue} placeholder="" />
              }
            </div>

            {this.sections.map(s =>
              <div className="card card-edit FireActionPlan-card" key={s.id}>
                {/*<div className="card-header input-group">
                  <div className="input-group-prepend">
                    
                  </div>
                  <span className="input-group-text form-control">{s.title}</span>
                </div>*/}
                <div className="card-block">
                  {(this.state.values[`fields_${s.id}`] || []).map(f =>
                    <APField key={f.id} fielddata={f} updateField={this.updateField(s.id, f.id)} live />
                  )}
                </div>
              </div>
            )}

            <div className="form-group">
              <label className="APtitle">Warning Text</label>
              {this.state.values.bottomreadonly
                ? <div className="readonly">{this.state.values.bottomtext}</div>
                : <FormTextArea name="bottomtext" value={this.state.values.bottomtext} update={this.props.actions.setValue} placeholder="Warning text" />
              }
            </div>

          </ButtonModal>

          {/*<StButtons actions={this.props.actions} /> */}
        </div>

        {/*<StButtons actions={this.props.actions} /> */}
      </div>
    )
  }
}


const GlobalHelp = () => {

  const [search, setSearch] = useState('');
  const [help, setHelp] = useState('');
  const [searchHelp, setSearchHelp] = useState(false);
  const [foundCount, setFoundCount] = useState(false);
  const [scrollId, setScrollId] = useState(false);

  useEffect(() => {
    //(async () => {
      /*try {
        //var gethelp = await fetch(gh);
      } catch (err) {
        console.log("Failed to get global help");
      }
      
      setHelp(gethelp?await gethelp.text() || '':'');
      */
      setHelp(globalhelp);
    //})();
  }, []);

  useEffect(() => {
    if (scrollId) scrollTo(`help_${scrollId}`);
  });

  const updateSearch = s => {
    setSearch(s);
    if (s && s.length > 2) {
      //for(var count=-1,index=-2; index != -1; count++,index=help.indexOf(s,index+1) );

      //setFoundCount(count);

      //!Ideally, grab the full line that the match is on, then create a match quality array and "next" to each match in that order

      const re = new RegExp(s, "gi");

      let c = 0;
      setSearchHelp(help.replace(re, (v, i) => {
        c++;
        return `<span id="help_${c}" class="hf">${v}</span>`
      }));

      setFoundCount(c);
      if (c) {
        setScrollId(1);
      }
    }
    else {
      setFoundCount(false);
      setSearchHelp(false);
      setScrollId(false);
    }
  }

  const goToNext = () => {
    if (search && scrollId && scrollId < foundCount) setScrollId(scrollId + 1);
  }

  return (
    <ButtonModal buttontitle="Help" modalClassName="" scroll icon={"fs-help"} modalIcon="fa fa-search" title={
      <div className="input-group">
        <FormText className="form-control" value={search} update={updateSearch} placeholder="Search Help" autoupdate autoupdatedelay={250} />
        <div className="input-group-append">
          {foundCount !== false && <span className="input-group-text">Found: {foundCount}</span>}
        </div>
      </div>
    }
      topButtons={foundCount && <FButton icon="fs-angle-double-right" onClick={goToNext} >Next</FButton>}
      buttonText="">

      <div className="globalhelp-body" id="ghb">
        <FSMarkdown markup={searchHelp || help} />
        {/*help*/}
      </div>

    </ButtonModal>
  );
}


const NextButton = ({ onClick, title, icon = 'fs-arrow-1-right ' }) => (
  <div className="NextButton">
    <button className="btn bluebutton" onClick={onClick}>{title || "Next"} <span className={icon} /></button>
  </div>
)

/* Bootstrap panes (revealed by tabs) */
const Pane = props => (
  <div className={"tab-pane" + (props.activepane === props.id ? " active" : "")} id={props.id} hidden={props.show === false}>
    {props.children}
    {props.next && <NextButton onClick={props.next} />}
  </div>
)

const orgtypes = [
  { type: 'Organisation', class: BaseFolder, title: 'Organisation', icon: '', childTypes: ['Site'] },
  { type: 'Site', class: Site, title: 'Site', icon: 'fs-map-marker', childTypes: [] }
];

//SiteTypes
const sitetypes = [
  /*{type: 'SiteData', class: SiteData, title: 'Site', icon: 'fs-map-marker', reserveWatcher: true, childTypes: ['Assessment',{type: 'SiteImage', minAllowed: 0, maxAllowed: 1},{type: 'SiteInfo', minAllowed: 1, maxAllowed: 1}, {type: 'SiteLogo', minAllowed: 0, maxAllowed: 1}]}, */
  { type: 'SiteData', class: SiteData, title: 'Site', icon: 'fs-map-marker', reserveWatcher: true, childTypes: ['CalcField', 'TextField', 'TextAreaField', 'DateField', 'ImageField', 'Assessment'] },
  { type: 'CalcField', class: AuditCalcField, title: 'Calc Field', icon: 'fs-calculator', reserveWatcher: true, childTypes: [] },
  { type: 'TextField', class: AuditTextField, title: 'Text Field', icon: 'fs-pencil-square-o', reserveWatcher: true, childTypes: [] },
  { type: 'TextAreaField', class: AuditTextAreaField, title: 'Text Area Field', icon: 'fs-pencil-square-o', reserveWatcher: true, childTypes: [] },
  { type: 'DateField', class: AuditDateField, title: 'Date Field', icon: 'fs-calendar', reserveWatcher: true, childTypes: [] },
  { type: 'ImageField', class: AuditImageField, title: 'Image Field', icon: 'fs-camera', reserveWatcher: true, childTypes: [] },
  { type: 'Assessment', class: Assessment, title: 'Assessment', icon: 'fs-content-7', reserveWatcher: true, childTypes: [] }
  /*{type: 'SiteImage', class: SiteImage, title: 'Site Image', icon: 'fs-camera', reserveWatcher: true, childTypes: []},
  {type: 'SiteLogo', class: SiteImage, title: 'Logo', icon: 'fs-circle-o', reserveWatcher: true, childTypes: []},
  {type: 'SiteInfo', class: SiteInfo, title: 'Site Info', icon: 'fs-clipboard', reserveWatcher: true, childTypes: []}*/
];

//Data sections on "Site" tab within Assessment
const siteinfotypes = [
  { type: 'SiteInfo', class: SiteInfo, title: 'Site', icon: 'fs-map-marker', childTypes: ['AssDataSection', 'CategorySection', 'LicensesSection', 'LocationSection'], pane: 'assinfo' },
  { type: 'AssDataSection', class: AssDataSection, title: 'Assessment Data', icon: 'fs-clipboard', childTypes: [] },
  { type: 'CategorySection', class: CategorySection, title: 'Categories', icon: 'fs-clipboard', childTypes: [] },
  { type: 'LicensesSection', class: LicensesSection, title: 'Licences', icon: 'fs-clipboard', childTypes: [] },
  { type: 'LocationSection', class: LocationSection, title: 'Locations', icon: 'fs-clipboard', childTypes: [] }
]

const assinfotypes = [
  { type: 'AssessmentData', class: AssessmentData, title: 'Assessment Data', icon: 'fs-clipboard', childTypes: ['CalcField', 'TextField', 'TextAreaField', 'DateField', 'ImageField', 'SiteTextField', 'SiteTextAreaField'], pane: 'assinfo' },
  { type: 'CalcField', class: AuditCalcField, title: 'Calc Field', icon: 'fs-calculator', childTypes: [] },
  { type: 'TextField', class: AuditTextField, title: 'Text Field', icon: 'fs-pencil-square-o', childTypes: [] },
  { type: 'TextAreaField', class: AuditTextAreaField, title: 'Text Area Field', icon: 'fs-pencil-square-o', childTypes: [] },
  { type: 'DateField', class: AuditDateField, title: 'Date Field', icon: 'fs-calendar', childTypes: [] },
  { type: 'ImageField', class: AuditImageField, title: 'Image Field', icon: 'fs-camera', childTypes: [] },
  { type: 'SiteTextField', class: SiteTextField, title: 'Site Text Field', icon: 'fs-pencil-square-o', childTypes: [] },
  { type: 'SiteTextAreaField', class: SiteTextAreaField, title: 'Site Text Area Field', icon: 'fs-pencil-square-o', childTypes: [] }
]

const categorytypes = [
  { type: 'Categories', class: Categories, title: 'Categories', icon: '', childTypes: ['Category'] },
  { type: 'Category', class: Category, title: 'Category', icon: 'fs-industry', childTypes: ['EscapeArea'] },
  { type: 'EscapeArea', class: EscapeArea, title: 'Escape Area', icon: 'fs-logout', childTypes: [] }
]

const risktypes = [
  { type: 'RiskSurvey', class: RiskSurvey, title: 'Survey', icon: '', childTypes: ['SurveySection'], pane: 'risk' },
  { type: 'SurveySection', class: RiskSurveySection, title: 'Survey Section', icon: 'fs-folder-o', childTypes: ['Audit', 'Risk'] },
  { type: 'Risk', class: Risk, title: 'Risk', icon: 'fs-exclamation-triangle', childTypes: ['Measure', 'PrecautionMeasure', 'CalcField', 'TextField', 'DateField', 'ImageField', 'Audit'] }, //,'Item'
  /*{type: 'GlobalAudit', class: GlobalAudit, title: 'Global Audit', icon: 'fs-clipboard', childTypes: ['Measure','CalcField','TextField','DateField']},*/
  { type: 'Measure', class: Measure, title: 'Measure', icon: 'fs-wrench', childTypes: [] },
  { type: 'Audit', class: Audit, title: 'Audit', icon: 'fs-clipboard', childTypes: ['Measure', 'CalcField', 'TextField', 'DateField', 'ImageField', 'LocationField'] },
  { type: 'CalcField', class: AuditCalcField, title: 'Calc Field', icon: 'fs-calculator', childTypes: [] },
  { type: 'TextField', class: AuditTextField, title: 'Text Field', icon: 'fs-pencil-square-o', childTypes: [] },
  { type: 'DateField', class: AuditDateField, title: 'Date Field', icon: 'fs-calendar', childTypes: [] },
  { type: 'ImageField', class: AuditImageField, title: 'Image Field', icon: 'fs-camera', childTypes: [] },
  { type: 'LocationField', class: AuditLocationField, title: 'Location Field', icon: 'fs-map-marker', childTypes: [] },
  { type: 'PrecautionMeasure', class: PrecautionMeasure, title: 'Precaution Measure', icon: 'fs-tools', childTypes: ['ExtinguisherPrecaution', 'Precaution'] },
  { type: 'ExtinguisherPrecaution', class: ExtinguisherPrecaution, title: 'Extinguisher', icon: 'fs-fire-extinguisher', childTypes: [] },
  { type: 'Precaution', class: Precaution, title: 'Precaution', icon: 'fs-tools', childTypes: [] }
  /*{type: 'Item', class: Item, title: 'Item', icon: 'fa fa-cube', childTypes: ['Audit']}*/
];

const equipmenttypes = [
  { type: 'EquipmentSurvey', class: EquipmentSurvey, title: 'Survey', icon: '', childTypes: [{ type: 'EscapeRouteSection', minAllowed: 1, maxAllowed: 1 }, { type: 'ExtinguisherSection', minAllowed: 1, maxAllowed: 1 }, 'SurveySection', 'ExtinguisherCalculator', { type: 'EmergencyEscapeSection', minAllowed: 1, maxAllowed: 1 }], pane: 'equipment' },
  { type: 'EscapeRouteSection', class: EscapeRouteSection, title: 'Escape Route Calculator', icon: 'fs-logout', childTypes: [{ type: 'EnclosedArea', userAdd: true }, { type: 'OpenPlanArea', userAdd: true }] }, //'Measure','CalcField','TextField','DateField','ImageField','Audit', , 'EnclosedAreaAudit'
  { type: 'ExtinguisherSection', class: ExtinguisherSection, title: 'Extinguisher Section', icon: 'fs-fire-extinguisher', childTypes: ['Measure', 'CalcField', 'TextField', 'DateField', 'ImageField', 'Audit', 'ExtinguisherType'] },
  { type: 'EmergencyEscapeSection', class: EmergencyEscapeSection, title: 'Emergency Evacuation Plan', icon: 'fs-clipboard', childTypes: [] },
  { type: 'ExtinguisherCalculator', class: ExtinguisherCalculatorSection, title: 'Extinguisher Calculator', icon: 'fs-fire-extinguisher', childTypes: [] },
  { type: 'SurveySection', class: PrecautionSurveySection, title: 'Survey Section', icon: 'fs-folder-o', childTypes: ['Measure', 'CalcField', 'TextField', 'DateField', 'ImageField', 'Audit', 'Equipment'] },
  { type: 'Equipment', class: Equipment, title: 'Precaution', icon: 'fs-tools', childTypes: ['Type', 'Measure', 'CalcField', 'TextField', 'DateField', 'ImageField', 'Audit'] }, //,'Item'
  /*{type: 'GlobalAudit', class: GlobalAudit, title: 'Global Audit', icon: 'fs-clipboard', childTypes: ['Measure','CalcField','TextField','DateField']},*/
  { type: 'Type', class: EquipmentType, title: 'Type', icon: 'fs-tools', childTypes: [] },
  { type: 'Measure', class: Measure, title: 'Measure', icon: 'fs-wrench', childTypes: [] },
  { type: 'Audit', class: Audit, title: 'Audit', icon: 'fs-clipboard', childTypes: ['Measure', 'CalcField', 'TextField', 'DateField', 'ImageField', 'LocationField'] },
  { type: 'ExtinguisherType', class: ExtinguisherType, title: 'Extinguisher Type', icon: 'fs-fire-extinguisher', childTypes: ['Extinguisher', 'Audit'] },
  { type: 'Extinguisher', class: Extinguisher, title: 'Extinguisher', icon: 'fs-fire-extinguisher', childTypes: [] },
  { type: 'CalcField', class: AuditCalcField, title: 'Calc Field', icon: 'fs-calculator', childTypes: [] },
  { type: 'TextField', class: AuditTextField, title: 'Text Field', icon: 'fs-pencil-square-o', childTypes: [] },
  { type: 'DateField', class: AuditDateField, title: 'Date Field', icon: 'fs-calendar', childTypes: [] },
  { type: 'ImageField', class: AuditImageField, title: 'Image Field', icon: 'fs-camera', childTypes: [] },
  { type: 'LocationField', class: AuditLocationField, title: 'Location Field', icon: 'fs-map-marker', childTypes: [] },
  /*{type: 'Item', class: Item, title: 'Item', icon: 'fa fa-cube', childTypes: ['Audit']},*/
  //{type: 'EscapeLocation', class: EscapeLocation, title:'Location', icon: 'fs-map-marker', childTypes: []}
  { type: 'EnclosedArea', class: EnclosedArea, title: 'Enclosed Area', icon: 'fs-room-1', childTypes: ['EscapeRoute'] },
  //{type: 'EnclosedAreaAudit', class: Audit, title: 'Enclosed Area Audit', icon: 'fs-clipboard', childTypes: ['Measure','CalcField','TextField','DateField','ImageField','LocationField']},
  { type: 'OpenPlanArea', class: OpenPlanArea, title: 'Open Plan Area', icon: 'fs-room-1', childTypes: ['EscapePoint'] },
  { type: 'EscapeRoute', class: EscapeRoute, title: 'Escape Route', icon: 'fs-logout', childTypes: [] },
  { type: 'EscapePoint', class: EscapePoint, title: 'Escape Point', icon: 'fs-map-marker', childTypes: [] }
  //{type: 'Level', class: ExtinguisherCalculatorLevel, title:'Level', icon: 'fs-map-marker', childTypes: []}
];

const locationtypes = [
  { type: 'LocationSurvey', class: LocationSurvey, title: 'Locations', icon: 'fs-map-marker', childTypes: ['Building'] },
  { type: 'Building', class: LocationBuilding, title: 'Building', icon: 'fs-industry', childTypes: ['Level'] },
  { type: 'Level', class: LocationLevel, title: 'Floor', icon: 'fs-level', childTypes: ['Room'] },
  { type: 'Room', class: LocationRoom, title: 'Room', icon: 'fs-room-1', childTypes: [] }
]

const licensetypes = [
  { type: 'LicenseSurvey', class: LicenseSurvey, title: 'Licences', icon: 'fs-clipboard', childTypes: ['License'] },
  { type: 'License', class: License, title: 'Licence', icon: 'fs-clipboard', childTypes: [] }
]

const eclocationtypes = [
  { type: 'LocationSurvey', class: ECLocationSurvey, title: 'Locations', icon: 'fs-map-marker', childTypes: ['Building'] },
  { type: 'Building', class: ECLocationBuilding, title: 'Building', icon: 'fs-industry', childTypes: ['Level'] },
  { type: 'Level', class: ECLocationLevel, title: 'Floor', icon: 'fs-level', childTypes: [] },
  { type: 'Room', class: LocationRoom, title: 'Room', icon: 'fs-room-1', childTypes: [] }
]

const actiontypes = [
  { type: 'ActionSummary', class: ActionSummary, title: 'Action Summary', icon: '', childTypes: [] }
]

const logtypes = [
  { type: 'Log', class: Log, title: 'Log', icon: '', childTypes: ['LogSection'] },
  { type: 'LogSection', class: LogSection, title: 'Log Section', icon: 'fs-clipboard', childTypes: ['LogAudit'] },
  { type: 'LogAudit', class: LogAudit, title: 'Log Audit', icon: 'fs-clipboard', childTypes: ['LogAuditRow'] },
  { type: 'LogAuditRow', class: LogAuditRow, title: 'Row', icon: 'fs-android-remove', childTypes: ['LogDateField', 'LogTextField', 'LogCheck', 'LogTextCheck'] },
  { type: 'LogDateField', class: LogDateField, title: 'Date', icon: 'fs-calendar', childTypes: [] },
  { type: 'LogTextField', class: LogTextField, title: 'Text', icon: 'fs-pencil-square-o', childTypes: [] },
  { type: 'LogCheck', class: LogCheck, title: 'Check', icon: 'fs-check', childTypes: [] },
  { type: 'LogTextCheck', class: LogTextCheck, title: 'Text Check', icon: 'fs-check-square-o', childTypes: [] }
]

const audittypes = [
  { type: 'Audit', class: NewAudit, title: 'Audit', icon: '', childTypes: ['AuditSection'] },
  { type: 'AuditSection', class: AuditSection, title: 'Audit Section', icon: 'fs-clipboard', childTypes: ['AuditAudit'] },
  { type: 'AuditAudit', class: LogAudit, title: 'Audit', icon: 'fs-clipboard', childTypes: ['AuditAuditRow'] },
  { type: 'AuditAuditRow', class: LogAuditRow, title: 'Row', icon: 'fs-android-remove', childTypes: ['AuditDateField', 'AuditTextField', 'AuditCheck', 'AuditTextCheck'] },
  { type: 'AuditDateField', class: LogDateField, title: 'Date', icon: 'fs-calendar', childTypes: [] },
  { type: 'AuditTextField', class: LogTextField, title: 'Text', icon: 'fs-pencil-square-o', childTypes: [] },
  { type: 'AuditCheck', class: LogCheck, title: 'Check', icon: 'fs-check', childTypes: [] },
  { type: 'AuditTextCheck', class: LogTextCheck, title: 'Text Check', icon: 'fs-check-square-o', childTypes: [] }
]

const actionplantypes = [
  { type: 'ActionPlanner', class: ActionPlanner, title: 'Action Plan Builder', icon: 'fs-fire-action-plan', childTypes: ['APTemplate', 'FireActionPlan'] },
  { type: 'APTemplate', class: APTemplate, title: 'Action Plan Template', icon: 'fs-clipboard', childTypes: [''] },
  { type: 'FireActionPlan', class: FireActionPlan, title: 'Fire Action Plan', icon: 'fs-clipboard', childTypes: [''] }
]


//Every type list
const typelist = [siteinfotypes, assinfotypes, risktypes, equipmenttypes, categorytypes, licensetypes, locationtypes, actiontypes, audittypes, logtypes, actionplantypes];

//Type lists that should be evaluated for sectionok=true
const sectiontypelist = [siteinfotypes, risktypes, equipmenttypes];


const forceOnline = [
  { id: null, title: 'Auto', color: 'black' },
  { id: true, title: 'Force Online', color: 'green' },
  { id: false, title: 'Force Offline', color: 'red' }
]

class Firesmart extends Component {
  constructor(props) {
    super(props);

    //! Add isLoggedIn

    //CacheImages();

    this.state = {
      loggedin: false,
      topinfo: [],
      view: "login",
      activepane: null,
      loadsiteid: null,
      siteid: null,
      sitename: null,
      surveyid: null,
      expandid: null,
      itemid: null,
      admin: false,
      isSuper: false,
      online: false,
      forceOnline: null,
      syncing: false,
      email: null,
      logoutreason: false,
      adminsite: null
    }

    this.tabs =
      [
        { id: "loading", view: ["loading"], title: "Loading", icon: "" },
        { id: "login", view: ["login"], title: "Login", icon: "fs-user" },
        { id: "org", view: ["org", "site"], title: "Org", icon: "fs-sitemap", onClick: this.org },
        { id: "site", view: ["site"], title: "Home", icon: "fs-home", onClick: () => this.setView("site", "site") },
        { id: "assinfo", view: ["survey"], title: "Site", icon: "fs-map-marker", onClick: () => this.setView("survey", "assinfo") },
        { id: "risk", view: ["survey"], title: "Hazards", icon: "fs-exclamation-triangle", onClick: () => this.setView("survey", "risk") },
        { id: "equipment", view: ["survey"], title: process.env.REACT_APP_TAB_EQUIPMENT || "Fire Precautions", icon: "fs-fire-precautions", onClick: () => this.setView("survey", "equipment") },
        { id: "actionsummary", view: ["survey"], title: "Action Summary", icon: "fs-action-summary", onClick: () => this.setView("survey", "actionsummary") },
        //{id: "log", view: ["survey"], title: "Log", icon: "fs-log", onClick: ()=>this.setView("survey","log")}
        //{id: "logout", view: ["org","site","survey"], title: "Exit", icon: "fs-logout", onClick: this.logout}
      ];

    this.sync = {};

    //Log out on unload
    window.onunload = () => this.logout;

    //Close window if requested by admin
    window.addEventListener('message', e => {
      if (e.data === "Close") window.close();
    })

  }

  org = async () => {
    //await pos({site: false});
    await clearPos();
    this.setState({ siteid: null });
    this.setView("org", "org");
  }

  setOnline = online => {
    //console.log("setOnline: ", online);

    const bonline = (this.state.forceOnline !== null) ? this.state.forceOnline : online;

    if (bonline !== this.state.online) {
      this.setState({ online: bonline });
      this.onlineStateChange(bonline);
    }
  }

  onlineStateChange = async online => {

    //console.log("Online: ", online);

    //!!!Experimental
    if (online) {
      //const em = this.email || await loadUseremail();
      const em = this.state.email || await loadUseremail();
      if (em && !this.state.adminsite) {
        try {
          if (!this.state.adminsite) {
            await cancelUserSync();
            await userSync(em, undefined, this.logout);
          }
        } catch (err) {
          console.log("onlineStateChange error: ", err);
          //this.logout();
        }
      }
      if (this.state.siteid) {

        try {
          await cancelSiteSync();
          await siteSync(this.state.siteid, this.syncUpdate, this.syncStart, this.syncComplete, this.logout);
        } catch (err) {
          this.logout();
        }
      }
    }
    else {
      cancelUserSync();
      cancelSiteSync();
    }

  }

  syncStart = () => {
    if(this._mounted!==false) this.setState({ syncing: true });
    //console.log("Sync start");
  }

  syncComplete = () => {
    if(this._mounted!==false) this.setState({ syncing: false });
    //console.log("Sync complete");
  }

  /**
   * Runs (ping) check to see if app is online
   */
  runOnlineChecker = async () => {

    let scheck = 0;

    this.setOnline(await isOnline({ timeout: 2000 }));

    const check = (timeout = 2000) => async () => {

      const isonline = await isOnline({ timeout });
      this.setOnline(isonline);
      scheck++;

      if (scheck > 4) {
        scheck = 0;
        if (!this.state.online) {
          this.pollingId = setTimeout(check(6000), 10000);
        }
        else this.pollingId = setTimeout(check(), 5000);
      }
      else this.pollingId = setTimeout(check(), 5000);
    }

    this.pollingId = setTimeout(check(), 5000);

    /*this.pollingId = setInterval(async () => {
      //console.log("ping check...");
      this.setOnline(await isOnline({timeout: 2000}));
    }, 5000);*/

  }

  douserlogin = async (email, password, adminsite) => {

    /*if(!(email && password)) {
      this.setState({logoutreason: 'Please enter username (email) and password'});
      return;
    }*/

    //console.log("DO user login: ", email, password, this.state);

    this.setState({ logoutreason: false });

    /*const prevlogin =*/ await userlogin(email, password, this.logout);

    //console.log("douserlogin: ", prevlogin);

    this.setState({ email });

    //!Good concept, but too soon to log out?
    /*if(!prevlogin) {
      console.log("No previous login - sync required");
      return this.logout();
    }*/

    var sync;

    //if(this.state.online) {
    try {
      //console.log("douserlogin sync...");
      if (!this.state.adminsite) {
        sync = await userSync(email, password, this.logout);
      }
      //this.setState({email});
      this.login(this.state.adminsite);

      if (this.state.adminsite) {
        setUserPassword(password);

        this.loadSite(this.state.adminsite, this.state.adminsitename || 'User Site');
      }

      //console.log("douserlogin sync complete");
    } catch (err) {
      //this.setState({email: null});
      console.log("douserlogin error: ", err);

      if(err.status===401) this.logout(err.message);
      else this.logout('Please log in');
    }
    //} else {
    //  this.logout("Must be online to login!");
    //}

    return sync;
  }

  /**
   * If Firesmart is page is refreshed, attempt to return to position left by user
   */
  loadpos = async () => {

    this.setView('loading', 'loading');

    const email = this.state.email || await loadUseremail();

    if (!email) {
      this.setView('login', 'login');
      return;
    }

    let user;

    try {
      console.log("Attempting user login...");
      //user = await userlogin(email, undefined, this.logout);
      user = await this.douserlogin(email);
    } catch (err) {
      console.log("Failed to auto-login");
      if (!email) this.setView('login', 'login');
      return;
    }

    if (user === false || (typeof user === 'object' && user.success === false)) {
      this.setView('login', 'login');
      return;
    }

    const posin = await pos();
    if (!posin) {
      //this.login();
      this.setView('login', 'login');
      return;
    }

    //console.log("Firesmart pos: ", posin);

    if (posin.siteid) await this.loadSite(posin.siteid, posin.sitename);
    else return;

    if (posin.surveyid) await this.loadSurvey(posin.surveyid, false);
    //else return;

    //Pane to expand
    //if(posin && posin.id) {
    //  this.setState({expandid: posin.id});
    //}

    if (posin.view && posin.activepane) this.setView(posin.view, posin.activepane, posin.id, posin.itemid);
  }

  loadactualname = async () => {
    const a = await localData('actualname');

    if (a) actualname = a;
  }

  onExpand = (id, itemid) => {
    return pos({ siteid: this.state.siteid, sitename: this.state.sitename, surveyid: this.state.surveyid, view: this.state.view, activepane: this.state.activepane, id, itemid });
  }

  //Log user out
  //!Needs soft/hard logout
  logout = async (logoutreason, keeplocal = false) => {
    console.log("FS logout");

    this.setState({ logoutreason });

    this.setState({ loggedin: false, siteid: null, adminsite: null, adminsitename: null, surveyid: null, admin: false, isSuper: false, topinfo: [] });

    clearInterval(this.pollingId);

    try {
      await userlogout('fs'); //keeplocal
    } catch (err) {
      console.log("Log out failed! " + err.message); //!Error handling
      //return;
    }

    if (this.syncTimeout) clearTimeout(this.syncTimeout);


    this.setView("login", "login");
  }

  //Log out then close window (if possible - for admin)
  logoutclose = async () => {
    await this.logout();
    window.close();
  }

  /*_bind(...methods) {
    methods.forEach(method=>this[method]=this[method].bind(this));
  }*/

  componentDidMount = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const adminsite = urlParams.get('site');
    const adminsitename = urlParams.get('sitename');
    const emailparam = urlParams.get('email');

    if (adminsite) {

      console.log("adminsite!! - setting isSuper");

      this.setState({ adminsite, adminsitename, siteid: null, isSuper: true });
      window.history.pushState({}, null, window.location.href.split('?')[0]);
    }

    if (emailparam) {
      this.setState({ email: emailparam });
      window.history.pushState({}, null, window.location.href.split('?')[0]);
      this.logout();
    } else {
      if (!adminsite) {
        this.loadactualname();
        await this.loadpos();
      }
    }

    this.runOnlineChecker();
  }

  componentWillUnmount() {
    clearInterval(this.pollingId);

    this._mounted = false; //This is bad
  }

  //setState that returns a promise
  setStateP = (stateobj) => {
    return new Promise(resolve => { if (this._mounted !== false) this.setState(stateobj, () => resolve()) });
  }

  //What to do once a user has been verified for log in
  login = (noSetView = false) => {
    this.setState({ loggedin: true });
    this.addTopInfo({ id: "user", icon: "fs-user", info: email() });

    if (noSetView) return; //Just for admin site login

    this.setView("org", "org");

    //this.loadpos();
  }

  syncUpdate = (dir, info) => {
    //console.log("SyncUpdate... ",dir);
    /*if(!this.state.siteid) {
      console.log("Syncupdate without siteid: ", dir, info);
      return;
    }*/

    if (dir === "pause") this.sync["pause"] = true;
    else {
      this.sync["pause"] = false;
      this.addTopInfo({ id: dir, icon: dir === "push" ? "fs-arrow-1-up fs-throb" : "fs-arrow-1-down fs-throb", info: info ? info : (dir === "push" ? "Uploading" : "Downloading") })
      //.then(()=>{
      const r = Math.random();
      this.sync[dir] = r;
      this.syncTimeout = setTimeout(() => {
        if (this.sync[dir] === r) {
          if (this.sync["pause"] === true) this.addTopInfo({ id: dir, info: false });
          else this.syncUpdate(dir, info);
        }
      }, info ? 5000 : 1000);
      //})
    }
  }

  //Add info to info bar (current user, site). Remove item if info.info===false
  addTopInfo = async (info) => {
    if (!this.state.loggedin) return;
    let ns = (this.state && this.state.topinfo ? this.state.topinfo.slice() : []);

    const fi = ns.findIndex(ti => ti.id && ti.id === info.id);
    if (fi > -1) {
      if (info.info === false) ns.splice(fi, 1);
      else ns[fi] = info;
    }
    else ns.push(info);

    return this.setStateP({ topinfo: ns });
    //console.log("addTopInfo state: ", this.state);
    //return new Promise(resolve => {resolve()});
  }

  //Change tabs set, optionally set active pane (default=first pane in set)
  setView = async (view, activepane, id, itemid, subitemid) => {
    //console.log("setView", view, activepane, id, itemid);

    //Save current location
    const prevPos = await pos();
    this.setState({ prevPos });

    pos({ siteid: this.state.siteid, sitename: this.state.sitename, view, activepane, surveyid: this.state.surveyid, id, itemid });
    return this.setStateP({ view: view, activepane: activepane || null, expandid: id, itemid }).then(()=>{
      scrollTo(itemid);

      //Temporarily highlight item
      if(subitemid) {
        document.getElementById(subitemid).classList.add('temphighlight');
        setTimeout(()=>{
          document.getElementById(subitemid).classList.remove('temphighlight');
        }, 5000);
      }
    });
  }

  //Go back to previous position
  prevView = () => {
    if(this.state.prevPos) {
      const {view, activepane, id, itemid} = this.state.prevPos;
      this.setView(view, activepane, id, itemid);
      return true;
    }
    return false;
  }

  actionSetView = (activepane, id, itemid) => {
    //console.log("actionSetView: ", activepane, id, itemid);
    return this.setView("survey", activepane, id, itemid);
  }

  //Load a site
  loadSite = async (siteid, sitename) => {

    //console.log("Load site ",siteid);

    if (!siteid) return;

    //clearInterval(this.pollingId);
    //await pos({siteid: false}); //Clear pos
    await clearPos();

    this.setState({ siteid: null });

    try {
      //await openSiteDB(siteid, this.syncUpdate, this.logout);
      var hasSynced = await openSiteDB(siteid, this.logout);

      const isSuper = this.state.isSuper || await isSuperUser();

      this.setState({ isSuper });
    } catch (err) {
      //console.log(err); //! Do something more useful
    }

    //console.log("Loadsite - online? ", this.state.online);

    //!Need to check if site has synced if offline
    if (this.state.online) {
      try {
        await siteSync(siteid, this.syncUpdate, this.syncStart, this.syncComplete, this.logout);
      } catch (err) {
        console.log("loadSite error: ", err);
        //this.logout();
        return;
      }
    }
    else if (!hasSynced) {
      console.log("Not previously synced!");
      return;
    }

    //console.log("openSiteDB arguments: ", arguments);
    this.setState({ siteid, sitename });
    this.addTopInfo({ id: "site", icon: "fs-map-marker", info: sitename });
    this.setView("site", "site");
  }

  //add a new survey (assessment)
  addSurvey = async () => {
    const sid = cuid();

    console.log("Add survey!!");

    try {
      addSurvey(sid, typelist) //areatypes,
    } catch (err) {
      console.log("add Survey error: ", err);
      return; //!
    }

    return sid;
  }

  deleteSurvey = (surveyid) => {
    //!Should be promise
    return deleteSurvey(surveyid, typelist)
      .catch(err => {
        console.log("Error deleting survey: ", err);
      })
  }

  //Load a survey
  loadSurvey = async (surveyid, setview = true) => {
    console.log("Load Survey ", surveyid);

    //resetWatchers();
    resetSiteWatchers();

    await this.setStateP({ surveyid: surveyid })

    await updateAssessmentLoginDate(surveyid);

    if (setview === true) return this.setView("survey", "assinfo");
    else if (setview) return this.setView("survey", setview);
  }

  cloneSurvey = (surveyid) => {
    const newsid = cuid();

    return cloneSurvey(newsid, surveyid, typelist)
      .then(res => res && newsid)
      .catch(err => {
        console.log("Clone Survey error: ", err);
      })
  }

  /*importSurvey(newsid, items) {
    const newsid=cuid();

    return importSurvey(newsid, items)
    .then(res=>newsid)
    .catch(err=>{
      console.log("Error in importSurvey: ", err);
    })
  }*/

  downloadSurvey = (surveyid) => {
    return downloadSurvey(surveyid, typelist);
  }

  //Expand from the root down
  doExpand = id => {

    //const expanded = {"survey": {"risk": { }}
  }

  xbutton = () => {
    if (this.state.view === "survey") this.setView('site', 'site');
    else this.logout("You logged out");
  }

  adminmode = () => {
    this.setState({ admin: !this.state.admin });
  }

  siteView = () => this.setView('site', 'site');

  forceOnline = (fstate = null) => {

    const forceOnline =
      fstate === this.state.forceOnline
        ? null
        : fstate;

    this.setState({ forceOnline });
    if (forceOnline !== null) this.setOnline(forceOnline);
  }

  onSearch = id => {
    const p = getPath(id);
    //console.log("onSearch: ", id, p);

    this.setView("survey", p[0], p[1], p[2]);
  }

  render = () => {

    const view = this.state.view;
    const activepane = this.state.activepane || 'login';

    //console.log("fs online: ", this.state.online);

    return (
      <MainContext.Provider
        value={{
          setView: this.setView,
          prevView: this.prevView
        }}
      >
      <div id="fsmain">

        {this.state.topinfo &&
          <div className="fixed-bottom topinfobar" id="footerbar">
            {/*<span className="fa fa-circle" style={{color: this.state.online?'green':'red'}} />*/}
            <BSDrop icon="fa fa-circle" up small point={false} buttonStyle={{ color: this.state.online ? 'green' : 'red', marginBottom: '0.5rem' }}>
              {forceOnline.map(f =>
                <BSDropItem icon={f.id === this.state.forceOnline ? "fs-check" : "fa fa-circle"} iconStyle={{ color: f.color }} onClick={() => this.forceOnline(f.id)} key={f.id}>
                  {f.title}
                </BSDropItem>
              )}
            </BSDrop>
            {/*<Detector render={({ online }) => (
              <span className="fa fa-circle" style={{color: online?'green':'red'}} />
            )} />*/}
            {this.state.topinfo.map(info => <span key={info.id}><span className={info.icon} /> {info.info}</span>)}
          </div>
        }

        {activepane !== "login" && activepane !== 'loading' &&
          <nav id="fsmainnav" className={"navbar fixed-top"}>
            <ul className="nav nav-tabs">
              {this.tabs.filter(tab => (tab.view.includes(view) && !(this.state.adminsite && tab.id === 'org'))).map(tab =>
                <li className={"nav-item " + (tab.className || "")} key={tab.id}>
                  <button type="button" onClick={tab.onClick} className={`nav-link ${(tab.icon || '')} ${(activepane === tab.id ? ' active' : '')}`}>
                    <span className="tabTitle"> {tab.title}</span>
                  </button>
                </li>
              )}
            </ul>

            <div id="rightbuttons">
              {(view === "survey" && (!process.env.REACT_APP_HIDE_AUDITS || this.state.admin)) && <FButton title="Audits" icon="fs-clipboard" onClick={() => this.setView("survey", "audit")} active={activepane === 'audit'}></FButton>}
              {(view === "survey" && (!process.env.REACT_APP_HIDE_LOGS || this.state.admin)) && <FButton title="Logs" icon="fs-log" onClick={() => this.setView("survey", "log")} active={activepane === 'log'}></FButton>}
              {(view === "site" || view === "survey") && <GlobalHelp title="Help" />}
              {(view === "site" || view === "survey") && this.state.isSuper && <FButton title="Edit Assessment" icon={this.state.admin ? "fs-unlock-alt" : "fs-lock"} vcenter onClick={this.adminmode} />}
              {(view === "survey") && <FSearch onChange={this.onSearch} />}
              {this.state.adminsite
                ? <FButton title="Log Out" icon="fs-times" onClick={this.logoutclose} />
                : view === "survey"
                  ? <FButton title="Close Assessment" icon="fs-times" onClick={this.xbutton} />
                  : <SureButton title="Log Out" icon="fs-times" onClick={this.xbutton} >Log Out?</SureButton>
              }
            </div>
          </nav>
        }

        {activepane === "loading" && <Pane id="loadingpane" key="loginpane" title="Loading" icon="">
          <div style={{ textAlign: 'center', marginTop: '2rem' }}><span>{`Loading ${process.env.APPNAME || 'Firesmart'}...`}<img src={loadinggif} alt="Loading" /></span></div>
          {/*<div style={{textAlign: 'center'}}><Loading /></div>*/}
        </Pane>}
        {activepane === "login" && <Pane id="loginpane" key="loginpane" title="Login" icon="fs-user"><LoginPane login={this.login} email={this.state.email} userlogin={this.douserlogin} message={this.state.logoutreason} adminsite={this.state.adminsite} adminsitename={this.state.adminsitename} /></Pane>}
        {activepane === "org" && <Pane id="orgpane" key="orgpane" title="Org" icon="fs-sitemap"><OrgPane loadSite={this.loadSite} /></Pane>}
        {(view === "site" || view === "survey") &&
          <Pane id="sitepane" show={activepane === "site"}>
            <SitePane siteid={this.state.siteid} addSurvey={this.addSurvey} cloneSurvey={this.cloneSurvey} importSurvey={importSurvey} loadSurvey={this.loadSurvey} downloadSurvey={this.downloadSurvey} deleteSurvey={this.deleteSurvey} admin={this.state.admin} props={{ admin: this.state.admin && activepane === "site", onExpand: this.onExpand, expandid: this.state.expandid, itemid: this.state.itemid, siteView: this.siteView }} />
          </Pane>}
        {(view === "survey") &&
          <>
            <Pane id="assinfopane" show={activepane === "assinfo"} next={e => this.setView("survey", "risk")}>
              <RiskPane title="assinfo" types={siteinfotypes} surveyid={this.state.surveyid} props={{ admin: this.state.admin && activepane === "assinfo", onExpand: this.onExpand, expandid: this.state.expandid, itemid: this.state.itemid }} />
            </Pane>
            <Pane id="riskpane" show={activepane === "risk"} next={e => this.setView("survey", "equipment")}>
              <RiskPane title="risks" types={risktypes} surveyid={this.state.surveyid} props={{ admin: this.state.admin && activepane === "risk", onExpand: this.onExpand, expandid: this.state.expandid, itemid: this.state.itemid }} />
            </Pane>
            <Pane id="equipmentpane" show={activepane === "equipment"} next={e => this.setView("survey", "actionsummary")}>
              <RiskPane title="precautions" types={equipmenttypes} surveyid={this.state.surveyid} props={{ admin: this.state.admin && activepane === "equipment", isSuper: this.state.isSuper, onExpand: this.onExpand, expandid: this.state.expandid, itemid: this.state.itemid, setView: this.actionSetView }} />
            </Pane>
            <Pane id="actionsummarypane" show={activepane === "actionsummary"}>
              <RiskPane title="actionsummary" types={actiontypes} siteid={this.state.siteid} surveyid={this.state.surveyid} props={{ admin: this.state.admin && activepane === "actionsummary", setView: this.setView, selected: activepane === "actionsummary", siteid: this.state.siteid, surveyid: this.state.surveyid, onExpand: this.onExpand, expandid: this.state.expandid, itemid: this.state.itemid, online: this.state.online || false, syncing: this.state.syncing || false }} />
            </Pane>
            <Pane id="auditpane" show={activepane === "audit"}>
              <RiskPane title="audit" types={audittypes} siteid={this.state.siteid} surveyid={this.state.surveyid} props={{ admin: this.state.admin && activepane === "audit", siteid: this.state.siteid, surveyid: this.state.surveyid, onExpand: this.onExpand, expandid: this.state.expandid, itemid: this.state.itemid }} />
            </Pane>
            <Pane id="logpane" show={activepane === "log"}>
              <RiskPane title="log" types={logtypes} siteid={this.state.siteid} surveyid={this.state.surveyid} props={{ admin: this.state.admin && activepane === "log", siteid: this.state.siteid, surveyid: this.state.surveyid, onExpand: this.onExpand, expandid: this.state.expandid, itemid: this.state.itemid }} />
            </Pane>
          </>
        }

        <CacheBuster activepane={activepane} />

      </div>
      </MainContext.Provider>
    )
  }
}

export default Firesmart;