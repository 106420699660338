import React, { useEffect, useRef } from "react";

import { schema, defaultMarkdownParser, defaultMarkdownSerializer } from "prosemirror-markdown";
import { EditorState, /*Plugin,*/ PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { exampleSetup } from "prosemirror-example-setup"

import './css/editor.css';

const reactPropsKey = new PluginKey("reactProps");

/*function reactProps(initialProps) {
  return new Plugin({
    key: reactPropsKey,
    state: {
      init: () => initialProps,
      apply: (tr, prev) => tr.getMeta(reactPropsKey) || prev,
    },
  });
}*/

function Editor(props) {
  const viewHost = useRef();
  const view = useRef(null);

  //const [doc, setDoc] = useState(defaultMarkdownParser.parse(props.value));

  const { onChange, value } = props;

  useEffect(() => { // initial render

    const state = EditorState.create({ schema, doc: defaultMarkdownParser.parse(value), plugins: exampleSetup({ schema }) });

    view.current = new EditorView(viewHost.current, { 
      state,
      dispatchTransaction(transaction) {
        const { state, transactions } = view.current.state.applyTransaction(transaction);

        view.current.updateState(state);

        if(transactions.some(transaction => transaction.docChanged)) {
          onChange(defaultMarkdownSerializer.serialize(state.doc));
        }
      }
    });
    return () => view.current.destroy();
  }, [onChange, value]);

  useEffect(() => { // every render
    const tr = view.current?.state.tr.setMeta(reactPropsKey, props);
    view.current?.dispatch(tr);
  });

  return <div ref={viewHost} />;
}

//<Editor value={`Here is some markdown`} onChange={val=>console.log(defaultMarkdownSerializer.serialize(val))} />

export default Editor;